import React, { createRef, useEffect, useState } from 'react';

const Tour = (props) => {

    const {TourData, tourState, switchTourState, showTour, hideTour, propPos, startPrevTour, startNextTour, hasNextTour = false, hasPrevTour = false} = props;

    const TourRef = createRef();

    const [state, setState] = useState(0);
    const [topPosition, setTopPosition] = useState(0);
    const [leftPosition, setLeftPosition] = useState(0);

    //rhombus
    const [rhombusTopOffset, setRhombusTopOffset] = useState(75);
    const [rhombusLeftOffset, setRhombusLeftOffset] = useState(150);

    useEffect(()=>{
        if(showTour && Number.isInteger(state)){
            moveTour()
        }
    },[state, showTour])

    useEffect(()=>{
        if(Number.isInteger(tourState)){
            setState(tourState)
        }
    },[tourState, switchTourState])

    const moveTour = ()=>{

        let data = TourData[state];
        let position = data['position'];

        if(data.anchor !== null && data.anchor !== undefined && TourRef !== undefined && TourRef !== null){ 
        if(data.anchor.current !== null && data.anchor.current !== undefined && TourRef.current !== undefined && TourRef.current !== null){ 
            
            const rect = data.anchor.current.getBoundingClientRect();
            const tourRect = TourRef.current.getBoundingClientRect();
    
            if(position !== undefined && position !== null){
                switch(position){
                    case "left":
                        setLeftPosition(rect.left - ((tourRect.right - tourRect.left))-15)
                        setTopPosition(((rect.top + rect.bottom) / 2) - ((tourRect.bottom - tourRect.top) / 2))
                        setRhombusLeftOffset((tourRect.right-tourRect.left)-65)
                        setRhombusTopOffset(((tourRect.bottom - tourRect.top) / 2)-35)
                    break

                    case "right":
                    break

                    case "top":
                    break

                    case "bottom":
                    break

                    default:
                        setLeftPosition(((rect.left + rect.right) / 2) - ((tourRect.right - tourRect.left) / 2))
                        setTopPosition(((rect.top + rect.bottom) / 2) - ((tourRect.bottom - tourRect.top) / 2))
                        setRhombusLeftOffset(((tourRect.right - tourRect.left) / 2)-35)
                        setRhombusTopOffset(((tourRect.bottom - tourRect.top) / 2)-35)
                }
            }
        }
        }
    }

    const prev = () => {
        //const pos = state - 1;
        if (state > 0) {
            setState(state - 1);
        }else if(hasPrevTour){
            startPrevTour();
            end()
        }
    };

    const next = () => {
        if (state < TourData.length-1) {
            setState(state + 1);
        }else if(hasNextTour){
            startNextTour();
            end()
        }
    };

    const pause = () => {
        hideTour();
    }

    const end = () => {
        setState(0);
        hideTour()
    }

    return(
    <>
    {showTour &&
    <div className={"tourContainer"} style={{top: topPosition, left: leftPosition, position: propPos}} ref={TourRef}>
        <div style={{position: "absolute", width: "100%", height: "100%", top: "0", left: "0", zIndex: "-2"}}>
            <div class="rhombus" style={{top: rhombusTopOffset, left: rhombusLeftOffset}} ></div>
        </div>
        <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center"}}><span style={{color: 'white', textAlign: "center", paddingBottom: "3px"}}>{TourData[state].text}</span></div>
        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
            <div>
                {(state > 0 || ( state <= 0 && startPrevTour !== null && startPrevTour !== undefined)) &&
                    <button className="prevBtn" style={{
                        'border-top-right-radius': 0,
                        'border-bottom-right-radius': 0,
                    }} onClick={prev} > « Prev</button>
                }
                {(state < TourData.length-1 || (state >= TourData.length-1 && startNextTour !== null && startNextTour !== undefined)) &&
                    <button className="nextBtn" style={{
                        height: "100%",
                        'border-top-right-radius': 0,
                        'border-bottom-right-radius': 0
                    }} onClick={next}>Next »</button>
                }
                <button className={'pauseBtn'} style={{
                    'border-top-left-radius': 0,
                    'border-bottom-left-radius': 0,
                }} onClick={pause}>Pause</button>
                <button style={{ marginTop: '0', marginLeft: '3px'}}className="endTour" onClick={end}>End Tour</button>
            </div>
        </div>
    </div>
    }
    </>
    )
}

export default Tour
