import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import MuiTreeItem from "@material-ui/lab/TreeItem";
import { Formik, useField } from "formik";
import FolderIcon from '../../assets/image/folder.png';
import FolderOpenedIcon from '../../assets/image/folder.png';
import FolderIcons from '../../assets/image/folder3.png';
import MuiAccordion from '@material-ui/core/Accordion';
import ArrowIcon from '../../assets/image/sort-right-19g.png';
import ArrowDownIcon from '../../assets/image/sort-down-19g.png';
import { useSelector } from "react-redux";

const initialValues = {};

const useStyles = makeStyles({
	root: {
		// height: 110,
		//flexGrow: 1,
		maxWidth: 400,
		'&.MuiTreeItem-label': {
			backgroundColor: 'none !important'
		},
	},
	projectTitle: {
		margin: '0px 4px',
		position: 'relative',
		top: '2px',
		fontSize: '14px',
		padding: '0'
	},
	projTitleActive: {
		backgroundColor: '#337ab7',
		color: '#fff',
		padding: '3px',
		borderRadius: '3px'
	},
	folderIcon: {
		width: '15px'
	},
	folderIcons: {
		width: '3%'
	},
	noWrap:{
		whiteSpace: "nowrap",
		padding: '2px',
    	overflow: "hidden",
    	textOverflow: 'ellipsis',
    	zIndex: "351 !important",
	},
	StandWrap:{
		width: "full",
		padding: '2px',
    	backgroundColor: 'white',
    	whiteSpace: 'nowrap',
    	zIndex: '100',
	},
	StandWrapDark:{
		width: "full",
		padding: '2px',
    	backgroundColor: '#292929',
    	whiteSpace: 'nowrap',
    	zIndex: '100',
	}
});
const TreeItem = withStyles((theme) => ({
	root: {
	  "&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label": {
		backgroundColor: (props) => (props.toggle ? '#fff' : '#292929') + ' !important',
	  },
	},
	label: {
	  right: '19px',
	  paddingLeft: 0,
	},
  }))(MuiTreeItem);

const FolderTreeMenu = ({ items, parentCallback, selectedTitle, selectedTitleId, type, moveFolderCallback, expandedIds, infoFolderIds, moveFolderId }) => {
	const classes = useStyles();
	const [defaultTitle, setDefaultTitle] = useState('');
	const [defaultTitleId, setDefaultTitleId] = useState('');
	const [moveToFolderId, setMoveToFolderId] = useState('');
	const [selectTitle, setSelectTitle] = useState('');
	const [divStyle, setDivStyle] = useState(classes.noWrap);
	const toggle = useSelector(state => state.setToggle.toggle);
	//const [isOpen, setIsOpen] = useState(true);
	items.text_label = 'My Searches';
	expandedIds = expandedIds ? expandedIds : [];
	infoFolderIds = infoFolderIds ? infoFolderIds : [];

	useEffect(() => {
		setDefaultTitle(selectedTitle);
		setDefaultTitleId(selectedTitleId);
		setMoveToFolderId(moveFolderId);
	});
	function showString(str) {
		let getLength = str.length;
		if (getLength > 23) {
			str = str.substring(0, 23) + ' ...';
		}
		return str;

	}
	const renderTree = (nodes, onSelect, type) =>
		<TreeItem
			key={nodes.id}
			nodeId={nodes.id}
			toggle={toggle}
			label={
				<div className = {divStyle}>
					<>
						{/* <FolderOpenIcon /> {nodes.text_label} */}
						{/* <a className="cursorPointer text-decoration-none appTextColor" onClick={() => changeTitle(nodes.text_label)}> */}
						{type === "selectFolder" && <a className="cursorPointer text-decoration-none appTextColor" onMouseEnter={() =>{setDivStyle(toggle? classes.StandWrap: classes.StandWrapDark)}} onMouseLeave={() =>{setDivStyle(classes.noWrap)}}>
						    <img src={nodes.isOpen ? ArrowDownIcon : ArrowIcon} className={classes.folderIcon}/> <img src={nodes.isOpen ? FolderOpenedIcon : FolderIcon} className={classes.folderIcon}/> <span className={classes.projectTitle + ' ' + (defaultTitleId === nodes.id ? classes.projTitleActive : '')} title={nodes.text_label}>{showString(nodes.text_label)}</span></a>
						}
						{type === "moveFolder" && <a className="cursorPointer text-decoration-none appTextColor">
							<img src={nodes.isOpen ? ArrowDownIcon : ArrowIcon} className={classes.folderIcon}/> <img src={nodes.isOpen ? FolderOpenedIcon : FolderIcon} className={classes.folderIcons} /> <span className={classes.projectTitle + ' ' + (moveFolderId === nodes.id ? classes.projTitleActive : '')} title={nodes.text_label}>{showString(nodes.text_label)}</span></a>
						}
					</>
				</div>
			}
			onLabelClick={() => {
				window.scrollTo(0, 0);
				onSelect(nodes.id, nodes.text_label);
				setSelectTitle(nodes.text_label);
				if(nodes['isOpen']){
					nodes['isOpen'] = false;
				}else{
					nodes['isOpen'] = true;
				}
				if (type === "selectFolder") {
					parentCallback(nodes);
				} else if (type === "moveFolder") {
					parentCallback(nodes);
					moveFolderCallback(nodes);
				}

			}}
			onIconClick={() => {
				onSelect(nodes.id, nodes.text_label);
				setSelectTitle(nodes.text_label);
				if (type === "selectFolder") {
					parentCallback(nodes);
				} else if (type === "moveFolder") {
					parentCallback(nodes);
					moveFolderCallback(nodes);
				}
			}}
		>
			{(Array.isArray(nodes.children) && (type === "moveFolder" || (type === "selectFolder" && nodes['isOpen'])))
				? nodes.children.map(node => renderTree(node, onSelect, type))
				: null}
		</TreeItem>

		


	initialValues["yellow"] = 'My Searches';

	return (
		<Formik className={classes.root} initialValues={initialValues}>
			{props => (
				<>
					<Tree
						data={items}
						setFieldValue={props.setFieldValue}
						renderTree={renderTree}
						type={type}
						expandedIds={expandedIds}
						infoFolderIds={infoFolderIds}
					/>
					{/* <pre>{JSON.stringify(props.values, null, 2)}</pre> */}
				</>
			)}
		</Formik>
	);
};

export default FolderTreeMenu;

const Tree = props => {
	return (
		<>
			{props.type === "selectFolder" && props.infoFolderIds.length == 0 && <TreeView
				// defaultCollapseIcon={<ExpandMoreIcon />}
				// defaultExpanded={["My Searches"]}
				defaultExpanded={props.expandedIds}
			// expanded={props.infoFolderIds}
			// defaultExpandIcon={<ChevronRightIcon />}
			// multiSelect
			>
				{props.renderTree(props.data, props.setFieldValue, props.type)}
			</TreeView>
			}
			{props.type === "selectFolder" && props.infoFolderIds.length > 0 && <TreeView
				// defaultCollapseIcon={<ExpandMoreIcon />}
				// defaultExpanded={["My Searches"]}
				defaultExpanded={props.expandedIds}
				expanded={props.infoFolderIds}
			// defaultExpandIcon={<ChevronRightIcon />}
			// multiSelect
			>
				{props.renderTree(props.data, props.setFieldValue, props.type)}
			</TreeView>
			}
			{props.type === "moveFolder" && <TreeView
				// defaultCollapseIcon={<ExpandMoreIcon />}
				// defaultExpanded={["root"]}
				// defaultExpanded={props.expandedIds}
				defaultExpanded={props.expandedIds}
				expanded={props.infoFolderIds}
				//expanded={props.expandedIds}
			// defaultExpandIcon={<ChevronRightIcon />}
			// multiSelect
			>
				{props.renderTree(props.data, props.setFieldValue, props.type)}
			</TreeView>
			}
			<pre>{JSON.stringify(props.values, null, 2)}</pre>
		</>
	);
};

