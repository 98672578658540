import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';

import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	modalClassConDSI: {
		position: 'absolute',
		width: '105%',
		padding: '35px 15px 0px',
		right: 'auto',
		bottom: 'auto',
	},
	modalClassConDSIDark: {
		position: 'absolute',
		width: '105%',
		padding: '35px 15px 0px',
		right: 'auto',
		bottom: 'auto',
		backgroundColor: '#292929'
	},
	modalBoxContent: {
		maxHeight: '600px',
	},
	modalText: {
		position: 'absolute',
		top: '10px',
		right: '10px'
	},
}));

const DiscoverExportChoicesModal = props => {
	const [choice, setChoice] = useState('all');
	const [option, setOption] = useState('sf');
	const classes = useStyles();
	const toggle = useSelector(state => state.setToggle.toggle);

	const callback = () => {
		props.actionModal(props.fieldConfig, props.filter, props.resInfo, props.downloadType, choice, option);
	}

	const changeSelection = (e) => {
		setChoice(e.target.value);
	}

	const changeOption = (e) => {
        setOption(e.target.value);
    }

	return (
		<Modal
			size="lg"
			aria-labelledby="contained-modal-title-vcente"
			centered
			show={props.showModal}
			contentClassName={toggle ? classes.modalClassConDSI : classes.modalClassConDSIDark}
		>
			<Link href="#" onClick={(e) => e.preventDefault()} className={classes.modalText}>
				<CloseIcon  style = {{ fill : '#777777'}} onClick={props.closeModal} />
			</Link>
			<br></br>
			<div className={toggle? "jumbotron appTextColor": "jumbotronDark appTextColor" } >
				<h2 >You are about to export alignments to {props.downloadTypeDesc}</h2>
				{props.downloadType === 'Clustal' &&
				<p className="mb-3" style={{fontSize: '21px',fontFamily: 'Helvetica', fontWeight: '200', wordSpacing: '-2px' }}>
                    Your Clustal file is generated in the background.
                    You can continue to work on your results.
                    As soon as it is ready, we will open a new tab for you to review.
                </p>}
				<div className="filter-widget-textarea">
					<label style={{padding: '0px'}}>Selections:</label>
					{props.selectedAlignmentsCount > 0 &&
						<div>
							<label style={{padding: '0px'}}>
								<input id="selected" type="radio" value="selected" name="exportGroup" defaultChecked={choice === 'selected'} onChange={changeSelection}/>
								<span htmlFor="selected"> {Number(props.selectedAlignmentsCount).toLocaleString()} selected alignment(s)</span>
							</label>
						</div>
					}
					{props.displayCustomSelection &&
						<div>
							<label style={{padding: '0px'}}>
								<input id="custom" type="radio" value="custom" name="exportGroup" defaultChecked={choice === 'custom'} onChange={changeSelection}/>
								<span htmlFor="custom"> {props.customSelectionLabel}</span>
							</label>
						</div>
					}
					<div>
						<label style={{padding: '0px'}}>
							<input id="all" type="radio" value="all" name="exportGroup" defaultChecked={choice === 'all'} onChange={changeSelection}/>
							<span htmlFor="all"> All {Number(props.totalAlignmentNumber).toLocaleString()} alignment(s)</span>
						</label>
					</div>
				</div>

                {props.downloadType === 'Clustal' && <br></br>}
				{props.downloadType === 'Clustal' && <div className="filter-widget-textarea">
                    <label style={{padding: '0px'}}>Options:</label>
                    <div>
                        <label style={{padding: '0px'}}>
                            <input id="sf" type="radio" value="sf" name="exportOption" defaultChecked={option === 'sf'} onChange={changeOption}/>
                            <span htmlFor="sf"> Both Query Sequences and Subject Matching Fragments</span>
                        </label>
                    </div>
                    <div>
                        <label style={{padding: '0px'}}>
                            <input id="both" type="radio" value="both" name="exportOption" defaultChecked={option === 'both'} onChange={changeOption}/>
                            <span htmlFor="both"> Both Query and Subject Sequences</span>
                        </label>
                    </div>
                    <div>
                        <label style={{padding: '0px'}}>
                            <input id="qs" type="radio" value="qs" name="exportOption" defaultChecked={option === 'qs'} onChange={changeOption}/>
                            <span htmlFor="qs"> Query Sequences</span>
                        </label>
                    </div>
                    <div>
                        <label style={{padding: '0px'}}>
                            <input id="ss" type="radio" value="ss" name="exportOption" defaultChecked={option === 'ss'} onChange={changeOption}/>
                            <span htmlFor="ss"> Subject Sequences</span>
                        </label>
                    </div>
                </div>
                }

				<br></br>
				{choice === 'selected' && props.downloadType !== 'Clustal' && props.selectedAlignmentsCount > 20000 &&
					<b style={{color: 'red'}}>
						Maximum data export limit reached. 20,000 of {Number(props.selectedAlignmentsCount).toLocaleString()} alignments will be exported.
					</b>
				}
				{choice === 'selected' && props.downloadType === 'Clustal' && props.selectedAlignmentsCount > 2000 &&
					<b style={{color: 'red'}}>
						Maximum data export limit reached. Only first 2,000 of {Number(props.selectedAlignmentsCount).toLocaleString()} alignments will be exported.
					</b>
				}
				{choice === 'all' && props.downloadType !== 'Clustal' && props.totalAlignmentNumber > 20000 &&
					<b style={{color: 'red'}}>
						Maximum data export limit reached. 20,000 of {Number(props.totalAlignmentNumber).toLocaleString()} records will be exported.
					</b>
				}
				{choice === 'all' && props.downloadType === 'Clustal' && props.totalAlignmentNumber > 2000 &&
					<b style={{color: 'red'}}>
						Maximum data export limit reached. Only first 2,000 of {Number(props.totalAlignmentNumber).toLocaleString()} records will be exported.
					</b>
				}
				{choice === 'custom' && props.downloadType !== 'Clustal' && props.customTtlCount > 20000 &&
					<b style={{color: 'red'}}>
						Maximum data export limit reached. 20,000 of {Number(props.customTtlCount).toLocaleString()} results will be exported.
					</b>
				}
				{choice === 'custom' && props.downloadType === 'Clustal' && props.customTtlCount > 2000 &&
					<b style={{color: 'red'}}>
						Maximum data export limit reached. Only first 2,000 of {Number(props.customTtlCount).toLocaleString()} results will be exported.
					</b>
				}
				<br></br>
				<div style={{padding: '10px'}}>
					{props.totalAlignmentNumber > 0 &&
					<button onClick={callback} className="pull-right large-button gqblue">Start Export</button>
					}
					<button onClick={props.closeModal} className="pull-right large-button grey">Cancel</button>
				</div>
			</div>
		</Modal>
	);
}

export default DiscoverExportChoicesModal;
