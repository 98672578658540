import Modal from 'react-bootstrap/Modal'
import _ from "lodash";
import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import TextInput from '../../shared/Fields/TextInput';
import fieldConfig from '../../components/Discover/FieldConfig';
import {FiChevronDown,FiChevronUp,FiChevronRight,FiChevronLeft} from "react-icons/fi";
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    modalClassContent: {
        position: 'absolute',
        width: '100%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -0%)'
    },
    modalClassContentDark: {
        position: 'absolute',
        width: '100%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -0%)',
        backgroundColor: '#292929' 
    },
    modalBoxContent: {
        maxHeight: '675px',
    },
    modalHeader: {
        borderBottom: 'none !important',
        position: 'absolute',
        right: '5px',
        top: '3px',
        zIndex: '1',
        display: "block !important"
    },
    modalHeaderDark: {
        borderBottom: 'none !important',
        position: 'absolute',
        right: '5px',
        top: '3px',
        zIndex: '1',
        display: "block !important",
        backgroundColor: '#292929'
    },
    colorContainer: {
        //backgroundColor: '#EEEEEE',
        textAlign: 'left',
        margin: '15px 0 12px',
        padding: '15px 15px 0px 15px',
        borderRadius: '5px',
    },
    colorContainerDark: {
        //backgroundColor: '#EEEEEE',
        textAlign: 'left',
        margin: '15px 0 12px',
        padding: '15px 15px 0px 15px',
        borderRadius: '5px',
        backgroundColor: '#292929'
    },
    closeButton: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
}))

  let currentTemplate;
const DisplayColumnModal = props => {
    const classes = useStyles();
    const { t } = useTranslation('common');
    const [selectedDispOption, setSelectedDispOption] = useState();
    const [selectedAvlOption, setSelectedAvlOption] = useState([]);
   // const [selectedAvlOption, setSelectedAvlOption] = useState([]);
    const [saveView, setSaveView] = useState();
    const [alertButton, setAlertButton] = useState(false);
    const [seed, setSeed] = useState(1);
    const toggle = useSelector(state => state.setToggle.toggle)
    let flatarray = fieldConfig.basicFieldConfig['flat'];
    let sinarray = fieldConfig.basicFieldConfig['sin'];
    let docarray = fieldConfig.basicFieldConfig['doc'];
    let famarray = fieldConfig.basicFieldConfig['fam'];
    let ufsarray = fieldConfig.basicFieldConfig['ufs'];
    let ssvarray = fieldConfig.basicFieldConfig['ssv'];
    let casarray = fieldConfig.basicFieldConfig['cas'];
    let qsarray = fieldConfig.basicFieldConfig['qs'];
    let hsparray = fieldConfig.basicFieldConfig['hsp'];
    let aliarray = fieldConfig.basicFieldConfig['ali'];
    let npaarray = fieldConfig.basicFieldConfig['npa'];
    let gnamearray = fieldConfig.basicFieldConfig['gname'];
    let orgarray = fieldConfig.basicFieldConfig['org'];
    let algo = props.algorithm;
    let isAB = props.isAbWorkflow;
    useEffect((props) => {
       
        //currentTemplate = fieldConfig.userTableTemplate;
      },[props.showModal]);

    
      const resetseed = () => {
        setSeed(Math.random());
    };
  
    
    fieldConfig.tableDisplayModeFromView = fieldConfig.tableDisplayMode;
    
    function getDivOptions() {
        let kk = props.fieldConfig.userTableTemplate;
         return Object.keys(kk).map((type,group) => {
            if(props.fieldConfig.typeIsInDisplayMode(type))
                 return(
                    <optgroup  className="field-optgroup-sub" label={fieldConfig.getTypeDescription(type)}>
                       
                    {Object.keys(kk[type]).map((i) => (
                            <option key={kk[type][i]} className= "field-option" value={type +"|"+ (kk[type][i])}
                            onDoubleClick={e => handleDoubleClickDispCols(e,type,(kk[type][i]))}  dangerouslySetInnerHTML={{__html: fieldConfig.getFieldName(kk[type][i],algo,isAB)}}>
                                {/*(fieldConfig.getFieldName(kk[type][i],algo,isAB))*/}	
                                </option>
                    )
                    )}
                    </optgroup>  
                    )
                return(null)
            
             });
            
     }

     const handleclick = () => {  
        fieldConfig.newViewName=saveView;      
        fieldConfig.saveView(fieldConfig,saveView, props.filter);
         props.saveViews();
    }


      const handleCancel = () => {
       //fieldConfig.userTableTemplate = currentTemplate;
       fieldConfig.newViewName = '';
       setSaveView('');
       setAlertButton(false);
       props.cancelModal();
       props.closeModal();
        
      };

      const handleApply = () => {
        
        fieldConfig.newViewName = '';
        setSaveView('');
        setAlertButton(false);
        {props.columns()};
        {props.closeModal()};
         
       };

      const handleview = (e) => {
        setSaveView(e.target.value)
        fieldConfig.newViewName = e.target.value;
        if(e.target.value.length > 0){setAlertButton(true);}
        else{setAlertButton(false); }

      };

      const modalLoaded = () => {
        //this is called only when modal is loaded, used for cancel button later
        currentTemplate = _.cloneDeep(props.fieldConfig.userTableTemplate);
        setSaveView(fieldConfig.newViewName);
        if(fieldConfig.newViewName !== ''){
            setAlertButton(true);
        }
      };


      const handleRight = (e) => { 
        props.fieldConfig.addFieldsToTemplate(selectedAvlOption, props.fieldConfig.userTableTemplate);
        setSeed(Math.random());
       
      };

      const handleLeft = (e) => { 
        fieldConfig.delFieldsFromTemplate(selectedDispOption, props.fieldConfig.userTableTemplate);
        setSeed(Math.random());
       
      };

      const handleUp = (e) => { 
        fieldConfig.promiseMoveFieldsUp(selectedDispOption, props.fieldConfig.userTableTemplate);
        setSeed(Math.random());
        //let element = document.activeElement.tagName;
        //console.log(element,"element")  ; 
       
      };

      const handleDown = (e) => { 
        fieldConfig.promiseMoveFieldsDown(selectedDispOption, props.fieldConfig.userTableTemplate);
        setSeed(Math.random());
       
      };

      const handleAvlChange = (e) => {
        let value = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedAvlOption(value);
       
      };

      const handleDblClickAvl = (e,type,field) => {
        fieldConfig.addFieldToTemplate(type,field, props.fieldConfig.userTableTemplate);
        setSeed(Math.random());

      };

      const handleDoubleClickDispCols = (e,type,field) => {
        fieldConfig.removeFieldFromTemplate(type,field,props.fieldConfig.userTableTemplate)
        //fieldConfig.refreshCols();
        resetseed();
      };

      const handleSelChange = (e) => {
        let value = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedDispOption(value);
      };


  
    return (
        <Modal 
            show={props.showModal}
            fieldConfig={props.fieldConfig}
            size="lg"
            aria-labelledby="contained-modal-title-vcente"
            centered
            contentClassName={toggle ? classes.modalClassContent : classes.modalClassContentDark}
            className={classes.modalBoxContent}
            onEntered={modalLoaded}
        >
            
            <div className={toggle ? classes.modalHeader: classes.modalHeaderDark}>
                <div className={classes.closeButton} href="#" onClick={(e) => e.preventDefault()}>
                    <CloseIcon onClick={props.closeModal} />
                </div>
            </div>
            <Modal.Body className="appTextColor text-center">
                <div className={classes.colorContainer}>
                <table>
                <tbody>
                    <tr>
                        <td colSpan="3" className="really-large-font field-optgroup"> {fieldConfig.getDisplayModeDescription()} View
                            <div className="pull-right">
                                
                            {fieldConfig.checkViewNameUsed()=== -1 ? (
                                <button
                                    className={alertButton ? 'normal-button gqblue-alert' : 'normal-button'}
                                    style={toggle ? { marginTop: '10px', fontSize: '13px', fontWeight: 'bold' }: {marginTop: '10px', fontSize: '13px', fontWeight: 'bold', color: '#292929'}}
                                    onClick={handleclick}
                                >
                                    Save
                                </button>
                            ):
                            (
                                <button
                                    className={alertButton ? 'normal-button gqblue-alert' : 'normal-button'}
                                    style={toggle ? { marginTop: '10px', fontSize: '13px', fontWeight: 'bold' }: { marginTop: '10px', fontSize: '13px', fontWeight: 'bold', color: '#292929'}}
                                    onClick={handleclick}
                                >
                                    {alertButton ? 'Overwrite' : 'Save'}
                                </button>
                            )
                            }


                                <TextInput style= {{marginTop:'210x'}}
                                        spellcheck="false"
                                        id="viewname"
                                        name="viewname"
                                        label="Name your View"
                                        variant="outlined"
                                        className= "view-widget-input"
                                        value={saveView}
                                        onChange={(e) => handleview(e)}
                                    />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="large-font field-container-label">All Available Columns</div>
                            <select multiple={true} className="field-container" 
                             //value={selectedAvlOption}
                             //onDoubleClick={handleDblClickAvl}
                             onChange={handleAvlChange}
                             style={toggle ? {backgroundColor:''}: {backgroundColor: '#292929'}}
                             >

                            {/*For cas views */}
                            {(fieldConfig.tableDisplayModeFromView === 'cas')  ?
                                <optgroup className="field-optgroup-new" label={fieldConfig.getTypeDescription('cas')}>
                                    </optgroup> :
                                null
                            
                            }
                                {Object.keys(casarray).map((f) => {
                                     if((fieldConfig.tableDisplayModeFromView==='cas') )
                                                 return(
                                                    <optgroup  className="field-optgroup-sub" label={casarray[f].group}>
                                                    {Object.keys(casarray[f].fields).map((i) => {
                                                        if(!fieldConfig.fieldIsInTableTemplate('cas',(casarray[f].fields[i])))
                                                        return(
                                                            <option className= "field-option" value={"cas|"+casarray[f].fields[i]}
                                                            onDoubleClick={e => handleDblClickAvl(e,'cas',(casarray[f].fields[i]))}
                                                            dangerouslySetInnerHTML={{__html: fieldConfig.getFieldName((casarray[f].fields[i]))}}>
                                                                {/*fieldConfig.getFieldName((casarray[f].fields[i]))*/}</option>
                                                        )
                                                        return(null)
                                                    }
                                                    )}
                                                    </optgroup>  
                                                    )
                                                    return(null)
                                            
                                            })
                                }

                                {/*For Query Sequence views */}
                                {(fieldConfig.tableDisplayModeFromView === 'qs')  ?
                                    <optgroup className="field-optgroup-new" label={fieldConfig.getTypeDescription('qs')}>
                                    </optgroup> :
                                    null

                                }
                                {Object.keys(qsarray).map((f) => {
                                    if((fieldConfig.tableDisplayModeFromView==='qs') )
                                        return(
                                            <optgroup  className="field-optgroup-sub" label={qsarray[f].group}>
                                                {Object.keys(qsarray[f].fields).map((i) => {
                                                        if(!fieldConfig.fieldIsInTableTemplate('qs',(qsarray[f].fields[i])))
                                                            return(
                                                                <option className= "field-option" value={"qs|"+qsarray[f].fields[i]}
                                                                        onDoubleClick={e => handleDblClickAvl(e,'qs',(qsarray[f].fields[i]))}>
                                                                    {fieldConfig.getFieldName((qsarray[f].fields[i]))}</option>
                                                            )
                                                        return(null)
                                                    }
                                                )}
                                            </optgroup>
                                        )
                                    return(null)

                                })
                                }

                                {/*For Blast HSPs views */}
                                {(fieldConfig.tableDisplayModeFromView === 'hsp')  ?
                                    <optgroup className="field-optgroup-new" label={fieldConfig.getTypeDescription('hsp')}>
                                    </optgroup> :
                                    null

                                }
                                {Object.keys(hsparray).map((f) => {
                                    if((fieldConfig.tableDisplayModeFromView==='hsp') )
                                        return(
                                            <optgroup  className="field-optgroup-sub" label={hsparray[f].group}>
                                                {Object.keys(hsparray[f].fields).map((i) => {
                                                        if(!fieldConfig.fieldIsInTableTemplate('hsp',(hsparray[f].fields[i])))
                                                            return(
                                                                <option className= "field-option" value={"hsp|"+hsparray[f].fields[i]}
                                                                        onDoubleClick={e => handleDblClickAvl(e,'hsp',(hsparray[f].fields[i]))}>
                                                                    {fieldConfig.getFieldName((hsparray[f].fields[i]))}</option>
                                                            )
                                                        return(null)
                                                    }
                                                )}
                                            </optgroup>
                                        )
                                    return(null)

                                })
                                }

                                {/*For Blast Alignments views */}
                                {(fieldConfig.tableDisplayModeFromView === 'ali')  ?
                                    <optgroup className="field-optgroup-new" label={fieldConfig.getTypeDescription('ali')}>
                                    </optgroup> :
                                    null

                                }
                                {Object.keys(aliarray).map((f) => {
                                    if((fieldConfig.tableDisplayModeFromView==='ali') )
                                        return(
                                            <optgroup  className="field-optgroup-sub" label={aliarray[f].group}>
                                                {Object.keys(aliarray[f].fields).map((i) => {
                                                        if(!fieldConfig.fieldIsInTableTemplate('ali',(aliarray[f].fields[i])))
                                                            return(
                                                                <option className= "field-option" value={"ali|"+aliarray[f].fields[i]}
                                                                        onDoubleClick={e => handleDblClickAvl(e,'ali',(aliarray[f].fields[i]))}>
                                                                    {fieldConfig.getFieldName((aliarray[f].fields[i]))}</option>
                                                            )
                                                        return(null)
                                                    }
                                                )}
                                            </optgroup>
                                        )
                                    return(null)

                                })
                                }

                                {/*For Normalize Patent Assignee views */}
                                {(fieldConfig.tableDisplayModeFromView === 'npa')  ?
                                    <optgroup className="field-optgroup-new" label={fieldConfig.getTypeDescription('npa')}>
                                    </optgroup> :
                                    null

                                }
                                {Object.keys(npaarray).map((f) => {
                                    if((fieldConfig.tableDisplayModeFromView==='npa') )
                                        return(
                                            <optgroup  className="field-optgroup-sub" label={npaarray[f].group}>
                                                {Object.keys(npaarray[f].fields).map((i) => {
                                                        if(!fieldConfig.fieldIsInTableTemplate('npa',(npaarray[f].fields[i])))
                                                            return(
                                                                <option className= "field-option" value={"npa|"+npaarray[f].fields[i]}
                                                                        onDoubleClick={e => handleDblClickAvl(e,'npa',(npaarray[f].fields[i]))}>
                                                                    {fieldConfig.getFieldName((npaarray[f].fields[i]))}</option>
                                                            )
                                                        return(null)
                                                    }
                                                )}
                                            </optgroup>
                                        )
                                    return(null)

                                })
                                }

                                {/*For Subject database views */}
                                {(fieldConfig.tableDisplayModeFromView === 'gname')  ?
                                    <optgroup className="field-optgroup-new" label={fieldConfig.getTypeDescription('gname')}>
                                    </optgroup> :
                                    null

                                }
                                {Object.keys(gnamearray).map((f) => {
                                    if((fieldConfig.tableDisplayModeFromView==='gname') )
                                        return(
                                            <optgroup  className="field-optgroup-sub" label={gnamearray[f].group}>
                                                {Object.keys(gnamearray[f].fields).map((i) => {
                                                        if(!fieldConfig.fieldIsInTableTemplate('gname',(gnamearray[f].fields[i])))
                                                            return(
                                                                <option className= "field-option" value={"gname|"+gnamearray[f].fields[i]}
                                                                        onDoubleClick={e => handleDblClickAvl(e,'gname',(gnamearray[f].fields[i]))}>
                                                                    {fieldConfig.getFieldName((gnamearray[f].fields[i]))}</option>
                                                            )
                                                        return(null)
                                                    }
                                                )}
                                            </optgroup>
                                        )
                                    return(null)

                                })
                                }

                                {/*For Normalize Organism views */}
                                {(fieldConfig.tableDisplayModeFromView === 'org')  ?
                                    <optgroup className="field-optgroup-new" label={fieldConfig.getTypeDescription('org')}>
                                    </optgroup> :
                                    null

                                }
                                {Object.keys(orgarray).map((f) => {
                                    if((fieldConfig.tableDisplayModeFromView==='org') )
                                        return(
                                            <optgroup  className="field-optgroup-sub" label={orgarray[f].group}>
                                                {Object.keys(orgarray[f].fields).map((i) => {
                                                        if(!fieldConfig.fieldIsInTableTemplate('org',(orgarray[f].fields[i])))
                                                            return(
                                                                <option className= "field-option" value={"org|"+orgarray[f].fields[i]}
                                                                        onDoubleClick={e => handleDblClickAvl(e,'org',(orgarray[f].fields[i]))}>
                                                                    {fieldConfig.getFieldName((orgarray[f].fields[i]))}</option>
                                                            )
                                                        return(null)
                                                    }
                                                )}
                                            </optgroup>
                                        )
                                    return(null)

                                })
                                }

                            {/*For Subject Sequence views */}
                            {(fieldConfig.tableDisplayModeFromView === 'ssv')  ?
                                <optgroup className="field-optgroup-new" label={fieldConfig.getTypeDescription('ssv')}>
                                    </optgroup> :
                                null
                            
                            }
                                {Object.keys(ssvarray).map((f) => {
                                     if((fieldConfig.tableDisplayModeFromView==='ssv') )
                                                 return(
                                                    <optgroup  className="field-optgroup-sub" label={ssvarray[f].group}>
                                                    {Object.keys(ssvarray[f].fields).map((i) => {
                                                        if(!fieldConfig.fieldIsInTableTemplate('ssv',(ssvarray[f].fields[i])))
                                                        return(
                                                            <option className= "field-option" value={"ssv|"+ssvarray[f].fields[i]}
                                                            onDoubleClick={e => handleDblClickAvl(e,'ssv',(ssvarray[f].fields[i]))}>
                                                                {fieldConfig.getFieldName((ssvarray[f].fields[i]))}</option>
                                                        )
                                                        return(null)
                                                        }
                                                    )}
                                                    </optgroup>  
                                                    )
                                                    return(null)
                                            
                                            })
                                }

                            {/*For UFS views, Unique Family Sequence group */}
                            {(fieldConfig.tableDisplayModeFromView === 'ufs')  ?
                                <optgroup className="field-optgroup-new" label={fieldConfig.getTypeDescription('ufs')}>
                                    </optgroup> :
                                null
                            
                            }
                                {Object.keys(ufsarray).map((f) => {
                                     if((fieldConfig.tableDisplayModeFromView==='ufs') )
                                                 return(
                                                    <optgroup  className="field-optgroup-sub" label={ufsarray[f].group}>
                                                    {Object.keys(ufsarray[f].fields).map((i) => {
                                                        if(!fieldConfig.fieldIsInTableTemplate('ufs',(ufsarray[f].fields[i])))
                                                        return(
                                                            <option className= "field-option" value={"ufs|"+ufsarray[f].fields[i]}
                                                            onDoubleClick={e => handleDblClickAvl(e,'ufs',(ufsarray[f].fields[i]))}>
                                                                {fieldConfig.getFieldName((ufsarray[f].fields[i]))}</option>
                                                        )
                                                        return(null)

                                                    }
                                                    )}
                                                    </optgroup>  
                                                    )
                                                    return(null)
                                            
                                            })
                                }

                            {/*For PF/UFS views, Patent Family group */}
                            {(fieldConfig.tableDisplayModeFromView === 'ufs' ||
                                fieldConfig.tableDisplayModeFromView === 'family' )  ?
                                <optgroup className="field-optgroup-new" label={fieldConfig.getTypeDescription('fam')}>
                                    </optgroup> :
                                null
                            
                            }
                                {Object.keys(famarray).map((f) => {
                                     if((fieldConfig.tableDisplayModeFromView==='ufs' || fieldConfig.tableDisplayModeFromView === 'family' ) )
                                                 return(
                                                    <optgroup  className="field-optgroup-sub" label={famarray[f].group}>
                                                    {Object.keys(famarray[f].fields).map((i) => {
                                                        if(!fieldConfig.fieldIsInTableTemplate('fam',(famarray[f].fields[i])))
                                                        return(
                                                            <option className= "field-option" value={"fam|"+famarray[f].fields[i]} 
                                                            onDoubleClick={e => handleDblClickAvl(e,'fam',(famarray[f].fields[i]))} >
                                                                {fieldConfig.getFieldName((famarray[f].fields[i]))}</option>
                                                        )
                                                        return(null)
                                                    }
                                                    )}
                                                    </optgroup>  
                                                    )
                                                    return(null)
                                            
                                            })
                                }

                            {/*For For PN/PF views, Patent Document group */}
                            {(fieldConfig.tableDisplayModeFromView === 'document' ||
                                fieldConfig.tableDisplayModeFromView === 'family' )  ?
                                <optgroup className="field-optgroup-new" label={fieldConfig.getTypeDescription('doc')}>
                                    </optgroup> :
                                null
                            
                            }
                                {Object.keys(docarray).map((f) => {
                                     if((fieldConfig.tableDisplayModeFromView==='document' ) )
                                                 return(
                                                    <optgroup  className="field-optgroup-sub" label={docarray[f].group}>
                                                    {Object.keys(docarray[f].fields).map((i) => {
                                                        if(!fieldConfig.fieldIsInTableTemplate('doc',(docarray[f].fields[i])))
                                                        return(
                                                            <option className= "field-option" value={"doc|"+docarray[f].fields[i]}
                                                            onDoubleClick={e => handleDblClickAvl(e,'doc',(docarray[f].fields[i]))} >
                                                                {fieldConfig.getFieldName((docarray[f].fields[i]))}</option>
                                                        )
                                                        return(null)
                                                    }
                                                    )}
                                                    </optgroup>  
                                                    )
                                                    return(null)
                                            
                                            })
                                }
                                {Object.keys(docarray).map((f) => {
                                     if((fieldConfig.tableDisplayModeFromView==='family' && docarray[f].group!=='Patent Family' ) )
                                                 return(
                                                    <optgroup  className="field-optgroup-sub" label={docarray[f].group}>
                                                    {Object.keys(docarray[f].fields).map((i) => {
                                                        if(!fieldConfig.fieldIsInTableTemplate('doc',(docarray[f].fields[i])))
                                                        return(    
                                                            <option className= "field-option" value={"doc|"+docarray[f].fields[i]}
                                                            onDoubleClick={e => handleDblClickAvl(e,'doc',(docarray[f].fields[i]))} >
                                                                {fieldConfig.getFieldName((docarray[f].fields[i]))}</option>
                                                        )
                                                        return(null)
                                                    }       
                                                    )}
                                                    </optgroup>  
                                                    )
                                                    return(null)
                                            
                                            })
                                }


                                {/*For Alignment view, Patent Document group */}
                                {(fieldConfig.tableDisplayModeFromView === 'ssv' ||
                                    fieldConfig.tableDisplayModeFromView === 'qs' || fieldConfig.tableDisplayModeFromView === 'hsp'
                                    || fieldConfig.tableDisplayModeFromView === 'ali'|| fieldConfig.tableDisplayModeFromView === 'npa'
                                    || fieldConfig.tableDisplayModeFromView === 'gname' || fieldConfig.tableDisplayModeFromView === 'org') ?
                                    <optgroup className="field-optgroup-new" label={fieldConfig.getTypeDescription('doc')}>
                                    </optgroup> :
                                    null

                                }
                                {Object.keys(flatarray).map((f) => {
                                    if((fieldConfig.tableDisplayModeFromView==='ssv' || fieldConfig.tableDisplayModeFromView==='qs'
                                            || fieldConfig.tableDisplayModeFromView==='hsp'
                                            || fieldConfig.tableDisplayModeFromView === 'ali'|| fieldConfig.tableDisplayModeFromView === 'npa'
                                            || fieldConfig.tableDisplayModeFromView === 'gname' || fieldConfig.tableDisplayModeFromView === 'org') &&
                                        (flatarray[f].group ==='Patent Information' || flatarray[f].group ==='Legal Statuses'
                                            || flatarray[f].group ==='Inventors & Assignees' || flatarray[f].group ==='Dates & Documents' || flatarray[f].group ==='GQ-related' ||
                                            (flatarray[f].group ==='Patent Family' && fieldConfig.tableDisplayModeFromView !== 'ufs') ))
                                        return(
                                            <optgroup  className="field-optgroup-sub" label={flatarray[f].group}>
                                                {Object.keys(flatarray[f].fields).map((i) => {
                                                        if(!fieldConfig.fieldIsInTableTemplate('sin',(flatarray[f].fields[i])))
                                                            return(
                                                                <option  className= "field-option" value={"sin|"+flatarray[f].fields[i]}
                                                                         onDoubleClick={e => handleDblClickAvl(e,'sin',(flatarray[f].fields[i]))}>
                                                                    {fieldConfig.getFieldName((flatarray[f].fields[i]))}</option>
                                                            )
                                                        return(null)
                                                    }
                                                )}
                                            </optgroup>
                                        )
                                    return(null)

                                })
                                }


                                {/*For Alignment view, Patent Document group */}
                                {(fieldConfig.tableDisplayModeFromView === 'flat' ||
                                    fieldConfig.tableDisplayModeFromView === 'ufs' || fieldConfig.tableDisplayModeFromView === 'cas') ?
                                    <optgroup className="field-optgroup-new" label={fieldConfig.getTypeDescription('doc')}>
                                    </optgroup> :
                                    null

                                }
                                {Object.keys(flatarray).map((f) => {
                                    if((fieldConfig.tableDisplayModeFromView==='flat' || fieldConfig.tableDisplayModeFromView==='ufs'
                                            || fieldConfig.tableDisplayModeFromView==='cas') &&
                                        (flatarray[f].group ==='Patent Information' || flatarray[f].group ==='Legal Statuses'
                                            || flatarray[f].group ==='Inventors & Assignees' || flatarray[f].group ==='Dates & Documents' || flatarray[f].group ==='GQ-related' ||
                                            (flatarray[f].group ==='Patent Family' && fieldConfig.tableDisplayModeFromView !== 'ufs') ))
                                        return(
                                            <optgroup  className="field-optgroup-sub" label={flatarray[f].group}>
                                                {Object.keys(flatarray[f].fields).map((i) => {
                                                        if(!fieldConfig.fieldIsInTableTemplate('flat',(flatarray[f].fields[i])))
                                                            return(
                                                                <option  className= "field-option" value={"flat|"+flatarray[f].fields[i]}
                                                                         onDoubleClick={e => handleDblClickAvl(e,'flat',(flatarray[f].fields[i]))}>
                                                                    {fieldConfig.getFieldName((flatarray[f].fields[i]))}</option>
                                                            )
                                                        return(null)
                                                    }
                                                )}
                                            </optgroup>
                                        )
                                    return(null)

                                })
                                }

                            {/*For PN/PF views, Alignment/Subject/Query group */}
                            {(fieldConfig.tableDisplayModeFromView === 'document' ||
                                fieldConfig.tableDisplayModeFromView === 'family' || fieldConfig.tableDisplayModeFromView === 'ssv'
                                || fieldConfig.tableDisplayModeFromView === 'qs' || fieldConfig.tableDisplayModeFromView === 'hsp'
                                    || fieldConfig.tableDisplayModeFromView === 'ali'|| fieldConfig.tableDisplayModeFromView === 'npa'
                                    || fieldConfig.tableDisplayModeFromView === 'gname' || fieldConfig.tableDisplayModeFromView === 'org')  ?
                                <optgroup className="field-optgroup-new" label={fieldConfig.getTypeDescription('sin')}>
                                    </optgroup> :
                                null
                            
                            }

                                {Object.keys(sinarray).map((f) => {
                                     if((fieldConfig.tableDisplayModeFromView==='document' || fieldConfig.tableDisplayModeFromView==='family'
                                            || fieldConfig.tableDisplayModeFromView==='ssv'
                                             || fieldConfig.tableDisplayModeFromView === 'qs' || fieldConfig.tableDisplayModeFromView === 'hsp'
                                            || fieldConfig.tableDisplayModeFromView === 'ali'|| fieldConfig.tableDisplayModeFromView === 'npa'
                                            || fieldConfig.tableDisplayModeFromView === 'gname' || fieldConfig.tableDisplayModeFromView === 'org') &&
                                            ( (sinarray[f].group!=='CAS Biosequences™' && sinarray[f].group!=='CAS Biosequences™ ')
                                              || (sinarray[f].group==='CAS Biosequences™' && props.regAccess && props.regNP) 
                                              || (sinarray[f].group==='CAS Biosequences™ ' && props.regAccess && !props.regNP)))
                                                 return(
                                                    <optgroup  className={(sinarray[f].group==='CAS Biosequences™' || sinarray[f].group==='CAS Biosequences™ ') ? 'field-optgroup-new' : 'field-optgroup-sub'}label={sinarray[f].group}>
                                                    {Object.keys(sinarray[f].fields).map((i) => {
                                                        if(!fieldConfig.fieldIsInTableTemplate('sin',(sinarray[f].fields[i])))
                                                        return(
                                                            <option className= {(sinarray[f].group === 'CAS Biosequences™' || sinarray[f].group==='CAS Biosequences™ ') && fieldConfig.tableDisplayModeFromView!=='cas' ? 'field-option-cas' : 'field-option'} 
                                                            value={"sin|"+sinarray[f].fields[i]} onDoubleClick={e => handleDblClickAvl(e,'sin',(sinarray[f].fields[i]))} 
                                                            dangerouslySetInnerHTML={{__html: fieldConfig.getFieldName((sinarray[f].fields[i]),algo,isAB)}}>
                                                                {/*ReactHtmlParser(fieldConfig.getFieldName((sinarray[f].fields[i]),algo,isAB))*/}</option>
                                                        )
                                                        return(null)
                                                     }
                                                    )}
                                                    </optgroup>  
                                                    )
                                                    return(null)
                                            
                                            })
                                }

                            
                            {/*For UFS/Alignment view, Alignment/Subject/Query group */}
                            {(fieldConfig.tableDisplayModeFromView === 'flat' ||
                                fieldConfig.tableDisplayModeFromView === 'ufs' || fieldConfig.tableDisplayModeFromView === 'cas') ?
                                <optgroup className="field-optgroup-new" label={fieldConfig.getTypeDescription('sin')}>
                                    </optgroup> :
                               null
                            
                            }
                                 
                                 {Object.keys(flatarray).map((f) => {
                                     if((fieldConfig.tableDisplayModeFromView==='flat' || fieldConfig.tableDisplayModeFromView==='ufs'
                                            || fieldConfig.tableDisplayModeFromView==='cas') &&
                                             (flatarray[f].group==='Identifiers'||flatarray[f].group==='Alignment Quality'||flatarray[f].group==='Alignment Position'||flatarray[f].group==='Patent Info'||flatarray[f].group==='Sequence Attributes'
                                            ||flatarray[f].group==='GenePast Parameters'||flatarray[f].group==='Blast Scores'||flatarray[f].group==='Organism'||flatarray[f].group==='Non-Patent Records'||flatarray[f].group==='Complex Alignment Quality'
                                            || (flatarray[f].group==='CAS Biosequences™' && props.regAccess && props.regNP) || (flatarray[f].group==='CAS Biosequences™ ' && props.regAccess && !props.regNP)))
                                                 return(
                                                    <optgroup  className={((flatarray[f].group==='CAS Biosequences™' || flatarray[f].group==='CAS Biosequences™ ') && props.regAccess) ? 'field-optgroup-new' : 'field-optgroup-sub'} label={flatarray[f].group}>
                                                    {Object.keys(flatarray[f].fields).map((i) => {
                                                        
                                                        if(!fieldConfig.fieldIsInTableTemplate('flat',(flatarray[f].fields[i])))
                                                        
                                                        return(
                                                            
                                                            <option className= {(f.group === 'CAS Biosequences™' || f.group==='CAS Biosequences™ ') ? 'field-option-cas' : 'field-option'} 
                                                            value={"flat|"+flatarray[f].fields[i]} onDoubleClick={e => handleDblClickAvl(e,'flat',(flatarray[f].fields[i]))}
                                                            dangerouslySetInnerHTML={{__html: fieldConfig.getFieldName((flatarray[f].fields[i]),algo,isAB)}} >
                                                                {/*ReactHtmlParser(fieldConfig.getFieldName((flatarray[f].fields[i]),algo,isAB))*/}</option>
                                                                
                                                        )
                                                        return(null)
                                                        }
                                                    )}
                                                    </optgroup>  
                                                    )
                                                    return(null)
                                            
                                            })
                                }
                                       
                            </select>
                        </td>
                        
                        <td className="field-controls">
                            <div>
                                <FiChevronRight onClick={e => handleRight(e)}></FiChevronRight>
                            </div>
                            <div>
                                <FiChevronLeft onClick={e => handleLeft(e)}></FiChevronLeft>
                            </div>
                            <div>
                                <FiChevronUp onMouseDown={e => handleUp(e)} 
                                onMouseUp={(e) => {fieldConfig.refreshCols();}}></FiChevronUp>
                            </div>
                            <div>
                                <FiChevronDown onMouseDown={e => handleDown(e)}
                                onMouseUp={(e) => {fieldConfig.refreshCols();}}></FiChevronDown>
                            </div>

                        </td> 

                        <td>
                            <div className="large-font field-container-label">Displayed Columns</div>
                            
                            <select  className="field-container" 
                                key={seed} 
                                multiple={true} 
                                value={selectedDispOption}
                                //onDoubleClick={() => {setSeed(Math.random())}}
                                onChange={handleSelChange}
                                id="dispCols"
                                style={toggle ? {backgroundColor:''}: {backgroundColor: '#292929'}}
                                >
                                {getDivOptions()}
                                   
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" >
                            <button  onClick={() => { if (window.confirm('Confirm to reset displayed columns?')) 
                                        {fieldConfig.resetColsDisplay(props.fieldConfig, props.filter);
                                            setSeed(Math.random());
                                        }
                             } }
                                className="pull-left normal-button gqblue"  style={{marginTop:'10px', fontSize:'14px'}}> Reset</button>
                        </td>
                        <td>
                            <button onClick={handleApply}className="pull-right normal-button gqblue" style={{marginTop:'10px', fontSize:'14px'}}> Apply</button>
                            <button onClick={handleCancel} className="pull-right normal-button grey" style={{marginTop:'10px', fontSize:'14px'}}> Cancel</button>
                        </td>
                    </tr>
         </tbody>           
        </table>
                
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DisplayColumnModal
