import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import _ from "lodash";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { FaTimes} from "react-icons/fa";
import Dropdown from 'react-bootstrap/Dropdown';
import DiscoverSelectBox from "../../shared/Fields/DiscoverSelectBox";
import { useSelector } from 'react-redux';
import UtilsService from '../../helpers/utils';
import TextInput from '../../shared/Fields/TextInput';

const useStyles = makeStyles((theme) => ({
    modalClassConDSI: {
        position: 'relative',
        width: '80%',
        right: 'auto',
        bottom: 'auto',
        color: '#777',
    },
    modalClassConDSIDark: {
        position: 'relative',
        width: '80%',
        right: 'auto',
        bottom: 'auto',
        color: 'white',
        backgroundColor: '#292929'
    },
    modalBoxContent: {
        maxHeight: '600px',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px'
    },
    inputLight:{
        //padding: '4px',
        //marginLeft: '6px',
        //marginTop: '-7px',
        //border: '1px solid #77777770',
    },
    inputDark:{
        color: 'white',
        background: '#292929',
        //border: '1px solid white',
        //padding: '4px',
        //marginLeft: '6px',
        //marginTop: '-7px'
    }
}));

const AdvancedGroupModal = props => {

    //console.log('AdavancedGroupModal props', props);
    const classes = useStyles();
    const [selectedGroupOption, setSelectedGroupOption] = useState('ali');
    const [groupSizeOper, setGroupSizeOper] = useState('LESS_NE');
    const [selectedViewAliOption,setSelectedViewAliOption] =  useState('10');
    const [groupSizeOption, setGroupSizeOption] = useState('1');
    const [groupSizeVal, setGroupSizeVal] = useState('');
    const toggle = useSelector(state => state.setToggle.toggle);

    const groupOptions = [
        //{value:'alignment', label:'Ungrouped'},
        {value:'ali', label:'Alignment'},
        {value:'gname', label: 'Subj. Database Name'},
        {value:'npa', label:'Normalized Patent Assignee'},
        {value:'org', label:'Normalized Organism'},
    ];

    const viewOptions = [
        {value:'1', label:'1 Result'},
        {value:'3', label:'3 Results'},
        {value:'5', label:'5 Results'},
        {value:'10', label:'10 Results'},
        {value:'All', label:'All'}
    ];
    const groupSizeOptions = [
        {value:'1', label:'Group size'},
    ];
    const groupSize = [
        {value: "LESS_NE", label: "less than or equal to"},
        {value: "LESS", label: "less than"},
        {value: "EQUALS", label: "equals"},
        {value: "MORE", label: "greater than"},
        {value: "MORE_NE", label: "greater than or equal to"}
    ];
    const modalClose = () => {
        props.closeModal();
    }

    const modalSave = () => {
        props.callback(props.fieldConfig, selectedGroupOption, selectedViewAliOption, groupSizeVal, groupSizeOper);
    }

    return (
        <Modal
            size="xl"
            aria-labelledby="contained-modal-title-vcente"
            centered
            show={props.showModal}
            contentClassName={toggle ? classes.modalClassConDSI: classes.modalClassConDSIDark }
        >
            <div className="really-large-font" style={{padding: '20px 20px 0 300px'}}>Advanced Group Options</div>
            <div className={classes.closeButton}>
                <FaTimes style={{ height: '2em' }} onClick={modalClose} />
            </div>
            <div className="groupBig-message" >
                <div className="filter-widget-container">
                    <div className="rTable">
                        <div className="rTableRow single-line-container">
                            <span >Group by</span>
                            <DiscoverSelectBox
                                margin="normal"
                                variant="outlined"
                                name="gro_opt"
                                id="gro_opt"
                                value={selectedGroupOption}
                                items={groupOptions}
                                onChange={(e) => setSelectedGroupOption(e.target.value)}
                            />
                            {(selectedGroupOption !== '1') && (
                                <>
                                    <span>and show</span>
                                    <DiscoverSelectBox
                                        margin="normal"
                                        variant="outlined"
                                        name="pn_opt"
                                        id="pn_opt"
                                        value={selectedViewAliOption}
                                        items={viewOptions}
                                        onChange={(e) => setSelectedViewAliOption(e.target.value)}
                                    />
                                    <span>per group</span>
                                </>
                            )}

                        </div>
                        <br></br>
                        <span >Show only groups with</span>

                        <div className="control-spacer" style={{ display: 'flex', gap: '10px' }}>
                            <DiscoverSelectBox
                                margin="normal"
                                variant="outlined"
                                name="group_opt"
                                id="group_opt"
                                value={groupSizeOption}
                                items={groupSizeOptions}
                            />
                            <DiscoverSelectBox
                                margin="normal"
                                variant="outlined"
                                name="pn_opt"
                                id="pn_opt"
                                value={groupSizeOper}
                                items={groupSize}
                                onChange={(e) => setGroupSizeOper(e.target.value)}
                            />
                            {/*<input className = {toggle ? classes.inputLight : classes.inputDark } type="text"  value={groupSizeVal}
                                    autoComplete="off" spellCheck="false" onChange={(e) => {setGroupSizeVal(e.target.value)}}/>*/}
                            <TextInput fullWidth={false} type="number" className = {toggle ? classes.inputLight : classes.inputDark }
                                    variant="outlined"
                                    InputProps={{ inputProps: { min: 0, max: 1000000 } }}
                                    value={groupSizeVal}
                                    onKeyDown={UtilsService.restrictCharacter}
                                    onChange={(e) => {setGroupSizeVal(e.target.value)}}/>
                        </div>

                        {/* buttons */}
                        <div className="rTableRow">
                            <div className="rTableCell clearfix colspan">
                                <div className="clearfix filter-widget-bottom">
                                    <button className={'gqblue pull-right normal-button'} onClick={() => { modalSave() }}>Apply</button>
                                    <button className="pull-right normal-button grey" onClick={() => { props.closeModal() }}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default AdvancedGroupModal;
