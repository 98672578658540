import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import Button from "@material-ui/core/Button";
import _ from "lodash";
import DiscoverService from "../../services/discoverService";

function ClustalOmegaReport() {
    const { file } = useParams();
    const [content, setContent] = useState('');

    useEffect(() => {
        async function fetchData() {
            const response = await DiscoverService.downloadClustal(file, true);
		    setContent(response.response_content);
        }
        if (!file || file === 'null') {
            if (!_.isEmpty(window.message)) {
                setContent(window.message);
            } else {
                setContent("Error: at least 2 sequences must be selected for Clustalo.");
            }
        } else if (!_.isEmpty(window.reportString)) {
            setContent(window.reportString);
		} else {
		    // For refresh or coming from the link in email
		    fetchData();
		}
    }, [file]);

    function print() {
        const printContents = document.getElementById("content").innerHTML;
        if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
            const popupWin = window.open('', '_blank', 'width=600,height=600,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
            popupWin.window.focus();
            popupWin.document.write('<!DOCTYPE html><html><body onload="window.print()"><div style="width: 900px">' + printContents + '</div></body></html>');
            popupWin.document.close();
            popupWin.onbeforeunload = function (event) {
                popupWin.close();
                return '.\n';
            };
            popupWin.onabort = function (event) {
                //popupWin.document.close();
                popupWin.close();
            }
        } else {
            const popupWin = window.open('', '_blank', 'width=800,height=600');
            popupWin.document.open();
            popupWin.document.write('<html><body onload="window.print()" style="width: 900px">' + printContents + '</body></html>');
            popupWin.document.close();
        }
    }

    async function download(input) {
        if (input === true) {
            let response = await DiscoverService.downloadClustal(file, false);
            if (response) {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(new Blob([(response.response_content)], {type: 'text/plain'}));
                link.download = 'gq_clustal_fasta.txt'; // file + '_clustal.html'
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } else {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(new Blob([(content)], {type: 'text/html'}));
            link.download = 'gq_clustal.html'; // file + '_clustal.html'
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    return (
        <div>
            {content && !content.startsWith('Error:') &&
                <div style={{paddingTop: "20px"}}>
                    <h5 style={{float: "left"}}>Note: The files will be removed after 7 days, please download or print a copy in advance.</h5>
                    <Button color="inherit" onClick={() => print()} style={{float: "right", paddingRight: "30px"}}><span className="appLink text-initial appTextFont">Print</span></Button>
                    <Button color="inherit" onClick={() => download(false)} style={{float: "right"}}><span className="appLink text-initial appTextFont">Download Output</span></Button>
                    <Button color="inherit" onClick={() => download(true)}  style={{float: "right"}}><span className="appLink text-initial appTextFont">Download Input</span></Button>
                </div>
            }
            {content && !content.startsWith('Error:') &&
                <div id="content">
                    <br></br>
                    <br></br>
                    <pre className="clustal-pre">{ReactHtmlParser(content)}</pre>
                </div>
            }
            {content && content.startsWith('Error:') &&
                <h3><br></br>{content}</h3>
            }
            <br></br>
            <br></br>
        </div>
    );
}
export default ClustalOmegaReport;
