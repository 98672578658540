
import React, { useState, useEffect, useCallback, createRef } from "react";
import CheckBox from '../../shared/Fields/CheckBox';
import { useSelector } from "react-redux";
import {useHistory} from "react-router-dom";

import Add from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";

import { getInitialKeywordValue, getInitialSeqIdValue } from "../../services/FTDocService";
import SettingsIcon from '@material-ui/icons/Settings';

import FtOntologyModal from "../../shared/Modal/FtOntologyModal";
import TextFieldsModal from "../../shared/Modal/TextFieldsModal";

import { toast } from 'react-toastify';

import fullTextService from "../../services/fulltextsearch"
import Tour from "../../shared/components/Tour"
import {BsFillQuestionCircleFill} from "react-icons/bs"; 

import VisibilityIcon from '@material-ui/icons/Visibility';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import CheckIcon from '@material-ui/icons/Check';
import CheckBoxIcon from '@material-ui/icons/CheckBox';


const KeywordsInContext = (props) =>{

    const [showTour, setShowTour] = useState(false)
    
    const handleTooltipGuide = () =>{
        setShowTour(!showTour)
    }


	const history = useHistory();
    const userInfo = useSelector((state) => state.setUserInfo);
    const {docsSelected, applyKeywords, applySeqIds, startPrevTour, startTour, setNextTour, 
        isDocument = false, hasPrevTour = false, moveToElementIdAtIndex, documentKeywordInfo, documentSeqIdInfo, setTextFields} = props

    const [tourState, setTourState] = useState(0);
    useEffect(() => {
        if(startTour === true){
            setTourState(0)
            setShowTour(true)
            setNextTour()
        }
    },[startTour]);

    const [ontologyState, setOntologyState] = useState({});

    const [keywordsState, setKeywordsState] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const [seqIds, setSeqIds] = useState("");

    const [seqIdsApplied, setSeqIdsApplied] = useState(false);
    const [seqidIndex, setSeqIdsIndex] = useState(0);

    const [useOntologies, setUseOntologies] = useState(true)
    const [ontologyOptionsPopup, setOntologyOptionsPopup] = useState(false)
    const [textFieldsPopup, setTextFieldsPopup] = useState(false)


    const highlightColors = [
        'FFEB3B', 'FFA726', 'EF9A9A', 'B39DDB', '80CBC4', 'F48FB1', '81D4FA',
        'A5D6A7', 'E6EE9C', 'FFF59D', 'FFE082', 'FFCC80', 'FFAB91',
        'FF7043', 'BCAAA4', 'B0BEC5'
    ];

    const HighlightAtom = {
        taxonomy: "",
        term: "",
        synonyms: [],
        color: highlightColors[6],
        focused: false,
        colorBoxFocused: false,
        selected: false,
        edited: false,
        applied: false,
        currentIndex: 0,
        count: 0
    }

    useEffect(()=>{
        let test = getInitialKeywordValue()
        setKeywordsState([...test])
    },[])

    useEffect(() => {

        let kws = [];
        for(let i = 0; i < keywordsState.length; i++){
            
            let highAtom = _.cloneDeep(HighlightAtom)
            highAtom.taxonomy = keywordsState[i].taxonomy;
            highAtom.term = keywordsState[i].term;
            highAtom.synonyms = keywordsState[i].synonyms? keywordsState[i].synonyms: [];
            highAtom.color = keywordsState[i].color;

            kws.push(highAtom)
        }
        
        setKeywords([...kws])
    }, [keywordsState]); // Only run the effect if 'value' changes

    useEffect(() =>{

        if(keywords.length > 0 && isDocument){
            for(let i = 0; i< keywords.length; i++){

                let index = findTermIndex(documentKeywordInfo, keywords[i].term)

                if(index !== -1){

                    keywords[i].applied = true;

                    if(isDocument && (keywords[i].count !== documentKeywordInfo[index].count)){
                        keywords[i].count = documentKeywordInfo[index].count;
                        if(documentKeywordInfo[index].count >0){
                            keywords[i].currentIndex = 1
                        }
                    }
                }else{
                    keywords[i].applied = false;
                    keywords[i].count = 0;
                    keywords[i].currentIndex = 0;
                }
            }
            setKeywords([...keywords])
        }
    },[documentKeywordInfo])


    useEffect(() =>{

        let seqidstring = ""
        let keywordsSelected = []

        for(let i = 0; i< docsSelected.length; i++){
            let docString = localStorage.getItem(docsSelected[i]);

            if(docString){
                let docObj = JSON.parse(docString);
                if(i === 0){
                    seqidstring = docObj.seqIds;
                    keywordsSelected = docObj.keywords.map(item => item.term);
                }else{

                    let theseKeys = docObj.keywords.map(item => item.term);
                    keywordsSelected = keywordsSelected.filter(element => theseKeys.includes(element));


                    const numbers1 = seqidstring.split(/[\s,]+/).map(Number);
                    const numbers2 = docObj.seqIds.split(/[\s,]+/).map(Number);
                    const commonNumbers = numbers1.filter(num => numbers2.includes(num));

                    seqidstring = commonNumbers.join(', ');
                }
            }
        }
        setSeqIds(seqidstring)

        if(!isDocument){
            if(keywords.length > 0){
                for( let i = 0; i< keywords.length; i++){
                    if(keywordsSelected.includes(keywords[i].term)){
                        keywords[i].applied = true;
                    }else{
                        keywords[i].applied = false;
                    }
                }

                setKeywords([...keywords])

            }
        }
        
    }, [docsSelected])

    const findTermIndex = (arr, searchTerm) => {
        return arr.findIndex(item => item.term === searchTerm);
    };
    

    useEffect(() => {
        let ontFromMem = JSON.parse(localStorage.getItem('FTOntologies'));
		if (ontFromMem != null) {
			setOntologyState(ontFromMem);
		}
    },[]);

    useEffect(() => {
        const handleStorageChange = (event) => {
          if (event.key === "keywords") {
            setKeywordsState(event.newValue ? JSON.parse(event.newValue) : []);
            console.log("GotTerms")
          }
        };
      
        window.addEventListener('storage', handleStorageChange);
      
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('storage', handleStorageChange);
        };
    },[]);

    const handleSeqId = (e) => {
        const filteredValue = e.target.value.replace(/[^0-9,\s\n\-]/g, '');
        setSeqIds(filteredValue);
        setSeqIdsApplied(false);
    };

    const setTextFieldsDoc = (tf) =>{
        if(isDocument){
            setTextFields(tf)
        }
    }


    const addKeyword = (e) =>{
        for (var i = 0; i < keywords.length; i++) {
            if (keywords[i].term === "") {
                keywords.splice(i, 1);
                toast.info("Please Use Blank Provided")
                i--;
            }
        }

        let newItem = _.cloneDeep(HighlightAtom);
        newItem.selected = true;

        keywords.push(newItem);
        setKeywords([...keywords]);
        saveTerms();
    }

    function getOntologyString(){

		let ontologyString = "";
		let keys = Object.keys(ontologyState);

		for(var i =0; i<keys.length; i++){
			if(ontologyState[keys[i]][1]){
				ontologyString+=';'+keys[i];
			}
		}

		return ontologyString;
	}

    const [ontologyTextList, setOntologyTextList] = useState([]);
    const [searchedTerm, setSearchedTerm] = useState("")

    const [typingTimeout, setTypingTimeout] = useState(null);
    const toggle =  useSelector(state => state.setToggle.toggle);
    const findOntology = (event) => {

        // Clear the previous timeout if the user is still typing
        if (typingTimeout) {
          clearTimeout(typingTimeout);
        }
    
        // Set a new timeout to trigger the function after 1 second of no typing
        const newTimeout = setTimeout(() => {
            searchOntologies(event.target.value);
        }, 1000);
    
        setTypingTimeout(newTimeout);

    }

    const searchOntologies = async (value) => {
        console.log('User stopped typing for 1 second:', value);

        let userId = userInfo && userInfo.current_user.gq_user_id;        	
		//toast.success('start');

        if(fullTextService.levenshteinDistance(value, searchedTerm) > 2){

            let searchParam = `&json_query=${encodeURIComponent(value)}&rows=25&ontologies=${getOntologyString()}&user_id=${userId}`;
            const searchRes = await fullTextService.getFullTextSearchTerm(
                history,
                searchParam
            );

            //const searchRes = Test.test2;
            setSearchedTerm(value)
            if (searchRes && searchRes.response_status == 0) {
                if(searchRes.response_content && searchRes.response_content.results){
                    if (searchRes.response_content.results.length > 0) {
                        if(searchRes.response_content.results){
                            setOntologyTextList(searchRes.response_content.results);
                            return true;
                        }
                    }
                }	
            }
        }

    };

    //synonym popup
    const [synonymPopupX, setSynonymPopupX] = useState(0);
    const [synonymPopupY, setSynonymPopupY] = useState(0);
    const [synonymItems, setSynonymItems] = useState([]);
 
    const showSynonyms = (event, synonymList) =>{
        setSynonymPopupX(event.clientX);
        setSynonymPopupY(event.clientY);
        if(synonymList != null && synonymList != undefined){
            setSynonymItems(synonymList);
        }else{
            setSynonymItems([]);
        }
    }

    const hideSynonyms = () =>{
        setSynonymItems([]);
    }

    const removeKeyword = (index) => {
        keywords.splice(index, 1);
        setKeywords([...keywords])
    }


    //todo
    const saveTerms = () =>{

        let allTerms =[]
        for(let i = 0 ; i < keywords.length; i++){

            let kwStorageItem = {
                term: keywords[i].term, 
                taxonomy: keywords[i].taxonomy, 
                synonyms: keywords[i].synonyms,
                color: keywords[i].color
            };

            keywords[i].edited = false;
            allTerms.push(kwStorageItem)
        }
        

        setKeywords([...keywords])
        let keystring = JSON.stringify(allTerms);
        localStorage.setItem("keywords", keystring)
        
    }

    const handleKeywordEdit = (event, index) => {
        let editedValue = event.target.value;

        //notice '?' is elseif
        keywords[index].term !== editedValue? 
        keywords[index].edited = true: //if true
        keywords[index].edited = false; // if false

        keywords[index].term = editedValue;
        keywords[index].taxonomy = "";
        keywords[index].synonyms = [editedValue];
        keywords[index].applied = false;

        setKeywords([...keywords])
        saveTerms()
    }

    const getKeywordsSelected = () =>{

        const selectionAtom = {
            term: "",
            synonyms: [],
            color: "",
        }

        let selection = []

        for(let i = 0; i< keywords.length; i++){

            if(keywords[i].selected && keywords[i].term.replace(/[^a-zA-Z0-9]/g, '').length > 0){

                keywords[i].applied = true
                let newTerm = _.cloneDeep(selectionAtom)

                newTerm.term = keywords[i].term;
                newTerm.color = keywords[i].color;
                newTerm.synonyms = keywords[i].synonyms;

                selection.push(newTerm)
            }
        }
        setKeywords([...keywords])

        return selection  = uniqueTerms(selection);;
    }

    const uniqueTerms = (arr) => {
        const seenTerms = new Set();
        return arr.filter(item => {
            if (!seenTerms.has(item.term)) {
                seenTerms.add(item.term);
                return true;
            }
            return false;
        });
    };

    const handleCheck = (e, index) =>{
      
        let checked = e.target.checked
        keywords[index].selected = checked;
        setKeywords([...keywords])
    }

    const selectOntology = (data, index) => {
        setSynonymItems([]);
        setOntologyTextList([]);
        
        keywords[index].term = data.term
        keywords[index].synonyms = data.synonyms
        keywords[index].taxonomy = data.taxonomy
        keywords[index].focused = false;
        keywords[index].edited = true;

        setKeywords([...keywords]);
        saveTerms();
    }

    const handleFocus = (index) => {
        keywords[index].isFocused = true;
        setKeywords([...keywords])
        console.log('Input is focused');
      };
    
    const handleBlur = (index) => {
        setTimeout(() => {
            keywords[index].isFocused = false;
            setKeywords([...keywords])
            console.log('Input is blurred');
        }, 1000);
    };


    const openColorBox = (index) =>{
        keywords[index].colorBoxFocused = true;
        setKeywords([...keywords])
    }

    const selectColor = (index, colorIdx) => {
        keywords[index].color = highlightColors[colorIdx]
        keywords[index].colorBoxFocused = false;
        setKeywords([...keywords]);
    }

    const moveToKeyword = (term, idIndex, index1) =>{

        if(idIndex < 1){
            idIndex = keywords[index1].count
        }

        if(idIndex > keywords[index1].count){
            idIndex = 1
        }

        keywords[index1].currentIndex = idIndex;

        term = term.replace(/\s+/g, '').toLowerCase();

        moveToElementIdAtIndex(term, idIndex-1)

        setKeywords([...keywords])
    }

    const moveToSeqId = (index) =>{

        if(documentSeqIdInfo === 0){
            return
        }

        if(index < 1){
            index = documentSeqIdInfo
        }

        if(index > documentSeqIdInfo){
            index = 1
        }
        
        setSeqIdsIndex(index);
        moveToElementIdAtIndex("seqid", index-1)
    }

    const HandleSelectAll = () =>{
        let allSelected = true;
        for(let i = 0; i< keywords.length; i++){
            if(!keywords[i].selected){
                allSelected = false;
                break;
            }
        }

        for(let i = 0; i< keywords.length; i++){
            keywords[i].selected = !allSelected
        }

        setKeywords([...keywords])

    }
 
    
    const tour1 = React.createRef();
    const tour2 = React.createRef();
    const tour3 = React.createRef();
    const tour4 = React.createRef();
    const tour5 = React.createRef();

    const TourData = [
        {
            text: "This panel displays Keywords and Sequnce IDs for Text Documents",
            anchor: tour1,
            position: "center"
        },
        {
            text: "Keywords can be created and edited here",
            anchor: tour1,
            position: "left",
        },
        {
            text: "Select this button to add a new Keyword.",
            anchor: tour2,
            position: "left",
        },
        {
            text: "This icon will save the current format of keywords",
            anchor: tour3,
            position: "left",
        },
        {
            text: "Click this to apply keywords to selected documents for highlighting",
            anchor: tour4,
            position: "left",
        },
        {
            text: "This shows Seqids applied to selected documents",
            anchor: tour5,
            position: "left",
        },
    ]

    return(
        <div className="docManagerColumn" style={{width: "330px"}}>
            <div>
                <Tour
                    tourState = {tourState}
                    TourData = {TourData}
                    showTour = {showTour}
                    hideTour = {()=> setShowTour(false)}
                    propPos = {'fixed'}
                    startPrevTour = {startPrevTour}
                    hasPrevTour = {hasPrevTour}
                >
                </Tour>
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
                <span className="subHeading" style={{textWrap: "nowrap"}}>Keyword Highlighter</span>
                <span>
                    <Tooltip arrow title="Short Guided Tour" placement="bottom">
                        <button className="tourButtonDocManager" onClick={handleTooltipGuide}> 
                        <BsFillQuestionCircleFill style={{marginTop: "-3px"}}></BsFillQuestionCircleFill>
                        </button>
                    </Tooltip>
                </span>
            </div>
            <div 
                ref={tour1}
                className="docKey"
                style={{padding: "5px 5px 0 0", overflowX: "scroll"}}
            >
                {keywords.length > 0 && keywords.map((data, index1)=> {
                    return(
                    <div style={{ marginLeft: "2px", marginBottom: "4px"}} key={index1}>
                        <div style={{ margin: "0 3px", display: "flex", flexDirection: "row"}}>
                            <div  style={{marginTop: "5px"}}>
                                <CheckBox
                                    checked = {data.selected}
                                    onChange= {(event)=>{handleCheck(event, index1)}}
                                    style={{width: "15px", color: "#0182C5" }}
                                />
                            </div>
                            <div className="color-select-div" style={{display: "flex", alignItems: "center", paddingLeft: "0", marginTop: "4px"}}>
                                <div 
                                    onClick={() => {openColorBox(index1)}}
                                    className={'color-select-highlight highlight-' + keywords[index1].color}>
                                </div>
                                {keywords[index1].colorBoxFocused &&
                                    <div style={{marginTop: "5px"}} id="highlightcolorbox" className="clearfix color-box-container">
                                    {highlightColors.map(
                                        (color, colorIdx) => (
                                        <div key={'colorbox_' + color} className={'color-box pull-left highlight-' + color} onClick={() => {selectColor(index1, colorIdx)}}></div>
                                        ))
                                    }
                                    </div>
                                }
                            </div>
                            <div 
                                style={{display: "flex", flexDirection: "row", alignItems: "center"}}
                                onFocus={()=>{handleFocus(index1)}}
                                onBlur={()=>{handleBlur(index1)}}
                            >
                                <div style={{borderBottom: "1px solid lightgray", textWrap: "nowrap", minHeight: "20px", width: "220px", marginTop: "2px"}}>
                                    {keywords[index1].taxonomy !== "" &&
                                    <span onMouseOver={(event) => {showSynonyms(event, keywords[index1].synonyms)}} onMouseOut={()=>{hideSynonyms()}} className={keywords[index1].taxonomy === "USER"? "autoqueryUser": "autoquery"}>
                                        {keywords[index1].taxonomy}
                                    </span>
                                    }
                                    <input
                                        id={"keywordable"+index1}
                                        onChange={(event)=>{findOntology(event); handleKeywordEdit(event, index1)}} 
                                        style={toggle ? {width: "100%", outline: "none", border: "none"}: {width: "100%", outline: "none", border: "none", backgroundColor: '#292929'}} type="text" 
                                        className="appTextFont appTextColor" 
                                        value={data.term}
                                        autoFocus
                                    >
                                    </input>
                                </div>
                                {/* {(useOntologies && fullTextService.levenshteinDistance(data.term, searchedTerm) > 2 && ontologyTextList.length > 0) && */}
                                {useOntologies && ontologyTextList.length > 0 && keywords[index1].isFocused && fullTextService.levenshteinDistance(keywords[index1].term, searchedTerm) < 2 &&
                                    <div className="ontolist">
                                        {ontologyTextList.map((data, index) =>{
                                            return(
                                                <span onClick={()=>{selectOntology(data, index1)}} key={index} style={{padding: "3px", textWrap: "pretty", cursor: "pointer"}} onMouseOver={(event) => {showSynonyms(event, data.synonyms)}} onMouseOut={()=>{hideSynonyms()}}>
                                                    <span className={data.taxonomy == "USER"? "autoqueryUser":"autoquery"}>{data.taxonomy}</span> {data.term}
                                                </span>
                                            )})
                                        }
                                    </div>
                                }
                                <div style={{display: "flex",flexWrap: "nowrap"}}>
                                    <Tooltip title="Applied">
                                        <CheckIcon
                                            className={keywords[index1].applied? "simpleGQBlue": "simpleGrey"}
                                            style={{marginTop: "8px", fontSize: 19, cursor: "pointer" }}
                                        />
                                    </Tooltip>
                                </div>
                                <div style={{display: "flex",flexWrap: "nowrap"}}>
                                    <Tooltip title="Delete">
                                        <DeleteForeverIcon
                                            onClick={()=> {removeKeyword(index1);saveTerms();}}
                                            className="trashbutton"
                                            style={{marginTop: "8px", fontSize: 19, color: "#db862c", cursor: "pointer" }}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        {isDocument && data.applied &&
                        <div style={{paddingLeft: "5px", display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <div style={{width: "100%", display: "flex" , justifyContent: "flex-end"}}>
                                <FirstPageIcon style={{cursor: "pointer"}} onClick={()=>{moveToKeyword(data.term,  1, index1)}}/>
                                <ArrowLeft style={{cursor: "pointer"}} onClick={()=>{moveToKeyword(data.term, data.currentIndex-1, index1)}}/>
                                    <span style={{cursor: "default"}}>{data.currentIndex} : {data.count}</span>
                                <ArrowRight style={{cursor: "pointer"}} onClick={()=>{moveToKeyword(data.term,data.currentIndex +1, index1)}}/>
                                <LastPageIcon style={{cursor: "pointer"}} onClick={()=>{moveToKeyword(data.term, data.count, index1)}}/>
                                <VisibilityIcon style={{cursor: "pointer", margin: "0 5px"}} onClick={()=>{moveToKeyword(data.term, data.currentIndex, index1)}}/>
                            </div>
                        </div>
                        }
                    </div>
                )})}
                    <div style={{width: "100%", display: "flex", padding: "0 0 5px"}}>
                        <button onClick={()=> addKeyword()} className="docManagerAddKeywordButton appTextFont appTextColor">
                        <Add/>
                            <span ref={tour2}>New Highlight</span>
                        </button>
                    </div>
                </div>
                <div className="buttonContainer">
                    <a href="#" onClick={(e)=>{ e.preventDefault(); HandleSelectAll()}}>Select All</a><span>&nbsp;|&nbsp;</span>
                    <a href="#" onClick={(e)=>{ e.preventDefault(); setTextFieldsPopup(true)}}>Text Fields</a><span>&nbsp;|&nbsp;</span>
                    <a href="#" onClick={(e)=>{ e.preventDefault(); setOntologyOptionsPopup(!ontologyOptionsPopup)}}>Ontologies</a>
                </div>
                <div className="buttonContainer" style={{paddingTop: "0"}}  >
                    <button ref={tour4} style={{width: "95%"}}  className={docsSelected.length > 0? "docManagerButton" : "docManagerButtonDisabled"} onClick={()=>{if(docsSelected.length > 0){saveTerms(); applyKeywords(getKeywordsSelected())}}}>Apply Keywords Highlights</button>
                </div>
                <div className="seqidSelector">
                    <span className="subHeading">Sequence ID Highlighter</span>
                    <div style={{paddingLeft: "5px", display: "flex", flexDirection: "row", alignItems: "center"}}>
                        {isDocument && seqIdsApplied &&
                        <div style={{width: "100%", display: "flex"}}>
                            <FirstPageIcon style={{cursor: "pointer"}} onClick={()=>{moveToSeqId(1)}}/>
                            <ArrowLeft style={{cursor: "pointer"}} onClick={()=>{moveToSeqId(seqidIndex-1)}}/>
                                <span style={{cursor: "default"}}>{seqidIndex} : {documentSeqIdInfo}</span>
                            <ArrowRight style={{cursor: "pointer"}} onClick={()=>{moveToSeqId(seqidIndex+1)}}/>
                            <LastPageIcon style={{cursor: "pointer"}} onClick={()=>{moveToSeqId(documentSeqIdInfo)}}/>
                        </div>
                        }
                    </div>
                </div>
                <div style={{height: "100px"}}>
                    <textarea
                        ref={tour5}
                        className="docSeq appTextFont appTextColor"
                        placeholder={"231, 4-765, 34\n675309, 76"}
                        type="text"
                        value={seqIds}
                        onChange={handleSeqId}
                    />
                </div>
                <div className="buttonContainer">
                    <button style={{width: "95%"}} className={docsSelected.length > 0 ? "docManagerButton" : "docManagerButtonDisabled"} onClick={()=>{setSeqIdsApplied(true); applySeqIds(seqIds)}}>Apply SEQ ID Highlights</button>
                </div>

            <div>
                <FtOntologyModal
					show={ontologyOptionsPopup}
					closeModal={() => setOntologyOptionsPopup(false)}
					saveOntologyState = {(e)=>{setOntologyState(e)}}
				/>
			</div>
            <div>
                <TextFieldsModal
                    show={textFieldsPopup}
                    closeModal={() => setTextFieldsPopup(false)}
                    setTextFields = {(tf)=>setTextFieldsDoc(tf)}
                />
			</div>
            <div>
            {synonymItems.length > 0 &&
				<div 
					className={"ftSynonymPopup"}
					style={{ top: synonymPopupY, left: synonymPopupX}}
				>
					<table>
						<th>
							<tr>
								<span style={{display: "flex", textWrap: "nowrap", padding: "8px 8px 0 8px", marginBottom: "5px !important"}} className="ontologySymTitle">This Ontology includes:</span>
							</tr>
						</th>
						<tbody>
						<ul className="col-md-12 ftOntologyItem paddingletf15">
							{synonymItems !=[] &&
								synonymItems.map((data, i) => {
									return(
											<p className="ftNoLineBreak" style={{marginBottom: "5px"}}>
												{data}
											</p>
									);
								})
							}
		   			    </ul>
						</tbody>
					</table>
				</div>
            }
			</div>
        </div>
    )};

export default KeywordsInContext