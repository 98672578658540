import React, { useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import utilsService, { TOAST_TYPE } from '../../helpers/utils'
import _, { values } from "lodash";


import TextInput from '../../shared/Fields/TextInput';
import Constant from '../../helpers/constant';
import st26service from '../../services/st26service';
import Switch from '@mui/material/Switch';
import * as FileSaver from 'file-saver';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        width: '96%',
        margin: '0 auto 28px',
        minHeight: '260px',
        padding: '23px 16px 14px',
    },
    headerPipe: {
        margin: '0 10px'
    },
    searchInput: {
        width: '30%'
    },
    buttonStyleCancel: {
        float: 'right',
        textTransform: 'none',
        margin: '4px',
        color: 'white',
        backgroundColor: '#008EC5 !important',
        border: '1px solid #1F4E79 !important',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        }
    },
    antibodyNumInput: {
        width: '8%'
    }
}));

function ThreeLetterCodeConversion() {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const history = useHistory();
    const [authInfo, setAuthInfo] = useState();
    const [formdata, setFormData] = useState({});
    const { workflowId } = useParams();
    const toastRef = useRef(null);
    const [getResponse, setGetResponse] = useState(null);
    const [toggle1, setToggle] = useState(false);
    const [uploadedFileName, setUploadedFileName] = useState("");
    const [conversionCompletedforThree, setConversionCompletedforThree] = useState(false);
    const [conversionCompletedforSingle, setConversionCompletedforSingle] = useState(false);
    const toggle = useSelector(state => state.setToggle.toggle)
    function showfil() {
        if (window.File && window.FileReader && window.FileList && window.Blob) {
             var preview = document.getElementById('letterCodeConversion');
             var file = document.querySelector('input[type=file]').files[0];
             if (file) {
                // If a file is uploaded, set the uploaded file name
                setUploadedFileName(file.name);
            } else {
                setUploadedFileName(null);
            }
             var reader = new FileReader();
       
             var textFile = /text.*/;
             if (file.type.match(textFile)) {
                reader.onload = function (event) {
             // preview.innerHTML = event.target.result;
                preview.value = event.target.result;
                formik.setFieldValue("st26input", event.target.result);
               };
             } else {
               preview.innerHTML =
                 "<span class='error'>It doesn't seem to be a text file!</span>";
             }
             reader.readAsText(file);
          } else {
             alert('Your browser is too old to support HTML5 File API');
           }
    }  

    function downloadTextFile() {
        if (getResponse != null) {
            const blob = new Blob([getResponse], {
                type: "text/plain;charset=utf-8;"
            });
            FileSaver.saveAs(blob, uploadedFileName + ".txt");
            window.location.reload();
        }
    }

    const resetFileName = () => {
        var fileInput = document.querySelector('input[type=file]');
        fileInput.value = ''; // Clear the file input
        setUploadedFileName(''); // Reset the uploaded file name
    };

    const handleChanged = () => {
        // Reset the form values
        formik.resetForm();
        // Reset the response state
        setGetResponse(null);
        // Clear the text box value
        formik.setFieldValue("st26input", "");
        setConversionCompletedforThree(false);
        setConversionCompletedforSingle(false);
        resetFileName(); // Call resetFileName when toggling
        setToggle(prevToggle => !prevToggle);
    };

    const handleConvertToSingle = async () => {
        formik.setFieldValue("st26input", "");
        const postData = {
            st26input: formik.values.st26input
        };
        try {
            const response = await st26service.convertingThreeToOne(postData, history, t);
            if(response === 'empty'){
                utilsService.showToast(TOAST_TYPE.ERROR, "The .txt file has no sequence data to be parsed.", toastRef);
                setConversionCompletedforThree(false);
            }else if(response === 'parsererror'){
                utilsService.showToast(TOAST_TYPE.ERROR, "The data is not correctly structured.\nPlease correct the .txt and try again.", toastRef);
                setConversionCompletedforThree(false);
            }else if(response === 'missing'){
                utilsService.showToast(TOAST_TYPE.ERROR, "The data is not correctly structured.\nEither the moltype or Sequence data is missing.", toastRef);
                setConversionCompletedforThree(false);
            }
            else{
                setGetResponse(response);
                setConversionCompletedforThree(true);
                formik.setFieldValue("st26input", response); // Update the input field value
            }
            
        } catch (error) {
            console.error("Error during conversion:", error);
            // Handle the error if necessary
        }
    }

    const handleConvertToThree = async () => {
        formik.setFieldValue("st26input", "");
        const a = values.st26input;
        const postData = {
            st26input: formik.values.st26input
            // Add other properties if needed
        };
        // Call the API and update the state with the response
        try {
            const response = await st26service.convertingSingleToThree(postData, history, t);
            if(response === 'empty'){
                utilsService.showToast(TOAST_TYPE.ERROR, "The .txt file has no sequence data to be parsed.", toastRef);
                setConversionCompletedforSingle(false); 
            }else if(response === 'parsererror'){
                utilsService.showToast(TOAST_TYPE.ERROR, "The data is not correctly structured.\nPlease correct the .txt and try again.", toastRef);
                setConversionCompletedforSingle(false);
            }else if(response === 'missing'){
                utilsService.showToast(TOAST_TYPE.ERROR, "The data is not correctly structured.\nEither the moltype or Sequence data is missing.", toastRef);
                setConversionCompletedforSingle(false);
            }else{
                setGetResponse(response);
                formik.setFieldValue("st26input", response); // Update the input field value
                setConversionCompletedforSingle(true); 
            }
        } catch (error) {
            console.error("Error during conversion:", error);
            // Handle the error if necessary
        }
    }

    function cncl() {
        window.location.reload();
    }

    const formik = useFormik({
        initialValues: {
            st26input: formdata.st26input

        },
        enableReinitialize: true,
        //validationSchema: Validate.AntibodySearchValidation(),
        onSubmit: async (values) => {
            let { st26input } = values;

            let postData = {
                st26input
            }
            let response;
            if(!toggle1){
                response = await st26service.convertingThreeToOne(postData, history, t);
            }
            else{
                response = await st26service.convertingSingleToThree(postData, history, t);
            }
            setGetResponse(response);

        },
    });
    return (
        <div className={classes.grow}>
            <form name="antibodySearchForm" onSubmit={formik.handleSubmit} className={classes.loginDiv}>
                {!toggle1 ?
                    <Row>
                        <Col lg="12" md="12" className={"mb-2 " + (authInfo && !authInfo.syscontrol_search_submit ? 'd-block' : 'd-none')}>
                            <Typography className="text-danger">
                                {t('ABsearchDisableText')}
                                {authInfo && authInfo.syscontrol_search_submit_txt}
                                {t('patienceThanksText')}</Typography>
                        </Col>
                        <Col>
                        {/* <Switch
                                checked={toggle1}
                                onChange={handleChanged}
                                sx={{
                                    '& .MuiSwitch-thumb': {
                                        border: '2px solid #1976d2', // Blue border around the thumb
                                    },
                                    '& .MuiSwitch-track': {
                                        border: '2px solid #1976d2', // Blue border around the track
                                        backgroundColor: toggle1 ? '#1976d2' : '#ccc', // Change color when toggled
                                    },
                                }}
                            /> */}
                        </Col>
                        <Col lg="12" md="12" className="mb-2">
                            <h6 className={"appTextColor loginTitle"}>Three Letter Code Conversion</h6>
                            <Row className="mb-3">
                                <Col lg="12" md="12" className="p-0 content float-left">
                                    <div className="form-group px-3 ">
                                        <TextInput
                                            spellcheck="false"
                                            rows="20"
                                            multiline={true}
                                            fullWidth
                                            id="letterCodeConversion"
                                            name="st26input"
                                            label="Copy and Paste your Three Letter sequences here"
                                            variant="outlined"
                                            value={formik.values.st26input}
                                            onChange={(event) => {
                                                formik.handleChange(event);
                                                if (!event.target.value) {
                                                    resetFileName(); // Clear file input when text input is cleared
                                                }
                                            }}                                            
                                            error={formik.touched.st26input && Boolean(formik.errors.st26input)}
                                            helperText={formik.errors.st26input}
                                        //disabled={authInfo && authInfo.redo}
                                        />
                                    </div>

                                </Col>

                            </Row>
                            <hr style={toggle ? {backgroundColor: '#dfdfdf'}: {backgroundColor: '#aea9a9'}}/>
                        </Col>

                        <Col lg="12" md="12" className="float-right mb-3">

                        <p className='uploadTextData'> <b id="show-text">Upload Three Letter Code file</b></p>
                        <input type="file" accept=".txt" onChange={showfil} />

                            {!formik.values.st26input ?
                                <Button className='cancelButtonDisable' color="default" disableRipple={true} variant="contained">Convert to Single Letter Code</Button>
                                : <Button
                                className='accountInfo'
                                color="default"
                                disableRipple={true}
                                variant="contained"
                                onClick={() => {
                                    if (conversionCompletedforThree) {
                                        downloadTextFile();
                                    } else {
                                        handleConvertToSingle();
                                    }
                                }}
                            >
                                {conversionCompletedforThree ? 'Download' : 'Convert to Single Letter Code'}
                            </Button>
                            }
                            <Button variant="contained" disableRipple={true} color="default" className={classes.buttonStyleCancel} onClick={cncl}>{t('cancel')}</Button>

                            {/* <Button variant="contained" disableRipple={true} color="default" className={classes.buttonStyleCancel} onClick={() => downloadTextFile()}>{'Download'}</Button> */}
                        </Col>
                    </Row>
                :
                <Row>
                    <Col lg="12" md="12" className={"mb-2 " + (authInfo && !authInfo.syscontrol_search_submit ? 'd-block' : 'd-none')}>
                        <Typography className="text-danger">
                            {t('ABsearchDisableText')}
                            {authInfo && authInfo.syscontrol_search_submit_txt}
                            {t('patienceThanksText')}</Typography>
                    </Col>
                    <Col>
                            <Switch
                                checked={toggle1}
                                onChange={handleChanged}
                            />
                        </Col>
                    <Col lg="12" md="12" className="mb-2">
                        <h6 className={"appTextColor loginTitle"}>Single Letter Code Conversion</h6>
                        <Row className="mb-3">

                            <Col lg="12" md="12" className="p-0 content float-left">
                                <div className="form-group px-3 ">
                                    <TextInput
                                        spellcheck="false"
                                        rows="20"
                                        multiline={true}
                                        fullWidth
                                        id="letterCodeConversion"
                                        name="st26input"
                                        label="Copy and Paste your Nucleotide sequences here"
                                        variant="outlined"
                                        value={formik.values.st26input}
                                        onChange={(event) => {
                                            formik.handleChange(event);
                                            if (!event.target.value) {
                                                resetFileName(); // Clear file input when text input is cleared
                                            }
                                        }}  
                                        error={formik.touched.st26input && Boolean(formik.errors.st26input)}
                                        helperText={formik.errors.st26input}
                                    //disabled={authInfo && authInfo.redo}
                                    />
                                </div>

                            </Col>

                        </Row>
                        <hr />
                    </Col>

                    <Col lg="12" md="12" className="float-right mb-3">

                        <p style={{display: 'table-cell' , fontSize: '15px' }}> <b id="show-text">Upload Single Letter Code File</b></p>
                        <input type="file" accept= ".txt" onChange={showfil} />
                        
                        {!formik.values.st26input ?
                            <Button className='cancelButtonDisable' color="default" disableRipple={true} variant="contained" >Convert to Three Letter Code</Button>
                            : 
                            <Button
                                className='accountInfo'
                                color="default"
                                disableRipple={true}
                                variant="contained"
                                onClick={() => {
                                    if (conversionCompletedforSingle) {
                                        downloadTextFile();
                                    } else {
                                        handleConvertToThree();
                                    }
                                }}
                            >
                                {conversionCompletedforSingle ? 'Download' : 'Convert to Three Letter Code'}
                            </Button>                        }
                        <Button variant="contained" disableRipple={true} color="default" className={classes.buttonStyleCancel} onClick={cncl}>{t('cancel')}</Button>

                    </Col>
                </Row>
                }  

            </form>
        </div>
    )
}

export default ThreeLetterCodeConversion;