import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import {useTranslation} from "react-i18next";
import TextInput from "../Fields/TextInput";
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	modalClassConDSI: {
		position: 'absolute',
		width: '114%',
		height: '82%',
		padding: '35px 15px 0px',
		right: 'auto',
		bottom: 'auto',
	},
	modalClassConDSIDark: {
		position: 'absolute',
		width: '114%',
		height: '82%',
		padding: '35px 15px 0px',
		right: 'auto',
		bottom: 'auto',
		backgroundColor: '#292929'
	},
	modalBoxContent: {
		maxHeight: '600px',
	},
	modalText: {
		position: 'absolute',
		top: '10px',
		right: '10px'
	},
}));

const DiscoverSaveResultsModal = props => {
	const { t } = useTranslation("common");
	const classes = useStyles();
	const [choice, setChoice] = useState('all');
	const [title, setTitle] = useState('');
	const [saveResultAsError, setSaveResultAsError] = useState(false);
	const toggle = useSelector(state => state.setToggle.toggle);

	useEffect(() => {
		document.getElementById("title")?.focus();
	})

	const changeSelection = (e) => {
		setChoice(e.target.value);
	}

	const callback = () => {
		// Get by DOM instead of title, especially for the default value case
		let val = document.getElementById("title").value;
		if (val && val.trim().length > 0 && val.length <= 188) {
			setSaveResultAsError(false);
			props.actionModal(props.fieldConfig, props.filter, props.resInfo, val, props.downloadType, choice);
		} else {
			setSaveResultAsError(true);
		}
	}

	const closeModal = () => {
		setSaveResultAsError(false);
		props.closeModal();
	}

	const changeTitle = (e) => {
		checkTitle(e.target.value);
	}

	function checkTitle(title) {
		let val = title;
		setTitle(val);
		if (!val || val.trim().length === 0 || val.length > 188) {
			setSaveResultAsError(true);
		} else {
			setSaveResultAsError(false);
		}
	}

	return (
		<Modal
			size="lg"
			aria-labelledby="contained-modal-title-vcente"
			centered
			show={props.showModal}
			contentClassName={toggle ? classes.modalClassConDSI : classes.modalClassConDSIDark}
		>
			<Link href="#" onClick={(e) => e.preventDefault()} className={classes.modalText}>
				<CloseIcon style={{fill : '#777777' }} onClick={closeModal} />
			</Link>
			<br></br>
			<div className={toggle ? "jumbotron appTextColor": "jumbotronDark appTextColor" } style = {{height: '90%'}}>
				<h2>{props.downloadType === 'FullTextV2' ? t("exportToFT") : t("saveResultAs")}</h2>
				<p style={{fontFamily: 'Helvetica', fontWeight: '200', wordSpacing: '-2px'}}>
					{props.downloadType === 'FullTextV2' ? "We will do it in the background and notify you when it is ready." :  t("saveResultAsBody")}
				</p>
				{props.downloadType === 'FullTextV2' &&
					<div className="filter-widget-textarea" >
						<label style={{padding: '0px'}}>Selections:</label>
						{props.selectedAlignmentsCount > 0 &&
							<div>
								<label style={{padding: '0px'}}>
									<input id="selected" type="radio" value="selected" name="exportGroup" defaultChecked={choice === 'selected'} onChange={changeSelection}/>
									<span htmlFor="selected"> {Number(props.selectedAlignmentsCount).toLocaleString()} selected alignment(s)</span>
								</label>
							</div>
						}
						{props.displayCustomSelection &&
							<div>
								<label style={{padding: '0px'}}>
									<input id="custom" type="radio" value="custom" name="exportGroup" defaultChecked={choice === 'custom'} onChange={changeSelection}/>
									<span htmlFor="custom"> {props.customSelectionLabel}</span>
								</label>
							</div>
						}
						<div>
							<label style={{padding: '0px'}}>
								<input id="all" type="radio" value="all" name="exportGroup" defaultChecked={choice === 'all'} onChange={changeSelection}/>
								<span htmlFor="all"> All {Number(props.totalAlignmentNumber).toLocaleString()} alignment(s)</span>
							</label>
						</div>
					</div>
				}
				{props.downloadType === 'FullTextV2' && choice === 'selected' && props.selectedAlignmentsCount > process.env.REACT_APP_EXPORT_LIMIT &&
					<b style={{color: 'red'}}>
						Maximum data export limit reached. 20,000 of {Number(props.selectedAlignmentsCount).toLocaleString()} alignments will be exported.
					</b>
				}
				{props.downloadType === 'FullTextV2' && choice === 'all' && props.totalAlignmentNumber > process.env.REACT_APP_EXPORT_LIMIT &&
					<b style={{color: 'red', paddingTop: '5px'}}>
						Maximum data export limit reached. 20,000 of {Number(props.totalAlignmentNumber).toLocaleString()} records will be exported.
					</b>
				}
				{props.downloadType === 'FullTextV2' && choice === 'custom' && props.customTtlCount > process.env.REACT_APP_EXPORT_LIMIT &&
					<b style={{color: 'red', paddingTop: '5px'}}>
						Maximum data export limit reached. 20,000 of {Number(props.customTtlCount).toLocaleString()} results will be exported.
					</b>
				}
				<div>
					<label style={{padding: '0px'}}>Result Name</label>&nbsp;&nbsp;
					<TextInput id="title" name="title" variant="outlined" onChange={changeTitle}
							   inputProps={{maxLength: 188}} defaultValue={title || props.defaultTitle}
							   error={saveResultAsError} fullWidth={true} autoFocus={true} spellcheck="false"
							   helperText={saveResultAsError ? t('required') : ""}/>
				</div>
				<div style={{padding: '20px'}}>
					<button onClick={callback} className="pull-right large-button gqblue">Save</button>
					<button onClick={closeModal} className="pull-right large-button grey">Cancel</button>
				</div>
			</div>
		</Modal>
	);
}

export default DiscoverSaveResultsModal;
