import ReactHtmlParser from "react-html-parser";
import React, {useCallback, useEffect, useState, useRef} from "react";
import styled from "styled-components";
import DiscoverService from "../../services/discoverService";
import _, { isInteger } from "lodash";
import pencilImg from "../../assets/image/pencil.png";
import RenameContainer from "../../shared/components/RenameContainer";
import {makeStyles} from "@material-ui/core/styles";
import DiscoverAnnotationModal from "../../shared/Modal/DiscoverAnnotationModal";
import DiscoverReportDataIssueModal from "../../shared/Modal/DiscoverReportDataIssueModal";
import "./ResultTableRecDetails.css"

const useStyles = makeStyles((theme) => ({
    loginSubmitCancel: {
        backgroundColor: '#0182C5',
        borderColor: '#1F4E79',
        border: '1px solid #1F4E79',
        color: 'white',
        margin: '4px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#0182C5',
            boxShadow: 'none',
        },
    },
}));

const QID_INFO = ['QUERY_ID', 'QUERY_L', 'QUERY_DE', 'RESULT_RIQ', 'RESULT_FQ', 'RESULT_GAPQ'];
const SID_INFO = ['SUBJECT_ID', 'SUBJECT_AC', 'SUBJECT_SI', 'SUBJECT_L', 'RESULT_RID', 'RESULT_NFD', 'RESULT_GAPD'];
const CAS_RGGROUP = ['SUBJECT_RG', 'SUBJECT_RB', 'SUBJECT_RC'];
const CAS_CDGROUP = ['SUBJECT_CD', 'SUBJECT_CK', 'SUBJECT_CL'];
const GXGROUP = ['SUBJECT_GX', 'UFS_DISPLAY', 'SUBJECT_PJ'];
const PCT_GROUP = ['SUBJECT_P4', 'SUBJECT_P5', 'SUBJECT_P8'];

const GROUPS = ['DOCHEADER', 'DOCLINKS', 'SUBJECT_FAMINFO', 'SUBJECT_P1', 'SUBJECT_PC', 'SUBJECT_CT', 'SUBJECT_CC', 'SUBJECT_SEQIDNOSUMM',
                'ASSIGNEES', 'PATENT_DATES', 'LEGAL_STATUS', 'ORGANISM', 'QUERY_ALI_INFO', 'SUBJECT_ALI_INFO', 'ALI_INFO', 'UFS_DISPLAY',
                'CAS_RGGROUP', 'CAS_CDGROUP', 'GXGROUP', 'PCT_GROUP'];

export default function ResultTableRecDetail({ data, item, idx, fieldConfig, filter, annotState, highlight, toggleFullRecordExpand, refreshAnnotState,
                                                 expandAllDetailPage, expandAllClaims, expandClaim, showLessClaim, expandAllAlignments, expandAlignment,
                                                 fromItemsPage, groupValue1, groupValue2, saveAnnotation, parentAnnotState, isAbWorkflow,
                                                setFtWindowsToOpen}) {
    const [selectedItem, setSelectedItem] = useState(item);
    // To Update Text Notes
    const [updateNotes, setUpdateNotes] = useState(false);
    const notesRef = useRef(annotState.notes[selectedItem.dbkey]);
    const classes = useStyles();

    // To batch update notes
    const [showAnnotationModal, setShowAnnotationModal] = useState(false);
    const [key, setKey] = useState('');
    const [type, setType] = useState('');
    const [value, setValue] = useState(0);
    let singleClickTimer = null;
    const[annotGroup, setAnnotGroup] = useState('');
    const[annotGroupDisplay, setAnnotGroupDisplay] = useState('');

    // Report data issue
    const [reportModal, setReportModal] = useState(false);
    const [reportPn, setReportPn] = useState('');

    const updateNewNotes = async (name) => {
        let notes = decodeURIComponent(name);
        notesRef.current = notes;
        annotState.notes[selectedItem.dbkey] = notes;
        refreshAnnotState(annotState);

        let selectedIds = [];
        selectedIds.push(annotState.dbkeys[selectedItem.dbkey]);
        let call = window.$javaCall;
        call.data['selectedIds'] = selectedIds;
        call.data['allPages'] = false;
        call.data['comment'] = notes;
        call.data['annotType'] = 'TN';
        DiscoverService.saveAnnotation(call.data, call.data.gqApiKey);

        setUpdateNotes(false);
    }

    const showUpdateNotes = (e, key, fieldConfig, item) => {
        if (singleClickTimer !== null) {
            clearTimeout(singleClickTimer);
            singleClickTimer = null;

            e.preventDefault();
            notesRef.current = annotState.notes[key];
            setUpdateNotes(true);
        } else {
            singleClickTimer = setTimeout(() => {
                clearTimeout(singleClickTimer);
                singleClickTimer = null;

                // Set parameters and open the pop up dialog
                setType('notes');
                setValue(annotState.notes[key]);
                setKey(key);
                calAnnotGroup(fieldConfig, item);
                setShowAnnotationModal(true);
            },  300);
        }
    }

    const calAnnotGroup = (fieldConfig, item) => {
        if (!groupValue1) {
            let groupVal = "";
            let groupName = "";
            // Allow batch update annotations for normal groups except CAS/HSP groups
            switch (fieldConfig.tableDisplayMode) {
                case 'family':
                    groupVal = item["SUBJECT_PB"];
                    groupName = "Patent Family";
                    break;
                case 'document':
                    groupVal = item["SUBJECT_PN"];
                    groupName = "Patent Number";
                    break;
                case 'ufs':
                    groupVal = item["SUBJECT_PJ"];
                    groupName = "Unique Family Sequence";
                    break;
                /*case 'cas':
                    groupVal = item["SUBJECT_RG"];
                    groupName = "CAS Registry Number";
                    break;*/
                case 'ssv':
                    groupVal = item["SUBJECT_ID"];
                    groupName = "Subject Sequence";
                    break;
                case 'qs':
                    groupVal = item["QUERY_ID"];
                    groupName = "Query Sequence";
                    break;
                default:
                    break;
            }
            setAnnotGroup(groupVal);
            setAnnotGroupDisplay(groupName);
        }
    }

    const callBack = (type, choice, annotated, dbkey, value, annotState,
                      filter, fieldConfig, fromItemsPage, groupValue1, groupValue2) => {
        saveAnnotation(type, choice, annotated, dbkey, value, annotState,
            filter, fieldConfig, fromItemsPage, groupValue1, groupValue2, parentAnnotState);
        closeModal();
    }

    const closeModal = () => {
        setShowAnnotationModal(false);
        setReportModal(false);
    }

    const reportDataIssue = (item) => {
        setReportPn(item.SUBJECT_ID);
        setReportModal(true);
    }

    useEffect(() => {
        // To draw the alignment chart, copied from "app.directive('alignmentChart', function (){})"
        let w = 600,
            //h = 45,
            barheight = 15, // how high is the bar we draw (px)
            spacer = 8; // how much space between query and subject (px)
        let chart = document.getElementById(selectedItem.dbkey + "-alignmentchart");
        let d = _.cloneDeep(item);
        if (d === undefined || d === null || chart === undefined || chart === null) {
            return;
        }
        // we are using HTML5 canvas here
        let canvas = chart.firstElementChild;
        let context = canvas.getContext('2d');
        // if rev complemented switch the vals
        if (d.RESULT_OOBD > d.RESULT_OOED) {
            let tmp = d.RESULT_OOED;
            d.RESULT_OOED = d.RESULT_OOBD;
            d.RESULT_OOBD = tmp;
        }
        let left_qry = d.RESULT_OOBQ - 1;
        let left_sbj = d.RESULT_OOBD - 1;
        let over_qry = d.RESULT_OOEQ - d.RESULT_OOBQ + 1;
        let over_sbj = d.RESULT_OOED - d.RESULT_OOBD + 1;
        let right_qry = d.QUERY_L - d.RESULT_OOEQ;
        let right_sbj = d.SUBJECT_L - d.RESULT_OOED;

        let left_max = ((left_qry > left_sbj) ? left_qry : left_sbj);
        let over_max = ((over_qry > over_sbj) ? over_qry : over_sbj);
        let right_max = ((right_qry > right_sbj) ? right_qry : right_sbj);
        let total = left_max + over_max + right_max;
        let scale = (w / total);

        // left and right side of the query
        context.fillStyle = '#66ADEA';
        context.fillRect(
            scale * (left_max - left_qry), // x
            0, // y
            scale * left_qry, // width
            barheight // height
        );
        context.fillRect(
            scale * (left_max + over_max), // x
            0, // y
            scale * right_qry, // width
            barheight // height
        );

        // left and right side of the subject
        context.fillStyle = '#CCCCCC';
        context.fillRect(
            scale * (left_max - left_sbj),
            barheight + spacer,
            scale * left_sbj,
            barheight
        );
        context.fillRect(
            scale * (left_max + over_max),
            barheight + spacer,
            scale * right_sbj,
            barheight
        );

        // query overlap region
        context.fillStyle = '#337ab7';
        context.fillRect(
            scale * left_max,
            0,
            scale * over_max,
            barheight
        );

        // subject overlap region
        context.fillStyle = '#999999';
        context.fillRect(
            scale * left_max,
            barheight + spacer,
            scale * over_max,
            barheight
        );
    }, []);

    function isSelectedDatabase(dbkey, dbId) {
        return data.docItemsSelected[dbkey] === dbId;
    }

    function selectDatabase(dbkey, name, value) {
        data.docItems[dbkey] = value;
        data.docItemsSelected[dbkey] = name;
        value.dbkey = dbkey;
        setSelectedItem(value);
    }

    function getWordAfterFulldocview(inputString) {
        const regex = /fulldocview\/(\w+)/;
        const match = inputString.match(regex);
        return match ? match[1] : null;
    }
    const openFtWindow = (thing)=>{

        let data ={
            pn: getWordAfterFulldocview(thing.VT_FT),
            seqIds: extractSeqIdNumber(thing),
            kws: ""
        }
        setFtWindowsToOpen(data)
    }

    const extractSeqIdNumber = (thing) => {

        if(thing !== undefined){

            if(thing.SUBJECT_SI !== undefined && thing.SUBJECT_SI !== null && thing.SUBJECT_SI !==""){
                return thing.SUBJECT_SI;
            }

            if(thing.SUBJECT_PS !== undefined){
                let str = thing.SUBJECT_PS
                const regex = /SEQ ID NO (\d+)/;
                // Use the regex to find the match
                const match = str.match(regex);
                // If a match is found, return the captured group (the number)
                if (match) {
                    if(match[1]){
                        return match[1];
                    }
                }
            }

            if(thing.SUBJECT_SEQIDNOSUMM !== undefined){
                if(thing.SUBJECT_SEQIDNOSUMM.Disclosure !== undefined){
                    if(thing.SUBJECT_SEQIDNOSUMM.Disclosure[""] !== undefined){
                        let str = thing.SUBJECT_SEQIDNOSUMM.Disclosure[""]
                        const regex = /SEQ ID NO (\d+)/;
                        // Use the regex to find the match
                        const match = str.match(regex);
                        // If a match is found, return the captured group (the number)
                        if (match) {
                            if(match[1]){
                                return match[1];
                            }
                        }
                    }else{
                        if(Object.keys(thing.SUBJECT_SEQIDNOSUMM.Disclosure)[0] !== null && Object.keys(thing.SUBJECT_SEQIDNOSUMM.Disclosure)[0] !== undefined){
                            if(Number.isInteger(parseInt(Object.keys(thing.SUBJECT_SEQIDNOSUMM.Disclosure)[0]))){
                                return Object.keys(thing.SUBJECT_SEQIDNOSUMM.Disclosure)[0];
                            }
                        }
                    }

                }else{
                    if(thing.SUBJECT_SEQIDNOSUMM.Claim  !== null && thing.SUBJECT_SEQIDNOSUMM.Claim  !== undefined){
                        if(Number.isInteger(parseInt(Object.keys(thing.SUBJECT_SEQIDNOSUMM.Claim)[0]))){
                            return Object.keys(thing.SUBJECT_SEQIDNOSUMM.Claim)[0];
                        }
                    }
                }
            }
        }

        // If no match is found, return null or any default value
        return "";
    };

    return (
        
            <div className="detail-container">
                <div className="clearfix">
                    {selectedItem.type === 'doc' &&
                    <div className="pull-left">
                        {selectedItem.databases && Object.keys(selectedItem.databases).length <= 1 &&
                            <span>
                            {Object.keys(selectedItem.databases).map(
                                    (name) => (
                                        <span key={'span_' + name}>{fieldConfig.getDbBriefNameForDocDetail(name)}</span>
                                    )
                            )}
                            </span>
                        }
                        {item.databases && Object.keys(item.databases).length > 1 &&
                            <span>
                            {Object.keys(item.databases).map(
                                (name, dbIdx) => (
                                    <span key={'span_' + name}>
                                    {dbIdx > 0 &&
                                        <span> | </span>
                                    }
                                    {isSelectedDatabase(selectedItem.dbkey, name) &&
                                        <span>{fieldConfig.getDbBriefNameForDocDetail(name)}</span>
                                    }
                                    {!isSelectedDatabase(selectedItem.dbkey, name) &&
                                        <a href="#" onClick={(e) => {selectDatabase(selectedItem.dbkey, name, item.databases[name]);e.preventDefault();}}>{fieldConfig.getDbBriefNameForDocDetail(name)}</a>
                                    }
                                    </span>
                                )
                            )}
                            </span>
                        }
                    </div>
                    }
                    {(selectedItem.type === 'sin' || selectedItem.type === 'flat') &&
                        <div className="pull-left">
                            <span className={"float-left appTextColor loginTitle"}><img onClick={e => showUpdateNotes(e, selectedItem.dbkey, fieldConfig, selectedItem)} src={pencilImg}/>&nbsp;&nbsp;</span>
                            {updateNotes ?
                                <RenameContainer applyNewName={updateNewNotes}
                                                 nameRef={notesRef}
                                                 placeHolderText={'Input your notes here'}
                                                 maxLength={256}
                                                 className={"float-left"}
                                                 allowEmpty={true}
                                                 cancelButtonClass={classes.loginSubmitCancel} setRenameEnabled={setUpdateNotes} />
                                : <span className={"text-notes"} onClick={e => showUpdateNotes(e, selectedItem.dbkey, fieldConfig, selectedItem)}>{annotState.notes[selectedItem.dbkey]}</span>}
                        </div>
                    }

                    <div className="pull-right details-header">
                        <span>Expand this | </span>
                        <a href="#" onClick={(e) => {expandAllDetailPage(selectedItem.type, true);e.preventDefault();}}>Expand all</a><span> | </span>
                        <a href="#" onClick={(e) => {toggleFullRecordExpand(selectedItem.dbkey);e.preventDefault();}}>Close this</a><span> | </span>
                        <a href="#" onClick={(e) => {expandAllDetailPage(selectedItem.type, false);e.preventDefault();}}>Close all</a>
                        {selectedItem.SUBJECT_GNAME && selectedItem.SUBJECT_GNAME.startsWith('GQPAT_') &&
                            <span> | <a href="#" onClick={(e) => {reportDataIssue(selectedItem);e.preventDefault();}}>Report Data Issue</a></span>
                        }
                    </div>
                </div>
                {selectedItem.type === 'doc' &&
                    <div><br/><br/></div>
                }

                {selectedItem.type && fieldConfig.getFullRecordFields(selectedItem.type, data.displayMode).map(
                    (col, colIdx) =>
                    <div key={selectedItem.dbkey + '_details_' + colIdx} className="details">
                        {col === 'DOCHEADER' &&
                            <div>
                                <div className="detail-header">{ReactHtmlParser(highlight(fieldConfig, filter, 'SUBJECT_PT', data.docItems[selectedItem.dbkey]['SUBJECT_PT'], true))}</div>
                                <div className="clearfix detail-spacer">
                                    {selectedItem.SUBJECT_PN &&
                                        <div className="pull-left">
                                            <span className="detail-field">{fieldConfig.getFieldInfo('SUBJECT_PN').title}</span> : {selectedItem.SUBJECT_PN}
                                            {selectedItem.SUBJECT_KC && selectedItem.SUBJECT_KC!=='' &&
                                                <span>-{selectedItem.SUBJECT_KC}</span>
                                            }
                                        </div>
                                    }
                                    {selectedItem.SUBJECT_AN &&
                                        <div className="pull-left pad-menu-item">
                                            <span className="detail-field">{fieldConfig.getFieldInfo('SUBJECT_AN').title}</span> : {selectedItem.SUBJECT_AN}
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {col === 'DOCLINKS' &&
                            <div>
                                <div class="detail-paragraph clearfix detail-spacer">
                                    {ReactHtmlParser(fieldConfig.prepLinks(data.docItems[selectedItem.dbkey]))}
                                    {data.docItems[selectedItem.dbkey].SUBJECT_PN && data.docItems[selectedItem.dbkey].VT_FT &&
                                        <div class="pull-left pad-menu-item sep-menu-item">
                                            <span className="ftLinkBlue" onClick={()=>{openFtWindow(data.docItems[selectedItem.dbkey])}}>Full Text Document</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {col === 'SUBJECT_FAMINFO' && fieldConfig.displayField(data.docItems[selectedItem.dbkey], col) &&
                            <div className="detail-spacer">
                                <table><tbody>
                                    <tr>
                                        <td className="detail-label">{fieldConfig.getFieldInfo('SUBJECT_PB').title}</td>
                                        <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'SUBJECT_PB', item['SUBJECT_PB'], false))}</td>
                                    </tr>
                                    <tr>
                                        <td className="detail-label">{fieldConfig.getFieldInfo('NBFAMMEMBERS').title}</td>
                                        <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'NBFAMMEMBERS', item['NBFAMMEMBERS'], false))}</td>
                                    </tr>
                                    {data.docItems[selectedItem.dbkey]['FAMMEMBERS_IN_CURRENT'] &&
                                        <tr>
                                            <td className="detail-label">{fieldConfig.getFieldInfo('FAMMEMBERS_IN_CURRENT').title}</td>
                                            <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'FAMMEMBERS_IN_CURRENT', data.docItems[selectedItem.dbkey]['FAMMEMBERS_IN_CURRENT'], false))}</td>
                                        </tr>
                                    }
                                    {data.docItems[selectedItem.dbkey]['FAMMEMBERS_OUT_CURRENT'] &&
                                        <tr>
                                            <td className="detail-label">{fieldConfig.getFieldInfo('FAMMEMBERS_OUT_CURRENT').title}</td>
                                            <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'FAMMEMBERS_OUT_CURRENT', data.docItems[selectedItem.dbkey]['FAMMEMBERS_OUT_CURRENT'], false))}</td>
                                        </tr>
                                    }
                                    {data.docItems[selectedItem.dbkey]['FAMMEMBERS_OUT_GQ'] &&
                                        <tr>
                                            <td className="detail-label">{fieldConfig.getFieldInfo('FAMMEMBERS_OUT_GQ').title}</td>
                                            <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'FAMMEMBERS_OUT_GQ', data.docItems[selectedItem.dbkey]['FAMMEMBERS_OUT_GQ'], false))}</td>
                                        </tr>
                                    }
                                </tbody></table>
                            </div>
                        }
                        {/*Abstract*/}
                        {col === 'SUBJECT_P1' && data.docItems[selectedItem.dbkey][col] &&
                            <div>
                                <div className="detail-label field-header">{fieldConfig.getFieldInfo(col).title}</div>
                                {/*sel-text="'{{field}}','{{docItems[selectedItem.dbkey].dbkey}}'" */}
                                <div className="detail-spacer">{ReactHtmlParser(highlight(fieldConfig, filter, col, data.docItems[selectedItem.dbkey][col], false))}</div>
                            </div>
                        }
                        {/*Patent Classification*/}
                        {col === 'SUBJECT_PC' && data.docItems[selectedItem.dbkey][col] && Object.keys(data.docItems[selectedItem.dbkey][col]).length > 0 &&
                            <div>
                                <div className="detail-label field-header">{fieldConfig.getFieldInfo(col).title}</div>
                                <div className="detail-spacer">
                                    <table className="classification"><tbody>
                                    {Object.keys(data.docItems[selectedItem.dbkey][col]).map(
                                        (cls) => (
                                            <tr key={'SUBJECT_PC_' + cls}>
                                                <td className="detail-label-sub">{cls}</td>
                                                <td>{data.docItems[selectedItem.dbkey][col][cls]}</td>
                                            </tr>
                                        )
                                    )}
                                    </tbody></table>
                                </div>
                            </div>
                        }

                        {/*Claim*/}
                        {col === 'SUBJECT_CT' && fieldConfig.displayField(data.docItems[selectedItem.dbkey], col)
                            && data.docItems[selectedItem.dbkey][col] && Object.keys(data.docItems[selectedItem.dbkey][col]).length > 0 &&
                            <div>
                                <div className="detail-label-no-width field-header">
                                    {fieldConfig.getFieldInfo(col).title}
                                    <div className="expand-close-right">
                                        {data.docItems[selectedItem.dbkey].SUBJECT_CT.length>0 && (annotState.claimsCollapse[selectedItem.dbkey]===undefined||annotState.claimsCollapse[selectedItem.dbkey]===true) &&
                                            <a href="#" onClick={(e) => {expandClaim(selectedItem.dbkey,false);e.preventDefault();}}>Expand this | </a>
                                        }
                                        {data.docItems[selectedItem.dbkey].SUBJECT_CT.length>0 && annotState.claimsCollapse[selectedItem.dbkey]===false &&
                                            <span>Expand this | </span>
                                        }
                                        <a href="#" onClick={(e) => {expandAllClaims(false);e.preventDefault();}}>Expand all</a><span> | </span>
                                        {data.docItems[selectedItem.dbkey].SUBJECT_CT.length>0 && (annotState.claimsCollapse[selectedItem.dbkey]===undefined||annotState.claimsCollapse[selectedItem.dbkey]===true) &&
                                            <span>Close this | </span>
                                        }
                                        {annotState.claimsCollapse[selectedItem.dbkey]===false &&
                                            <a href="#" onClick={(e) => {expandClaim(selectedItem.dbkey,true);e.preventDefault();}}>Close this | </a>
                                        }
                                        <a href="#" onClick={(e) => {expandAllClaims(true);e.preventDefault();}}>Close all</a>
                                    </div>
                                </div>
                                <div className="detail-spacer" >
                                    <div> {/*uib-collapse="annotState.claimsCollapse[selectedItem.dbkey]"*/}
                                        {annotState.claimsCollapse[selectedItem.dbkey]===false && annotState.claimsCollapseLess[selectedItem.dbkey] &&
                                            <div>
                                                {Object.keys(data.docItems[selectedItem.dbkey].SUBJECT_CT).map(
                                                    (cls, idx) => {
                                                        if (idx <= 3) {
                                                            return <div key={selectedItem.dbkey + '_claim_' + cls} className="claim small-claim-container">{ReactHtmlParser(highlight(fieldConfig, filter, col, data.docItems[selectedItem.dbkey].SUBJECT_CT[cls], false))}</div>;
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                <div className="clearfix">
                                                    <div className="pull-right">
                                                        <a href="#" onClick={(e) => {showLessClaim(selectedItem.dbkey,false);e.preventDefault();}}>show all claims ...</a>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {annotState.claimsCollapse[selectedItem.dbkey]===false && !annotState.claimsCollapseLess[selectedItem.dbkey] &&
                                            <div>
                                                {Object.keys(data.docItems[selectedItem.dbkey].SUBJECT_CT).map(cls => (
                                                    <div key={selectedItem.dbkey + '_claim_' + cls} className="claim small-claim-container">{ReactHtmlParser(highlight(fieldConfig, filter, col, data.docItems[selectedItem.dbkey].SUBJECT_CT[cls], false))}</div>
                                                ))}
                                                <div className="clearfix">
                                                    <div className="pull-right">
                                                        <a href="#" onClick={(e) => {showLessClaim(selectedItem.dbkey,true);e.preventDefault();}}>show less claims</a>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        {/*Comment*/}
                        {col === 'SUBJECT_CC' && data.docItems[selectedItem.dbkey][col] &&
                            <div>
                                <table className="detail-spacer"><tbody>
                                    <tr>
                                        <td className="detail-label">{fieldConfig.getFieldInfo(col).title}</td>
                                        <td>{ReactHtmlParser(highlight(fieldConfig, filter, col, data.docItems[selectedItem.dbkey][col], false))}</td>
                                    </tr></tbody>
                                </table>
                            </div>
                        }

                        {/*PSL*/}
                        {col === 'SUBJECT_SEQIDNOSUMM' && fieldConfig.displayField(data.docItems[selectedItem.dbkey], col) &&
                            <div>
                                <table className="detail-spacer"><tbody>
                                    <tr>
                                        <td className="detail-label">{fieldConfig.getFieldInfo(col).title}</td>
                                        <td>
                                            <table className="classification"><tbody>
                                                {data.docItems[selectedItem.dbkey].SUBJECT_SEQIDNOSUMM.Claim && Object.keys(data.docItems[selectedItem.dbkey].SUBJECT_SEQIDNOSUMM.Claim).length > 0 &&
                                                <tr>
                                                    <td className="side-label">Claimed</td>
                                                    <td>
                                                        {Object.keys(data.docItems[selectedItem.dbkey].SUBJECT_SEQIDNOSUMM.Claim).map(
                                                            (cls) => (
                                                                <div key={colIdx + cls}>
                                                                    SEQ ID NOs:{cls}
                                                                    {data.docItems[selectedItem.dbkey].SUBJECT_SEQIDNOSUMM.Claim[cls] &&
                                                                        <span key={'span_' + colIdx + cls}> ({data.docItems[selectedItem.dbkey].SUBJECT_SEQIDNOSUMM.Claim[cls]})</span>
                                                                    }
                                                                </div>
                                                            )
                                                        )}
                                                    </td>
                                                </tr>
                                                }
                                                {data.docItems[selectedItem.dbkey].SUBJECT_SEQIDNOSUMM.Disclosure && Object.keys(data.docItems[selectedItem.dbkey].SUBJECT_SEQIDNOSUMM.Disclosure).length > 0 &&
                                                    <tr>
                                                        <td className="side-label">Probable disclosure</td>
                                                        <td>
                                                            {Object.keys(data.docItems[selectedItem.dbkey].SUBJECT_SEQIDNOSUMM.Disclosure).map(
                                                                (cls) => (
                                                                    <div key={colIdx + cls}>
                                                                        SEQ ID NOs:{cls}
                                                                        {data.docItems[selectedItem.dbkey].SUBJECT_SEQIDNOSUMM.Disclosure[cls] &&
                                                                            <span key={'span_' + colIdx + cls}> ({data.docItems[selectedItem.dbkey].SUBJECT_SEQIDNOSUMM.Disclosure[cls]})</span>
                                                                        }
                                                                    ;&nbsp;</div>
                                                                )
                                                            )}
                                                        </td>
                                                    </tr>
                                                }
                                                {data.docItems[selectedItem.dbkey].SUBJECT_SEQIDNOSUMM['TBD'] && Object.keys(data.docItems[selectedItem.dbkey].SUBJECT_SEQIDNOSUMM['TBD']).length > 0 &&
                                                    <tr>
                                                        <td className="side-label">TBD</td>
                                                        <td>
                                                            {Object.keys(data.docItems[selectedItem.dbkey].SUBJECT_SEQIDNOSUMM['TBD']).map(
                                                                (cls) => (
                                                                    <div key={colIdx + cls}>
                                                                        SEQ ID NOs:{cls}
                                                                        {data.docItems[selectedItem.dbkey].SUBJECT_SEQIDNOSUMM['TBD'][cls] &&
                                                                            <span key={'span_' + colIdx + cls}> ({data.docItems[selectedItem.dbkey].SUBJECT_SEQIDNOSUMM['TBD'][cls]})</span>
                                                                        }
                                                                    ;&nbsp;</div>
                                                                )
                                                            )}
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody></table>
                                        </td>
                                    </tr>
                                </tbody></table>
                            </div>
                        }

                        {/*Assignees*/}
                        {col === 'ASSIGNEES' && (data.docItems[selectedItem.dbkey]['SUBJECT_PA'] || data.docItems[selectedItem.dbkey]['SUBJECT_PU'] || data.docItems[selectedItem.dbkey]['SUBJECT_PZ']
                            || data.docItems[selectedItem.dbkey]['SUBJECT_PI']) &&
                            <div>
                                <div className="detail-label field-header">{fieldConfig.getFieldInfo(col).title}</div>
                                <div className="detail-spacer">
                                    <table className="classification"><tbody>
                                    {data.docItems[selectedItem.dbkey]['SUBJECT_PA'] &&
                                        <tr>
                                            <td className="detail-label-sub">{fieldConfig.getFieldInfo('SUBJECT_PA').title}</td>
                                            <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'SUBJECT_PA', data.docItems[selectedItem.dbkey]['SUBJECT_PA'], false))}</td>
                                        </tr>
                                    }
                                    {data.docItems[selectedItem.dbkey]['SUBJECT_PU'] &&
                                        <tr>
                                            <td className="detail-label-sub">{fieldConfig.getFieldInfo('SUBJECT_PU').title}</td>
                                            <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'SUBJECT_PU', data.docItems[selectedItem.dbkey]['SUBJECT_PU'], false))}</td>
                                        </tr>
                                    }
                                    {data.docItems[selectedItem.dbkey]['SUBJECT_PZ'] &&
                                        <tr>
                                            <td className="detail-label-sub">{fieldConfig.getFieldInfo('SUBJECT_PZ').title}</td>
                                            <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'SUBJECT_PZ', data.docItems[selectedItem.dbkey]['SUBJECT_PZ'], false))}</td>
                                        </tr>
                                    }
                                    {data.docItems[selectedItem.dbkey]['SUBJECT_PI'] &&
                                        <tr>
                                            <td className="detail-label-sub">{fieldConfig.getFieldInfo('SUBJECT_PI').title}</td>
                                            <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'SUBJECT_PI', data.docItems[selectedItem.dbkey]['SUBJECT_PI'], false))}</td>
                                        </tr>
                                    }
                                    </tbody></table>
                                </div>

                            </div>
                        }

                        {/*Patent Dates*/}
                        {col === 'PATENT_DATES' && (data.docItems[selectedItem.dbkey]['SUBJECT_D3'] || data.docItems[selectedItem.dbkey]['SUBJECT_D4'] || data.docItems[selectedItem.dbkey]['SUBJECT_PR']
                                || data.docItems[selectedItem.dbkey]['SUBJECT_D5'] || data.docItems[selectedItem.dbkey]['SUBJECT_D2']) &&
                            <div>
                                <div className="detail-label field-header">{fieldConfig.getFieldInfo(col).title}</div>
                                <div className="detail-spacer">
                                    <table className="classification"><tbody>
                                    {data.docItems[selectedItem.dbkey]['SUBJECT_D3'] && data.docItems[selectedItem.dbkey]['SUBJECT_D3'] > 0 &&
                                        <tr>
                                            <td className="detail-label-sub">{fieldConfig.getFieldInfo('SUBJECT_D3').title}</td>
                                            <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'SUBJECT_D3', data.docItems[selectedItem.dbkey]['SUBJECT_D3'], false))}</td>
                                        </tr>
                                    }
                                    {data.docItems[selectedItem.dbkey]['SUBJECT_D4'] && data.docItems[selectedItem.dbkey]['SUBJECT_D4'] > 0 &&
                                        <tr>
                                            <td className="detail-label-sub">{fieldConfig.getFieldInfo('SUBJECT_D4').title}</td>
                                            <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'SUBJECT_D4', data.docItems[selectedItem.dbkey]['SUBJECT_D4'], false))}</td>
                                        </tr>
                                    }
                                    {data.docItems[selectedItem.dbkey]['SUBJECT_PR'] &&
                                        <tr>
                                            <td className="detail-label-sub">{fieldConfig.getFieldInfo('SUBJECT_PR').title}</td>
                                            <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'SUBJECT_PR', data.docItems[selectedItem.dbkey]['SUBJECT_PR'], false))}</td>
                                        </tr>
                                    }
                                    {data.docItems[selectedItem.dbkey]['SUBJECT_D5'] && data.docItems[selectedItem.dbkey]['SUBJECT_D5'] > 0 &&
                                        <tr>
                                            <td className="detail-label-sub">{fieldConfig.getFieldInfo('SUBJECT_D5').title}</td>
                                            <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'SUBJECT_D5', data.docItems[selectedItem.dbkey]['SUBJECT_D5'], false))}</td>
                                        </tr>
                                    }
                                    {data.docItems[selectedItem.dbkey]['SUBJECT_D2'] && data.docItems[selectedItem.dbkey]['SUBJECT_D2'] > 0 &&
                                        <tr>
                                            <td className="detail-label-sub">{fieldConfig.getFieldInfo('SUBJECT_D2').title}</td>
                                            <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'SUBJECT_D2', data.docItems[selectedItem.dbkey]['SUBJECT_D2'], false))}</td>
                                        </tr>
                                    }
                                    </tbody></table>
                                </div>

                            </div>
                        }

                        {/*Legal Status*/}
                        {col === 'LEGAL_STATUS' && (data.docItems[selectedItem.dbkey]['SUBJECT_PG'] || data.docItems[selectedItem.dbkey]['SUBJECT_PH'] || data.docItems[selectedItem.dbkey]['SUBJECT_L3']) &&
                            <div>
                                <div className="detail-label field-header">{fieldConfig.getFieldInfo(col).title}</div>
                                <div className="detail-spacer">
                                    <table className="classification"><tbody>
                                    {data.docItems[selectedItem.dbkey]['SUBJECT_PG'] &&
                                        <tr>
                                            <td className="detail-label-sub">{fieldConfig.getFieldInfo('SUBJECT_PG').title}</td>
                                            <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'SUBJECT_PG', data.docItems[selectedItem.dbkey]['SUBJECT_PG'], false))}</td>
                                        </tr>
                                    }
                                    {data.docItems[selectedItem.dbkey]['SUBJECT_PH'] &&
                                        <tr>
                                            <td className="detail-label-sub">{fieldConfig.getFieldInfo('SUBJECT_PH').title}</td>
                                            <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'SUBJECT_PH', data.docItems[selectedItem.dbkey]['SUBJECT_PH'], false))}</td>
                                        </tr>
                                    }
                                    {data.docItems[selectedItem.dbkey]['SUBJECT_L3'] &&
                                        <tr>
                                            <td className="detail-label-sub">{fieldConfig.getFieldInfo('SUBJECT_L3').title}</td>
                                            <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'SUBJECT_L3', data.docItems[selectedItem.dbkey]['SUBJECT_L3'], false))}</td>
                                        </tr>
                                    }
                                    </tbody></table>
                                </div>

                            </div>
                        }

                        {/*ORGANISM*/}
                        {col === 'ORGANISM' && (data.docItems[selectedItem.dbkey]['SUBJECT_OS'] || data.docItems[selectedItem.dbkey]['SUBJECT_OO'] || data.docItems[selectedItem.dbkey]['SUBJECT_OX']) &&
                            <div>
                                <table className="detail-spacer classification"><tbody>
                                    <tr>
                                        <td className="detail-label">{fieldConfig.getFieldInfo('ORGANISM').title}</td>
                                        <td>
                                            <span>{ReactHtmlParser(highlight(fieldConfig, filter, 'SUBJECT_OS', data.docItems[selectedItem.dbkey]['SUBJECT_OS'], false))}
                                            </span> ( <span>{ReactHtmlParser(highlight(fieldConfig, filter, 'SUBJECT_OX', data.docItems[selectedItem.dbkey]['SUBJECT_OX'], false))}</span>)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="detail-label-sub"></td>
                                        <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'SUBJECT_OO', data.docItems[selectedItem.dbkey]['SUBJECT_OO'], false))}</td>
                                    </tr>
                                    </tbody></table>
                            </div>
                        }

                        {/*Query info*/}
                        {col === 'QUERY_ALI_INFO' &&
                            <div>
                                <div className="detail-label field-header">{fieldConfig.getFieldInfo(col).title}</div>
                                <div className="detail-spacer">
                                    <table className="classification"><tbody>
                                        {
                                            QID_INFO.map(fieldId =>
                                            {
                                                if (data.docItems[selectedItem.dbkey][fieldId] || data.docItems[selectedItem.dbkey][fieldId] === 0) {
                                                    return <tr key={fieldId}>
                                                        <td className="detail-label-sub">{fieldConfig.getFieldInfo(fieldId).title}</td>
                                                        <td>{ReactHtmlParser(highlight(fieldConfig, filter, fieldId, data.docItems[selectedItem.dbkey][fieldId], false))}</td>
                                                    </tr>;
                                                } else {
                                                    return null;
                                                }
                                            })
                                        }
                                        <tr>
                                            <td className="detail-label-sub">Query Alignment Position</td>
                                            <td>{data.docItems[selectedItem.dbkey]['RESULT_OOBQ'] + ' - ' + data.docItems[selectedItem.dbkey]['RESULT_OOEQ']}</td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                        }

                        {/*Subject info*/}
                        {col === 'SUBJECT_ALI_INFO' &&
                            <div>
                                <div className="detail-label field-header">{fieldConfig.getFieldInfo(col).title}</div>
                                <div className="detail-spacer">
                                    <table className="classification"><tbody>
                                    {
                                        SID_INFO.map(fieldId =>
                                            {
                                                if (data.docItems[selectedItem.dbkey][fieldId] || data.docItems[selectedItem.dbkey][fieldId] === 0) {
                                                    return <tr key={fieldId}>
                                                        <td className="detail-label-sub">{fieldConfig.getFieldInfo(fieldId).title}</td>
                                                        <td>{ReactHtmlParser(highlight(fieldConfig, filter, fieldId, data.docItems[selectedItem.dbkey][fieldId], false))}</td>
                                                    </tr>;
                                                } else { return null};
                                            })
                                    }
                                    <tr>
                                        <td className="detail-label-sub">Subject Alignment Position</td>
                                        <td>{data.docItems[selectedItem.dbkey]['RESULT_OOBD'] + ' - ' + data.docItems[selectedItem.dbkey]['RESULT_OOED']}</td>
                                    </tr>
                                    <tr>
                                        <td className="detail-label-sub">Subject Sequence Orientation</td>
                                        {data.docItems[selectedItem.dbkey].RESULT_NFQ >= 3 && data.docItems[selectedItem.dbkey].RESULT_NFQ <= 6 &&
                                            <td>Reverse</td>
                                        }
                                        {(data.docItems[selectedItem.dbkey].RESULT_NFQ < 3 || data.docItems[selectedItem.dbkey].RESULT_NFQ > 6) &&
                                            <td>Forward</td>
                                        }
                                    </tr>
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                        }

                        {/*Alignment*/}
                        {col === 'ALI_INFO' &&
                            <div>
                                <div className="detail-label-no-width field-header">
                                    {fieldConfig.getFieldInfo(col).title}
                                    <div className="expand-close-right">
                                        {(annotState.alignmentsCollapse[selectedItem.dbkey]===undefined||annotState.alignmentsCollapse[selectedItem.dbkey]===true) &&
                                            <a href="#" onClick={(e) => {expandAlignment(selectedItem.dbkey,false);e.preventDefault();}}>Expand this | </a>
                                        }
                                        {annotState.alignmentsCollapse[selectedItem.dbkey]===false &&
                                            <span>Expand this | </span>
                                        }
                                        <a href="#" onClick={(e) => {expandAllAlignments(false);e.preventDefault();}}>Expand all</a><span> | </span>
                                        {(annotState.alignmentsCollapse[selectedItem.dbkey]===undefined||annotState.alignmentsCollapse[selectedItem.dbkey]===true) &&
                                            <span>Close this | </span>
                                        }
                                        {annotState.alignmentsCollapse[selectedItem.dbkey]===false &&
                                            <a href="#" onClick={(e) => {expandAlignment(selectedItem.dbkey,true);e.preventDefault();}}>Close this | </a>
                                        }
                                        <a href="#" onClick={(e) => {expandAllAlignments(true);e.preventDefault();}}>Close all</a>
                                    </div>
                                </div>
                                <div className="detail-spacer">
                                    {annotState.alignmentsCollapse[selectedItem.dbkey]===false &&
                                    <table className="classification"><tbody>
                                        <tr>
                                            <td className="detail-label-sub">Algorithm</td>
                                            <td>{data.algorithm}</td>
                                        </tr>
                                        <tr>
                                            <td className="detail-label-sub">{fieldConfig.getFieldInfo('RESULT_RL').title}</td>
                                            <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'RESULT_RL', data.docItems[selectedItem.dbkey]['RESULT_RL'], false))}</td>
                                        </tr>
                                        <tr>
                                            <td className="detail-label-sub">{fieldConfig.getFieldInfo('RESULT_RI').title}</td>
                                            <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'RESULT_RI', data.docItems[selectedItem.dbkey]['RESULT_RI'], false))}</td>
                                        </tr>
                                        {data.algorithm === 'BLAST' &&
                                            <tr>
                                                <td className="detail-label-sub">{fieldConfig.getFieldInfo('RESULT_RE').title}</td>
                                                <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'RESULT_RE', data.docItems[selectedItem.dbkey]['RESULT_RE'], false))}</td>
                                            </tr>
                                        }
                                        {data.algorithm === 'BLAST' &&
                                            <tr>
                                                <td className="detail-label-sub">{fieldConfig.getFieldInfo('RESULT_RS').title}</td>
                                                <td>{ReactHtmlParser(highlight(fieldConfig, filter, 'RESULT_RS', data.docItems[selectedItem.dbkey]['RESULT_RS'], false))}</td>
                                            </tr>
                                        }
                                    </tbody></table>
                                    }
                                </div>
                                <div className={"detail-spacer-alignment " + (annotState.alignmentsCollapse[selectedItem.dbkey]===false ? "" : "hidden")}>
                                    <div>
                                        <div className="alignment-chart" id={selectedItem.dbkey + "-alignmentchart"}><canvas width="600" height="45"></canvas></div>
                                        <pre style={{overflowX: 'hidden'}} className="ali-pre" dangerouslySetInnerHTML={{__html: data.gqAlignments[data.docItems[selectedItem.dbkey].dbkey]}}></pre>
                                    </div>
                                </div>
                            </div>
                        }

                        {/*UFS*/}
                        {col === 'UFS_DISPLAY' && fieldConfig.displayField(data.docItems[selectedItem.dbkey], col) &&
                            <div>
                                <table className="detail-spacer">
                                    <tr>
                                        <td className="detail-label">{fieldConfig.getFieldInfo(col).title}</td>
                                        <td>{ReactHtmlParser(highlight(fieldConfig, filter, col, data.docItems[selectedItem.dbkey][col], false))}</td>
                                    </tr>
                                </table>
                            </div>
                        }

                        {/*CAS RG Group*/}
                        {col === 'CAS_RGGROUP' && (data.docItems[selectedItem.dbkey]['SUBJECT_RG'] || data.docItems[selectedItem.dbkey]['SUBJECT_RB'] || data.docItems[selectedItem.dbkey]['SUBJECT_RC']) &&
                            <div>
                                <div className="detail-spacer">
                                    <table className="classification"><tbody>
                                    {
                                        CAS_RGGROUP.map(fieldId =>
                                        {
                                            if (data.docItems[selectedItem.dbkey][fieldId]) {
                                                return <tr key={fieldId}>
                                                    <td className="detail-label-sub" dangerouslySetInnerHTML={{__html: fieldConfig.getFieldName(fieldId, data.algorithm, isAbWorkflow)}}></td>
                                                    <td>{ReactHtmlParser(highlight(fieldConfig, filter, fieldId, data.docItems[selectedItem.dbkey][fieldId], false))}</td>
                                                </tr>;
                                            } else {
                                                return null;
                                            }
                                        })
                                    }
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                        }

                        {/*CAS CD Group*/}
                        {col === 'CAS_CDGROUP' && (data.docItems[selectedItem.dbkey]['SUBJECT_CD'] || data.docItems[selectedItem.dbkey]['SUBJECT_CK'] || data.docItems[selectedItem.dbkey]['SUBJECT_CL']) &&
                            <div>
                                <div className="detail-spacer">
                                    <table className="classification"><tbody>
                                    {
                                        CAS_CDGROUP.map(fieldId =>
                                        {
                                            if (data.docItems[selectedItem.dbkey][fieldId]) {
                                                return <tr key={fieldId}>
                                                    <td className="detail-label-sub">{fieldConfig.getFieldInfo(fieldId).title}</td>
                                                    <td>{ReactHtmlParser(highlight(fieldConfig, filter, fieldId, data.docItems[selectedItem.dbkey][fieldId], false))}</td>
                                                </tr>;
                                            } else {
                                                return null;
                                            }
                                        })
                                    }
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                        }

                        {/*GQ ID Group*/}
                        {col === 'GXGROUP' && (data.docItems[selectedItem.dbkey]['SUBJECT_GX'] || data.docItems[selectedItem.dbkey]['UFS_DISPLAY'] || data.docItems[selectedItem.dbkey]['SUBJECT_PJ']) &&
                            <div>
                                <div className="detail-spacer">
                                    <table className="classification"><tbody>
                                    {
                                        GXGROUP.map(fieldId =>
                                        {
                                            if (data.docItems[selectedItem.dbkey][fieldId]) {
                                                return <tr key={fieldId}>
                                                    <td className="detail-label">{fieldConfig.getFieldInfo(fieldId).title}</td>
                                                    <td>{ReactHtmlParser(highlight(fieldConfig, filter, fieldId, data.docItems[selectedItem.dbkey][fieldId], false))}</td>
                                                </tr>;
                                            } else {
                                                return null;
                                            }
                                        })
                                    }
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                        }

                        {/*PCT Group*/}
                        {col === 'PCT_GROUP' && (data.docItems[selectedItem.dbkey]['SUBJECT_P4'] || data.docItems[selectedItem.dbkey]['SUBJECT_P5'] || data.docItems[selectedItem.dbkey]['SUBJECT_P8']) &&
                            <div>
                                <div className="detail-spacer">
                                    <table className="classification"><tbody>
                                    {
                                        PCT_GROUP.map(fieldId =>
                                        {
                                            if (data.docItems[selectedItem.dbkey][fieldId]) {
                                                return <tr key={fieldId}>
                                                    <td className="detail-label">{fieldConfig.getFieldInfo(fieldId).title}</td>
                                                    <td>{ReactHtmlParser(highlight(fieldConfig, filter, fieldId, data.docItems[selectedItem.dbkey][fieldId], false))}</td>
                                                </tr>;
                                            } else {
                                                return null;
                                            }
                                        })
                                    }
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                        }

                        {GROUPS.indexOf(col) < 0 && data.docItems[selectedItem.dbkey][col] &&
                            <div>
                                <table className="detail-spacer"><tbody>
                                <tr>
                                    <td className="detail-label">{fieldConfig.getFieldInfo(col).title}</td>
                                    <td>{ReactHtmlParser(highlight(fieldConfig, filter, col, data.docItems[selectedItem.dbkey][col], false))}</td>
                                </tr>
                                </tbody></table>
                            </div>
                        }

                    </div>
                )}

            {showAnnotationModal && <DiscoverAnnotationModal showModal={showAnnotationModal} type={type} dbkey={key} annotState={annotState} filter={filter}
                                                                   fieldConfig={fieldConfig} fromItemsPage={fromItemsPage} groupValue1={groupValue1} groupValue2={groupValue2}
                                                                   actionModal={callBack} closeModal={closeModal} value={value} parentAnnotState={parentAnnotState}
                                                                   annotGroup={annotGroup} annotGroupDisplay={annotGroupDisplay}/>}
            {reportModal && <DiscoverReportDataIssueModal showModal={reportModal} closeModal={closeModal} patentNumber={reportPn} />}
        </div>
    )
}
