import React, { useState, useEffect } from "react";
import {useHistory, Link, useParams} from "react-router-dom";
import fullTextService from "../../../../services/fulltextsearch"


function FtOntologySuggestion(props){
    
    const history = useHistory();

    //returns luceneQueryValue == Search
    const {FocusedTerm, searchedTerm, setSearchedTerm, setResCount, userInfo, ontologyString, selectSearchTerm, openOrClosed, checkOpen, openOntologyOptions} = props;
    const [ontologyTextList, setOntologyTextList] = useState({});

    useEffect(() => {
        if(FocusedTerm !="" && fullTextService.levenshteinDistance(FocusedTerm, searchedTerm) >=2 && userInfo != undefined){
            getOntologySuggestions();     
            console.log("Searching "+FocusedTerm)  
        }
                
        // const interval = setInterval(function(){
        //     checkOpen();
        // }, 3000);
        // return () => {
        //     clearInterval(interval);
        // }

	}, [FocusedTerm, searchedTerm]);

    const getOntologySuggestions = async () => {
		
		//toast.success('start');
		let userId = userInfo && userInfo.current_user.gq_user_id;
		let searchParam = `&json_query=${encodeURIComponent(FocusedTerm)}&rows=25&ontologies=${ontologyString}&user_id=${userId}`;
		const searchRes = await fullTextService.getFullTextSearchTerm(
		 	history,
		 	searchParam
		);

        //const searchRes = Test.test2;
		if (searchRes && searchRes.response_status == 0) {
		    if(searchRes.response_content && searchRes.response_content.results){
		    	if (searchRes.response_content.results.length > 0) {
                    setResCount(searchRes.response_content.results.length)
		    		if(searchRes.response_content.results){
		    			setOntologyTextList(searchRes.response_content.results);
                        setSearchedTerm(FocusedTerm);
                        return true;
                    }
		    	}
		    }	
		}
        setResCount(0)
        return false;
	}

    //synonym popup
    const [synonymPopup, setSynonymPopup] = useState(false);
	const [synonymPopupX, setSynonymPopupX] = useState(0);
	const [synonymPopupY, setSynonymPopupY] = useState(0);
	const [synonymItems, setSynonymItems] = useState([]);

    const showSynonyms = (event, synonymList) =>{
		setSynonymPopupX(event.clientX);
		setSynonymPopupY(event.clientY);
		if(synonymList != null && synonymList != undefined){
			setSynonymItems(synonymList);
		}else{
			setSynonymItems([]);
		}
	}

    const showOntologyCode = (id) => {
		let splitId = id.split(":");
		return splitId[0];
	};


    function handleSelection(e, data){ 
        showSynonyms(e, []);
        selectSearchTerm(data);
    }

    return(
        <>
        {openOrClosed && 
            <div>
            <span className="ftCustomHeader">Ontologies:</span>
                <div className="createOntologyButton" onClick={()=>openOntologyOptions()}>
                    <span onClick={()=>openOntologyOptions()}>Create New</span>
                </div>
                
                <div className="ftScrolly ftScrollx ftOntologySuggestions">
                {synonymItems.length !=0 && synonymItems[0] != "" &&
				<div 
					className={"ftSynonymPopup"}
					style={{ top: synonymPopupY, left: synonymPopupX}}
				>
					<table>
						<th>
							<tr>
								<span className="loginTitle synonymTitle">This Ontology includes the following:</span>
							</tr>
						</th>
						<tbody>
						<ul className="col-md-12 ftOntologyItem paddingletf15">
							{synonymItems !=[] &&
								synonymItems.map((data, i) => {
									return(
											<p className="ftNoLineBreak">
												{data}
											</p>
									);
								})
							}
		   			    </ul>
						</tbody>
					</table>
				</div>}
                {ontologyTextList && ontologyTextList.length > 0 && ontologyTextList.map((data, i) => {
                        return (
                            <div
                                key={i}
                                className="col-md-12 list-inline ftOntologyItem"
                                onClick={(e) => handleSelection(e, data)}
                            >
                                <span onMouseOver={(e)=>showSynonyms(e, data.synonyms)} 
                                onMouseLeave={(e)=>showSynonyms(e, [])}
                                >
                                    <span
                                        className={showOntologyCode(data.id) == "USER"? "autoqueryUser" : "autoquery"}
                                    >
                                        {showOntologyCode(data.id)}
                                    </span>{" "}
                                    <span
                                        className="ftNoLineBreak"
                                    >
                                        {data.term}
                                    </span>
                                </span>
                            </div> 
                        );
                    })}
                </div>
            </div>
        } 
        </>
    );

}

export default FtOntologySuggestion;
