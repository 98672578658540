import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import _ from "lodash";
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, 
    PointElement, LineElement,Title, Tooltip, Legend } from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';

ChartJS.register(ArcElement, 
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title, Tooltip, Legend);

    const Styles = styled.div`

  .pull-left {
    float: left !important;
  }
  .multi_body{
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #777 !important;
  }

`


    export default function TabUnderTool({
        chartobj,
        chart,
        multiQuery,
        queryDbStats,
        querydb,
        supportedDisplayModes,
        handleMulti,
    }) {

    
    const[selectChart, setSelectChart] = useState('dateTrendChart');
    //console.log(queryDbStats,"queryDbStats");
    let queryDbLength = queryDbStats ? (Object.keys(queryDbStats).length -1) : 1;
    let newObj = [];
    //  console.log(queryDbLength,"queryDbLength");
    let revqueryDbStats = queryDbStats;

    function handleApplyGroup(field,nbseqs){
        handleMulti(field,nbseqs);
    }

        return(
            <Styles>
            { chart &&    
            <div className="arrow_box">
                
                    <div className="clearfix">
                    <div className="pull-left chart-thumb-container">
                                <div className="clearfix ">
                                    <div className={(selectChart == 'dateTrendChart'? "chart-thumb-sel ":"")+"chart-thumb pull-left"} onClick={() => setSelectChart('dateTrendChart')} >
                                        <Line width={130} height={100} data={chartobj['dateTrendChart_thumb'][1].data} options={chartobj['dateTrendChart_thumb'][1].options}></Line>
                                    </div>
                                    <div className={(selectChart == 'authFacetChart'? "chart-thumb-sel ":"")+"chart-thumb pull-left"} onClick={() => setSelectChart('authFacetChart')}>
                                        <Doughnut data={chartobj['authFacetChart_thumb'][1].data} options={chartobj['authFacetChart_thumb'][1].options}></Doughnut>
                                    </div>
                                </div>
                                <div className="clearfix">
                                    <div className={(selectChart == 'statusFacetChart'? "chart-thumb-sel ":"")+"chart-thumb pull-left"} onClick={() => setSelectChart('statusFacetChart')}>
                                       <Doughnut width={200} height={150} data={chartobj['statusFacetChart_thumb'][1].data} options={chartobj['statusFacetChart_thumb'][1].options}></Doughnut>
                                    </div>
                                    <div className={(selectChart == 'AssigneeFacetChart'? "chart-thumb-sel ":"")+"chart-thumb pull-left"} onClick={() => setSelectChart('AssigneeFacetChart')} >
                                        <Doughnut width={200} height={150} data={chartobj['AssigneeFacetChart_thumb'][1].data} options={chartobj['AssigneeFacetChart_thumb'][1].options}></Doughnut>
                                    </div>
                                </div>
                                <div className="clearfix">
                                    <div className={(selectChart == 'PslFacetChart'? "chart-thumb-sel ":"")+"chart-thumb pull-left"} onClick={() => setSelectChart('PslFacetChart')} >
                                        <Doughnut width={200} height={150} data={chartobj['PslFacetChart_thumb'][1].data} options={chartobj['PslFacetChart_thumb'][1].options}></Doughnut>
                                    </div>
                                </div>
                                
                    </div>
                    <div className="pull-left">
                       {selectChart == 'dateTrendChart' && <Line width={700} height={460} data={chartobj['dateTrendChart'][1].data} options={chartobj['dateTrendChart'][1].options}></Line>}
                       {selectChart == 'AssigneeFacetChart' && <Doughnut width={700} height={360} data={chartobj['AssigneeFacetChart'][1].data} options={chartobj['AssigneeFacetChart'][1].options}></Doughnut>}
                       {selectChart == 'statusFacetChart' && <Doughnut width={700} height={360} data={chartobj['statusFacetChart'][1].data} options={chartobj['statusFacetChart'][1].options}></Doughnut>}
                       {selectChart == 'PslFacetChart' && <Doughnut width={700} height={360} data={chartobj['PslFacetChart'][1].data} options={chartobj['PslFacetChart'][1].options}></Doughnut>}
                       {selectChart == 'authFacetChart' && <Doughnut width={700} height={360} data={chartobj['authFacetChart'][1].data} options={chartobj['authFacetChart'][1].options}></Doughnut>}
                    </div>
                    </div>
                <style>{`.arrow_box:after, .arrow_box:before { left: 60px; }`}</style>
            </div>
            }
            { multiQuery &&
            <div className="arrow_box">
                <div>
                    <div className="header-font multi_body">Multiple Query Sequence Analysis</div>
                    {Object.keys(revqueryDbStats)
                    //.sort()
                    .reverse()
                    .map((nbseq) => {
                        if(nbseq != 'TOTAL') //to avoid displaying the total object
                        return(
                        <div className="clearfix multi_body">
                            
                            <div  className={(nbseq == queryDbLength ? "top-spacer ":"")+"pull-left query-stats-left "} >
                                matching {(nbseq < querydb.count) ? nbseq +' out of '+querydb.count+' query seqs':(querydb.count >2 ?'all '+querydb.count :'both')+' query seqs'}:
                            </div>
                            <div className={(nbseq == queryDbLength ? "top-spacer ":"")+"pull-left "}>
                                <ul>
                                    {supportedDisplayModes.family && revqueryDbStats[nbseq]['SUBJECT_PB'] >0 && <li><a onClick={() => handleApplyGroup('SUBJECT_PB',nbseq)}>{revqueryDbStats[nbseq]['SUBJECT_PB']} Patent Families</a></li>}
                                    {supportedDisplayModes.document && revqueryDbStats[nbseq]['SUBJECT_PN'] >0 && <li><a onClick={() => handleApplyGroup('SUBJECT_PN',nbseq)}>{revqueryDbStats[nbseq]['SUBJECT_PN']} Patent Numbers</a></li>}
                                    {supportedDisplayModes.ufs && revqueryDbStats[nbseq]['SUBJECT_PJ'] >0 && <li><a onClick={() => handleApplyGroup('SUBJECT_PJ',nbseq)}>{revqueryDbStats[nbseq]['SUBJECT_PJ']} Unique Sequences</a></li>}
                                </ul>
                             </div>
                    
                        </div>)
                        return(null)
                    
                    })}
                    <div className="clearfix multi_body">
                            <div className="pull-left query-stats-left top-spacer ">
                                <div className="header-font">Total</div>
                                matching at least one query seq
                            </div>
                            <div className="pull-left top-spacer">
                                <ul>
                                    {supportedDisplayModes.family && queryDbStats.TOTAL.SUBJECT_PB > 0 && <li>{queryDbStats.TOTAL.SUBJECT_PB} Patent Families</li>}
                                    {supportedDisplayModes.document && queryDbStats.TOTAL.SUBJECT_PN > 0 && <li>{queryDbStats.TOTAL.SUBJECT_PN} Patent Numbers</li>}
                                    {supportedDisplayModes.ufs && queryDbStats.TOTAL.SUBJECT_PJ > 0 && <li>{queryDbStats.TOTAL.SUBJECT_PJ} Unique Sequences</li>}
                                </ul>
                            </div>
                    </div>
                    <div className="clearfix">
                     <button className="pull-left normal-button gqblue" style={{margin:"10px 0 0 0"}} onClick={() => handleApplyGroup('TOTAL',0)}>Reset</button>
                     </div>
                </div>
                <style>{`.arrow_box:after, .arrow_box:before { left: 110px; }`}</style>
            </div>
            }
            </Styles>
        )
    }
