import React, { useState, useEffect, useRef } from "react";
import CheckBox from '../../shared/Fields/CheckBox';
import CloseIcon from '@material-ui/icons/Close';
import {BsFillQuestionCircleFill} from "react-icons/bs"; 
import Tooltip from "@material-ui/core/Tooltip";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import KeywordsInContext from "./KeywordsInContext";
import _, { set } from "lodash";
import { useSelector } from "react-redux";
import Tour from "../../shared/components/Tour";


import { ftDocManagerState, getInitialManagerValue } from "../../services/FTDocService";
//import Tour from "../../shared/components/Tour"

function FullDocManager(props){
    

    const {ftWindowsToOpen, setFtWindowsToOpen, closeManager } = props

    const managerKey = "ftDocManager"

    const [managerState, setManagerState] = useState(getInitialManagerValue(managerKey));
    const [docsSelected, setDocsSelected] = useState([]);
    const toggle = useSelector(state => state.setToggle.toggle);

    const [showTour, setShowTour] = useState(false)

    const [nextTour, setNextTour] = useState(false)
    const startNextTour = ()=> {
        setNextTour(true)
    }

    const [documentKeywordInfo, setDocumentKeywordInfo] = useState([])

    const [switchTourState, setSwitchTourState] = useState(false);
    const [tourState, setTourState] = useState(0);
    const startPrevTour = ()=> {
        setTourState(TourData.length-1)
        setShowTour(true);
        setSwitchTourState(!switchTourState)
    }

    const tour1 = React.createRef();
    const tour2 = React.createRef();
    const tour3 = React.createRef();


    const TourData = [
        {
            text: "This is the Text Document manager. Text documents opened on this discover page can be viewed Here",
            anchor: tour1,
            position: "center"
        },
        {
            text: "This panel shows GQ text documents open from this page.",
            anchor: tour2,
            position: "center",
        },
        {
            text: "When documents are open. the Focus buttom will bring the document to the front. Close will do close the document for you",
            anchor: tour2,
            position: "center",
        },
        {
            text: "When documents are open. the checkbox on a document allows you to select it for keyword and sequence-ID application",
            anchor: tour2,
            position: "center",
        },
    ]

    useEffect(() => {
        let state = localStorage.getItem(managerKey);
        if(state == null || state == undefined || state ==""){
            localStorage.setItem(managerKey, JSON.stringify(managerState))
        }

    },[])

    const [parentWindow, setParentWindow] = useState(null);
    const [parentKey, setParentKey] = useState("");
    useEffect(()=>{
        if(window.parent){

            if (!/discover\/wf:[0-9]+\.resdb$/.test(window.parent.location.href)) {
                return;
            }

            let match = window.parent.location.href.match(/wf:[0-9]+\.resdb/)
            let urlKey = match ? match[0] : null;
            setParentKey(urlKey)

            setParentWindow(window.parent)
        }
    },[])

    const [windows, setWindows] = useState({});
    useEffect(() => {
    
        if(ftWindowsToOpen!==null){
            

                if(windows[ftWindowsToOpen.pn] && !windows[ftWindowsToOpen.pn].closed){
                    windows[ftWindowsToOpen.pn].focus();
                }else{
                    let newWindow = window.open("#/fulldocview/" + encodeURIComponent(ftWindowsToOpen.pn))
                    newWindow.starterSeqId = ftWindowsToOpen.seqIds
                    newWindow.starterKws = ftWindowsToOpen.kws
                    windows[ftWindowsToOpen.pn] = newWindow;
                    setWindows({...windows})
                }
            
            setFtWindowsToOpen(null)
        }
     
    }, [{...ftWindowsToOpen}]);


    const focusParent = () => {
        if(parentWindow !== null && parentWindow !== undefined){
            if(!parentWindow.closed){
                parentWindow.focus();
            }else{
                setParentWindow(null)
            }
        }
    }


    useEffect(() => {

        const storageListener = (event) => {
          if (event.key === managerKey) {
            let value = JSON.parse(event.newValue)
            setManagerState(event.newValue ? value : ftDocManagerState);

            let pns = Object.keys(value.documents)
            if(pns !== null && pns !== undefined){
                if(pns.length > 0){
                    docsSelected = docsSelected.filter(element => pns.includes(element));
                    setDocsSelected([...docsSelected])
                }else{
                    setDocsSelected([])
                }
            }
          }
        };
    
        // Add the storage event listener when the component mounts
        window.addEventListener('storage', storageListener);
            return () => {
          window.removeEventListener('storage', storageListener);
        };
    }, []);

    const handleDocCheck = (e, pn) =>{
       let checked = e.target.checked
       
       let dex = docsSelected.indexOf(pn)
       if(checked && dex === -1){
        docsSelected.push(pn);
       }else if(dex !== -1){
        docsSelected.splice(dex, 1)
       }
        setDocsSelected([...docsSelected])

        if(docsSelected.length==0){
            setDocsSelected([])
        }
        setManagerState({...managerState})

    }

    const closeFtDocument = (pn) => {

        windows[pn].close()
        delete windows[pn]
        setWindows({...windows})
    }

    const focusFtDocument = (pn) => {
        if(!windows[pn].closed){
            windows[pn].focus()
        }
    }

    const applyKeywords = (keywords) => {

        for(let i =0; i< docsSelected.length; i++){
            let docString = localStorage.getItem(docsSelected[i]);
            let docObj = JSON.parse(docString);

            docObj.keywords = keywords;

            docString = JSON.stringify(docObj);
            localStorage.setItem(docsSelected[i], docString);
        }
    }

    const applySeqIds = (seqids)=>{
        for(let i =0; i< docsSelected.length; i++){
            let docString = localStorage.getItem(docsSelected[i]);
            let docObj = JSON.parse(docString);

            docObj.seqIds = seqids;

            docString = JSON.stringify(docObj);
            localStorage.setItem(docsSelected[i], docString);

        }
    }

    const handleTooltipGuide = () =>{
        setShowTour(!showTour)
    }

    const HandleSelectAll = () =>{
        if(Object.keys(managerState.documents).length !== docsSelected.length){
            let docs = []

            for (const key of Object.keys(managerState.documents)) {
                docs.push(key)
            }
            setDocsSelected([...docs])
        }else{
            setDocsSelected([...[]])
        }
          setManagerState({...managerState})
    }
 
    return (
    <div className="docContainerContainerContainer"  ref={tour1}>
        <div>
            <Tour
                TourData = {TourData}
                tourState={tourState}
                switchTourState={switchTourState}
                showTour = {showTour}
                hideTour = {()=> setShowTour(false)}
                propPos = {'fixed'}
                startNextTour = {startNextTour}
                hasNextTour={true}

            >
            </Tour>
        </div>
        <div className="docManagerX">
                <CloseIcon onClick={()=>closeManager()} style={{cursor: "pointer"}}/>
        </div>
        <div className="docContainerContainer">
            {managerState.documents !== null &&
            <div className="docManagerColumn" style={{width: "100%", paddingBottom: "10px"}}>
                <span className="pageTitle paddingLeft5" style={{marginTop: "-10px"}}>Document Manager
                    <span>
                        <Tooltip arrow title="Short Guided Tour" placement="bottom">
                            <button className="tourButtonDocManager" onClick={handleTooltipGuide}> 
                            <BsFillQuestionCircleFill style={{marginTop: "-3px"}}></BsFillQuestionCircleFill>
                            </button>
                        </Tooltip>
                    </span>

                    <span>
                        <a href="#" onClick={(e)=>{ e.preventDefault(); HandleSelectAll()}}>Select All</a>
                    </span>
                </span>
                <div className="DocListContainer" ref={tour2}>
                {Object.entries(managerState.documents).length > 0 && Object.entries(managerState.documents).map(([key, value]) =>{
                    if(windows[key]){
                    return(
                    <div className="DocListItem" key={key+"ftdoc"}>
                        <div style={{display: 'flex', paddingLeft: "3px", paddingRight: '2px', height: "100%"}}>
                            <CheckBox 
                                checked={(docsSelected.indexOf(key) !== -1)}
                                onChange = {(e)=>{handleDocCheck(e, key)}}
                            ></CheckBox>
                        </div>
                        <div className="docManagerInfoBlock">
                            <div>
                                {value.title !== "" &&
                                <strong className="appTextColor appTextFont">{value.title}</strong>
                                }
                                {value.title === "" &&
                                <strong className="appTextColor appTextFont">Loading...</strong>
                                }
                            </div>
                            <div>
                                <span>PN: {key}</span>
                                {parentKey &&
                                <span onClick={()=>{focusParent()}}>
                                </span>
                                }
                            </div>
                        </div>
                        {windows[key] &&
                        <div className="docListItemGetX">   
                                <span className="appLink" onClick={()=>{closeFtDocument(key)}}>Close</span>
                                <span className="appLink" onClick={()=>{focusFtDocument(key)}}>Focus</span>
                            </div>
                        }
                    </div>
                )}})}
                </div>
            </div>
            }
            <div>
                <KeywordsInContext
                    startPrevTour = {()=>{startPrevTour()}} 
                    startTour = {nextTour}
                    setNextTour = {()=>{setNextTour(false)}}
                    docsSelected={docsSelected}
                    hasPrevTour = {true}
                    applyKeywords={(keywords)=>{applyKeywords(keywords)}}
                    applySeqIds ={(seqids)=>{applySeqIds(seqids)}}
                >
                </KeywordsInContext>
            </div>
        </div>
    </div>

    );
}

export default FullDocManager
