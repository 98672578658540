// src/slices/toggleSlice.js
import { createSlice } from '@reduxjs/toolkit';
import UtilsService from '../../helpers/utils'; // Adjust the path as necessary

const { loadState, saveState } = UtilsService;

const persistedState = loadState() || { toggle: true };

const toggleSlice = createSlice({
  name: 'toggle',
  initialState: persistedState,
  reducers: {
    setToggle: (state, action) => {
      state.toggle = action.payload;
    },
  },
});

export const { setToggle } = toggleSlice.actions;
export const toggleReducer = toggleSlice.reducer;

export const persistMiddleware = store => next => action => {
  const result = next(action);
  saveState(store.getState().setToggle); // Adjust to match the reducer name
  return result;
};

export default toggleSlice;
