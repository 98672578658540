import React, { useState, useEffect } from "react";
import {useHistory, Link, useParams} from "react-router-dom";
import fullTextService from "../../../../services/fulltextsearch"
import { toast } from 'react-toastify';
import CloseIcon from '@material-ui/icons/Close';
import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";


function FtOntologyOptions(props){
    
    const history = useHistory();

    //returns luceneQueryValue == Search
    const {setSearchLock, TermData, openOrClosed, saveOntologyList, editMode, setEditMode, closeSelf} = props;

    const [ontEditorError, setOntEditorError] = useState(false);
    const toggle = useSelector(state => state.setToggle.toggle);

    useEffect(() => {
    });

    const saveOntologyEditor = () =>{

        var saveArray = document.getElementById("ftOptionsInput").innerText.split('\n')
        saveArray = saveArray.filter(function (el) {
            return el != "";
        });

        var returnString = "";
        if(saveArray != undefined && saveArray != null && saveArray.length>0){
            for(var i = 0; i < saveArray.length; i++){
                var testString = saveArray[i].trim();

                if(testString.length > 0 && testString !== "" && testString !== "\n"){
                    returnString+=testString
                    if(i < saveArray.length-1){
                        returnString+="\n";
                    }
                }
            }
        }

        saveOntologyList(returnString);
        setEditMode(false);
        setSearchLock(false)
    }

    function formatData(data){
        return data.trim()+"\n";
    }

    function handlePaste(e){
        e.preventDefault();
        //var info = e.clipboardData.getData('text/plain')
    }

    function cancel(){                 
        
        setSearchLock(true)
        setEditMode(false);
        closeSelf();
        
    }


    return(
        <>
        {openOrClosed &&
            <div>
                <span className="ftCustomHeader">Custom Word List:</span>
                {!editMode &&
                <div className="ftScrolly ftScrollx ftCustomOntologyList">
                    {Object.entries(TermData).map(([key, value])=>{return(
                        <span className={toggle ? "ontologyItem" : "ontologyItemDark"}>
                            {formatData(value)}
                        </span>
                    );})}
                </div>
                }{editMode &&
                    <div 
                    id="ftOptionsInput"
                    contentEditable = {true}
                    className="ftScrolly ftScrollx ftCustomOntologyList"
                    onPaste={(e)=>handlePaste(e)}
                >
                    {Object.entries(TermData).map(([key, value])=>{return(
                        <>
                        {(value != "") &&
                        <span className={toggle ? "ontologyItem" : "ontologyItemDark"}>
                            {formatData(value)}
                        </span>
                        }
                        </>
                    );})}
                </div>
                } 
                <div className="ftOptionsButtons">
                    {!editMode &&
                    <div width={'100%'}>
                        <button className={"submitButtonClass"} onClick={()=>{setEditMode(true);setSearchLock(true)}}>Edit</button>
                    </div>
                    }
                    {editMode &&
                    <div>
                        <button className={"submitButtonClass"} 
                        onClick={()=>{cancel()}}>Cancel</button>
                        <button className={"ml-1 submitButtonClass"} onClick={()=>saveOntologyEditor()}>Save</button>
                    </div>
                    }
                </div>
            </div>
        }
        </>
    );

}

export default FtOntologyOptions;
