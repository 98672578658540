import React, { useState, useEffect } from "react";
import AntibodyAnalysis from "./AntibodyAnalysis";
import ResultReportAnalysis from "./ResultReportAnalysis";
import SequenceVariationDiscover from "./SequenceVariationDiscover";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import {FaInfoCircle} from "react-icons/fa";
import {withStyles} from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Tooltip from "@material-ui/core/Tooltip";
import DocManagerLink from "../../FullDocManager/DocManagerLink";
//import FullDocManager from "./FullDocManager";

const toolTipStyles = {
    tooltip: {
        width: "auto",
        //height: "40px",
        fontSize: '12px',
        textAlign: 'justify',
        padding: "8px 14px 8px 14px",
        boxShadow: "0px 1px 7px #888",
        backgroundColor: '#777',
        color: '#FFF'
    }
};
const CustomTooltip = withStyles(toolTipStyles)(Tooltip);
const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        minHeight: 3,
        width: '102%',
        margin: 0,
        '&$expanded': {
            minHeight: 3,
            margin: 0,
        },
    },
    content: {
        margin: '0',
        padding: '0',
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

function GQPowerTools(props){

    const { 
        applyFilterForJava, 
        userdir, 
        AB, 
        VM,
        //AbReportNames, 
        saveResultState, 
        loadResultsFromBackend, 
        abFilters, 
        apiKey, 
        vmIds,  
        widgetState, 
        filter, 
        fieldConfig,
        setLvaFilterCheckbox,
        removeLvaFilterFromList,
        sideMenuDetail,
        lvaLoadingReady,
        reportId,
        reportFilters,
        editable,

        ftWindowsToOpen,
        setFtWindowsToOpen

    } = props;

    useEffect( async() => {
        // TODO, refresh the apiKey? props.apiKey
    }, []);

	return (
		<div>
            <DocManagerLink
                ftWindowsToOpen={ftWindowsToOpen}
                setFtWindowsToOpen ={(value)=>{setFtWindowsToOpen(value)}}
            ></DocManagerLink>
            
		    <ResultReportAnalysis reportFilters={reportFilters} apiKey={apiKey}
		                          sideMenuDetail={sideMenuDetail} userDir={userdir}></ResultReportAnalysis>
            
            
            {AB === true &&
                <AntibodyAnalysis
                    //AbReportNames={AbReportNames}
                    abFilters={abFilters}
                    apiKey={apiKey}
                ></AntibodyAnalysis>
            }
            {(VM === true || AB === true) &&
                <SequenceVariationDiscover
                    applyFilterForJava={applyFilterForJava}
                    apiKey={apiKey}
                    userDir={userdir}
                    vmIds={vmIds}
                    saveResultState={saveResultState}
                    loadResultsFromBackend={loadResultsFromBackend}
                    filter={filter}
                    fieldConfig={fieldConfig}
                    setLvaFilterCheckbox={setLvaFilterCheckbox}
                    removeLvaFilterFromList={removeLvaFilterFromList}
                    menuDetail={sideMenuDetail}
                    lvaLoadingReady={lvaLoadingReady}
                    reportId={reportId}
                    editable={editable}
                ></SequenceVariationDiscover>
            }
            {VM !== true &&
                <Accordion square expanded={false}>
                    <AccordionSummary aria-controls="panel-lva" className="subMenuHeading p-0">
                        <p className="subMenuHeading m-0">
                            <span style={{color: '#CCC'}}><ArrowRightIcon/>Sequence Variation Discovery&nbsp;</span>
                            <span className="glyphicon glyphicon-info-sign"></span>
                        </p>
                        <CustomTooltip arrow title={"The Sequence Variation Discovery Power Tools suite simplifies and streamlines the complex task of variant IP search and analysis. To learn more, email support@gqlifesciences.com."} placement="top">
                            <span><FaInfoCircle/>&nbsp;&nbsp;</span>
                        </CustomTooltip>
                    </AccordionSummary>
                </Accordion>
            }
        </div>
    );
}

export default GQPowerTools;
