import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import ReactHtmlParser from 'react-html-parser';
import {useSelector} from "react-redux";

const useStyles = makeStyles({
    grow: {
        flexGrow: 1,
    },
    footerNav: {
        paddingLeft: '0',
        backgroundColor: '#fff !important',
        boxShadow: 'none !important',
        color: '#0C90C6 !important',
        width: '96% !important',
        marginTop: '75px',
        '& p': {
            fontSize: '14px'
        }
    },
    footerNavDark: {
        paddingLeft: '0',
        backgroundColor: '#292929 !important',
        boxShadow: 'none !important',
        color: '#0C90C6 !important',
        width: '96% !important',
        marginTop: '75px',
        '& p': {
            fontSize: '14px'
        }
    },
});

export default function Footer() {
    const classes = useStyles();
    const [copyRightData, setCopyRightData] = useState();
    const userInfo = useSelector((state) => state.setUserInfo);
    const toggle = useSelector(state => state.setToggle.toggle);

    useEffect(() => {
        setCopyRightData(userInfo.copyright);
    }, [userInfo]);
    return (
        <div className={classes.grow}>
            <AppBar position="static" color="primary" className={toggle? classes.footerNav : classes.footerNavDark}>
                <div>
                    <p className={"w-100 text-center appTextColor "} style={{textWrap: "nowrap"}}>{ReactHtmlParser(copyRightData)}</p>
                </div>
            </AppBar>
        </div>
    )
}
