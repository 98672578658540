import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import utilsService, { TOAST_TYPE } from '../../helpers/utils'
import _ from "lodash";


import TextInput from '../../shared/Fields/TextInput';
import Constant from '../../helpers/constant';
import st26service from '../../services/st26service';
import { useSelector } from 'react-redux';





const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        width: '96%',
        margin: '0 auto 28px',
        minHeight: '260px',
        // borderBottom: '1px solid #cec7c7',
        padding: '23px 16px 14px',
    },
    headerPipe: {
        margin: '0 10px'
    },
    searchInput: {
        width: '30%'
    },
    buttonStyleCancel: {
        float: 'right',
        textTransform: 'none',
        margin: '4px',
        color: 'white',
        backgroundColor: '#008EC5 !important',
        border: '1px solid #1F4E79 !important',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        }
    },

    antibodyNumInput: {
        width: '8%'
    }
}));

function ConvertSequence() {
    const { t, i18n } = useTranslation('common');

    const classes = useStyles();
    const history = useHistory();
    // const [strategy, setStrategy] = useState('genepast');
    const [authInfo, setAuthInfo] = useState();
    // const [workflowId, setWorkflowId] = useState();
    const [formdata, setFormData] = useState({});
    const { workflowId } = useParams();
    const toastRef = useRef(null)
    const [modalDisplayed, setModalDisplayed] = useState();
    const toggle = useSelector(state => state.setToggle.toggle);

    useEffect(async () => {
        const getResponse = await st26service.getAuthInfoSt26(workflowId, history);
        document.title = 'GenomeQuest - Sequence Format Conversion';
        if (getResponse && getResponse.response_status == 0) {
            setAuthInfo(getResponse.response_content);
            updateFormData(getResponse.response_content);
            //setDisableSearch(true);
        }
        //dispatch(userActions.logout()); 
    }, []);
    function updateFormData(data) {
        setFormData(data.formData);
        var expiredTime = data.expiredTime;
        if (expiredTime && expiredTime.date) {
            var expiredTimeInSecs = Math.round(new Date(expiredTime.date).getTime() / 1000);
            // Sets the session timeout based on the user expired time in database, -90 for timeout 60 secs and 30 more buffer
            expiredTimeInSecs = expiredTimeInSecs - Math.round(new Date().getTime() / 1000) - 90;
            // Idle.setIdle(expiredTimeInSecs < 0 ? (30 * 59) : expiredTimeInSecs);
            //Idle.watch();
        }
    }

    function showfil() {
       if (window.File && window.FileReader && window.FileList && window.Blob) {
            var preview = document.getElementById('st26input');
            var file = document.querySelector('input[type=file]').files[0];
            var reader = new FileReader();
      
            var textFile = /text.*/;
            if (file.type.match(textFile)) {
               reader.onload = function (event) {
            // preview.innerHTML = event.target.result;
               preview.value = event.target.result;
               formik.setFieldValue("st26input", event.target.result);
              };
            } else {
              preview.innerHTML =
                "<span class='error'>It doesn't seem to be a text file!</span>";
            }
            reader.readAsText(file);
         } else {
            alert('Your browser is too old to support HTML5 File API');
          }
       
    }
    function resetFileInput() {
        var fileInput = document.querySelector('input[type=file]');
        fileInput.value = '';
    }
    

    function cncl() {
        history.push('/home');
    }

    const formik = useFormik({
        initialValues: {
            st26input: formdata.st26input

        },
        enableReinitialize: true,
        //validationSchema: Validate.AntibodySearchValidation(),
        onSubmit: async (values) => {
            let { st26input } = values;

            let postData = {
                st26input
            }

            var getResponse = await st26service.convertXml(postData, history, t);
            if (getResponse == 'parsererror') {
                setModalDisplayed(getResponse);
                utilsService.showToast(TOAST_TYPE.ERROR, 'The ST.26 data is not correctly structured.\nPlease correct the XML and try again.', toastRef)
            } else if (getResponse == 'missing') {
                utilsService.showToast(TOAST_TYPE.ERROR, 'The ST.26 data is not correctly structured.\nEither the moltype or Sequence data  is missing.', toastRef);
            }
            else if (getResponse == 'empty') {
                utilsService.showToast(TOAST_TYPE.ERROR, 'The ST.26 xml has no sequence data to be parsed.', toastRef)
            }
            else {
                history.push({
                    pathname: '/parsedxml',
                    state: getResponse
                });
            }

        },
    });
    return (
        <div className={classes.grow}>
            <form name="antibodySearchForm" onSubmit={formik.handleSubmit} className={classes.loginDiv}>

                <Row>
                    <Col lg="12" md="12" className={"mb-2 " + (authInfo && !authInfo.syscontrol_search_submit ? 'd-block' : 'd-none')}>
                        <Typography className="text-danger">
                            {t('ABsearchDisableText')}
                            {authInfo && authInfo.syscontrol_search_submit_txt}
                            {t('patienceThanksText')}</Typography>
                    </Col>

                    <Col lg="12" md="12" className="mb-2">
                        <h6 className={"appTextColor loginTitle"}>ST.26 Sequence Input</h6>
                        <Row className="mb-3">

                            <Col lg="12" md="12" className="p-0 content float-left">
                                <div className="form-group px-3 ">
                                    <TextInput
                                        spellcheck="false"
                                        rows="20"
                                        multiline={true}
                                        fullWidth
                                        id="st26input"
                                        name="st26input"
                                        label="Copy and Paste your ST.26 XML sequences here"
                                        variant="outlined"
                                        value={formik.values.st26input}
                                        onChange={(event) => {
                                            formik.handleChange(event);
                                            if (!event.target.value) {
                                                resetFileInput(); // Clear file input when text input is cleared
                                            }
                                        }} 
                                        error={formik.touched.st26input && Boolean(formik.errors.st26input)}
                                        helperText={formik.errors.st26input}
                                    //disabled={authInfo && authInfo.redo}
                                    />
                                </div>

                            </Col>

                        </Row>
                        <hr style={toggle ? {backgroundColor: '#dfdfdf'}: {backgroundColor: '#aea9a9'}}/>
                    </Col>

                    <Col lg="12" md="12" className="float-right mb-3">

                    <p className='uploadTextData'> <b id="show-text">Upload .xml ST.26 file</b></p>
                    <input type="file" accept= ".xml, .txt, .seq" onChange={showfil} />
                        
                        {!formik.values.st26input ?
                            <Button className='cancelButtonDisable' color="default" disableRipple={true} variant="contained">{t('Next')}</Button>
                            : <Button className='accountInfo' color="default" disableRipple={true} type="submit" variant="contained">{t('Next')}</Button>
                        }
                        <Button variant="contained" disableRipple={true} color="default" className={classes.buttonStyleCancel} onClick={cncl}>{t('cancel')}</Button>
                    </Col>
                </Row>

            </form>

        </div>

    )

}

export default ConvertSequence;
