const Constant = {
    searchType: {
        "GqWfIpSearch": "IP Sequence",
        "GqWfVMIpSearch": "Variation",
        "GqWfIpSearchVM": "Variation",
        "GqWfABIpSearch": "Antibody",
        "GqFolder": "Folder",
        "GqWfSeqSearch": "Blast Search",
        "GqWfAlndbCopy": "Save Filtered Result",
        "DlPhysicalSeqdb": "Sequence Database",
        "DlVirtualSeqdb": "Sequence Database",
        "DlWorkflow": "Sequence",
        "DlFolder": "Folder",
        "GqWfMerge": "Merge Result",
        "GqWfSearchFT": "Document"
        //"GqWfSeqSearch": "Blast Search"
    },
    folderRestrictNames: ["Recent Search Results", "My Data", 'Reference Data', 'Data Shared With Me', 'Raw Uploaded Files', 'Search Folders'],
    strategies: [
        { value: "genepast", label: "GenePAST Search", val: "kerr" },
        { value: "hash", label: "Gapless Search", val: "hash" },
        { value: "blast", label: "BLAST Search", val: "blast" },
        { value: "fragment", label: "Fragment Search", val: "fragment" },
        { value: "motif", label: "MOTIF Search", val: "motif" }
    ],
    strategiesAB: [
        { value: "genepast", label: "GenePAST Search", val: "kerr" },
        { value: "hash", label: "Gapless Search", val: "hash" },
        { value: "blast", label: "BLAST Search", val: "blast" }
    ],
    patientSearchDatabases: [
        { value: "GQPAT_PRT", label: "GQ-Pat Gold+ Protein", ticked: true, selected: true },
        { value: "GQPAT_PREMIUM_PRT", label: "GQ-Pat Platinum Protein", ticked: true, selected: true },
        { value: "GEAA", label: "GENESEQ Protein", ticked: false, selected: false },
        { value: "CASPAT_PRT", label: "CAS Biosequences™ - Proteins from Patents", ticked: false, selected: false }
    ],
    alertOptions: {
        "0": "every time a database gets updated",
        null: "every time a database gets updated",
        "7": "every week",
        "14": "every 2 weeks",
        "30": "every month",
        "180": "every 6 months",
        "365": "every year"
    },
    nucleotideDB: ['PROTEIN-PROTEIN', 'NUCLEOTIDE-PROTEIN', 'PROTEIN-NUCLEOTIDE', 'PROTEIN-MIX'],
    recordPerPage: 5,
    fullTextSearchFields: [
        { key: "text_all", label: "Full Text" },
        { key: "title", label: "Title" },
        { key: "ttl_abst", label: "Title or Abstract" },
        { key: "ttl_abst_clm", label: "Title, Abstract or Claims" },
        { key: "claim", label: "Claims" },
        { key: "abst", label: "Abstract" },
        { key: "desc", label: "Description" }
    ],
    fullTextIdSearchFields: [
        { key: "patentid", label: "Patent or Publication Number" },
        { key: "applid", label: "Application Number" },
        { key: "pubkind", label: "Patent Kind Code" },
        { key: "pubcountry", label: "Authority" },
        { key: "status", label: "Legal Status" },
        { key: "pubtype", label: "Publication Type" },
        { key: "publang", label: "Publication Language" },
        { key: "pairaia", label: "American Invent Act" }
    ],
    fullTextClsSearchFields: [
        { key: "cpc_ipcr", label: "CPC or IPCR Classification" },
        { key: "cpccode", label: "CPC Classification" },
        { key: "ipcrcode", label: "IPCR Classification" }
    ],
    fullTextNameSearchFields: [
        { key: "names", label: "All Names" },
        { key: "assigneegq", label: "Assignee or Applicant (normalized)" },
        { key: "assignee", label: "Assignee or Applicant (full)" },
        { key: "inventor", label: "Inventors" }
    ],
    fullTextDateSearchFields: [
        { key: "appldate", label: "Filing Date" },
        { key: "priordate", label: "Earliest Priority Date" },
        { key: "pubdate", label: "Publication Date" },
        { key: "lq_pnkl_create_date", label: "GQ Document Added Date" },
        { key: "lq_main_family_create_date", label: "GQ Family Added Date" }
    ],
    fullTextSeqSearchFields: [
        { key: "gq_doc", label: "Contains Sequences" },
        { key: "gq_nucleos", label: "Nucleotide Sequence Count" },
        { key: "gq_proteins", label: "Protein Sequence Count" }
    ],



    ftOntologies :{
        CHEBI: 'Chemical Entities of Biological Interest (CHEBI)',
        DOID: 'Human Disease Ontology (DOID)',
        GO: 'Gene Ontology (GO)',
        HP: 'Human Phenotype Ontology (HP)',
        IMR: 'INOH Protein name/family name ontology (IMR)',
        MOD: 'Protein Modification (MOD)',
        MSH: 'Medical Subject Headings (MSH)',
        NCBI: 'NCBI organismal classification (NCBI)',
        PO: 'Plant Ontology (PO)',
        PR: 'Protein Ontology (PR)',
        // Remove below item for now due to the license restriction
        // SNOMEDCT: 'Systematized Nomenclature of Medicine - Clinical Terms (SNOMEDCT)',
        TO: 'Plant Trait Ontology (TO)',
        USER: 'Your word lists (USER)'
    },


    ftKeywords: [
        "text_all:",
        "title:",
        "ttl_abst:", 
        "ttl_abst_clm:", 
        "claim:",
        "abst:",
        "desc:",
        "patentid:",
        "applid:",
        "pubkind:",
        "pubcountry:",
        "status:",
        "pubtype:",
        "publang:",
        "pairaia:",
        "cpc_ipcr:",
        "cpccode:",
        "ipcrcode:",
        "names:",
        "assigneegq:",
        "assignee:",
        "inventor:",
        "appldate:",
        "priordate:", 
        "pubdate:",
        "lq_pnkl_create_date:",
        "lq_main_family_create_date:", 
        "gq_doc:",
        "gq_nucleos:",
        "gq_proteins:",
    ],












    genePastItemsText: {
        "QUERY": " entire query sequence length",
        "SUBJECT": " entire length of any subject sequence",
        "SHORTER": " entire query sequence length or over the entire length of any subject sequence"
    },
    searchStrategies: {
        "kerr": "GenePAST",
        "hash": "Gapless",
        "blast": "BLAST",
        "fragment": "Fragment",
        "motif": "MOTIF"
    },
    dateSortingField: [
        {
            value: "pub_date",
            label: "Publication Date"
        },
        {
            value: "filing_date",
            label: "Filing Date"
        },
    ],
    dateSortingDirection: [
        {
            value: "desc",
            label: "Latest"
        },
        {
            value: "asc",
            label: "Earliest"
        }
    ]
}
export default Constant;
