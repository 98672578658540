import React, { useState, useEffect } from "react";
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from "react-redux";

function FtAutoComplete(props){
    
    //returns luceneQueryValue == Search
    const {callBackFunction, openOrClosed, fieldStack} = props;
    const toggle = useSelector(state => state.setToggle.toggle);

    const [refresh, setRefresh] = useState(false);

    const selectTerm = (term) => {
        var returnString = "";
        if(Array.isArray(term)){

            for(let i =0; i<term.length; i++){
                returnString+=term[i]
                if(i < term.length-1){
                    returnString+=',';
                }
            }

        }else{
            returnString = term;
        }
        callBackFunction(returnString);
    }

    function addStack(label){
        if(!fieldStack.includes(label)){
            fieldStack.push(label)
        }
        setRefresh(!refresh)
    }

    function subStack(label){
        var index = fieldStack.indexOf(label)
        if (index > -1) { 
            fieldStack.splice(index, 1); 
        }
        setRefresh(!refresh)
    }

    const AutoLabels ={
        "Text Fields": [
            {key: "text_all", label: "Full Text" },
            { key: "title", label: "Title" },
            { key: "ttl_abst", label: "Title or Abstract" },
            { key: "ttl_abst_clm", label: "Title, Abstract or Claims" },
            { key: "claim", label: "Claims" },
            { key: "abst", label: "Abstract" },
            { key: "desc", label: "Description" }
        ],

        "Identification": [
            { key: "patentid", label: "Patent or Publication Number" },
            { key: "applid", label: "Application Number" },
            { key: "pubkind", label: "Patent Kind Code" },
            { key: "pubcountry", label: "Authority" },
            { key: "status", label: "Legal Status" },
            { key: "pubtype", label: "Publication Type" },
            { key: "publang", label: "Publication Language" },
            { key: "pairaia", label: "American Invent Act" }
        ],

        "Classification": [
            { key: "cpc_ipcr", label: "CPC or IPCR Classification" },
            { key: "cpccode", label: "CPC Classification" },
            { key: "ipcrcode", label: "IPCR Classification" }
        ],

        "Assignees and Inventors": [
            { key: "names", label: "All Names" },
            //{ key: "assigneegq", label: "Assignee or Applicant (normalized)" },
            { key: "assignee", label: "Assignee or Applicant (full)" },
            { key: "inventor", label: "Inventors" }
        ],

        "Dates": [
            { key: "appldate", label: "Filing Date" },
            { key: "priordate", label: "Earliest Priority Date" },
            { key: "pubdate", label: "Publication Date" },
            { key: "lq_pnkl_create_date", label: "GQ Document Added Date" },
        ],

        "Biological Sequences":[
            { key: "gq_doc", label: "Contains Sequences" },
            { key: "gq_nucleos", label: "Nucleotide Sequence Count" },
            { key: "gq_proteins", label: "Protein Sequence Count" }
        ]
    };

    return(
        <>
        {openOrClosed &&
            <div>
                <div className="ftScrollx ftFieldStackBox">
                    {fieldStack.length > 0 && fieldStack.map((label)=>{
                        
                        return(
                            <div>
                            <span className="ftAlwaysGreenLabel">
                                <span>
                                {label}
                                </span>
                                &nbsp;
                                <CloseIcon className="ftAutoXIcon" onClick={()=> subStack(label)}/>
                            </span>
                            &nbsp;
                            </div>

                        );})
                    }
                    {!fieldStack.length > 0 &&
                        <span className="ftSFText">Stacked Fields</span>
                    }
                </div>
                {fieldStack.length > 0&&
                <div className="ftInsertButtonActive" onClick={()=>selectTerm(fieldStack)}>
                    <span>Insert</span>
                </div>
                }
                {fieldStack.length <= 0 &&
                <div className="ftInsertButtonNot">
                    <span>Insert</span>
                </div>
                }
                <div className="ftScrolly ftScrollx ftAutoBox">
                {AutoLabels && Object.entries(AutoLabels).map(([key, value])=>{return(
                        <div key={key+"AC"} className="ftAcKeyGroup">
                            <b className="ftPaddingLeft5 ftHeader">{key}</b><br/>
                            {value && value.map(({key, label})=>{return(
                            <div className={toggle ? "ontologyItem" : "ontologyItemDark"} key={key+"s"}>
                            <span className="ftLabel" onClick={()=>{selectTerm(key)}}>
                                {label}
                            </span>
                            {!fieldStack.includes(key) &&
                                <span className="ftAdd" onClick={()=>addStack(key)}>add</span>
                            }
                            {fieldStack.includes(key) &&
                                <span className="ftRemove" onClick={()=>subStack(key)}>remove</span>
                            }
                            </div>
                            )})}
                        </div>
                    )})}
                </div>
                <div className="ftDisplayNone">{refresh}</div>
            </div>
        }
        </>
    );

}

export default FtAutoComplete;
