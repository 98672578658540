import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	modalClassConDSI: {
		position: 'absolute',
		width: '96%',
		padding: '35px 15px 0px',
		right: 'auto',
		bottom: 'auto',
	},
	modalClassConDSIDark: {
		position: 'absolute',
		width: '96%',
		padding: '35px 15px 0px',
		right: 'auto',
		bottom: 'auto',
		backgroundColor: '#292929'
	},
	modalBoxContent: {
		maxHeight: '600px',
	},
	modalText: {
		position: 'absolute',
		top: '10px',
		right: '10px'
	},
}));

const DiscoverContinueModal = props => {
	const classes = useStyles();
	const toggle = useSelector(state => state.setToggle.toggle);
	const callback = () => {
		props.actionModal(props.filter, props.filterState, props.db, props.fieldConfig);
	}

	const close = () => {
		props.closeModal(props.db);
	}

	return (
		<Modal
			size="lg"
			aria-labelledby="contained-modal-title-vcente"
			centered
			show={props.showModal}
			contentClassName={toggle? classes.modalClassConDSI: classes.modalClassConDSIDark}
		>
			<Link href="#" onClick={(e) => e.preventDefault()} className={classes.modalText}>
				<CloseIcon onClick={close} />
			</Link>
			<div className={toggle ? "jumbotron appTextColor": "jumbotronDark appTextColor" }>
				<p className="mb-3">{props.message}</p>
				<br></br>
				<div>
					<button onClick={close} className="pull-right large-button gqblue">{props.closeButton}</button>
					<button onClick={callback} className="pull-right large-button grey">{props.actionButton}</button>
				</div>
			</div>
		</Modal>
	);
}

export default DiscoverContinueModal;
