import React, {useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import {FaCaretDown, FaPlus, FaMinus} from "react-icons/fa";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import ReactHtmlParser from "react-html-parser";
import Dropdown from "react-bootstrap/Dropdown";
import { ModalBody } from 'react-bootstrap';
import { useSelector } from 'react-redux';

//styles
const toolTipStyles = {
    tooltip: {
        width: "auto",
        //height: "40px",
        fontSize: '12px',
        textAlign: 'justify',
        padding: "8px 14px 8px 14px",
        boxShadow: "0px 1px 7px #888",
        backgroundColor: '#777',
        color: '#FFF'
    }
};
const CustomTooltip = withStyles(toolTipStyles)(Tooltip);
const useStyles = makeStyles((theme) => ({
    modalClassConDSI: {
        position: 'absolute',
        padding: '15px 12px 10px',
        minHeight: '250px',
        width: '100%',
        maxHeight: '100vh',
        //overflowY: 'auto',
    },
    closeBtn: {
        position: 'fixed',
        right: '10px',
        bottom: '10px'
        },
    input: {
        margin: theme.spacing(0),
        height: 36,
    },
    dataInput: {
        borderRadius: "7px",
    }
}));


const lvaGroupedMethods = [
    {
        group: 'Non-specific changes in a region',
        methods: ['Matches, replacements, indels present', 'Matches, replacements, indels absent', 'Percent Identity >=', 'No. of Diff. &#60;=']
    },
    {
        group: 'Presence of Specific Changes at a Position',
        methods: ['Specific replacement present', 'Deletion present beginning at', 'Any insertion present', 'Specific insertion present']
    },
    {
        group: 'Absence of Specific Changes at a Position',
        methods: ['Specific replacement absent', 'No deletion beginning at', 'No insertion', 'Specific insertion absent']
    }
];
/*
const lvaMethods = {
    atleastone: {text_label: "At Least One", count: 1}, // Preset to one because matches are always available
    matches: {text_label: "Exact Match", count: 1},      //  Preset to one because matches are always available
    replacements: {text_label: "Replacement(s)", count: 1},
    deletions: {text_label: "Deletion(s)", count: 1},
    anyinsert: {text_label: "Any Insertion", count: 1},
    specinserts: {text_label: "Specific Insertion(s)", count: 1}
};*/

const lvaEventOptions = [
    {event: 'M', label: 'Any match'},
    {event: 'R', label: 'Any replacement'},
    {event: 'I', label: 'Any insertion'},
    {event: 'D', label: 'Any deletion'}
];

const lvaEventNegatedOptions = [
    {event: 'M', label: 'No match'},
    {event: 'R', label: 'No replacements'},
    {event: 'I', label: 'No insertions'},
    {event: 'D', label: 'No deletions'}
];

const colors = ["", "red", "orange", "yellow", "green", "blue", "purple"];

const defaultLvaAtom = {
    locked: false,
    id: 0,
    method: null,
    start: '',
    stop: '',
    maxDel: '',
    events: [],
    replacements: [],
    inserts: [],
    negated: false,
    lvaEventOptions: _.cloneDeep(lvaEventOptions),
};

const VmFilterModal = props => {

    const classes = useStyles();

    const {filter, vmIds, fieldConfig} = props;

    const [lvaWidgetState, setLvaWidgetState] = useState(props.lvaWidgetState);

    //name of filter
    const [lvaFilterNameUsed, setLvaFilterNameUsed] = useState(-1);
    const [lvaFilterNameErr, setLvaFilterNameErr] = useState('');

    //error messages
    const [customQueryMessage, setCustomQueryMessage] =useState("");
    //const [lvaFilterQueryErr, setLvaFilterQueryErr] = useState("");
    const [lvaWidgetAtomErrStatus, setLvaWidgetAtomErrStatus] = useState([]);
    const [filterCorrect, setFilterCorrect] = useState(true);

    const [showColorBox, setShowColorBox] = useState(false);
    const [saveAsGlobal, setSaveAsGlobal] = useState(false);
    const toggle = useSelector(state => state.setToggle.toggle);

    useEffect(() => {
        setSaveAsGlobal(false);
        if (props.lvaWidgetState && props.lvaWidgetState.lvaFilterName) {
            let state = {...lvaWidgetState};
            state = _.cloneDeep(props.lvaWidgetState);
            checkCustomFilterName(props.lvaWidgetState.lvaFilterName);
            setLvaWidgetState(state);
            //verifyLvaFilterWidget(state);
        } else if (props.lvaWidgetState) {
            clearLvaFilterWidget();
            let state = {...lvaWidgetState};
            state.lvaFilters = _.cloneDeep(props.lvaWidgetState.lvaFilters);
            setLvaWidgetState(state);
        }
    }, [props.lvaWidgetState, props.vmIds]);

    function setLvaMethod(index, method) {
        lvaWidgetState.widgetContent[index].text_label = method;
        lvaWidgetState.widgetContent[index].lvaEventOptions = _.cloneDeep(lvaEventOptions);
        lvaWidgetState.widgetContent[index].startPosText = 'Start at';
        lvaWidgetState.widgetContent[index].stopPosText = 'End at';
        lvaWidgetState.widgetContent[index].startPosHint = '';
        lvaWidgetState.widgetContent[index].stopPosHint = '';
        lvaWidgetState.widgetContent[index].startPosMinValue = 1;
        lvaWidgetState.widgetContent[index].hasPosMaxValue = true;
        lvaWidgetState.widgetContent[index].events = []; // Clear the selected events
        lvaWidgetState.widgetContent[index].replacements = "";
        lvaWidgetState.widgetContent[index].inserts = "";
        lvaWidgetState.widgetContent[index].mindel = "";
        lvaWidgetState.widgetContent[index].maxdel = "";
        lvaWidgetState.widgetContent[index].stop = "";
        if (method === lvaGroupedMethods[0].methods[0]) {
            lvaWidgetState.widgetContent[index].method = 'atleastone';
            lvaWidgetState.widgetContent[index].negated = false;
            lvaWidgetState.widgetContent[index].startPosHint = 'Enter value between 1 and ';
            lvaWidgetState.widgetContent[index].stopPosHint = 'Enter value between 1 and ';
            //lvaWidgetState.widgetContent[index].startPosMinValue = 0;
            //lvaWidgetState.widgetContent[index].hasPosMaxValue = false;
        } else if (method === lvaGroupedMethods[0].methods[1]) {
            lvaWidgetState.widgetContent[index].method = 'atleastone';
            lvaWidgetState.widgetContent[index].negated = true;
            lvaWidgetState.widgetContent[index].lvaEventOptions = _.cloneDeep(lvaEventNegatedOptions);
            lvaWidgetState.widgetContent[index].startPosHint = 'Enter value between 1 and ';
            lvaWidgetState.widgetContent[index].stopPosHint = 'Enter value between 1 and ';
            //lvaWidgetState.widgetContent[index].startPosMinValue = 1;
            //lvaWidgetState.widgetContent[index].hasPosMaxValue = false;
        } else if (method === lvaGroupedMethods[0].methods[2]) {
            lvaWidgetState.widgetContent[index].method = 'percIdentity';
            lvaWidgetState.widgetContent[index].startPosHint = 'Enter value between 1 and ';
            lvaWidgetState.widgetContent[index].stopPosHint = 'Enter value between 1 and ';
            lvaWidgetState.widgetContent[index].events.push({"event": "M"})
        } else if (method === lvaGroupedMethods[0].methods[3]) {
            lvaWidgetState.widgetContent[index].method = 'maxDiff';
            lvaWidgetState.widgetContent[index].startPosHint = 'Enter value between 1 and ';
            lvaWidgetState.widgetContent[index].stopPosHint = 'Enter value between 1 and ';
            lvaWidgetState.widgetContent[index].events.push({"event": "M"})
        }
         else if (method === lvaGroupedMethods[1].methods[0]) {
            lvaWidgetState.widgetContent[index].method = 'replacements';
            lvaWidgetState.widgetContent[index].negated = false;
            lvaWidgetState.widgetContent[index].startPosHint = 'Enter single replacement position from 1 to ';
            lvaWidgetState.widgetContent[index].startPosText = 'Replacement position';
        } else if (method === lvaGroupedMethods[1].methods[1]) {
            lvaWidgetState.widgetContent[index].method = 'deletions';
            lvaWidgetState.widgetContent[index].startPosHint = 'Valid positions for deletion to start are between 1 and ';
            lvaWidgetState.widgetContent[index].negated = false;
            lvaWidgetState.widgetContent[index].startPosText = 'Deletion position';
        } else if (method === lvaGroupedMethods[1].methods[2]) {
            lvaWidgetState.widgetContent[index].method = 'anyinsert';
            lvaWidgetState.widgetContent[index].startPosHint = 'Valid positions for insertion to start are between 1 and ';
            lvaWidgetState.widgetContent[index].negated = false;
            lvaWidgetState.widgetContent[index].startPosText = 'Insertion position';
        } else if (method === lvaGroupedMethods[1].methods[3]) {
            lvaWidgetState.widgetContent[index].method = 'specinserts';
            lvaWidgetState.widgetContent[index].startPosHint = 'Valid positions for insertion to start are between 1 and ';
            lvaWidgetState.widgetContent[index].negated = false;
            lvaWidgetState.widgetContent[index].startPosText = 'Insertion position';
        } else if (method === lvaGroupedMethods[2].methods[0]) {
            lvaWidgetState.widgetContent[index].method = 'replacements';
            lvaWidgetState.widgetContent[index].startPosHint = 'Enter single replacement position from 1 to ';
            lvaWidgetState.widgetContent[index].negated = true;
            lvaWidgetState.widgetContent[index].startPosText = 'Replacement position';
        } else if (method === lvaGroupedMethods[2].methods[1]) {
            lvaWidgetState.widgetContent[index].method = 'deletions';
            lvaWidgetState.widgetContent[index].startPosHint = 'Valid positions for deletion to start are between 1 and ';
            lvaWidgetState.widgetContent[index].negated = true;
            lvaWidgetState.widgetContent[index].startPosText = 'Deletion position';
        } else if (method === lvaGroupedMethods[2].methods[2]) {
            lvaWidgetState.widgetContent[index].method = 'anyinsert';
            lvaWidgetState.widgetContent[index].startPosHint = 'Valid positions for insertion to start are between 1 and ';
            lvaWidgetState.widgetContent[index].negated = true;
            lvaWidgetState.widgetContent[index].startPosText = 'Insertion position';
        } else if (method === lvaGroupedMethods[2].methods[3]) {
            lvaWidgetState.widgetContent[index].method = 'specinserts';
            lvaWidgetState.widgetContent[index].startPosHint = 'Valid positions for insertion to start are between 1 and ';
            lvaWidgetState.widgetContent[index].negated = true;
            lvaWidgetState.widgetContent[index].startPosText = 'Insertion position';
        }
        verifyLvaFilterWidgetAtoms(lvaWidgetState);
        setLvaWidgetState({...lvaWidgetState});
        return method !== null;
    };

    const save = () => {
        if (verifyLvaFilterWidget()) {
            filter.lvaWidgetState = _.cloneDeep(lvaWidgetState);
            filter.lvaWidgetState.filterNameUsed = checkCustomFilterName(lvaWidgetState.lvaFilterName);
            filter.lvaWidgetState.lvaFilterName = lvaWidgetState.lvaFilterName;
            filter.lvaWidgetState.saveAsGlobal = saveAsGlobal;
            if (filter.lvaWidgetState.saveAsGlobal) { // Checks if saved as global
                filter.lvaWidgetState.globalLvaFilterNameUsed = globalLvaFilterNameUsed(lvaWidgetState.lvaFilterName);
            }
            filter.lvaWidgetState.widgetMode = lvaWidgetState.widgetMode;
            if (lvaWidgetState.widgetMode === 'CUSTOM') {
                filter.lvaWidgetState.widgetCustomQuery = lvaWidgetState.widgetCustomQuery;
            } else {
                filter.lvaWidgetState.widgetCustomQuery = '';
            }
            /*
            filter.lvaWidgetState.widgetContent = _.cloneDeep(lvaWidgetState.widgetContent);*/
            props.callback(filter, fieldConfig, props.sideMenuDetail);
        }
    }

    const checkCustomFilterName = (val) => {
        // see if a filter name has been used before and return the index number (or === false)
        let retVal = -1;
        if (props.lvaWidgetState && props.lvaWidgetState.lvaFilters) {
            for (let i = 0; i < props.lvaWidgetState.lvaFilters.length; i++) {
                if (props.lvaWidgetState.lvaFilters[i].lvaFilterName === val) {
                    retVal = i;
                    break;
                }
            }
        }
        setLvaFilterNameUsed(retVal);
        return retVal;
    }

    // clear the entire Landscape Variation Analysis filtering widget (reset)
    const clearLvaFilterWidget = () => {
        if (Object.keys(vmIds).length == 1) {
            //setlvaWidgetSeqId(1);
            // the index starts from 1, when only one sequence, default selected
            lvaWidgetState.lvaQuery = vmIds[1];
        } else {
            lvaWidgetState.lvaQuery = null;
        }
        lvaWidgetState.widgetContent = [_.cloneDeep(defaultLvaAtom)];
        lvaWidgetState.lvaQueryLocked = false;
        lvaWidgetState.savedWidgetContentQueries = {};
        lvaWidgetState.savedWidgetContents = {};
        lvaWidgetState.lvaFilterName = '';
        lvaWidgetState.widgetMode = 'AND';
        lvaWidgetState.selectedColor = "blue";
        lvaWidgetState.selectedColorIdx = "5";
        setSaveAsGlobal(false);
        setLvaWidgetState({...lvaWidgetState});
        setLvaFilterNameUsed(-1);
    };

    const setLvaFilterName = (e) =>{
        lvaWidgetState.lvaFilterName = e.target.value;
        setLvaWidgetState({...lvaWidgetState});
        checkCustomFilterName(e.target.value);
    }

    const changeSelection = (e) => {
        //setChoice(e.target.value);
        lvaWidgetState.widgetMode = e.target.value;
        setLvaWidgetState({...lvaWidgetState});
    }

    const changeStopPostion = (index, e) =>{
        lvaWidgetState.widgetContent[index].stop = parseInt(e.target.value);
        setLvaWidgetState({...lvaWidgetState});
    }

    const changeStartPostion = (index, e) =>{
        lvaWidgetState.widgetContent[index].start = parseInt(e.target.value);
        setLvaWidgetState({...lvaWidgetState});
    }

    const checkCustomQuerySyntax = (e) => {
        lvaWidgetState.widgetCustomQuery = e.target.value;
        setLvaWidgetState({...lvaWidgetState});
        return verifyCustomQuerySyntax(lvaWidgetState, e.target.value);
    }

    function verifyCustomQuerySyntax(tempLvaWidgetState, value) {
        setCustomQueryMessage('');
        let val = value ? value : (tempLvaWidgetState.widgetCustomQuery ? tempLvaWidgetState.widgetCustomQuery : ''),
            msg = '',
            tmp_val = val.toUpperCase(),
            words = [],
            nbParenthesesLeft = 0,
            nbParenthesesRight = 0,
            i = 0,
            nbErrors = 0,
            totNbAtoms = _.size(tempLvaWidgetState.widgetContent),
            atomNb = 0,
            lastSeenAtom = 0;


        if (val === '' || !val) {
            nbErrors++;
            setCustomQueryMessage('Please type your custom query');
            return (nbErrors === 0);
        }

        // reset which atoms have been used in the custom filter
        tempLvaWidgetState.widgetCustomAtomUsed = {};

        // check if all words are either and, or, F[0-9]+
        // Unnecessary escape character: \( \)
        tmp_val = tmp_val
            .replace(/[ ()]/g, ' ')
            .replace(/^ +| +$/g, '')
            .replace(/ +/g, ' ');
        words = (_.split(tmp_val, ' ') || []);
        for (i = 0; i < words.length; i++) {
            switch (words[i]) {
                case 'AND':
                case 'OR':
                    // last word can't be AND / OR
                    if (i === words.length - 1) {
                        msg += 'Syntax error (last ' + words[i] + '). ';
                        nbErrors++;
                    }
                    break;
                default:
                    if (!(words[i].match(/^F[0-9]+$/) || []).length) {
                        // console.log('unknown word: ' + words[i])
                        if (words[i]) {
                            msg += 'Syntax error "' + words[i] + '" not recognized. ';
                            nbErrors++;
                        }
                    } else {
                        // keep track of which atoms are used
                        atomNb = parseInt(words[i].replace('F', ''));
                        if (atomNb <= totNbAtoms) {
                            // set the atom (F1 ... FN) as used in case of custom filters
                            tempLvaWidgetState.widgetCustomAtomUsed[words[i]] = true;
                            // make sure atoms are connected by AND / OR
                            if ((i - lastSeenAtom) !== 0 && (i - lastSeenAtom) !== 2) {
                                // error forget and / or somewhere
                                msg += 'Syntax error (AND/OR expected). ';
                                nbErrors++;
                            }
                            lastSeenAtom = i;
                        } else {
                            // non-existing atom number
                            msg += 'Syntax error ("' + words[i] + '" does not exist). ';
                            nbErrors++;
                        }
                    }
                    break;
            }
        }

        // check if number of opening parenthesis equals closing ones
        tmp_val = val;
        nbParenthesesLeft = (tmp_val.match(/\(/g) || []).length;
        nbParenthesesRight = (tmp_val.match(/\)/g) || []).length;
        if (nbParenthesesLeft !== nbParenthesesRight) {
            msg += 'Syntax error (parentheses). ';
            nbErrors++;
        }
        // Check if only one level of parenthesis, e.g. invalid query -> ((F1 and F2) and F3) or F4
        //if (nbErrors === 0) {
        let leftCount = 0, rightCount = 0;
        for (let i = 0; i < val.length; i++) {
            if (val[i] === '(') {
                leftCount++;
                if (rightCount > 0) {
                    rightCount--;
                }
            } else if (val[i] === ')') {
                rightCount++;
                if (leftCount > 0) {
                    leftCount--;
                }
            }
            if (leftCount > 1 || rightCount > 1) {
                msg += 'Variation filters currently only support one level of parentheses. ';
                nbErrors++;
                break;
            }
        }
        //}
        setCustomQueryMessage(msg);
        setLvaWidgetState({...tempLvaWidgetState});
        return (nbErrors === 0);
    }

    /*function getFieldId(idx) {
        return lvaWidgetState.widgetContent[idx].field;
    }*/

    function displayColorBox() {
        setShowColorBox(true);
    }

    function hideColorBox() {
        setShowColorBox(false);
    }

    const selectColor = (idx) => {
        lvaWidgetState.selectedColorIdx = idx;
        lvaWidgetState.selectedColor = colors[idx];
        setLvaWidgetState({...lvaWidgetState});
        hideColorBox();
    }

    function newLvaAtom() {
        var atom = _.cloneDeep(defaultLvaAtom);
        atom.id = Date.now();
        return atom;
    };

    // adds an atom to the filtering widget
    function lvaWidgetAddAtom(idx) {
        lvaWidgetState.widgetContent.splice((idx + 1), 0, newLvaAtom());
        verifyLvaFilterWidgetAtoms(lvaWidgetState);
        setLvaWidgetState({...lvaWidgetState});
    };

    // removes an atom to the filtering widget
    function lvaWidgetDelAtom(idx) {
        lvaWidgetState.widgetContent.splice(idx, 1);
        verifyLvaFilterWidgetAtoms(lvaWidgetState);
        setLvaWidgetState({...lvaWidgetState});
    };
    

    function usesLvaStopPosition(atom) {
        return (atom !== null && (atom.method === 'atleastone' || atom.method === 'matches'
            || atom.method === 'percIdentity' || atom.method === 'maxDiff'));
    };

    /*const changeSpecInserts = (index, e) =>{
        lvaWidgetState.widgetContent[index].inserts = e.value;
        setLvaWidgetState({...lvaWidgetState})
    }*/

    // see if a filtername has been used before and return the index number (or === false)
    function globalLvaFilterNameUsed(val) {
        var i = 0, retval = -1;
        if (props.filter.widgetState.globalLvaFilters) {
            for (i = 0; i < props.filter.widgetState.globalLvaFilters.length; i++) {
                if (props.filter.widgetState.globalLvaFilters[i].lvaFilterName === val) {
                    retval = i;
                    break;
                }
            }
        }
        return retval;
    };

    // verifies if everything is OK with the LVA filter (returns true when it is)
    function verifyLvaFilterWidget() {
        var filterCorrect = true;
        setLvaWidgetAtomErrStatus([]);
        //var lvaFilterQueryErr = false;
        setLvaFilterNameErr(false);
        if (filterCorrect) {
            // the filter must have a query
            filterCorrect = (lvaWidgetState.lvaQuery && lvaWidgetState.lvaQuery.QUERY_ID ? true : false);
            //setLvaFilterQueryErr(filterCorrect ? '' : 'Please select a query sequence.'); // set error when filter name is empty
        }
        if (filterCorrect) {
            // the filter must have a name
            filterCorrect = errorsInLvaFilterName();
        }
        if (filterCorrect && lvaWidgetState.widgetMode === 'CUSTOM') {
            // if custom, the query syntax must be good
            filterCorrect = verifyCustomQuerySyntax(lvaWidgetState);
        }
        if (filterCorrect) {
            // every atom must have a value
            filterCorrect = verifyLvaFilterWidgetAtoms();
        }
        setFilterCorrect(filterCorrect);
        return filterCorrect;
    };

    function errorsInLvaFilterName() {
        var filterNameCorrect = lvaWidgetState.lvaFilterName ? true : false;
        setLvaFilterNameErr(filterNameCorrect ? '' : 'Please name your filter.'); // set error when filter name is empty
        if (filterNameCorrect && lvaWidgetState.lvaFilterName.length > 500) {
            filterNameCorrect = false;
            setLvaFilterNameErr('Max length for filter name is 500.');
        }
        if (filterNameCorrect && lvaWidgetState.lvaQuery
            && lvaWidgetState.lvaQuery.copyFromOther && (lvaFilterNameUsed >= 0)) {
            filterNameCorrect = false;
            setLvaFilterNameErr('Please rename your filter.');
        }
        if (filterNameCorrect && saveAsGlobal
            && (globalLvaFilterNameUsed(lvaWidgetState.lvaFilterName) >= 0)) {
            // Duplicate name in global filters
            filterNameCorrect = false;
            setLvaFilterNameErr('Duplicate name exists in global filters, please rename your filter.');
        }
        return filterNameCorrect;
    };

    function verifyLvaFilterWidgetAtoms(lvaState) {
        let state = lvaWidgetState;
        if (lvaState) {
            state = lvaState;
        }

        let atom, nbErrors = 0;
        for (let i = 0; i < state.widgetContent.length; i++) {
            atom = state.widgetContent[i];
            lvaWidgetAtomErrStatus[i] = {method: "", start: "", stop: "", events: ""};
            if (!atom.method) {
                lvaWidgetAtomErrStatus[i].method = 'Please select a filter.';
                nbErrors++;
                continue;
            }
            if (!atom.start || parseInt(atom.start) < atom.startPosMinValue) {
                lvaWidgetAtomErrStatus[i].start = 'Value must be between 1 and ' + state.lvaQuery.QUERY_L;
                nbErrors++;
                continue;
            } else if (atom.hasPosMaxValue && parseInt(atom.start) > state.lvaQuery.QUERY_L) {
                lvaWidgetAtomErrStatus[i].start = 'Value must be between 1 and ' + state.lvaQuery.QUERY_L;
                nbErrors++;
                continue;
            }
            if (usesLvaStopPosition(atom)) {
                if (!atom.stop || parseInt(atom.stop) > state.lvaQuery.QUERY_L || (atom.start && parseInt(atom.stop) < parseInt(atom.start))) {
                    lvaWidgetAtomErrStatus[i].stop = 'Value must be between ' + atom.start + ' and ' + state.lvaQuery.QUERY_L;
                    nbErrors++;
                    continue;
                }
            } else {
                if (atom.method === 'deletions') {
                    if (atom.maxDel === "") {
                        atom.maxDel = state.lvaQuery.QUERY_L;
                    }
                    atom.stop = atom.start + atom.maxDel + 1;
                } else {
                    atom.stop = atom.start;
                }
            }
            if (atom.method === 'atleastone') {
                if (atom.events.length === 0) {
                    lvaWidgetAtomErrStatus[i].events = 'Please select at least one choice.';
                    nbErrors++;

                }
            } else if (atom.method === 'replacements') {
                // An empty replacements list will be treated as "any"
                // if (atom.replacements.length === 0) {
                //     lvaWidgetAtomErrStatus[i].replacements = 'Please declare at least one replacement residue, or a * for any.';
                //     nbErrors++;
                //
                // }
                if (atom.replacements === undefined || atom.replacements === null || atom.replacements.length === 0) {
                    continue;
                }
                if (!state.lvaPositionEvents || Object.keys(state.lvaPositionEvents).length === 0) {
                    continue;   //  It's not loaded, so just skip the edits and hope for the best
                }

                // Single letters separated with either comma, semicolon, or \n.  Reject all other entries.
                // Case insensitive, no letter validation, no wildcarding with the exception that blank means any letter
                let regex1 = new RegExp("^[A-Za-z]+[ A-Za-z,;\n]*$");
                if (!(regex1.test(atom.replacements))) {
                    lvaWidgetAtomErrStatus[i].replacements = 'Invalid input, only allows single letters separated by comma, semicolon or new line';
                    nbErrors++;
                }

            } else if (atom.method === 'deletions') {
                if (atom.mindel === undefined || atom.mindel === null || atom.mindel === ""
                    || atom.mindel < 1 || (atom.mindel > state.lvaQuery.QUERY_L - atom.start + 1)) {
                    lvaWidgetAtomErrStatus[i].min_del = 'Minimum deletions must be between 1 and ' + (state.lvaQuery.QUERY_L - atom.start + 1);
                    nbErrors++;
                    continue;
                }
                if (atom.maxdel === undefined || atom.maxdel === null || atom.maxdel === ""
                    || atom.maxdel < atom.mindel || (atom.maxdel > state.lvaQuery.QUERY_L - atom.start + 1)) {
                    lvaWidgetAtomErrStatus[i].max_del = 'Maximum deletions must be between ' + atom.mindel + ' and ' + (state.lvaQuery.QUERY_L - atom.start + 1);
                    nbErrors++;
                    continue;
                }
                if (atom.maxDel === undefined || atom.maxDel === null || atom.maxDel === "") {
                    atom.maxDel = atom.maxdel; // lvaWidgetState.lvaQuery.QUERY_L;
                }
            } else if (atom.method === 'specinserts') {
                if (!atom.inserts || atom.inserts.length === 0) {
                    lvaWidgetAtomErrStatus[i].inserts = 'Please declare at least one specific inserted sequence.';
                    nbErrors++;
                    continue;
                } /*else if (atom.inserts.length === 1 && atom.inserts === "*") {
                    lvaWidgetAtomErrStatus[i].inserts = 'Invalid input, * only is not allowed.';
                    nbErrors++;
                    continue;
                }*/
                // Letters, *, ?, + only, Multiple strings separated by comma, semicolon or newline
                let regex = new RegExp("^[A-Za-z?*+]+[ A-Za-z?*+,;\n]*$");
                if (!(regex.test(atom.inserts))) {
                    lvaWidgetAtomErrStatus[i].inserts = 'Invalid input, allows single letters, *, ? and + only, separated by comma, semicolon or new line';
                    nbErrors++;
                }

            } else if(atom.method === "percIdentity"){
                let regex = new RegExp(/^\d+$/);
                if(regex.test(atom.percIdentity) && atom.percIdentity !== "" && atom.percIdentity !== undefined){
                    if(atom.percIdentity < 0 || atom.percIdentity > 100){
                        lvaWidgetAtomErrStatus[i].percIdentity = "Enter a value between 0 and 100";
                        nbErrors++;
                        continue;
                    }
                }else{
                    lvaWidgetAtomErrStatus[i].percIdentity = "Please enter a numeric value";
                    nbErrors++;
                    continue;
                }

            } else if(atom.method === "maxDiff") {
                let regex = new RegExp(/^\d+$/);
                if(regex.test(atom.maxDiff) && atom.maxDiff !== "" && atom.maxDiff !== undefined){
                    //let maxDiffVal = parseInt(atom.stop) - parseInt(atom.start) + 1;
                    if (atom.maxDiff < 0){
                        lvaWidgetAtomErrStatus[i].maxDiff = "Enter a value greater than or equal to 0"; //  + maxDiffVal
                        nbErrors++;
                        continue;
                    }
                }else{
                    lvaWidgetAtomErrStatus[i].maxDiff = "Please enter a numeric value";
                    nbErrors++;
                    continue;
                }
            }

            // else if (atom.method === 'anyinsert') {} // Nothing to do for any insert
            // lvaWidgetState.widgetContent[i] = atom;
        }
        setLvaWidgetAtomErrStatus({...lvaWidgetAtomErrStatus});
        return (nbErrors === 0);
    };

    function toggleLvaEventSelection(idx, index, e) {
        lvaWidgetState.widgetContent[idx].lvaEventOptions[index].ticked = e.currentTarget.checked;
        setLvaWidgetState({...lvaWidgetState})
        if (!e.currentTarget.checked && index > -1) {
            // Bug here, the events array don't have every element for the index
            // lvaWidgetState.widgetContent[idx].events.splice(index, 1);
            let eventIdx = lvaWidgetState.widgetContent[idx].events.map(function (e) {
                return e.event;
            }).indexOf(lvaEventOptions[index].event);
            //let eventIdx = lvaWidgetState.widgetContent[idx].events.indexOf({"event": lvaWidgetState.widgetContent[idx].lvaEventOptions[index].event});
            lvaWidgetState.widgetContent[idx].events.splice(eventIdx, 1);
        } else if (e.currentTarget.checked) {
            lvaWidgetState.widgetContent[idx].events.push({"event": lvaWidgetState.widgetContent[idx].lvaEventOptions[index].event});
        }
    }

    function widgetGetLvaTextAreaNbRows(value) {
        // count number of return characters and allow up to 4 rows for the textarea
        let val = String(value),
            count = 1 + (val.match(/\r\n|\r|\n/g) || []).length;
        return ((count > 4) ? 4 : count);
    };

    function setlvaWidgetSeqId(index){
        let state = _.cloneDeep(lvaWidgetState);
        state.lvaQuery = vmIds[index];
        state.lvaQuery.copyFromOther = false;
        state.lvaQuery.copyFromQuery = "";

        if (!state.widgetContent) {
            state.widgetContent = {};
        }
        if (state.widgetContent.length === 0){
            state.widgetContent.push(newLvaAtom());
        }
        verifyLvaFilterWidgetAtoms(state);
        setLvaWidgetState(state);
    }

    return(
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcente"
            centered
            show={props.showModal}
           // contentClassName={classes.modalClassConDSI}
        >
            <div className="large-font">
                <span className="really-large-font" style={{textAlign: 'center', paddingLeft: '300px', color: '#777777'}}>Variation Filter</span>
                <Link href="#" onClick={(e) => e.preventDefault()} className="pull-right appTextColor">
                    <CloseIcon onClick={props.closeModal}/>
                </Link>
            </div>
            <Modal.Body style={toggle ? {backgroundColor: ''} : {backgroundColor: '#292929'}}>
            <div style={{padding: '10px 10px 20px 10px'}}> {/*className="filter-widget-container"*/}
                {/*<div className="really-large-font" style={{padding: "20px, 20px, 0, 360px"}}></div>*/}

                <table style={{width: "100%"}}>
                    <tbody>
                        {/*sequence Id, color and name*/}
                        <tr>
                            <td colSpan="7" className="clearfix">
                                <Dropdown className="btn-group">
                                    <Dropdown.Toggle className="btn btn-dropdown btn-dropdown-dates">
                                        <div className="clearfix">
                                            <div className="pull-left custom-filter-label">{(lvaWidgetState.lvaQuery && lvaWidgetState.lvaQuery.QUERY_ID) ? lvaWidgetState.lvaQuery.QUERY_ID : "Select Query Sequence:"}</div>
                                            <div style={{ display: 'inline-block', position: 'absolute', right: '2px' }}><FaCaretDown/></div>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-dates custom-filter-label" style={toggle ? {} :{backgroundColor: '#292929', border: '1px solid #777777'}}>
                                        {vmIds!==undefined && Object.keys(vmIds).length > 0 && Object.keys(vmIds).map((value, index)=>{return(
                                        <Dropdown.Item className="filter-widget-menu-item" style={{color: '#db862c'}} key={'option_' + index} value={value}
                                            onClick={(e) => setlvaWidgetSeqId(index+1)}>
                                            {ReactHtmlParser(vmIds[index+1] !== undefined? vmIds[index+1].QUERY_ID : "NA")}
                                        </Dropdown.Item>
                                        );})}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className="pull-right">
                                    <div className={"color-select-div pull-left filter-color-select"}>
                                        <div onClick={()=>{displayColorBox()}} onMouseLeave={() => hideColorBox()}
                                             className={"pull-right color-box color-"+lvaWidgetState.selectedColorIdx}
                                        >
                                            {showColorBox &&
                                            <div tabIndex="0" id="colorbox" className="clearfix lva-color-box-select">
                                                <div onClick={()=>{selectColor(1)}} className="color-1 color-box pull-left"></div>
                                                <div onClick={()=>{selectColor(2)}}  className="color-2 color-box pull-left"></div>
                                                <div onClick={()=>{selectColor(3)}}  className="color-3 color-box pull-left"></div>
                                                <div onClick={()=>{selectColor(4)}}  className="color-4 color-box pull-left"></div>
                                                <div onClick={()=>{selectColor(5)}}  className="color-5 color-box pull-left"></div>
                                                <div onClick={()=>{selectColor(6)}}  className="color-6 color-box pull-left"></div>
                                            </div>
                                        }
                                        </div>
                                    </div>
                                    <div className="pull-right">
                                        <input type="text" autoComplete="off" spellCheck="false"
                                            className={'filter-widget-input-textarea ' + (lvaFilterNameErr ? 'input-with-error' : '')}
                                            style={{width: '210px'}} placeholder="Name your Filter"
                                            value={lvaWidgetState.lvaFilterName}
                                            onChange={(e) => {setLvaFilterName(e)}}/>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        {/*all, any, random */}
                        <tr>
                            <td colSpan="7" className="clearfix">
                                <div className="pull-left filter-widget-field-middle custom-filter-label">
                                    Results must meet&nbsp;&nbsp;&nbsp;
                                    <input id="AND" type="radio" value="AND" name="radioGroup"
                                        style={{verticalAlign: 'middle'}}
                                        checked={lvaWidgetState.widgetMode === 'AND'}
                                        onChange={changeSelection}/>
                                    <span htmlFor="AND">&nbsp;all conditions</span>
                                    &nbsp;&nbsp;&nbsp;
                                    <input id="OR" type="radio" value="OR" name="radioGroup"
                                        style={{verticalAlign: 'middle'}}
                                        checked={lvaWidgetState.widgetMode === 'OR'}
                                        onChange={changeSelection}/>
                                    <span htmlFor="OR">&nbsp;at least one condition</span>
                                    &nbsp;&nbsp;&nbsp;
                                    <input id="CUSTOM" type="radio" value="CUSTOM" name="radioGroup"
                                        style={{verticalAlign: 'middle'}}
                                        checked={lvaWidgetState.widgetMode === 'CUSTOM'}
                                        onChange={changeSelection}/>
                                    <span htmlFor="CUSTOM">&nbsp;custom</span>
                                </div>
                            </td>
                        </tr>
                        {lvaWidgetState.widgetMode === 'CUSTOM' && //custom fiterbox
                        <tr>
                            <td colSpan="7" className="clearfix">
                                <textarea className="filter-widget-input-textarea"
                                        placeholder="Type your custom query here using the F-numbers below. For example: (F1 and F3) or (F2 and F4)"
                                        rows="3"
                                        value={lvaWidgetState.widgetCustomQuery}
                                        onChange={checkCustomQuerySyntax}></textarea>
                                <div className="custom-query-error">{customQueryMessage}</div>
                            </td>
                        </tr>
                        }
                        <tr style={{width: "100%"}}>
                            <td colSpan="7">
                            <hr style={toggle ? {backgroundColor: '#dfdfdf'}: {backgroundColor: '#aea9a9'}}/>
                            </td>
                        </tr>
                        

                        {lvaWidgetState.lvaQuery &&
                        <>
                            {/* table headers */}
                            <tr> {/*filter-widget-method*/}
                                <td className="filter-widget-title" style={{width: "250px"}}>Filter for</td>
                                <td className="filter-widget-options filter-widget-title" colSpan="2" style={{width: "250px", paddingRight: "10px"}}>Filter what?</td>
                                <td className="filter-widget-title" colSpan="2" style={{width: "250px", paddingRight: "10px"}}>Where?</td>
                                <td className="filter-widget-adddel"></td>
                                <td className="filter-widget-adddel"></td>
                            </tr>

                            {/* WIDGET CONTENT*/}
                            {lvaWidgetState.widgetContent.length > 0 &&
                                lvaWidgetState.widgetContent.map((atom, idx) => (
                                <tr key={'filter_' + idx}>
    
                                    {/* field className="filter-widget-field"*/}
                                    <td style={{width: '180px'}}>
                                        <div className="control-spacer">
                                        {/* F designation for the custom filter */}
                                        {lvaWidgetState.widgetMode === 'CUSTOM' &&
                                        <span className={' filter-widget-atom-index ' + (lvaWidgetState.widgetCustomAtomUsed ?  (lvaWidgetState.widgetCustomAtomUsed['F'+(1+idx)]?'green-font': 'red-font'):'red-font')}>
                                            F{(1 + idx)}
                                        </span>
                                        }
                                        <Dropdown className="btn-group">
                                            <Dropdown.Toggle className="btn btn-dropdown" style={{width: '215px'}}>
                                                <div className="clearfix">
                                                    <div className="pull-left custom-filter-label">{ReactHtmlParser(lvaWidgetState.widgetContent[idx].text_label !==undefined ? lvaWidgetState.widgetContent[idx].text_label.replace('Matches, replacements', "Matches, repls") : "Filters:")}</div>
                                                    <div style={{ display: 'inline-block', position: 'absolute', right: '2px' }}><FaCaretDown/></div>
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu dropdown-menu-dates custom-filter-label" style={toggle ? {} :{backgroundColor: '#292929', border: '1px solid #777777'}}>
                                            {lvaGroupedMethods.map(({group, methods}, key) =>
                                                (true) &&
                                                <div key={'dropdown_' + key}>
                                                    <div className="filter-widget-group-item">{group}</div>
                                                    {methods.map((field, subIdx) => (
                                                        <Dropdown.Item className="filter-widget-menu-item" style={{color: '#db862c'}} key={'option_' + subIdx} value={field}
                                                                onClick={(e) => setLvaMethod(idx, field)}>
                                                            {ReactHtmlParser(field)}
                                                        </Dropdown.Item>
                                                    ))}
                                                </div>
                                            )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        </div>
                                    </td>

                                    {/* value */}
                                    <td className={atom.method === 'deletions' ? 'filter-widget-position' : 'filter-widget-options'} colSpan={atom.method === 'deletions' ? 1:2}>
                                        <div className="control-spacer">
                                            {atom.method === 'atleastone' &&
                                            <CustomTooltip arrow title={(lvaWidgetAtomErrStatus[idx] && lvaWidgetAtomErrStatus[idx].events)? lvaWidgetAtomErrStatus[idx].events:'Select one or multiple filter conditions, which will be connected with OR'} placement="top">
                                            <div id={"eventselect_"+lvaWidgetState.lvaQuery.QUERY_N+"_"+idx} className={"filter-widget-textarea "+ (!filterCorrect && lvaWidgetAtomErrStatus[idx] && lvaWidgetAtomErrStatus[idx].events ?'input-with-error':'')}
                                            style={toggle? {backgroundColor: '', color: '' }:{backgroundColor: '#292929', color: 'white'}}>
                                                {lvaWidgetState.widgetContent[idx].lvaEventOptions.map((option, index)=>{
                                                    return(
                                                        <div key={"vmeventselect"+index}>
                                                            <input type="checkbox" id={option.event+"_"+idx} style={{verticalAlign: 'middle'}}
                                                                    checked={option.ticked}
                                                                    value = {lvaWidgetState.widgetContent[idx].events}
                                                                    onChange={(e) =>{toggleLvaEventSelection(idx /*widgetindex*/, index /*eventoptionindex*/, e)}}/>
                                                            <label htmlFor={option.event+"_"+idx} style={{fontWeight: "normal"}}>&nbsp;{option.label}</label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            </CustomTooltip>
                                            }
                                            {atom.method === 'replacements' &&
                                            <CustomTooltip arrow title={(lvaWidgetAtomErrStatus[idx] && lvaWidgetAtomErrStatus[idx].replacements)? lvaWidgetAtomErrStatus[idx].replacements:
                                                (atom.negated ? 'Enter specific replacement residues that may not be present, or blank for any residue. Separate multiple residues with comma, semicolon or new line.'
                                                : 'Enter specific replacement residues that must be present, or blank for any residue. Separate multiple residues with comma, semicolon or new line.')}>
                                                <textarea id={"replacements_"+lvaWidgetState.lvaQuery.QUERY_N+"_"+idx} rows={widgetGetLvaTextAreaNbRows(atom.replacements)} className={"filter-widget-textarea "+(!filterCorrect && lvaWidgetAtomErrStatus[idx] && lvaWidgetAtomErrStatus[idx].replacements ?'input-with-error':'')}
                                                        placeholder={atom.negated ? 'Absent residue(s)' : 'Replacement residue(s)'}
                                                        value={lvaWidgetState.widgetContent[idx].replacements}
                                                        onChange={(e) => {lvaWidgetState.widgetContent[idx].replacements = e.currentTarget.value; setLvaWidgetState({...lvaWidgetState});}}
                                                        style={toggle ? {backgroundColor: '', color: ''}:{backgroundColor: '#292929', color: 'white'}}>
                                                </textarea>
                                            </CustomTooltip>
                                            }
                                            {atom.method === 'deletions' &&
                                                <CustomTooltip arrow title={(lvaWidgetAtomErrStatus[idx] && lvaWidgetAtomErrStatus[idx].min_del)? lvaWidgetAtomErrStatus[idx].min_del:
                                                    (atom.negated ? 'Exclude hits with deletions fewer to this length. Filter will not not remove hits with longer deletions than specified.': 'What is the minimum number of residues that can be deleted? Filter will also find longer.')} placement="top">
                                                    <input
                                                        id={"deletions_min_"+lvaWidgetState.lvaQuery.QUERY_N+"_"+idx}
                                                        type="number" step="1" min="1"
                                                        max={lvaWidgetState.lvaQuery.QUERY_L}
                                                        className={"filter-widget-input "+ (!filterCorrect && lvaWidgetAtomErrStatus[idx] && lvaWidgetAtomErrStatus[idx].min_del ? 'input-with-error':'')}
                                                        placeholder="Minimum length"
                                                        value={lvaWidgetState.widgetContent[idx]["mindel"] !== undefined? lvaWidgetState.widgetContent[idx]["mindel"] : "error"}
                                                        onChange={(e) => {lvaWidgetState.widgetContent[idx]["mindel"] = parseInt(e.currentTarget.value); setLvaWidgetState({...lvaWidgetState});}}
                                                        no-float="true"
                                                    >
                                                    </input>
                                                </CustomTooltip>
                                            }
                                            {atom.method === 'specinserts' &&
                                            <CustomTooltip arrow title={(lvaWidgetAtomErrStatus[idx] && lvaWidgetAtomErrStatus[idx].inserts)? lvaWidgetAtomErrStatus[idx].inserts:
                                                (atom.negated ? 'Enter insertion sequence(s) you want to exclude. Wildcarding: ?=any one residue, +=one or more residues, *=zero or more residues' : 'Enter insertion sequence(s). Wildcarding: ?=any one residue, +=one or more residues, *=zero or more residues.')} placement="top">
                                                <textarea 
                                                    id={"specinserts_"+ lvaWidgetState.lvaQuery.QUERY_N+"_"+idx} 
                                                    rows={widgetGetLvaTextAreaNbRows(atom.inserts)} style={toggle? {height: "80px", backgroundColor: '', color: ''}:{height: "80px", backgroundColor: '#292929', color: 'white'}} 
                                                    className={"filter-widget-textarea "+ (!filterCorrect && lvaWidgetAtomErrStatus[idx] && lvaWidgetAtomErrStatus[idx].inserts ?'input-with-error':'')}
                                                    placeholder="Insertion sequence(s)"
                                                    value={lvaWidgetState.widgetContent[idx].inserts !== undefined? lvaWidgetState.widgetContent[idx].inserts : ""}
                                                    onChange={(e) => {lvaWidgetState.widgetContent[idx].inserts = e.currentTarget.value; setLvaWidgetState({...lvaWidgetState});}}
                                                    >
                                                </textarea>
                                            </CustomTooltip>
                                            }
                                            {atom.method === 'percIdentity' &&
                                            <td className="filter-widget-position">
                                                <div className="control-spacer">
                                                <CustomTooltip arrow title={(lvaWidgetAtomErrStatus[idx] && lvaWidgetAtomErrStatus[idx].percIdentity) ? lvaWidgetAtomErrStatus[idx].percIdentity : 'Enter a value between 1 and 100'} placement="top">
                                                    <input 
                                                        id={"percIdentity_"+lvaWidgetState.lvaQuery.QUERY_N+"_"+idx} 
                                                        type="number" disabled={lvaWidgetState.widgetContent[idx].method===null}
                                                        step="1" min="1" max="100" className={"filter-widget-input "+ (!filterCorrect && lvaWidgetAtomErrStatus[idx] && lvaWidgetAtomErrStatus[idx].percIdentity ? 'input-with-error':'')}
                                                        placeholder="Percentile"
                                                        value={lvaWidgetState.widgetContent[idx].percIdentity !== undefined? lvaWidgetState.widgetContent[idx].percIdentity : ""}
                                                        onChange={(e) => {lvaWidgetState.widgetContent[idx].percIdentity = e.currentTarget.value; setLvaWidgetState({...lvaWidgetState});}}
                                                    ></input>
                                                </CustomTooltip>
                                                </div>
                                            </td>
                                            }
                                            {atom.method === 'maxDiff' &&
                                                <td className="filter-widget-position">
                                                    <div className="control-spacer">
                                                        <CustomTooltip arrow title={(lvaWidgetAtomErrStatus[idx] && lvaWidgetAtomErrStatus[idx].maxDiff) ? lvaWidgetAtomErrStatus[idx].maxDiff : 'Enter a value larger than 0'} placement="top">
                                                            <input
                                                                id={"maxDiff_"+lvaWidgetState.lvaQuery.QUERY_N+"_"+idx}
                                                                type="number" disabled={lvaWidgetState.widgetContent[idx].method===null}
                                                                step="1" min="1" max="100" className={"filter-widget-input "+ (!filterCorrect && lvaWidgetAtomErrStatus[idx] && lvaWidgetAtomErrStatus[idx].maxDiff ? 'input-with-error':'')}
                                                                placeholder="Max No. of Diff."
                                                                value={lvaWidgetState.widgetContent[idx].maxDiff !== undefined? lvaWidgetState.widgetContent[idx].maxDiff : ""}
                                                                onChange={(e) => {lvaWidgetState.widgetContent[idx].maxDiff = e.currentTarget.value; setLvaWidgetState({...lvaWidgetState});}}
                                                            ></input>
                                                        </CustomTooltip>
                                                    </div>
                                                </td>
                                            }
                                        </div>
                                    </td>

                                    {atom.method === 'deletions' &&
                                        <td className="filter-widget-position">
                                            <div className="control-spacer">
                                                <CustomTooltip arrow title={(lvaWidgetAtomErrStatus[idx] && lvaWidgetAtomErrStatus[idx].max_del)? lvaWidgetAtomErrStatus[idx].max_del:
                                                    (atom.negated ? 'Exclude hits with deletions up to this length. Filter will not not remove hits with longer deletions than specified.': 'What is the maximum number of residues that can be deleted? Filter will also find shorter.')} placement="top">
                                                    <input
                                                        id={"deletions_"+lvaWidgetState.lvaQuery.QUERY_N+"_"+idx}
                                                        type="number" step="1" min="1"
                                                        max={lvaWidgetState.lvaQuery.QUERY_L}
                                                        className={"filter-widget-input "+ (!filterCorrect && lvaWidgetAtomErrStatus[idx] && lvaWidgetAtomErrStatus[idx].max_del ? 'input-with-error':'')}
                                                        placeholder="Maximum length"
                                                        value={lvaWidgetState.widgetContent[idx]["maxdel"] !== undefined? lvaWidgetState.widgetContent[idx]["maxdel"] : "error"}
                                                        onChange={(e) => {lvaWidgetState.widgetContent[idx]["maxdel"] = parseInt(e.currentTarget.value); setLvaWidgetState({...lvaWidgetState});}}
                                                        no-float="true"
                                                    >
                                                    </input>
                                                </CustomTooltip>
                                            </div>
                                        </td>
                                    }
                                    <td className="filter-widget-position" colSpan={usesLvaStopPosition(atom)?1:2}>
                                        <div className="control-spacer">
                                            <CustomTooltip arrow title={(lvaWidgetAtomErrStatus[idx] && lvaWidgetAtomErrStatus[idx].start) ? lvaWidgetAtomErrStatus[idx].start : atom.startPosHint + lvaWidgetState.lvaQuery.QUERY_L} placement='top'>
                                            <input id={"start_"+lvaWidgetState.lvaQuery.QUERY_N+"_"+idx} type="number" disabled={lvaWidgetState.widgetContent[idx].method===null}
                                                step="1"
                                                min={atom.startPosMinValue} max={atom.hasPosMaxValue ? lvaWidgetState.lvaQuery.QUERY_L : ''}
                                                className={"filter-widget-input "+(!filterCorrect && lvaWidgetAtomErrStatus[idx] && lvaWidgetAtomErrStatus[idx].start !== '' ? 'input-with-error':'')}
                                                placeholder={atom.startPosText}
                                                value={lvaWidgetState.widgetContent[idx].start}
                                                onChange={(e) => {changeStartPostion(idx, e)}}
                                                no-float="true"
                                            ></input>
                                            </CustomTooltip>
                                        </div>
                                    </td>
                                    {usesLvaStopPosition(atom) &&
                                    <td className="filter-widget-position">
                                        <div className="control-spacer">
                                        <CustomTooltip arrow title={(lvaWidgetAtomErrStatus[idx] && lvaWidgetAtomErrStatus[idx].stop) ? lvaWidgetAtomErrStatus[idx].stop : atom.stopPosHint + lvaWidgetState.lvaQuery.QUERY_L} placement="top">
                                            <input 
                                                id={"stop_"+lvaWidgetState.lvaQuery.QUERY_N+"_"+idx} 
                                                type="number" disabled={lvaWidgetState.widgetContent[idx].method===null}
                                                step="1" min="1" className={"filter-widget-input "+ (!filterCorrect && lvaWidgetAtomErrStatus[idx] && lvaWidgetAtomErrStatus[idx].stop ? 'input-with-error':'')}
                                                placeholder={atom.stopPosText} 
                                                value={lvaWidgetState.widgetContent[idx].stop} 
                                                onChange={(e) => {changeStopPostion(idx, e)}}
                                                no-float="true"
                                            ></input>
                                        </CustomTooltip>
                                        </div>
                                    </td>
                                    }
                

                                    {/* plus / minus buttons */}
                                    <td className="filter-widget-adddel">
                                    {Object.keys(lvaWidgetState.widgetContent).length > 1 &&
                                        <CustomTooltip arrow title="Remove this Condition" placement="bottom">
                                            <div className="control-spacer plus-min-button custom-filter-label"
                                                onClick={() => lvaWidgetDelAtom(idx)}>
                                                <FaMinus/>
                                            </div>
                                        </CustomTooltip>
                                    }
                                    </td>
                                    <td className="filter-widget-adddel">
                                        <CustomTooltip arrow title="Add Condition below" placement="bottom">
                                            <div className="control-spacer plus-min-button custom-filter-label"
                                                onClick={() => lvaWidgetAddAtom(idx)}>
                                                <FaPlus/>
                                            </div>
                                        </CustomTooltip>
                                    </td>
                                </tr>
                            ))
                            }
                        </>
                        }

                        {lvaWidgetState.lvaQuery &&
                            <tr>
                                <td colSpan="7" className="clearfix">
                                    <div>
                                        <input type="checkbox"  style={{verticalAlign: 'middle'}}
                                               checked={saveAsGlobal} id="saveAsGlobal"
                                               onChange={() => setSaveAsGlobal(!saveAsGlobal)}/>
                                        <label htmlFor="saveAsGlobal">&nbsp;Make available for all my searches</label>
                                    </div>
                                </td>
                            </tr>
                        }

                        {/* buttons */}
                        <tr>
                            <td colSpan="7" className="clearfix">
                                <div className="clearfix" style={{paddingTop: '40px'}}>
                                    <button className="pull-left normal-button grey" style={{margin: '0px'}}
                                            onClick={clearLvaFilterWidget}>Clear All
                                    </button>
                                    <CustomTooltip arrow title={lvaFilterNameErr} placement="top">
                                    <button className={'pull-right normal-button ' + (lvaFilterNameUsed === -1 ? 'gqblue' : 'gqblue-alert')}
                                    onClick={save}>
                                        {(lvaFilterNameUsed===-1 ? 'Save as New ' : 'Overwrite Existing My')} Filter
                                    </button>
                                    </CustomTooltip>
                                    <button className="pull-right normal-button grey" onClick={props.closeModal}>Cancel</button>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
            </Modal.Body>
        </Modal>
    );
}

export default VmFilterModal
