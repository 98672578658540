import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
    memberModal: {
        height: '353.091px',
        background: '#bee5f2',
        marginRight: '12px',
        marginBottom: '12px',
        marginLeft: '5px',
        borderLeft: '1px solid #dcd7c0',
        borderRight: '1px solid #dcd7c0',
        borderBottom: '1px solid #dcd7c0',
        color: 'black'
        // borderTop: '1px solid #dcd7c0'
    },
    paraStyle: {
        fontSize: '12px',
        fontFamily: 'verdana, helvetica',
        padding: '5px',
        color: 'black !important'
    },
    tableHead: {
        fontFamily: 'verdana, helvetica',
        textAlign: 'center',
        padding: '4px',
        borderLeft: '1px solid white',
        width: '220px',
        verticalAlign: 'top',
        fontWeight: 'normal',
        fontSize: '12px'
    },
    fontDetails: {
        fontFamily: 'verdana, helvetica',
        fontSize: '12px',
        verticalAlign: 'top',
        padding: '4px 5px',
        color: 'black'
    },
    scrollable: {
        // maxHeight: '353.091px',
        overflowY: 'auto',
        marginBottom: '12px',
    }
}));


function ManageTeamsMembers(props) {
    const classes = useStyles();

    const memberList = props.teamsDetails.member_list;
    if (!memberList) {
        return null;
    }
    const memberArray = Object.values(memberList);
    const usersInfo = memberArray.filter(candidate => candidate.class_name === "user");
    const groupsInfo = memberArray.filter(candidate => candidate.class_name === "group");
    const seatTypeInfo = memberArray.filter(candidate => candidate.class_name === "seat type");

    return (
        <div className={classes.scrollable}>
            <div className={classes.memberModal}>
                {(!props.allowEdit && props.teamsDetails._is_protected) &&
                    <p className={classes.paraStyle}>This is a special team containing every user in this system.</p>
                }
                {(!props.teamsDetails._is_protected)&&
                    <div style={{ padding: '10px' }}>
                        <table style={{ width: '60%', border: '1px solid #dcd7c0' }}>
                            <tr className='resulttable_head' style={{ padding: '12px' }}>
                                <th className={classes.tableHead}>Users </th>
                                <th className={classes.tableHead}>Groups </th>
                                <th className={classes.tableHead}>Seat Types </th>
                            </tr>
                            <tbody style={{ border: '1px solid white'}}>
                                <tr>
                                    <td className={classes.fontDetails}>
                                        {usersInfo.length > 0 ? (
                                            usersInfo.map((user, index) => (
                                                <div key={index}>
                                                    <li>{user.full_name}</li>
                                                </div>
                                            ))
                                        ) : "(none)"}
                                    </td>
                                    <td className={classes.fontDetails}>
                                        {groupsInfo.length > 0 ? (groupsInfo.map((group, index) => (
                                            <div key={index}>
                                                <li>{group.text_label}</li>
                                            </div>
                                        ))) : "(none)"}
                                    </td>
                                    <td className={classes.fontDetails} >
                                        {seatTypeInfo.length > 0 ? (seatTypeInfo.map((seat, index) => (
                                            <div key={index}>
                                                <li >{seat.def_id}</li>
                                            </div>
                                        ))
                                        ) : "(none)"}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </div>
    );

}

export default ManageTeamsMembers;