import React, { useState, useEffect, useRef } from 'react';
import MostUsedPanel from './MostUsedPanel';
import ApplicationPanel from "./ApplicationPanel";
import RecentResults from "./RecentResults";
import { Fragment } from 'react';
import { containerWidth } from '../../shared/constants'
import { useSelector } from 'react-redux';
import BundleShareHome from './BundleShareHome';

const homeContentStyle = {
    width: containerWidth
}
const homeContainer = {
    display: 'flex',
    justifyContent: 'center'
}
function Home() {
    const userInfo = useSelector(state => state.setUserInfo);
    //console.log(userInfo.current_user.user_class_id,'in HOME');
    return (
        <div style={homeContainer}>

            <div style={homeContentStyle}>
            {userInfo.current_user.user_class_id != 12 ?
                 <React.Fragment>
                <MostUsedPanel />
                <ApplicationPanel />
                <RecentResults />
                </React.Fragment>
                :<React.Fragment>
                    <BundleShareHome/>
                </React.Fragment>
                }
            </div>
                
        </div>
    )
}

export default Home;