import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from "react-i18next";
import {useHistory, Link, useParams} from "react-router-dom";
import Button from "@material-ui/core/Button";
import CloseIcon from '@material-ui/icons/Close';
import CheckBox from '../../shared/Fields/CheckBox';
import { useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
	modalHeader: {
		borderBottom: 'none !important',
		padding: '4px 4px 3px 0px',
		display: "block !important",
	},
	modalHeaderDark: {
		borderBottom: 'none !important',
		padding: '4px 4px 3px 0px',
		display: "block !important",
		background: '#292929'
	},
	modalBody: {
		margin: "0 20px 20px 20px",
		padding: "38px"
	},
	modalBodyDark: {
		// margin: "0 20px 20px 20px",
		backgroundColor: "#292929",
		padding: "38px"
	},
	titleFont: {
		fontSize: "20px !important"
	},
	submitDisabled: {
		border: '1px solid #e0e0e0 !important',
		textTransform: 'none',
	},
	checkboxAdjustments: {
		paddingLeft: '5px'
	},
	examplee: {
		color: 'black'
	}
}));

function FtOntologyModal(props) {
	const {saveOntologyState} = props;

	const getDefaultValue = () => {
		let initial = {
			CHEBI:    ['Chemical Entities of Biological Interest (CHEBI)', false],
			DOID:     ['Human Disease Ontology (DOID)', false],
			GO:       ['Gene Ontology (GO)', false],
			HP:       ['Human Phenotype Ontology (HP)', false],
			IMR:      ['INOH Protein name/family name ontology (IMR)', false],
			MOD: 	  ['Protein Modification (MOD)', false],
			MSH: 	  ['Medical Subject Headings (MSH)', true],
			NCBI: 	  ['NCBI organismal classification (NCBI)', true],
			PO: 	  ['Plant Ontology (PO)', false],
			PR: 	  ['Protein Ontology (PR)', false],
			// Remove below item for now due to the license restriction
			// SNOMEDCT: ['Systematized Nomenclature of Medicine - Clinical Terms (SNOMEDCT)', false],
			TO: 	  ['Plant Trait Ontology (TO)', false],
			USER: 	  ['Your word lists (USER)', true],
		}

		let state = localStorage.getItem("FTOntologies");
		if(state !== undefined && state !== null){
			let stateObj = JSON.parse(state);
			initial = {...stateObj}
			//saveOntologyState({...stateObj})
		}else{
			localStorage.setItem("FTOntologies", JSON.stringify(initial))
		}

		return initial
	}

	const [ontologyState, setOntologyState] = useState(getDefaultValue());


	useEffect(() => {

		// let state = localStorage.getItem("FTOntologies");
		// if(state !== undefined && state !== null){
		// 	let stateObj = JSON.parse(state);
		// 	setOntologyState({...stateObj})
		// 	saveOntologyState({...stateObj})
		// }

        const handleStorageChange = (event) => {
          if (event.key === "FTOntologies") {
			let state = event.newValue;
			if(state !== undefined && state !== null){
				let stateObj = JSON.parse(state);
				console.log("GotOntologies")
				setOntologyState({...stateObj})
				saveOntologyState({...stateObj})
			}
          }
        };
      
        window.addEventListener('storage', handleStorageChange);
      
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('storage', handleStorageChange);
        };
    },[]);

    const { t, i18n } = useTranslation("common");

    const classes = useStyles();
	const toggle = useSelector(state => state.setToggle.toggle);

    const changeOntology = (key) => { //handles change on checkboxes
		var thisOnt = ontologyState;
		var oldOntVal = thisOnt[key] //swaping in the new value
		oldOntVal = [oldOntVal[0], !oldOntVal[1]];
		thisOnt[key] = oldOntVal;

		//localStorage.setItem("FTOntologies", thisOnt);
		setOntologyState({...thisOnt});
	}

	const applyOntologyState = () => {
		localStorage.setItem("FTOntologies", JSON.stringify(ontologyState))
		saveOntologyState({...ontologyState});
		props.closeModal();
	}

	const cancelOntologies = () =>{

		let state = localStorage.getItem("FTOntologies");
		if(state !== undefined && state !== null){
			let stateObj = JSON.parse(state);
			setOntologyState(stateObj)
		}
		props.closeModal();
	}


	return (
	    <Modal
			size="lg"
            show={props.show}
            onHide={props.closeModal}
			aria-labelledby="example-modal-sizes-title-lg"
			contentClassName={classes.examplee}
		>
			<Modal.Header className={toggle? classes.modalHeader : classes.modalHeaderDark}> 
				<Link to="#" onClick={(e) => e.preventDefault()} className={"float-right appTextColor"}><CloseIcon onClick={() => cancelOntologies()} /></Link>
			</Modal.Header>
			<Modal.Body className={toggle? classes.modalBody : classes.modalBodyDark}>
				<div>
					<h4 className={"subHeading " + classes.titleFont}>{t('OntusedAuto')}</h4>
				</div> 
					{Object.entries(ontologyState).map(([key,value]) => {
    					return (
							<div style={{paddingLeft: '10px'}} key={key+""}>
    					      <table className="toppings-list-item">
    					        <tr className="left-section">
								 <td>
    					          <CheckBox
								    color="primary"
								    className={"float-left ml-2 mt-1"}
									id={key+'-id'}
									name={key+'-id'}
									checked={ontologyState[key][1]}
    					            onChange={()=> changeOntology(key)}
    					          />
								  </td>
								  <td className={classes.checkboxAdjustments}>
    					            <label onClick={(e) => changeOntology(key)} className="makeStyles-checkBoxContent-88 bodyText ml-0 cursorPointer ontologyFix">{value[0]}</label>
								  </td>
								</tr>
    					      </table>
                              {/* <span hidden>{modalUpdater}</span> */}
							  </div>
    					  );
    					})}
						{/* <span hidden>{modalUpdater}</span> */}

					<div className="clear">
						<Button className={"submitButtonClass float-right ml-2"} id="mergeSubmit" onClick={() => applyOntologyState()}>{t('apply')}</Button>
						<Button className={"cancelButtonClass float-right ml-2"} id="mergeCancel" onClick={() => cancelOntologies()}>{t('cancel')}</Button>
					</div>
			</Modal.Body>
		</Modal>
	);
}

export default FtOntologyModal;