import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { get } from '../../helpers/fetchServicesMethods';
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import { url } from '../../reducers/url';
import PubSub from 'pubsub-js';
import AlertsDeleteModal from '../../shared/Modal/AlertsDeleteModal';
import AlertWorkflow from './AlertWorkflow';
import { useSelector } from 'react-redux';

function showLoader() {
    PubSub.publish('msg', true);
}

function hideLoader() {
    PubSub.publish('msg', false);
}

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        width: '96%',
    },
    h2Css: {
        fontSize: '13px',
        marginLeft: '1em',
        fontFamily: 'verdana, helvetica',
        fontWeight: 'bold',
        lineHeight: '1.286em',
        marginTop: '1em',
        marginBottom: '0px',
    },
    innerTable: {
        fontFamily: 'verdana, helvetica',
        fontSize: '12px',
        verticalAlign: 'text-top'
    },
    checkbox: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
}));

async function getAlertsData(extendUrl, history) {
    try {
        let alertsData = url.alertsData + extendUrl;
        showLoader();
        return await get(alertsData, history)
            .then((response) => {
                hideLoader();
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
                hideLoader();
            });
    } catch (error) {
        hideLoader();
        console.error(error);
    }
}

async function getAlertWorkflow(id, history) {
    try {
        let alertWorkflow = url.alertWorkflow;
        alertWorkflow = alertWorkflow.replace('**', id)
        showLoader();
        return await get(alertWorkflow, history)
            .then((response) => {
                hideLoader();
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
                hideLoader();
            });
    } catch (error) {
        hideLoader();
        console.error(error);
    }
}

function Alerts(props) {
    const [alertResult, setAlertResult] = useState([])
    // const [TotalCount, setTotalCount] = useState(null)
    const [adjustedStop, setAdjustedStop] = useState();
    const [alertTotalCount, setAlertTotalCount] = useState(null);
    const toggle = useSelector(state => state.setToggle.toggle);

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                const result = await getAlertsData('');
                const initialData = result.response_content.results;
                const totalCountAsNumber = parseInt(result.response_content.totalcount, 10);

                setAlertResult(initialData);
                // setTotalCount(result.response_content.totalcount);
                setAlertTotalCount(totalCountAsNumber);
                setAdjustedStop(initialData.length);

                console.log(result.response_content.results);
            } catch (error) {
                console.error(error);
            }
        }
        fetchMyAPI();
    }, []);
    const classes = useStyles();
    const [termsDisable, setTermsDisable] = useState(false);
    const [sortedColumn, setSortedColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [PopupOpen, setPopupOpen] = useState(false);
    const [expandedRows, setExpandedRows] = useState({});
    const [alertWorkflowResult, setAlertWorkflowResult] = useState([]);
    const [start, setStart] = useState(1);
    const handleAllCheckboxChange = () => (event) => {
        const renderedItems = [...alertResult];
        renderedItems.forEach((item, index) => {
            if (!termsDisable) {
                renderedItems[index].checked = true;
                setTermsDisable(true);
            }
            else {
                renderedItems[index].checked = false;
                setTermsDisable(false);
            }
        });
    };
    async function onClickTableData(workflowID) {
        setExpandedRows((prevExpandedRows) => ({
            ...prevExpandedRows,
            [workflowID]: !prevExpandedRows[workflowID],
        }));
        if (!expandedRows[workflowID]) {
            if (!alertWorkflowResult[workflowID]) {
                const workflowData = await getAlertWorkflow(workflowID);
                setAlertWorkflowResult(prevState => ({
                    ...prevState,
                    [workflowID]: workflowData.response_content.relatives,
                }));
            }
        }
    }
    function sortData(column) {
        const newSortDirection = sortedColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortedColumn(column);
        setSortDirection(newSortDirection);

        const sortedCopy = [...alertResult];
        sortedCopy.sort((a, b) => {
            const aValue = a[column];
            const bValue = b[column];

            if (aValue === null && bValue === null) {
                return 0;
            } else if (aValue === null) {
                return newSortDirection === 'asc' ? -1 : 1;
            } else if (bValue === null) {
                return newSortDirection === 'asc' ? 1 : -1;
            }

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return newSortDirection === 'asc'
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue);
            } else {
                return newSortDirection === 'asc'
                    ? aValue - bValue
                    : bValue - aValue;
            }
        });
        setAlertResult(sortedCopy);
    }
    const handleCheckboxChange = (index) => (event) => {
        const updatedAlertsData = [...alertResult];
        updatedAlertsData[index].checked = event.target.checked;
        let checkedCount = 0;

        updatedAlertsData.forEach((item) => {
            if (item.checked) {
                checkedCount++;
            }
        });

        setAlertResult(updatedAlertsData);

        if (checkedCount === 0) {
            setTermsDisable(false);
        } else if (checkedCount === alertResult.length) {
            setTermsDisable(true);
        } else {
            setTermsDisable(false);
        }
    };
    function handleDeleteButton() {
        setPopupOpen(true);
    }
    function closeDeletePopup() {
        setPopupOpen(false);
    }
    const handleAfterPopupClose = (afterDeletedData) => {
        setAlertResult(afterDeletedData);
        console.log('now parent file got called');
        setPopupOpen(false);
    }
    const handleLeftDoubleShift = async ()=>{
        const extendUrl = `&start=${1}&stop=${50}`;
        const result = await getAlertsData(extendUrl);
        setAlertResult(result.response_content.results);
    
        setStart(1);
        setAdjustedStop(50);
    }

    const handleRightShift = async (adjustedStop, totalMtDataCount) => {
        console.log("clicked on right shift");
    
        const itemsPerPage = 50;
        const newStart = adjustedStop + 1;
        const newStop = adjustedStop + itemsPerPage;
    
        const newAdjustedStop = Math.min(newStop, totalMtDataCount);
        const extendUrl = `&start=${newStart}&stop=${newAdjustedStop}`;
        const result = await getAlertsData(extendUrl);
        setAlertResult(result.response_content.results);
    
        setStart(newStart);
        setAdjustedStop(newAdjustedStop);
    };

    const handleRightDoubleShift = async (mtTotalCount) => {
        const itemsPerPage = 50;  
        let newStart = Math.max(Math.ceil(mtTotalCount / itemsPerPage) * itemsPerPage - itemsPerPage + 1, 1);
        const stop = mtTotalCount;
        const extendUrl = `&start=${newStart}&stop=${stop}`;
        const result = await getAlertsData(extendUrl);
        setAlertResult(result.response_content.results);
      
        setStart(newStart);
        setAdjustedStop(stop);
    };
    const handleLeftShift = async ( totalMtDataCount) => {
        console.log("clicked on left shift");
    
        const itemsPerPage = 50;
        let newStart = start - itemsPerPage;
        if (newStart < 1) {
          newStart = 1;
        }
        const newStop = newStart + itemsPerPage - 1;
        const newAdjustedStop = Math.min(newStop, totalMtDataCount);
        const extendUrl = `&start=${newStart}&stop=${newAdjustedStop}`;
        const result = await getAlertsData(extendUrl);
        setAlertResult(result.response_content.results);
    
        setStart(newStart);
        setAdjustedStop(newAdjustedStop);
      };
    return (
        <div className={classes.grow}>
            <div className='mt_layout'>
                <div className='mt_container'>
                    <div className='toolbar mToolbar'>
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '33%' }}>
                                        <div>
                                            <button className='mTeamsBtn' onClick={() => handleDeleteButton()} >Delete</button>
                                            {alertResult.length > 0 && PopupOpen && <AlertsDeleteModal
                                                showModal={PopupOpen}
                                                alertResult={alertResult}
                                                closeDeletePopup={closeDeletePopup}
                                                afterCloseModal={handleAfterPopupClose}
                                            ></AlertsDeleteModal>}
                                        </div>
                                    </td>
                                    {alertResult.length > 0 &&
                                        <td style={{ width: '33%', fontSize: '12px', fontFamily: 'verdana, helvetica' }} align='center'>
                                            <button
                                                className={`columnNo ${start === 1 ? 'disabled' : ''}`}
                                                style={  { color: toggle ? (start === 1 ? 'gray' : 'black') : (start === 1 ? 'gray' : 'white') } }
                                                onClick={() => start !== 1 && handleLeftDoubleShift()}
                                            >
                                                &lt;&lt;
                                            </button>
                                            <button
                                                className={`columnNo ${start === 1 ? 'disabled' : ''}`}
                                                style={  { color: toggle ? (start === 1 ? 'gray' : 'black') : (start === 1 ? 'gray' : 'white') } }
                                                onClick={() => start !== 1 && handleLeftShift(alertTotalCount)}
                                            >
                                                &lt;
                                            </button>
                                            <span className='colNoCSS'> {start} - {adjustedStop} of {alertTotalCount}</span>
                                            <button
                                                className={`columnNo ${adjustedStop === alertTotalCount ? 'disabled' : ''}`}
                                                style={{ color: toggle ? (adjustedStop === alertTotalCount ? 'gray' : 'black' ) : (adjustedStop === alertTotalCount ? 'gray' : 'white') }}
                                                onClick={() => adjustedStop !== alertTotalCount && handleRightShift(adjustedStop, alertTotalCount)}
                                            >
                                                &gt;
                                            </button>
                                            <button
                                                className={`columnNo ${adjustedStop === alertTotalCount ? 'disabled' : ''}`}
                                                style={{ color: toggle ? (adjustedStop === alertTotalCount ? 'gray' : 'black' ) : (adjustedStop === alertTotalCount ? 'gray' : 'white') }}
                                                onClick={() => handleRightDoubleShift(alertTotalCount)}
                                            >
                                                &gt;&gt;
                                            </button>
                                        </td>}
                                    <td style={{ width: '33%' }}></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {alertResult.length > 0 && <div className='tableCSS'>
                        <table style={{ width: '100%' }}>
                            <thead className='resulttable_head'>
                                <tr style={{ fontFamily: 'verdana, helvetica', fontSize: '13px' }}>
                                    <th style={{ width: '2%', fontFamily: 'verdana, helvetica' }}>
                                        <Tooltip title="Select/Deselect all alerts in this table">
                                            <Checkbox checked={termsDisable} onChange={handleAllCheckboxChange()} style={{ color: 'white', backgroundColor: "transparent" }} />
                                        </Tooltip>
                                    </th>
                                    <th style={{ width: '24%', borderLeft: '1px solid white', fontWeight: 'normal', fontSize: '14px' }} >
                                        <div className='columnLink'>Owner
                                        </div>
                                    </th>

                                    <th style={{ width: '24%', borderLeft: '1px solid white' }}>
                                        <a className='columnLink' onClick={() => sortData('email')}>Email
                                            {sortedColumn === 'email' && (
                                                <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                            )}
                                        </a>
                                    </th>
                                    <th style={{ width: '24%', borderLeft: '1px solid white' }}>
                                        <a className='columnLink' onClick={() => sortData('relaunch_interval')}>Frequency
                                            {sortedColumn === 'relaunch_interval' && (
                                                <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                            )}
                                        </a>
                                    </th>
                                    <th style={{ width: '24%', borderLeft: '1px solid white' }}>
                                        <a className='columnLink' onClick={() => sortData('create_time')}>Create Time
                                            {sortedColumn === 'create_time' && (
                                                <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                                            )}
                                        </a>
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{ verticalAlign: 'top' }}>
                                {alertResult.map((item, index) => (
                                    <React.Fragment key={item}>
                                        <tr className={expandedRows[item.workflow_id] ? 'selected-row' : 'table_body'}>
                                            <td>
                                                <Checkbox
                                                    className={classes.checkbox}
                                                    checked={item.checked || false}
                                                    onChange={handleCheckboxChange(index)}
                                                    style={{ color: 'grey', marginTop: '-8px', backgroundColor: "transparent" }} /></td>
                                            <td onClick={() => onClickTableData(item.workflow_id)}>{item._owner_full_name}</td>
                                            <td onClick={() => onClickTableData(item.workflow_id)}>{item.email}</td>
                                            <td style={{ float: 'right', marginRight: '12px' }} onClick={() => onClickTableData(item.workflow_id)}>{item.relaunch_interval === null
                                                ? "whenever database is updated"
                                                : item.relaunch_interval}</td>
                                            <td onClick={() => onClickTableData(item.workflow_id)}>{item.create_time}</td>
                                        </tr>
                                        {expandedRows[item.workflow_id] &&
                                            <React.Fragment>
                                                <tr style={{ background: '#cbeaf5' }}>
                                                    <td colSpan="5">
                                                    <p className="black-text" style={{ padding: '26px 0px 0px 28px' }}>A list of workflow runs produced by the same alert (Bold one represents the current result).</p>
                                                    </td>
                                                </tr >
                                                <tr className={classes.innerTable} style={{ background: '#cbeaf5' }}>
                                                    <td colSpan="5">
                                                        <AlertWorkflow alertWorkflowResult={alertWorkflowResult} baseWorkflowID={item.workflow_id} />
                                                    </td>
                                                </tr>
                                            </React.Fragment>}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    {alertResult.length === 0 &&
                        <div className={classes.h2Css}> There are no items.</div>}
                </div>
            </div>
        </div>
    );
}
export default Alerts;
