import React, { useState, useEffect } from "react";
//import "../TableStyles.css"

import lctriple from "../tableImages/lcTripleVenn.png";
import hctriple from "../tableImages/hcTripleVenn.png";

import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function DocSeqMatchOneCdr(props){

    const { LcCDR, HcCDR, Switch, collName} = props;
    const {resid, reportName} = useParams();

    const [docOrSeq, setDocOrSeq] = useState("sequences")

    function getStyles(int, bool, extra = false){

        let style = ""
        if(int <= 0 || int == undefined){
            style += "disabled "
        }
        if(!extra && bool && int >= 0){
            style += "overlaps "
        }
        if(extra){
            style += "bolding f15pt"
        }

        return style;
    }

    useEffect(() =>{
        if(Switch){
            setDocOrSeq("documents")
        }
    },[props.LcCDR, props.HcCDR, Switch])


    function applyMatching(totalQueries){
        for(var i=0; i< totalQueries.length; i++){
            totalQueries[i] = totalQueries[i].toUpperCase();
            totalQueries[i] = totalQueries[i].replaceAll('-','_');
        }

        var abReportFilters = {
                //matchingSequences: totalQueries,
                groupBy: Switch? 'document':'ssv',
                reportName: decodeURIComponent(reportName) == "undefined"? "Unfiltered_Entire_Report": decodeURIComponent(reportName)
        };
        // Correct the parameter for Matching Documents
        if (Switch) {
            abReportFilters.matchingDocuments = totalQueries;
        } else {
            abReportFilters.matchingSequences = totalQueries;
        }
        
        return encodeURIComponent(JSON.stringify(abReportFilters))

    }

	return (
        <div class="tableFrame">
            <p class="textFlat pCenter paddingTitle"><strong>{docOrSeq.toUpperCase()}</strong> matching at least one CDR sequence</p>
            <div style={{width: "100%", display:"flex", height: "270px", paddingTop: "15px"}}>
                <div style={{width: "50%"}}>
                    <div style={{width: "100%", height:"100%", position: "relative"}}>
                        <span style={{position: "absolute", top: "-25px", left:"90px"}}>LC-CDR1</span>
                        <span style={{left :"10px", top: "74px"}} className="Circle CircleOrange">
                        </span>
                        <span style={{left :"50px"}} className="Circle CircleOrange">
                        </span>
                        <span style={{right :"10px", top: "74px"}} className="Circle CircleOrange">
                        </span>
                        <span style={{position: "absolute", bottom: "10px", left: "35px"}}>LC-CDR2</span>
                        <span style={{position: "absolute", bottom: "10px", right:"35px"}}>LC-CDR3</span>
                        <div className="AbTriple0">
                            <Link 
                                style={{zIndex:"251"}}
                                className={getStyles(LcCDR.LC_CDR1, false )+" textFlatCenter"}                                   
                                target="_blank"
                                disabled={LcCDR.LC_CDR1==0}
                                to={{
                                    pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['LC_CDR1']) + "/" + collName
                                }}>{LcCDR.LC_CDR1 != null? LcCDR.LC_CDR1 : 0}
                            </Link>
                        </div>
                        <div className="AbTriple1">
                        <Link 
                            style={{zIndex:"251"}}    
                            className={getStyles(LcCDR.LC_CDR1LC_CDR2, true )+" textFlatCenter"}                                  
                            target="_blank"
                            disabled={LcCDR.LC_CDR1LC_CDR2==0}
                            to={{
                                pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['LC_CDR1','LC_CDR2']) + "/" + collName
                            }}>{LcCDR.LC_CDR1LC_CDR2 != null? LcCDR.LC_CDR1LC_CDR2 : 0}
                        </Link>
                        </div>
                        <div className="AbTriple2">
                        <Link 
                            style={{zIndex:"251"}} 
                            className={getStyles(LcCDR.LC_CDR1LC_CDR2LC_CDR3, true, true)+" textFlatCenter"}                                  
                            target="_blank"
                            disabled={LcCDR.LC_CDR1LC_CDR2LC_CDR3==0}
                            to={{
                                pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['LC_CDR1','LC_CDR2','LC_CDR3']) + "/" + collName
                            }}>{LcCDR.LC_CDR1LC_CDR2LC_CDR3 != null? LcCDR.LC_CDR1LC_CDR2LC_CDR3 : 0}
                        </Link>
                        </div>
                        <div className="AbTriple3">
                        <Link 
                            style={{zIndex:"251"}} 
                            className={getStyles(LcCDR.LC_CDR1LC_CDR3, true )+" textFlatCenter"}
                            target="_blank"
                            disabled={LcCDR.LC_CDR1LC_CDR3==0}
                            to={{
                                pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['LC_CDR1','LC_CDR3']) + "/" + collName
                            }}>{LcCDR.LC_CDR1LC_CDR3 != null? LcCDR.LC_CDR1LC_CDR3 : 0}
                        </Link>
                        </div>
                        <div className="AbTriple4">

                        <Link 
                            style={{zIndex:"251"}}
                            className={getStyles(LcCDR.LC_CDR2, false )+" textFlatCenter"}
                            target="_blank"
                            disabled={LcCDR.LC_CDR2==0}
                            to={{
                                pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['LC_CDR2']) + "/" + collName
                            }}>{LcCDR.LC_CDR2 != null? LcCDR.LC_CDR2 : 0}
                        </Link>
                        </div>
                        <div className="AbTriple5">
                        <Link 
                            style={{zIndex:"251"}}
                            className={getStyles(LcCDR.LC_CDR2LC_CDR3, true )+" textFlatCenter"}
                            target="_blank"
                            disabled={LcCDR.LC_CDR2LC_CDR3==0}
                            to={{
                                pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['LC_CDR2','LC_CDR3']) + "/" + collName
                            }}>{LcCDR.LC_CDR2LC_CDR3 != null? LcCDR.LC_CDR2LC_CDR3 : 0}
                        </Link>
                        </div>
                        <div className="AbTriple6">
                        <Link 
                            style={{zIndex:"251"}}
                            className={getStyles(LcCDR.LC_CDR3, false )+" textFlatCenter"}
                            target="_blank"
                            disabled={LcCDR.LC_CDR3==0}
                            to={{
                                pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['LC_CDR3']) + "/" + collName
                            }}>{LcCDR.LC_CDR3!= null? LcCDR.LC_CDR3 : 0}
                        </Link>
                        </div>
                    </div>
                </div>
                <div style={{width: "50%"}}>
                    <div style={{width: "100%", height:"100%", position: "relative"}}>
                    <span style={{position: "absolute", top: "-25px", left:"90px"}}>HC-CDR1</span>
                        <span style={{left :"10px", top: "74px"}} className="Circle CircleBlue">
                        </span>
                        <span style={{left :"50px"}} className="Circle CircleBlue">
                        </span>
                        <span style={{right :"10px", top: "74px"}} className="Circle CircleBlue">
                        </span>
                        <span style={{position: "absolute", bottom: "10px", left: "35px"}}>HC-CDR2</span>
                        <span style={{position: "absolute", bottom: "10px", right:"35px"}}>HC-CDR3</span>
                        <div className="AbTriple0">
                        <Link 
                            style={{zIndex:"251"}}
                            className={getStyles(HcCDR.HC_CDR1, false )+" textFlatCenter"} 
                            target="_blank"
                            disabled={HcCDR.HC_CDR1==0}
                            to={{
                                pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['HC_CDR1']) + "/" + collName
                            }}>{HcCDR.HC_CDR1 != null? HcCDR.HC_CDR1 : 0}
                        </Link>
                        </div>
                        <div className="AbTriple1">
                        <Link 
                            style={{zIndex:"251"}}
                            className={getStyles(HcCDR.HC_CDR1HC_CDR2, true )+" textFlatCenter"} 
                            target="_blank"
                            disabled={HcCDR.HC_CDR1HC_CDR2==0}
                            to={{
                                pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['HC_CDR1','HC_CDR2']) + "/" + collName
                            }}>{HcCDR.HC_CDR1HC_CDR2 != null? HcCDR.HC_CDR1HC_CDR2 : 0}
                        </Link>
                        </div>
                        <div className="AbTriple2">
                        <Link 
                            style={{zIndex:"251"}}
                            className={getStyles(HcCDR.HC_CDR1HC_CDR2HC_CDR3, true, true)+" textFlatCenter"} 
                            target="_blank"
                            disabled={HcCDR.HC_CDR1HC_CDR2HC_CDR3==0}
                            to={{
                                pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['HC_CDR1','HC_CDR2','HC_CDR3']) + "/" + collName
                            }}>{HcCDR.HC_CDR1HC_CDR2HC_CDR3 != null? HcCDR.HC_CDR1HC_CDR2HC_CDR3 : 0}
                        </Link>
                        </div>
                        <div className="AbTriple3">
                        <Link 
                            style={{zIndex:"251"}}
                            className={getStyles(HcCDR.HC_CDR1HC_CDR3, true )+" textFlatCenter"}
                            target="_blank"
                            disabled={HcCDR.HC_CDR1HC_CDR3==0}
                            to={{
                                pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['HC_CDR1','HC_CDR3']) + "/" + collName
                            }}>{HcCDR.HC_CDR1HC_CDR3 != null? HcCDR.HC_CDR1HC_CDR3 : 0}
                        </Link>
                        </div>
                        <div className="AbTriple4">
                        <Link 
                            style={{zIndex:"251"}}
                            className={getStyles(HcCDR.HC_CDR2, false )+" textFlatCenter"}
                            target="_blank"
                            disabled={HcCDR.HC_CDR2==0}
                            to={{
                                pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['HC_CDR2']) + "/" + collName
                            }}>{HcCDR.HC_CDR2 != null? HcCDR.HC_CDR2 : 0}
                        </Link>
                        </div>
                        <div className="AbTriple5">
                        <Link 
                            style={{zIndex:"251"}}
                            className={getStyles(HcCDR.HC_CDR2HC_CDR3, true )+" textFlatCenter"} 
                            target="_blank"
                            disabled={HcCDR.HC_CDR2HC_CDR3==0}
                            to={{
                                pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['HC_CDR2','HC_CDR3']) + "/" + collName
                            }}>{HcCDR.HC_CDR2HC_CDR3 != null? HcCDR.HC_CDR2HC_CDR3 : 0}
                        </Link>
                        </div>
                        <div className="AbTriple6">
                        <Link 
                            style={{zIndex:"251"}}
                            className={getStyles(HcCDR.HC_CDR3, false )+" textFlatCenter"}  
                            target="_blank"
                            disabled={HcCDR.HC_CDR3==0}
                            to={{
                                pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyMatching(['HC_CDR3']) + "/" + collName
                            }}>{HcCDR.HC_CDR3!= null? HcCDR.HC_CDR3 : 0}
                        </Link>
                        </div>
                    </div>
                </div>
            </div>
            {!docOrSeq &&
                <p class="bottomText pCenter">The number of patent documents matching one, two, or three CDR sequences. Click the numbers to see the results.</p>
            }
            {docOrSeq &&
                <p class="bottomText pCenter">The number of sequences from patent documents matching one, two, or three CDR sequences. Click the numbers to see the results.</p>
            }
        
        </div>        
    );
}

export default DocSeqMatchOneCdr;
