import React, { useEffect , useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { Dropdown } from 'react-bootstrap';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { FaCaretDown, FaPlus, FaMinus } from "react-icons/fa";
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
 
const useStyles = makeStyles((theme) => ({
    modalHeader: {
        fontFamily: 'verdana, helvetica',
        fontSize: '12px',
        width: '63%'
    },
    formStyle:{
        border:'1px solid rgb(204,204,204)',
        background:'whitesmoke',
        padding: '12px'
    },
    dropDownStyle: {
        background: 'white',
        borderColor: '#dcd7c0',
        padding: '5px 21px 5px 10px',
        '&:hover': {
            backgroundColor: '#db862c',
            boxShadow: 'none',
        },
    }
}));
 
 
 
const ManageViewModal = (props) => {
 
    const classes = useStyles();
    const [openConfirmPopup,setOpenConfirmPopup] = useState();
 
    useEffect(() => {
    }, []);
 
    function onCancel(props) {
        props.closeModal();
    }
    function handleDelete(){
        setOpenConfirmPopup(true);
    }
    return (
        <Modal
            id='modalCss'
            aria-labelledby="contained-modal-title-vcente"
            centered
            size='lg'
            show={props.showModal}
            contentClassName={classes.modalHeader}
        >
            <div>
                <div className="titlebar">
                    <span>Manage Preferences</span>
                    <Link href="#" onClick={(e) => e.preventDefault()} className="pull-right">
                        <CloseIcon style={{ fill: '#b5b5b5' }} onClick={() => onCancel(props)} />
                    </Link>
                </div>
                <div style={{ borderTop: '1px solid #b5b5b5', padding:'12px' }}>
                    <br />
                    <form className={classes.formStyle}>
                        <table >
                            <tr>
                                <td width='33%'>
                                    <label style={{color:'black'}}>Set a preference as your default </label>
                                </td>
                                
                                <td width='33%'>
                                    <div >
                                        <Dropdown>
                                            <DropdownToggle className={classes.dropDownStyle}>
                                                <div className="clearfix">
                                                    <div className="pull-left custom-filter-label" >
                                                        Choose...
                                                    </div>
                                                    <div >
                                                        <FaCaretDown />
                                                    </div>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem >
                                                    Choose...
                                                </DropdownItem>
                                                <DropdownItem>
                                                    System Default
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </td>
                                
                                <td width='33%'>
                                    <button className='okButton DMTBtn'>Set default</button>
                                </td>
                            </tr>
                        </table>
                    </form>
                    <br/>
                    <form className={classes.formStyle}>
                        <table width='100%'>
                            <tr>
                                <td width='33%'>
                                    <label style={{color:'black'}}>Delete a preference </label>
                                </td>
                                <td>
                                    <div>
                                        <Dropdown >
                                            <DropdownToggle className={classes.dropDownStyle}>
                                                <div className="clearfix">
                                                    <div className="pull-left custom-filter-label" >
                                                        Choose...
                                                    </div>
                                                    <div >
                                                        <FaCaretDown />
                                                    </div>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu >
                                                <DropdownItem>
                                                    Choose...
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </td>
                                <td>
                                    <button className='okButton DMTBtn' style={{marginLeft:'34px', marginTop:'12px'}} onClick={handleDelete}>Delete</button>
                                </td>
                            </tr>
                        </table>
                    </form>
                </div>
            </div>
        </Modal>
    );
}
 
export default ManageViewModal;