import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { withStyles } from "@material-ui/core/styles";
import {FaCaretLeft, FaCaretRight, FaChartLine, FaThList, FaSort} from "react-icons/fa";
import {HiPhoto} from "react-icons/hi2";
import {TfiFiles} from "react-icons/tfi";
import Popover from "@material-ui/core/Popover";
import DiscoverSelectBox from "../../shared/Fields/DiscoverSelectBox";
import Tooltip from "@material-ui/core/Tooltip";
import {BsFillQuestionCircleFill} from "react-icons/bs"; 
import Tour from './ToolTip';
import VmFilterModal from "../../shared/Modal/VmFilterModal";
import Dropdown from 'react-bootstrap/Dropdown';
import _ from "lodash";
import { useSelector } from 'react-redux';
import "./ResultTableRecDetails.css";
import AdvancedGroupModal from "../../shared/Modal/AdvancedGroupModal";
import ShareEmailModal from '../../shared/Modal/ShareEmailModal';


const Styles = styled.div`

  .pull-left {
    float: left !important;
  }

  .toolbar-button {
    min-width: 30px;
    margin-left: 5px;
    color: #777 !important;
   // background-color: white;
  }

  .row-padding {
    padding: 4px 0 4px 0;
    display: inline-block;
  }

  .btn-default {
    -webkit-appearance: none !important;
    outline: 0px !important;
    box-shadow: none !important;
    border-color: #CCC !important;
  }

  .btn-default:focus, .btn-default:hover {
    -webkit-appearance: none !important;
    outline: 0px !important;
    box-shadow: none !important;
    border-color: #CCC !important;
    background-color: #e6e6e6;
}

// .btn-primary{
//     background-color: white !important;
//     -webkit-appearance: none !important;
//     outline: 0px !important;
//     box-shadow: none !important;
//     border-color: #CCC !important;
//   } 
  
//   .btn-primary:visited {
//     background-color: #e6e6e6 !important;
//   }

//   .btn-primary:hover {
//     background-color: #e6e6e6 !important;
//   } 

//   .btn-primary:active, .btn-primary:focus  {
//     background-color: #e6e6e6 !important;
//   }

  .fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

  }
  
.dedupBtn{
    border: 1px solid;
    border-color: #777;
    border-radius: 3px;

}
  .view-item {
    padding: 14px 0px 0px 10px;
  }
  
  .view-selection {
    margin-bottom: 10px;
  }
  .tooltip 
    {
        width: 250px;
    }
    .btn-dropdown-label {
        font-weight: normal;
        font-size: 14px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        color: #555 !important;
    }
`

const tooltipstyles = {
    tooltip: {
      width: "auto",
      //height: "40px",
      fontSize: '12px',
      textAlign: 'justify',
      padding: "8px 14px 8px 14px",
      boxShadow: "0px 1px 7px #888",
      backgroundColor: '#777',
      color: '#FFF'
    }
  };
  
  const CustomTooltip = withStyles(tooltipstyles)(Tooltip);

const famViewOptions = [
    {value:'1', label:'1 Patent Number per Family'},
    {value:'3', label:'3 Patent Numbers per Family'},
    {value:'5', label:'5 Patent Numbers per Family'},
    {value:'All', label:'All'}
];
const pnViewOptions = [
    {value:'1', label:'1 Alignment per Patent Number'},
    {value:'3', label:'3 Alignments per Patent Number'},
    {value:'5', label:'5 Alignments per Patent Number'},
    {value:'10', label:'10 Alignments per Patent Number'},
    {value:'All', label:'All'}
];
const ssvOptions = [
    {value:'1', label:'1 Alignment per Subject Seq.'},
    {value:'3', label:'3 Alignments per Subject Seq.'},
    {value:'5', label:'5 Alignments per Subject Seq.'},
    {value:'10', label:'10 Alignments per Subject Seq.'},
    {value:'All', label:'All'}
];
const ufsOptions = [
    {value:'1', label:'1 Alignment per UFS'},
    {value:'3', label:'3 Alignments per UFS'},
    {value:'5', label:'5 Alignments per UFS'},
    {value:'10', label:'10 Alignments per UFS'},
    {value:'All', label:'All'}
];
const qsOptions = [
    {value:'1', label:'1 Alignment per Query Seq.'},
    {value:'3', label:'3 Alignments per Query Seq.'},
    {value:'5', label:'5 Alignments per Query Seq.'},
    {value:'10', label:'10 Alignments per Query Seq.'},
    {value:'All', label:'All'}
];
const hspOptions = [
    {value:'1', label:'1 Alignment per Blast HSPs'},
    {value:'3', label:'3 Alignments per Blast HSPs'},
    {value:'5', label:'5 Alignments per Blast HSPs'},
    {value:'10', label:'10 Alignments per Blast HSPs'},
    {value:'All', label:'All'}
];

const annotOption = [
    'Current Page',
    'All Pages',
    'Current Page Unannotated',
    'All Pages Unannotated'
];

export default function ResultStatsBar({
                                           fieldConfig,
                                           tableDisplayMode,
                                           handleMenuCollapse,
                                           sideMenuCollapse,
                                           handleChartMenu,
                                           saveEsfGrouping,
                                           viewCount,
                                           viewOptions,
                                           querySeqIdsCount,
                                           handleMultiQuerySeqMenu,
                                           deduplication,
                                           setDedDuplication,
                                           databasesForDed,
                                           dedupModal,
                                           filter,
                                           handleSorting,
                                           handleManageSort,
                                           groupOptions,
                                           hasLvaAccess,
                                           saveResultState,
                                           vmIds,
                                           lvaLoadingReady,
                                           sideMenuDetail,
                                           editable,
                                           workflowId,
                                           userInfo,
                                           actionModal,
                                           totalCount
                                       }) {

    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const [sortIsOpen, setSortIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [sortAnchorEl, setSortAnchorEl] = useState(null);
    const [selectedGroupOption, setSelectedGroupOption] = useState(tableDisplayMode);
    const [selectedFamViewOption, setSelectedFamViewOption] = useState(viewOptions.selectedFamViewOption);
    const [selectedFamViewAliOption, setSelectedFamViewAliOption] = useState(viewOptions.selectedFamViewAliOption);
    const [selectedPnViewOption, setSelectedPnViewOption] = useState(viewOptions.selectedPnViewOption);
    const [selectedSsvOption, setSelectedSsvOption] = useState(viewOptions.selectedSsvOption);
    const [selectedUfsOption, setSelectedUfsOption] = useState(viewOptions.selectedUfsOption);
    const [selectedQsOption, setSelectedQsOption] = useState(viewOptions.selectedQsOption);
    const [selectedHspOption, setSelectedHspOption] = useState(viewOptions.selectedHspOption);
    const [openTooltipGuide, setOpenTooltipGuide] = useState(false);
    const [tourStart, setTourStart] = useState(false);
    const [tourFlag, setTourFlag] = useState(true);
    const [lvaWidgetState, setLvaWidgetState] = useState(filter.lvaWidgetState);
    const [addFilterPopup, setAddFilterPopup] = useState(false);
    const toggle = useSelector(state => state.setToggle.toggle);
    const [openAdvancedGroup,setOpenAdvancedGroup] = useState(false);
    const [shareModal, setShareModal] = React.useState(false);
    const [hideShare, setHideShare] = useState(false);
    useEffect( async() => {
        if (
            !document.cookie
              .split("; ")
              .find((row) => row.startsWith("doSomethingOnlyOnce"))
          ) {//for tour to start after cache clears
            document.cookie =
              "doSomethingOnlyOnce=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=Strict; Secure";
              setOpenTooltipGuide(true);
              setTourStart(true);
            }
            else{
                setTourStart(false);
            }
         
         const match = userInfo.current_user.email.match(/@(\S+)/);
        if(userInfo.current_user.user_class_id ==12 || userInfo.current_user.ppu_type == 1 || (match && match[1] == 'bayer.com')){
            setHideShare(true);
        }
     
        if(Object.keys(vmIds).length > 0) {
            // Sync the filters info
            filter.lvaWidgetState = {...filter.lvaWidgetState};
            filter.widgetState = {...filter.widgetState}; // When saved as global
            filter.state = {...filter.state};
            setLvaWidgetState(filter.lvaWidgetState);
        }
        setSelectedGroupOption(tableDisplayMode);
    },[vmIds, tableDisplayMode]);

    function handleTourFlag() {
        setTourFlag(false);
        //so that the tour does not start again from state(0) on the same wrkflw
    }

    function handleSide(e) {
        handleMenuCollapse(e.target.value);
    }

    const closePopover = () => {
        setAnchorEl(null);
        setDropdownIsOpen(false);
        setSortAnchorEl(null);
        setSortIsOpen(false);
    }

    const changeGrouping = (e) => {
        setSelectedGroupOption(e.target.value);
    }

    const saveGrouping = () => {
        let option1, option2;
        if (selectedGroupOption === 'family') {
            option1 = selectedFamViewOption;
            option2 = selectedFamViewAliOption;
        } else if (selectedGroupOption === 'document') {
            option1 = selectedPnViewOption;
        } else if (selectedGroupOption === 'ssv') {
            option1 = selectedSsvOption;
        } else if (selectedGroupOption === 'ufs') {
            option1 = selectedUfsOption;
        } else if (selectedGroupOption === 'qs') {
            option1 = selectedQsOption;
        } else if (selectedGroupOption === 'hsp') {
            option1 = selectedHspOption;
        }
        saveEsfGrouping(fieldConfig, selectedGroupOption, option1, option2);
        closePopover();
    }

    const saveAdvancedGrouping = (fieldConfig, selectedGroupOption, option1, groupSize, groupSizeOper) => {
        saveEsfGrouping(fieldConfig, selectedGroupOption, option1, "", groupSize, groupSizeOper);
        setOpenAdvancedGroup(false);
    }

    function handleChart(e){
        handleChartMenu(e.target.value);
    }
    function handleMultiQuerySeq(e){
        handleMultiQuerySeqMenu(e.target.value);
    }

    function handleDeduplication(e){
        setDedDuplication(e.target.value)
    }

    function handleDedupModal(e){
        dedupModal(e.target.value)
    }

    function handleSort(e,sortkey){
        handleSorting(sortkey);
        closePopover();
    }

    function handleManageSortModal() {
        if (editable === true) {
            setSortIsOpen(false);
            setSortAnchorEl(null);
            handleManageSort();
        }
    }

    function handleManageViewModal(){
        setOpenAdvancedGroup(true);
        setDropdownIsOpen(false);
    }
    function handleTooltipGuide(){
        if(sideMenuCollapse){
            setOpenTooltipGuide(false);
        }
        else{
            setOpenTooltipGuide(true);
        }
    }

    function handleOnClick(n){
        setOpenTooltipGuide(false);
    }

    const startFilteringWidget = () => {
        lvaWidgetState.widgetMode = 'AND';
        lvaWidgetState.widgetCustomQuery = '';
        lvaWidgetState.lvaFilterName = '';
        lvaWidgetState.selectedColor = 'blue';
        lvaWidgetState.selectedColorIdx = '5';
        lvaWidgetState.widgetContent = [];
        lvaWidgetState.lvaQuery = {};
        lvaWidgetState.lvaFilters = _.cloneDeep(filter.lvaWidgetState.lvaFilters);
        setLvaWidgetState({...lvaWidgetState});
        setAddFilterPopup(true);
    }

    const closeVmFilterModal = () => {
        setAddFilterPopup(false);
    }

    const closeAdvancedGroup = () => {
        setOpenAdvancedGroup(false);
    }

    const filteredResults = async(joinedEmails) => {
        actionModal(filter,"title",joinedEmails);

    }

    const saveFilter = (filter, fieldConfig, sideMenuDetail) => {
        sideMenuDetail['lvaModules'] = true;
        sideMenuDetail['lvaFilters'] = true;
        if (filter.lvaWidgetState.saveAsGlobal) {
            sideMenuDetail['globalLvaFilters'] = true;
        }
        saveResultState(filter, fieldConfig, sideMenuDetail);
        // Sync the filters info
        filter.lvaWidgetState = {...filter.lvaWidgetState};
        filter.widgetState = {...filter.widgetState}; // When saved as global
        filter.state = {...filter.state};
        setLvaWidgetState(filter.lvaWidgetState);
        setAddFilterPopup(false);
    }
    
    return (
        <Styles>
            <div className="clearfix cursor-default">
            <div className="pull-left margin-right">
            <CustomTooltip arrow title="Hide Sidebar" placement="bottom">
                <button className="btn btn-default toolbar-button" onClick={handleSide} style={toggle? {}:{backgroundColor:'#292929'}}>
                    {!sideMenuCollapse &&
                        <FaCaretLeft size={14}></FaCaretLeft>
                    }
                    {sideMenuCollapse &&
                        <FaCaretRight size={14}></FaCaretRight>
                    }
                </button></CustomTooltip>
                <CustomTooltip arrow title="Result Charts" placement="bottom">
                <button type="button" className="btn btn-default toolbar-button" onClick={handleChart} style={toggle? {}:{backgroundColor:'#292929'}}>
                    <FaChartLine></FaChartLine>
                </button>
                </CustomTooltip>
                { querySeqIdsCount > 1 &&
                <CustomTooltip  arrow title="Multiple Query Sequence Analysis" placement="bottom">
                <button type="button" className="btn btn-default toolbar-button"  style={toggle? {}:{backgroundColor:'#292929'}} onClick={handleMultiQuerySeq}>
                    <FaThList></FaThList>
                </button></CustomTooltip>
                 }
                {hasLvaAccess && lvaLoadingReady &&
                    <CustomTooltip arrow title="Variation Filter" placement="bottom">
                        <button type="button" className="btn btn-default toolbar-button" style={toggle? {}:{backgroundColor:'#292929'}} onClick={() => startFilteringWidget()}>
                            <HiPhoto></HiPhoto>
                        </button>
                    </CustomTooltip>
                }

                 {databasesForDed && Object.keys(databasesForDed).length > 1 && 
                    <CustomTooltip arrow title="Show/Hide duplicate records" placement="bottom" >
                    <div className="btn btn-default toolbar-button"style={toggle? {}:{backgroundColor:'#292929'}} >
                        <div className="pull-left" onClick={handleDedupModal}>
                            <TfiFiles></TfiFiles>&nbsp;&nbsp;
                        </div>
                        <button type="button" className="fa dedupBtn" onClick={handleDeduplication}>
                            <span className="btn-dropdown-label">{deduplication? 'ON' : 'OFF'}</span>
                        </button>

                    </div>
                    </CustomTooltip>}
                    
                    <Dropdown className="btn toolbar-button" style={{padding: '0px', margin:'0px'}} show={sortIsOpen}
                    onToggle={(isOpen, event, metadata) => {
                        setSortIsOpen(!sortIsOpen);
                      }}>
                        <Dropdown.Toggle
                    id = 'sortTooltip' className="toolbar-button  btn btn-default" style={toggle ? {backgroundColor: 'white'}:{backgroundColor: '#292929'}}
                    > <b>Sort:</b>&nbsp;
                            <span>{fieldConfig.getSortOptionDesc(filter.state.sort)}</span>
                            &nbsp;&nbsp;<FaSort className="fa"/>
                    
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                    <div className="sort-container" style={toggle ? {} : {backgroundColor: '#292929', color: '#ccc'}}>
                                    {Object.keys(fieldConfig.sortConfig).map((key,item) => (
                                        <div key={key} className="autocomplete-item"
                                        value={fieldConfig.sortConfig[key].title}
                                        onClick={(e) =>handleSort(e,key)}>
                                            <span>{fieldConfig.sortConfig[key].title}</span> 
                                        </div>
                                    )
                                    )}
                                    {Object.keys(fieldConfig.customSortConfig).map((key,item) => (
                                        <div key={fieldConfig.customSortConfig[key].sortOptionName} 
                                            onClick={(e)=> handleSort(e,fieldConfig.customSortConfig[key].sortOptionName)} 
                                            className="autocomplete-item" >
                                            <span>{fieldConfig.customSortConfig[key].sortOptionName}</span> 
                                        </div>
                                    )

                                    )}
                                    <div className="divider"></div>
                                    <div onClick={handleManageSortModal} style={toggle? {}:{backgroundColor:'#292929'}}><span>Manage Sort Options</span></div>
                    </div>
                    </Dropdown.Menu>
                                       
                    </Dropdown>

                    {/*sortIsOpen &&
                        <Popover
                                id={"sort-popover"}
                                open={sortIsOpen}
                                anchorEl={sortAnchorEl}
                                placement="bottom-end"
                                onClose={closePopover}
                                anchorReference="anchorEl"
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                PaperProps={{
                                    style: {
                                      //borderRadius: 0,
                                      //vertical: 200
                                    }
                                  }}
                            >
                                 <div className="sort-container">
                                    {Object.keys(fieldConfig.sortConfig).map((key,item) => (
                                        <div key={key} className="autocomplete-item"
                                        value={fieldConfig.sortConfig[key].title}
                                        onClick={(e) =>handleSort(e,key)}>
                                            <span>{fieldConfig.sortConfig[key].title}</span> 
                                        </div>
                                    )
                                    )}
                                    {Object.keys(fieldConfig.customSortConfig).map((key,item) => (
                                        <div key={fieldConfig.customSortConfig[key].sortOptionName} 
                                            onClick={(e)=> handleSort(e,fieldConfig.customSortConfig[key].sortOptionName)} 
                                            className="autocomplete-item" >
                                            <span>{fieldConfig.customSortConfig[key].sortOptionName}</span> 
                                        </div>
                                    )

                                    )}
                                    <div className="divider"></div>
                                    <div onClick={handleManageSortModal}><span>Manage Sort Options</span></div>
                                 </div> 
                        </Popover>
                                    */}                  


                <div id = 'viewTooltip' className="btn btn-default toolbar-button" 
                         onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                        setDropdownIsOpen(true);
                    }}>
                    <b>Group:</b>&nbsp;
                    {tableDisplayMode === 'family' &&
                        <span>{Number(viewCount.SUBJECT_PB).toLocaleString()} {viewCount.SUBJECT_PB > 1 ? 'Patent Families' : 'Patent Family'}</span>
                    }
                    {tableDisplayMode === 'document' &&
                        <span>{Number(viewCount.SUBJECT_PN).toLocaleString()} {viewCount.SUBJECT_PN > 1 ? 'Patent Numbers' : 'Patent Number'}</span>
                    }
                    {tableDisplayMode === 'flat' &&
                        <span>{Number(viewCount.RESULT_ID).toLocaleString()} {viewCount.RESULT_ID > 1 ? 'Ungrouped Alignments' : 'Ungrouped Alignment'}</span>
                    }
                    {tableDisplayMode === 'ufs' &&
                        <span>{Number(viewCount.SUBJECT_PJ).toLocaleString()} Unique {viewCount.SUBJECT_PJ > 1 ? 'Seqs' : 'Seq'} in {Number(viewCount.SUBJECT_PB).toLocaleString()} {viewCount.SUBJECT_PB > 1 ? 'Families' : 'Family'}</span>
                    }
                    {tableDisplayMode === 'ssv' &&
                        <span>{Number(viewCount.SUBJECT_ID).toLocaleString()} {viewCount.SUBJECT_ID > 1 ? 'Subject Sequences' : 'Subject Sequence'}</span>
                    }
                    {tableDisplayMode === 'cas' &&
                        <span>{Number(viewCount.SUBJECT_RG).toLocaleString()} {viewCount.SUBJECT_RG > 1 ? 'CAS Registry Numbers' : 'CAS Registry Number'}</span>
                    }
                    {tableDisplayMode === 'qs' &&
                        <span>{Number(viewCount.QUERY_ID).toLocaleString()} {viewCount.QUERY_ID > 1 ? 'Query Sequences' : 'Query Sequence'}</span>
                    }
                    {tableDisplayMode === 'hsp' &&
                        <span>{Number(viewCount.HSP).toLocaleString()} {viewCount.HSP > 1 ? 'Blast HSPs' : 'Blast HSP'}</span>
                    }
                    {tableDisplayMode === 'ali' &&
                        <span>{Number(totalCount).toLocaleString()} {totalCount > 1 ? 'Alignments' : 'Alignment'}</span>
                    }
                    {tableDisplayMode === 'npa' &&
                        <span>{Number(totalCount).toLocaleString()} {totalCount > 1 ? 'Normalized Patent Assignees' : 'Normalized Patent Assignee'}</span>
                    }
                    {tableDisplayMode === 'gname' &&
                        <span>{Number(totalCount).toLocaleString()} {totalCount > 1 ? 'Subject Databases' : 'Subject Database'}</span>
                    }
                    {tableDisplayMode === 'org' &&
                        <span>{Number(totalCount).toLocaleString()} {totalCount > 1 ? 'Normalized Organisms' : 'Normalized Organism'}</span>
                    }
                    &nbsp;&nbsp;&nbsp;<FaSort className="fa"/>
                </div>
                {!hideShare &&
                <div id = 'viewTooltip' className="btn btn-default toolbar-button"  onClick={() => setShareModal(true)}>
                    <span className="appLink text-initial appTextFont" >Share these Results</span>
                </div>}
                
                <ShareEmailModal
                            show={shareModal}
                            onHide={() => setShareModal(false)}
                            wfid ={workflowId}
                            actionModal={filteredResults}
                        />
                        
                <CustomTooltip arrow title="Short Guided Tour" placement="bottom" visible={openTooltipGuide} >
                    <button id = 'tourTooltip' className="btn btn-default toolbar-button" style={toggle? {}:{backgroundColor:'#292929'}} onClick={handleTooltipGuide}> 
                    <BsFillQuestionCircleFill></BsFillQuestionCircleFill>
                    </button>
                </CustomTooltip>
                {openTooltipGuide && <Tour handleOnClick ={handleOnClick} tourStart={tourStart} tourFlag={tourFlag} handleTourFlag={handleTourFlag}></Tour>}            


            </div>
            </div>

            {dropdownIsOpen &&
                <Popover id={"view-popover"}
                         open={dropdownIsOpen}
                         anchorEl={anchorEl}
                         onClose={closePopover}
                         anchorReference="anchorEl"
                         anchorOrigin={{
                             vertical: "bottom",
                             horizontal: "left",
                         }}
                         transformOrigin={{
                             vertical: "top",
                             horizontal: "left",
                         }}
                    //disableAutoFocus={true}
                    //disableEnforceFocus={true}
                >
                    <div className="view-container">
                        {groupOptions.map(
                            (option, optionIdx) => (
                                <div key={'view_' + optionIdx}>
                                    <input key={'viewRadio_' + groupOptions[optionIdx]} id={'viewRadio_' + groupOptions[optionIdx]}
                                           name="groupings" type="radio" value={groupOptions[optionIdx]}
                                           defaultChecked={groupOptions[optionIdx] === tableDisplayMode}
                                           onChange={changeGrouping} />
                                    <label key={'viewLabel_' + optionIdx} htmlFor={'viewRadio_' + groupOptions[optionIdx]}>&nbsp;&nbsp;
                                        {groupOptions[optionIdx] === 'family' &&
                                            <span>{Number(viewCount.SUBJECT_PB).toLocaleString()} {viewCount.SUBJECT_PB > 1 ? 'Patent Families' : 'Patent Family'}</span>
                                        }
                                        {groupOptions[optionIdx] === 'document' &&
                                            <span>{Number(viewCount.SUBJECT_PN).toLocaleString()} {viewCount.SUBJECT_PN > 1 ? 'Patent Numbers' : 'Patent Number'}</span>
                                        }
                                        {groupOptions[optionIdx] === 'flat' &&
                                            <span>{Number(viewCount.RESULT_ID).toLocaleString()} {viewCount.RESULT_ID > 1 ? 'Ungrouped Alignments' : 'Ungrouped Alignment'}</span>
                                        }
                                        {groupOptions[optionIdx] === 'ufs' &&
                                            <span>{Number(viewCount.SUBJECT_PJ).toLocaleString()} Unique {viewCount.SUBJECT_PJ > 1 ? 'Seqs' : 'Seq'} in {Number(viewCount.SUBJECT_PB).toLocaleString()} {viewCount.SUBJECT_PB > 1 ? 'Families' : 'Family'}</span>
                                        }
                                        {groupOptions[optionIdx] === 'ssv' &&
                                            <span>{Number(viewCount.SUBJECT_ID).toLocaleString()} {viewCount.SUBJECT_ID > 1 ? 'Subject Sequences' : 'Subject Sequence'}</span>
                                        }
                                        {groupOptions[optionIdx] === 'cas' &&
                                            <span>{Number(viewCount.SUBJECT_RG).toLocaleString()} {viewCount.SUBJECT_RG > 1 ? 'CAS Registry Numbers' : 'CAS Registry Number'}</span>
                                        }
                                        {groupOptions[optionIdx] === 'qs' &&
                                            <span>{Number(viewCount.QUERY_ID).toLocaleString()} {viewCount.QUERY_ID > 1 ? 'Query Sequences' : 'Query Sequence'}</span>
                                        }
                                        {groupOptions[optionIdx] === 'hsp' &&
                                            <span>{Number(viewCount.HSP).toLocaleString()} {viewCount.HSP > 1 ? 'Blast HSPs' : 'Blast HSP'}</span>
                                        }
                                    </label>
                                </div>
                            ))
                        }
                        <div className="divider"></div>
                        {/* <div className="autocomplete-spacer"></div> */}
                        {selectedGroupOption=== 'family' &&
                            <div className="view-selection">
                                <div>
                                    <DiscoverSelectBox
                                        margin="normal"
                                        variant="outlined"
                                        name="fam_opt"
                                        id="fam_opt"
                                        value={selectedFamViewOption}
                                        items={famViewOptions}
                                        onChange={(e) => setSelectedFamViewOption(e.target.value)}
                                    /></div>
                                <div>
                                    <DiscoverSelectBox
                                        margin="normal"
                                        variant="outlined"
                                        name="pn_opt"
                                        id="pn_opt"
                                        value={selectedFamViewAliOption}
                                        items={pnViewOptions}
                                        onChange={(e) => setSelectedFamViewAliOption(e.target.value)}
                                    /></div>
                            </div>
                        }
                        {selectedGroupOption=== 'document' &&
                            <div className="view-selection">
                                <DiscoverSelectBox
                                    margin="normal"
                                    variant="outlined"
                                    name="pn_opt"
                                    id="pn_opt"
                                    value={selectedPnViewOption}
                                    items={pnViewOptions}
                                    onChange={(e) => setSelectedPnViewOption(e.target.value)}
                                /></div>
                        }
                        {selectedGroupOption=== 'ssv' &&
                            <div className="view-selection">
                                <DiscoverSelectBox
                                    margin="normal"
                                    variant="outlined"
                                    name="ssv_opt"
                                    id="ssv_opt"
                                    value={selectedSsvOption}
                                    items={ssvOptions}
                                    onChange={(e) => setSelectedSsvOption(e.target.value)}
                                /></div>
                        }
                        {selectedGroupOption=== 'ufs' &&
                            <div className="view-selection">
                                <DiscoverSelectBox
                                    margin="normal"
                                    variant="outlined"
                                    name="ufs_opt"
                                    id="ufs_opt"
                                    value={selectedUfsOption}
                                    items={ufsOptions}
                                    onChange={(e) => setSelectedUfsOption(e.target.value)}
                                /></div>
                        }
                        {selectedGroupOption=== 'qs' &&
                            <div className="view-selection">
                                <DiscoverSelectBox
                                    margin="normal"
                                    variant="outlined"
                                    name="qs_opt"
                                    id="qs_opt"
                                    value={selectedQsOption}
                                    items={qsOptions}
                                    onChange={(e) => setSelectedQsOption(e.target.value)}
                                /></div>
                        }
                        {selectedGroupOption=== 'hsp' &&
                            <div className="view-selection">
                                <DiscoverSelectBox
                                    margin="normal"
                                    variant="outlined"
                                    name="hsp_opt"
                                    id="hsp_opt"
                                    value={selectedHspOption}
                                    items={hspOptions}
                                    onChange={(e) => setSelectedHspOption(e.target.value)}
                                /></div>
                        }

                        {(selectedGroupOption=== 'family' || selectedGroupOption=== 'document'
                            || selectedGroupOption=== 'ssv' || selectedGroupOption=== 'ufs'
                            || selectedGroupOption=== 'qs' || selectedGroupOption=== 'hsp') &&
                            <div className="autocomplete-spacer"></div>
                        }
                        <div onClick={handleManageViewModal} style={toggle ? {} : { backgroundColor: '#292929' }}><span className={toggle ? 'greyAdvanced' : 'greyAdvancedDark'} >Advanced Group Options</span></div>
                        <div className="autocomplete-spacer"></div>

                        <div className="clearfix">
                            <button className="pull-right normal-button gqblue" style={{marginLeft: '10px'}}
                                    onClick={saveGrouping}>Apply</button>
                            <button className="pull-right normal-button grey" onClick={closePopover}>Cancel</button>
                        </div>
                    </div>
                </Popover>
            }
            <VmFilterModal
                key="vmfiltermodal" showModal={addFilterPopup} closeModal={closeVmFilterModal} callback={saveFilter}
                fieldConfig={fieldConfig} lvaWidgetState={lvaWidgetState} vmIds={vmIds} filter={filter} sideMenuDetail={sideMenuDetail}
            />
            {openAdvancedGroup && 
                <AdvancedGroupModal showModal={openAdvancedGroup} closeModal={closeAdvancedGroup} callback={saveAdvancedGrouping} fieldConfig={fieldConfig} />}
        </Styles>

    )

}
