import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
//import "../TableStyles.css"

function HeavyChainVariants(props){
    
    const {resid, reportName} = useParams();
    const {cdr1, cdr2, cdr3, Switch, ExportExcel, collName} = props

    const [LightHeavy, setLightHeavy] = useState("LIGHT")

    useEffect(() =>{
        if(Switch){
            setLightHeavy("HEAVY")
        }
    },[props])

    function doLightDark(char, cdr, index){

        if(char == cdr.charAt(index)){
            return "lightLetter";
        }else{
            return "darkLetter";
        }

    }

    function applySubjSeqFilter(qId, sSeq, groupBy){
        
        var qIds = [qId];

        for(var i=0; i< qIds.length; i++){
            qIds[i] = qIds[i].toUpperCase();
            qIds[i] = qIds[i].replaceAll("-","_");

        }
    
        var abReportFilters = {
                querySeqDbs: qIds,
                subjSeqFrag: sSeq,
                groupBy: groupBy ? groupBy : 'flat',
                queryNums: [],
                reportName: decodeURIComponent(reportName) == "undefined"? "Unfiltered_Entire_Report": decodeURIComponent(reportName)
        };

        return encodeURIComponent(JSON.stringify(abReportFilters))

    }

	return (
        <div>
        <div class="tableFrame">
            <p class="textFlat pCenter paddingTitle"><strong>{LightHeavy} CHAIN CDR VARIANTS DETECTED</strong></p>
            <table class="MarginBottom15" width={"98%"}>
                <tbody>
                    <td class="borderR ChainWidth">
                        <table class="margin15">
                            <thead>
                                <th class="ChainPadding">
                                    <p class="ChainInline"><span className={"ChainMargin "+LightHeavy.charAt(0).toLowerCase()+"Square"}></span><span class="textFlat bolding variantFix">{LightHeavy.charAt(0)}C-CDR1</span></p>
                                </th>
                                <th width="40%"></th>
                            </thead>
                            <tbody>
                            {cdr1!=null && Object.entries(cdr1[Object.keys(cdr1)[0]]).map((value, index) => {

                            return(
                                <tr class="VirtAlign" key={index}>
                                    
                                    <td class="ChainPadding">
                                        <p class="Margin0 VirtAlign textFlatLeft">
                                        {value[0] && [...value[0]].map((value2, index2) => {
                                            return(
                                            <span>      
                                                {index2%11 == 0 && index2!= 0 &&
                                                    <span> </span>
                                                }
                                                <span key={index2} className={doLightDark(value2, Object.keys(cdr1)[0], index2)}>{value2[0]}</span>
                                            </span>
                                            )
                                        })}
                                        </p>
                                    </td>
                                    <td class="VirtAlign">
                                        <Link 
                                            class="textFlatLeft ChainMarginLeft VirtAlign"                                   
                                            target="_blank"
                                            disabled={value[1]==0}
                                            to={{
                                                pathname: '/discover/wf:'+resid+".resdb/antibody/"+applySubjSeqFilter(LightHeavy.charAt(0)+"C-CDR1" , value[0]) + "/" + collName
                                            }}>{value[1]}
                                        </Link>
                                    </td>
                                    
                                </tr>
                            )})}
                            </tbody>
                        </table>
                        <a>

                        </a>
                    </td>
                    <td class="borderR ChainWidth">
                        <table class="margin15">
                            <thead>
                                <th class="ChainPadding">
                                    <p class="ChainInline"><span className={"ChainMargin "+LightHeavy.charAt(0).toLowerCase()+"Square"}></span><span class="textFlat bolding variantFix">{LightHeavy.charAt(0)}C-CDR2</span></p>
                                </th>
                                <th width="40%"></th>
                            </thead>
                            <tbody>
                            {cdr2!=null && Object.entries(cdr2[Object.keys(cdr2)[0]]).map((value, index) => {

                                return(
                                    <tr class="VirtAlign" key={index}>
                                        
                                        <td class="ChainPadding">
                                            <p class="Margin0 VirtAlign textFlatLeft">
                                            {value[0] && [...value[0]].map((value2, index2) => {
                                            return(
                                                <span>      
                                                    {index2%11 == 0 && index2!= 0 &&
                                                        <span> </span>
                                                    }
                                                    <span key={index2} className={doLightDark(value2, Object.keys(cdr2)[0], index2)}>{value2[0]}</span>
                                                </span>                                            
                                                )
                                            })}
                                            </p>
                                        </td>
                                        <td class="VirtAlign">
                                            <Link 
                                                class="textFlatLeft ChainMarginLeft VirtAlign"                                   
                                                target="_blank"
                                                disabled={value[1]==0}
                                                to={{
                                                    pathname: '/discover/wf:'+resid+".resdb/antibody/"+applySubjSeqFilter(LightHeavy.charAt(0)+"C-CDR2" , value[0]) + "/" + collName
                                                }}>{value[1]}
                                            </Link>
                                        </td>
                                    </tr>
                                )})}
                            </tbody>
                        </table>
                    </td>
                    <td class="ChainWH30 VirtAlign">
                        <table class="margin15">
                            <thead>
                                <th class="ChainPadding">

                                    <p class="ChainInline"><span className={"ChainMargin "+LightHeavy.charAt(0).toLowerCase()+"Square"}></span><span class="textFlat bolding variantFix">{LightHeavy.charAt(0)}C-CDR3</span></p>
                                </th>
                                <th width="40%"></th>
                            </thead>
                            <tbody>
                            {cdr3!=null && Object.entries(cdr3[Object.keys(cdr3)[0]]).map((value, index) => {

                                    return(
                                        <tr class="VirtAlign" key={index}>
                                            
                                            <td class="ChainPadding">
                                                <p class="Margin0 VirtAlign textFlatLeft">
                                                    {value[0] && [...value[0]].map((value2, index2) => {
                                                        return(
                                                            <span>
                                                                {index2%11 == 0 && index2!= 0 &&
                                                                    <span> </span>
                                                                }
                                                                <span key={index2} className={doLightDark(value2, Object.keys(cdr3)[0], index2)}>{value2[0]}</span>
                                                            </span>
                                                        )
                                                    })}
                                                </p>
                                            </td>
                                            <td class="VirtAlign">
                                                <Link 
                                                    class="textFlatLeft ChainMarginLeft VirtAlign"                                   
                                                    target="_blank"
                                                    disabled={value[1]==0}
                                                    to={{
                                                        pathname: '/discover/wf:'+resid+".resdb/antibody/"+applySubjSeqFilter(LightHeavy.charAt(0)+"C-CDR3" , value[0]) + "/" + collName
                                                    }}>{value[1]}
                                                </Link>
                                            </td>
                                            
                                        </tr>
                                    )})}
                            </tbody>
                        </table>
                    </td>
                </tbody>
            </table>
            {(false && ((cdr1!=null && cdr2!=null && cdr3!=null) && (Object.entries(cdr1[Object.keys(cdr1)[0]]).length > 30 || Object.entries(cdr2[Object.keys(cdr2)[0]]).length > 30 || Object.entries(cdr3[Object.keys(cdr3)[0]]).length > 30 ))) &&
                <p class="textFlat pCenter">Only the first 30 sequences are displayed. Please <a className="textFlat" onClick={()=> ExportExcel(LightHeavy, cdr1, cdr2, cdr3)}>export to Excel</a> to view the full set.</p>
            }
            </div>
        </div>
    );
}

export default HeavyChainVariants;
