import React, { useEffect,useState,useRef } from 'react';
import '../../assets/css/flag-icon.css';

export default function AuthoritySearch({actionModal , isPatentAuth, redoPA, itemV}){
//const AuthoritySearch = () => {
  const inputRef = useRef(null);
    const countryMap = {
            "United States of America": "US",
            "European Patent Office (EPO)": "EP",
            "World Intellectual Property Organization (WIPO)": "WO",
            "Canada": "CA",
            "China": "CN",
            "Republic of Korea": "KR",
            "Germany": "DE",
            "Australia": "AU",
            "United Kingdom": "GB",
            "Andorra": "AD",
            "United Arab Emirates": "AE",
            "Afghanistan": "AF",
            "Antigua and Barbuda": "AG",
            "Anguilla": "AI",
            "Albania": "AL",
            "Armenia": "AM",
            "Angola": "AO",
            "African Regional IP Organization (ARIPO)": "AP",
            "Argentina": "AR",
            "Austria": "AT",
            "Aruba": "AW",
            "Azerbaijan": "AZ",
            "Bosnia and Herzegovina": "BA",
            "Barbados": "BB",
            "Bangladesh": "BD",
            "Belgium": "BE",
            "Burkina Faso": "BF",
            "Bulgaria": "BG",
            "Bahrain": "BH",
            "Burundi": "BI",
            "Benin": "BJ",
            "Bermuda": "BM",
            "Brunei Darussalam": "BN",
            "Bolivia": "BO",
            "Bonaire Saint Eustatius and Saba": "BQ",
            "Brazil": "BR",
            "Bahamas": "BS",
            "Bhutan": "BT",
            "Bouvet Island": "BV",
            "Botswana": "BW",
            "Benelux Office for Intellectual Property (BOIP)": "BX",
            "Belarus": "BY",
            "Belize": "BZ",
            "Democratic Republic of the Congo": "CD",
            "Central African Republic": "CF",
            "Congo": "CG",
            "Switzerland": "CH",
            "Côte d’Ivoire": "CI",
            "Cook Islands": "CK",
            "Chile": "CL",
            "Cameroon": "CM",
            "Colombia": "CO",
            "Costa Rica": "CR",
            "Czechoslovakia": "CS",
            "Cuba": "CU",
            "Cape Verde": "CV",
            "Curaçao": "CW",
            "Cyprus": "CY",
            "Czech Republic": "CZ",
            "German Democratic Republic": "DD",
            "Djibouti": "DJ",
            "Denmark": "DK",
            "Dominica": "DM",
            "Dominican Republic": "DO",
            "Algeria": "DZ",
            "Eurasian Patent Organization (EAPO)": "EA",
            "Ecuador": "EC",
            "Estonia": "EE",
            "Egypt": "EG",
            "Western Sahara": "EH",
            "Eritrea": "ER",
            "Spain": "ES",
            "Ethiopia": "ET",
            "Finland": "FI",
            "Fiji": "FJ",
            "Falkland Islands (Malvinas)": "FK",
            "Faroe Islands": "FO",
            "France": "FR",
            "Gabon": "GA",
            "Gulf Cooperation Council (GCC)": "GC",
            "Grenada": "GD",
            "Georgia": "GE",
            "Guernsey": "GG",
            "Ghana": "GH",
            "Gibraltar": "GI",
            "Greenland": "GL",
            "Gambia": "GM",
            "Guinea": "GN",
            "Equatorial Guinea": "GQ",
            "Greece": "GR",
            "South Georgia and the South Sandwich Islands": "GS",
            "Guatemala": "GT",
            "Guinea-Bissau": "GW",
            "Guyana": "GY",
            "Hong Kong Special Admin. Region of the PRC": "HK",
            "Honduras": "HN",
            "Croatia": "HR",
            "Haiti": "HT",
            "Hungary": "HU",
            "International Bureau of WIPO": "IB",
            "Indonesia": "ID",
            "Ireland": "IE",
            "Israel": "IL",
            "Isle of Man": "IM",
            "India": "IN",
            "Iraq": "IQ",
            "Iran (Islamic Republic of)": "IR",
            "Iceland": "IS",
            "Italy": "IT",
            "Jersey": "JE",
            "Jamaica": "JM",
            "Jordan": "JO",
            "Japan": "JP",
            "Kenya": "KE",
            "Kyrgyzstan": "KG",
            "Cambodia": "KH",
            "Kiribati": "KI",
            "Comoros": "KM",
            "Saint Kitts and Nevis": "KN",
            "Democratic People’s Republic of Korea": "KP",
            "Kuwait": "KW",
            "Cayman Islands": "KY",
            "Kazakhstan": "KZ",
            "Lao People’s Democratic Republic": "LA",
            "Lebanon": "LB",
            "Saint Lucia": "LC",
            "Liechtenstein": "LI",
            "Sri Lanka": "LK",
            "Liberia": "LR",
            "Lesotho": "LS",
            "Lithuania": "LT",
            "Luxembourg": "LU",
            "Latvia": "LV",
            "Libyan Arab Jamahiriya": "LY",
            "Morocco": "MA",
            "Monaco": "MC",
            "Republic of Moldova": "MD",
            "Montenegro": "ME",
            "Madagascar": "MG",
            "The former Yugoslav Republic of Macedonia": "MK",
            "Mali": "ML",
            "Myanmar": "MM",
            "Mongolia": "MN",
            "Macao": "MO",
            "Northern Mariana Islands": "MP",
            "Mauritania": "MR",
            "Montserrat": "MS",
            "Malta": "MT",
            "Mauritius": "MU",
            "Maldives": "MV",
            "Malawi": "MW",
            "Mexico": "MX",
            "Malaysia": "MY",
            "Mozambique": "MZ",
            "Namibia": "NA",
            "Niger": "NE",
            "Nigeria": "NG",
            "Nicaragua": "NI",
            "Netherlands": "NL",
            "Norway": "NO",
            "Nepal": "NP",
            "Nauru": "NR",
            "New Zealand": "NZ",
            "African Intellectual Property Organization (OAPI)": "OA",
            "Oman": "OM",
            "Panama": "PA",
            "Peru": "PE",
            "Papua New Guinea": "PG",
            "Philippines": "PH",
            "Pakistan": "PK",
            "Poland": "PL",
            "Portugal": "PT",
            "Palau": "PW",
            "Paraguay": "PY",
            "Qatar": "QA",
            "Community Plant Variety Office (EU) (CPVO)": "QZ",
            "Research Disclosures": "RD",
            "Rhodesia": "RH",
            "Romania": "RO",
            "Serbia": "RS",
            "Russian Federation": "RU",
            "Rwanda": "RW",
            "Saudi Arabia": "SA",
            "Solomon Islands": "SB",
            "Seychelles": "SC",
            "Sudan": "SD",
            "Sweden": "SE",
            "Singapore": "SG",
            "Saint Helena Ascension and Tristan da Cunha": "SH",
            "Slovenia": "SI",
            "Slovakia": "SK",
            "Sierra Leone": "SL",
            "San Marino": "SM",
            "Senegal": "SN",
            "Somalia": "SO",
            "Suriname": "SR",
            "Sao Tome and Principe": "ST",
            "Soviet Union": "SU",
            "El Salvador": "SV",
            "Sint Maarten (Dutch part)": "SX",
            "Syrian Arab Republic": "SY",
            "Swaziland": "SZ",
            "Turks and Caicos Islands": "TC",
            "Chad": "TD",
            "Togo": "TG",
            "Thailand": "TH",
            "Tajikistan": "TJ",
            "Timor–Leste": "TL",
            "Turkmenistan": "TM",
            "Tunisia": "TN",
            "Tonga": "TO",
            "International Technology Disclosures": "TP",
            "Turkey": "TR",
            "Trinidad and Tobago": "TT",
            "Tuvalu": "TV",
            "Taiwan": "TW",
            "United Republic of Tanzania": "TZ",
            "Ukraine": "UA",
            "Uganda": "UG",
            "Uruguay": "UY",
            "Uzbekistan": "UZ",
            "Holy See (Vatican City State)": "VA",
            "Saint Vincent and the Grenadines": "VC",
            "Venezuela": "VE",
            "Virgin Islands (British)": "VG",
            "Vietnam": "VN",
            "Vanuatu": "VU",
            "Samoa": "WS",
            "Nordic Patent Institute (NPI)": "XN",
            "Yemen": "YE",
            "Yugoslavia/Serbia and Montenegro": "YU",
            "South Africa": "ZA",
            "Zambia": "ZM",
            "Zimbabwe": "ZW"
           
        
      };
  const [inputValue, setInputValue] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);

    useEffect( async() => {
       if(redoPA && itemV != ''){ //only for redo
        setSelectedTags([]); //clear tags for different template selections
        const countryCodes = itemV.split(',').map(item => item.replace('*', ''));
        
        const result = countryCodes.map(code => {
            const countryName = Object.keys(countryMap).find(
              (name) => countryMap[name] === code
            );
      
            return countryName ; 
          });

          result.forEach((item, index) => {
            setSelectedTags(prevItems => [...prevItems, countryMap[item]]);
          });
       
        }
        if(!isPatentAuth){setSelectedTags([])}; //clear tags if patent auth checkbox is unchecked

    },[redoPA,itemV,isPatentAuth]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    if (value) {
      const filtered = Object.keys(countryMap).filter(country =>{
        //to include both two letter codes and full country names
        const countryName = country.toLowerCase();
        const countryCode = countryMap[country].toLowerCase(); 

        //country.toLowerCase().includes(value.toLowerCase()) && !selectedTags.includes(country) 
        return (countryName.includes(value.toLowerCase()) || countryCode.includes(value.toLowerCase())) && !selectedTags.includes(countryMap[country]);
                
    });
      setFilteredCountries(filtered);
    } else {
      setFilteredCountries([]); 
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue) {
      if (!selectedTags.includes(inputValue)) {
        setSelectedTags([...selectedTags, inputValue]);
      }
      setInputValue('');
      setFilteredCountries([]);
      event.preventDefault(); // Prevent form submission
      actionModal([...selectedTags, inputValue]);
    }
  };
  const handleRemoveTag = (tag) => {
    setSelectedTags(selectedTags.filter(t => t !== tag));
    actionModal(selectedTags.filter(t => t !== tag));
    inputRef.current.focus();
  };
  const handleSuggestionClick = (country) => {
    if (!selectedTags.includes(country)) {
      setSelectedTags([...selectedTags, countryMap[country]]);
    }
    setInputValue('');
    setFilteredCountries([]); 
    actionModal(([...selectedTags, countryMap[country]]));
    inputRef.current.focus();
  };
  const divStyle = {
      display: 'flex',
      flexWrap: 'wrap', 
      border: '1px solid #ccc', 
      borderColor: isPatentAuth ? '#337ab7' : 'gray',  
      padding: '5px',
      borderRadius: '5px'

  };

  return (
    <div style={{marginLeft: '-25px '}}>
      <div style={divStyle}>
        {selectedTags.map((tag, index) => (
          <div key={index} style={{ border: '1px solid #ccc', margin: '2px', padding: '5px' }}>
            {tag} <span className={'flag-icon flag-icon-' + tag.toLowerCase()}></span>&nbsp;<span onClick={() => handleRemoveTag(tag)} style={{ cursor: 'pointer', color: 'red' }}>×</span>
          </div>
        ))}
       
        <input
          type="text"
          ref={inputRef} 
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Add a country..."
          style={{ border: 'none', outline: 'none', flex: 1 , background: 'none', color: 'inherit'}}
          //disabled={isPatentAuth ? false : true}
        />
      </div>
      {filteredCountries.length > 0 && (
        <ul style={{ marginTop: '2px', maxHeight: '150px', overflowY: 'auto', padding: '0px' }}>
          {filteredCountries.map((country, index) => {
            const countryCode = countryMap[country];
            return (
              <ol
                key={index}
                onClick={() => handleSuggestionClick(country)}
                style={{ cursor: 'pointer', padding: '5px', display: 'flex', alignItems: 'center' }}
              >
                <span className={'flag-icon flag-icon-' + countryCode.toLowerCase()}></span>
                &nbsp; &nbsp;{country} ({countryCode})
              </ol>
            );
          })}
        </ul>
      )}
    </div>
  );
};
//export default AuthoritySearch;
