import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';

import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	modalClassConDSI: {
		position: 'absolute',
		width: '105%',
		padding: '35px 15px 0px',
		right: 'auto',
		bottom: 'auto',
	},
	modalClassConDSIDark: {
		position: 'absolute',
		width: '105%',
		padding: '35px 15px 0px',
		right: 'auto',
		bottom: 'auto',
		backgroundColor: '#292929'
	},
	modalBoxContent: {
		maxHeight: '600px',
		backgroundColor: '#292929'
	},
	modalText: {
		position: 'absolute',
		top: '10px',
		right: '10px'
	},
}));

const DiscoverExportAllModal = props => {
	const classes = useStyles();
	const [showOptions, setShowOptions] = useState(false);
	const [auditInfo, setAuditInfo] = useState(true);
	const [auditOnBottom, setAuditOnBottom] = useState(false);
	const [overview, setOverview] = useState(true);
	const [includeResult, setIncludeResult] = useState(props.includeResult || true);
	const [includeAli, setIncludeAli] = useState(true);
	const [clipAli, setClipAli] = useState(true);
	const [includeAnnotation, setIncludeAnnotation] = useState(props.includeAnnotation || false);
	const [excludeClaim, setExcludeClaim] = useState(false);
	const [excludeFamilyData, setExcludeFamilyData] = useState(false);
	const [includeSS, setIncludeSS] = useState(props.includeSS === undefined ? true : props.includeSS);
	const [includeQS, setIncludeQS] = useState(true);
	const toggle = useSelector(state => state.setToggle.toggle);

	useEffect(() => {
		if (props.includeAnnotation !== undefined) {
			setIncludeAnnotation(props.includeAnnotation);
		}
		if (props.includeSS !== undefined) {
			setIncludeSS(props.includeSS);
		}
	}, [props]);

	const callback = () => {
		let options = {};
		options.auditInfo = auditInfo;
		options.auditOnTop = !auditOnBottom;
		options.overview = overview;
		options.includeResult = includeResult;
		options.includeAli = includeResult && includeAli;
		options.clipAli = includeResult && clipAli;
		options.excludeClaim = includeResult && excludeClaim;
		options.excludeFamilyData = includeResult && excludeFamilyData;
		options.includeAnnotation = includeResult && includeAnnotation;
		options.includeSS = includeSS;
		options.includeQS = includeQS;
		props.resInfo.options = options;
		props.actionModal(props.fieldConfig, props.filter, props.resInfo, props.downloadType);
	}

	return (
		<Modal
			size="lg"
			aria-labelledby="contained-modal-title-vcente"
			centered
			show={props.showModal}
			contentClassName={toggle? classes.modalClassConDSI:classes.modalClassConDSIDark}
		>
			<Link href="#" onClick={(e) => e.preventDefault()} className={classes.modalText} >
				<CloseIcon style = {{ fill : '#777777'}} onClick={props.closeModal} />
			</Link>
			<br></br>
			<div className={toggle? "jumbotron appTextColor": "jumbotronDark appTextColor" } style={toggle ? {backgroundColor: '#eee',padding: '10px', marginBottom: '20px'}:{backgroundColor: 'rgb(238 238 238 / 12%)', padding: '10px', marginBottom: '20px'}}>
				<br></br>
				<br></br>
				<h1 style={{paddingTop: '0px', fontSize: '63px'}}>Export to {props.downloadTypeDesc}</h1>
				<p className="mb-3" style={{fontSize: '21px',fontFamily: 'Helvetica', fontWeight: '200', wordSpacing: '-2px' }}>
					Your {props.downloadTypeDesc} file is generated in the background.
					You can continue to work on your results.
					As soon as your download is ready, we will let you know.
				</p>
				{(props.downloadType === 'PNL' || props.downloadType === 'PNFIL') && props.totalPatentNumber > 20000 &&
					<div><b style={{color: 'red'}}>
						Maximum data export limit reached. 20,000 of {Number(props.totalPatentNumber).toLocaleString()} patent numbers will be downloaded.
					</b></div>
				}
				{props.downloadType !== 'PNL' && props.downloadType !== 'PNFIL' && props.totalAlignmentNumber > 20000 &&
					<div><b style={{color: 'red'}}>
						Maximum data export limit reached. 20,000 of {Number(props.totalAlignmentNumber).toLocaleString()} alignments will be downloaded.
					</b></div>
				}
				{props.downloadType === 'EXCEL' && props.totalAlignmentNumber > 1000 &&
					<div><b style={{color: 'red'}}>
						Alignments are uncolored in exports with more than 1,000 records.
					</b></div>
				}
				{props.downloadType === 'EXCEL' &&
					<div>
						<a href="#" onClick={(e) => {setShowOptions(!showOptions); e.preventDefault();}}>Advanced Options</a>
						{showOptions &&
							<div>
								<div>
									<input type="checkbox" style={{verticalAlign: 'middle'}}
											checked={includeAnnotation} id="includeAnnotation"
											onChange={() => setIncludeAnnotation(!includeAnnotation)}/>
									<label htmlFor="includeAnnotation">&nbsp;Include annotation fields</label>
								</div>
								<div>
									<input type="checkbox" style={{verticalAlign: 'middle'}}
										   checked={includeSS} id="includeSS"
										   onChange={() => setIncludeSS(!includeSS)}/>
									<label htmlFor="includeSS">&nbsp;Subject Sequences</label>
								</div>
							</div>
						}
					</div>
				}
				{(props.downloadType === 'Word' || props.downloadType === 'HTML') &&
					<div>
						<a href="#" onClick={(e) => {
							setShowOptions(!showOptions);
							e.preventDefault();
						}}>Advanced Options</a>
						{showOptions &&
							<div>
							<input type="checkbox"  style={{verticalAlign: 'middle'}}
									   checked={auditInfo} id="auditInfo" onChange={() => setAuditInfo(!auditInfo)}/>
								<label htmlFor="auditInfo">&nbsp;Launch Info</label>
								<div style={{paddingLeft: '15px'}}>
									<input type="checkbox"  style={{verticalAlign: 'middle'}} disabled={!auditInfo}
										   checked={auditOnBottom} id="auditOnBottom" onChange={() => setAuditOnBottom(!auditOnBottom)}/>
									<label htmlFor="auditOnBottom">&nbsp;Put launch info at the end</label>
								</div>
								<div>
									<input type="checkbox"  style={{verticalAlign: 'middle'}}
										   checked={overview} id="overview" onChange={() => setOverview(!overview)}/>
									<label htmlFor="overview">&nbsp;Overview</label>
								</div>
								<div>
									<input type="checkbox"  style={{verticalAlign: 'middle'}}
										   checked={includeResult} id="includeResult" onChange={() => setIncludeResult(!includeResult)}/>
									<label htmlFor="includeResult">&nbsp;Results</label>
									<div style={{paddingLeft: '15px'}}>
										<input type="checkbox"  style={{verticalAlign: 'middle'}} disabled={!includeResult}
											   checked={includeAli} id="includeAli" onChange={() => setIncludeAli(!includeAli)}/>
										<label htmlFor="includeAli">&nbsp;Include alignments</label>
									</div>
									<div style={{paddingLeft: '15px'}}>
										<input type="checkbox"  style={{verticalAlign: 'middle'}} disabled={!includeResult}
											   checked={clipAli} id="clipAli" onChange={() => setClipAli(!clipAli)}/>
										<label htmlFor="clipAli">&nbsp;Clip alignments after 15 lines</label>
									</div>
									<div style={{paddingLeft: '15px'}}>
										<input type="checkbox"  style={{verticalAlign: 'middle'}} disabled={!includeResult}
											   checked={includeAnnotation} id="includeAnnotation" onChange={() => setIncludeAnnotation(!includeAnnotation)}/>
										<label htmlFor="includeAnnotation">&nbsp;Include annotation fields</label>
									</div>
									<div style={{paddingLeft: '15px'}}>
										<input type="checkbox"  style={{verticalAlign: 'middle'}} disabled={!includeResult}
											   checked={excludeClaim} id="excludeClaim" onChange={() => setExcludeClaim(!excludeClaim)}/>
										<label htmlFor="excludeClaim">&nbsp;Exclude claim field</label>
									</div>
									<div style={{paddingLeft: '15px'}}>
										<input type="checkbox"  style={{verticalAlign: 'middle'}} disabled={!includeResult}
											   checked={excludeFamilyData} id="excludeFamilyData" onChange={() => setExcludeFamilyData(!excludeFamilyData)}/>
										<label htmlFor="excludeFamilyData">&nbsp;Exclude family member fields</label>
									</div>
								</div>
								<div>
									<input type="checkbox"  style={{verticalAlign: 'middle'}}
										   checked={includeSS} id="includeSS" onChange={() => setIncludeSS(!includeSS)}/>
									<label htmlFor="includeSS">&nbsp;Subject Sequences</label>
								</div>
								<div>
									<input type="checkbox"  style={{verticalAlign: 'middle'}}
										   checked={includeQS} id="includeQS" onChange={() => setIncludeQS(!includeQS)}/>
									<label htmlFor="includeQS">&nbsp;Query Sequences</label>
								</div>
							</div>
						}
					</div>
				}
				<br></br>
				<div style={{paddingTop: '10px', paddingBottom: '0px', margin : '12px', marginBottom: '70px'}}>
					<button onClick={callback} className="pull-right large-button gqblue">Start Export</button>
					<button onClick={props.closeModal} className="pull-right large-button grey">Cancel</button>
				</div>
			</div>
		</Modal>
	);
}

export default DiscoverExportAllModal;
