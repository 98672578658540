import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import _ from "lodash";
import moment from 'moment';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FolderTreeStructure from '../../shared/FolderTreeStructure/FolderTreeStructure';
import { getSeqSearchInit } from '../../services/seqSearchService';
import { supportMail } from '../../config';
import TextInput from '../../shared/Fields/TextInput';
import SelectBox from '../../shared/Fields/SelectBox';
import Constant from '../../helpers/constant';
import CheckBox from '../../shared/Fields/CheckBox';
import Validate from '../../helpers/validate';
import searchResAntibody from '../../services/searchResAntibody';
import UtilsService from '../../helpers/utils';
import SavedSearch from '../../services/savedsearch';
import SeqVIModal from '../../shared/Modal/SeqVIModal';
import { containerWidth } from '../../shared/constants'
import {useSelector} from "react-redux";

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        minHeight: 30,
        width: '102%',
        margin: 0,
        '&$expanded': {
            minHeight: 30,
            margin: 0,
        },
    },
    content: {
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
    '@media (max-width: 780px)': {
        root: {
            width: '100%',
        }
    }
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: '0 20px',
        margin: '12px 0',
        display: 'block'
    },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        width: '96%',
        margin: '0 auto 28px',
        minHeight: '260px',
        maxWidth: containerWidth,
        // borderBottom: '1px solid #cec7c7',
        padding: '23px 16px 14px',
    },
    loginSubmitCancel: {
        backgroundColor: '#0182C5',
        borderColor: '#1F4E79',
        border: '1px solid #1F4E79',
        color: 'white',
        margin: "4px",
        float: 'right',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#0182C5',
            boxShadow: 'none',
        },
    },
    headerPipe: {
        margin: '0 10px'
    },
    searchInput: {
        width: '30%'
    },
    antibodyNumInput: {
        width: '12%'
    },
    smallTextBox: {
        width: "80px"
    },
}));

function list_to_tree(list) {
    var map = {}, node, roots = [], i;

    for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parent !== null) {
            // if you have dangling branches check that map[node.parentId] exists
            if (node.parent === ':Patents' || node.type === 'folder' || node.hasPatents) { // node.type === 'vseqdb'
                // Only leaves the virtual sequence db which has PN info for personal databases
                list[map[node.parent]].children.push(node);
            }
        } else {
            roots.push(node);
        }
    }
    return roots;
}

function SearchResultAntibody() {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const history = useHistory();
    const [searchModal, setSearchModal] = useState(false);
    const [showDBRequiredError, setShowDBRequiredError] = useState(false);
    // const [strategy, setStrategy] = useState('genepast');
    const [authInfo, setAuthInfo] = useState();
    // const [workflowId, setWorkflowId] = useState();
    const [patientDBData, setPatientDBData] = useState(Constant.patientSearchDatabases);
    const [disableSearch, setDisableSearch] = React.useState(false);
    const [formdata, setFormData] = useState({});
    const [saveFormValue, setSaveFormValue] = useState(false);

    const [proPersonalData, setProPersonalData] = useState({});
    const [proSharedData, setProSharedData] = useState({});
    const [formCheck6, setformCheck6] = React.useState(false);
    const [formCheck7, setformCheck7] = React.useState(false);
    const [sequenceTypeValue, setSequenceType] = useState("nucleotide");
    const [proDb, setProDb] = useState([]);
    const [formSubmit, setFromSubmit] = useState(false);
    const [abSearchName, setAbSearchName] = useState();

    const { workflowId } = useParams();
    const { tempname } = useParams();

    const [credits, setCredits] = useState();
    const [expandedPrtIds, setExpandedPrtIds] = useState();
    const [expandedPrtSharedIds, setExpandedPrtSharedIds] = useState();

    const [namesCheckbox, setNamesCheckbox] = useState(false);
    //const [cdrCheckbox, setCdrCheckbox] = useState(false);

    const toggle = useSelector(state => state.setToggle.toggle);

    // Used to validation the Antibody access
    const userInfo = useSelector(state => state.setUserInfo);

    useEffect(async () => {
        document.title = 'GenomeQuest - Antibody Search';
        setAbSearchName('AB '+moment().format('YYYY-MM-DD h:mm:ss'));
        let resp;
        resp = await getSeqSearchInit(history, null);
        if (resp && resp.response_content && resp.response_content.group_credits){
                setCredits(resp.response_content && resp.response_content.group_credits);
        }
        const getResponse = await searchResAntibody.getAuthInfoAB(workflowId, history);
        if (getResponse && getResponse.response_status === 0) {
            setAuthInfo(getResponse.response_content);
            updateFormData(getResponse.response_content);
            setDisableSearch(true);
            if (getResponse.response_content.dbs && getResponse.response_content.dbs.length > 0) {
                getResponse.response_content.dbs.forEach(function (value) {
                    if (value.startsWith("CAS") || value.startsWith("REG")) {
                        patientDBData[3].ticked = true;
                        if (!getResponse.response_content.redo) {
                            patientDBData[3].selected = true;
                        }
                    }
                    if (value.startsWith("GEAA") || value.startsWith("GENA")) {
                        patientDBData[2].ticked = true;
                        if (!getResponse.response_content.redo) {
                            patientDBData[2].selected = true;
                        }
                    }
                    setPatientDBData([...patientDBData]);
                });
            }

            if (getResponse.response_content.ppuType > 0) {
                setDisableSearch(false);

                if (getResponse.response_content.ppuType === 2 && getResponse.response_content.redo) { // Bundle Redo
                    setDisableSearch(true);
                }
                if (!getResponse.response_content.syscontrol_search_submit && getResponse.response_content.className === "adminium") {
                    setDisableSearch(false);
                }
            }

            if (getResponse && getResponse.response_content && getResponse.response_content.pdbs?.sdb_pro_tree && getResponse.response_content.pdbs.sdb_pro_tree.length > 0) {
                let proteinData = getResponse.response_content.pdbs.sdb_pro_tree;
                let proteinPatent = [], proDefaultPatentDb = [], proMyData = [], proSharedData = [];
                let proFormattedData = list_to_tree(proteinData);
                let getProChild = [];
                if (proFormattedData && proFormattedData.length > 0) {
                    getProChild = proFormattedData[0].children;
                }
                getProChild && getProChild.length > 0 && getProChild.map((item, index) => {
                    if (item && item.id === ':Patents') {
                        proteinPatent = item.children;
                        item.children.filter(i => {
                            if (i.label.includes("Patent sequences")) {
                                proDefaultPatentDb.push(i.id);
                            }
                        });
                    } else if (item && item.id === ':My Data') {
                        //proMyData = item;
                        // Remove the empty folder
                        let myDataArray = [];
                        item.children.filter(i => {
                            if (i.type !== 'folder' || !i.children || i.children.length > 0) {
                                myDataArray.push(i);
                            }
                        });
                        proMyData = item;
                        proMyData.children = myDataArray;
                    } else if (item && item.id === ':Data Shared With Me') {
                        // Remove the empty folder
                        let myDataArray = [];
                        item.children.filter(i => {
                            if (i.type !== 'folder' || !i.children || i.children.length > 0) {
                                myDataArray.push(i);
                            }
                        });
                        proSharedData = item;
                        proSharedData.children = myDataArray;
                        setProSharedData(proSharedData);
                        if(proSharedData && _.size(proSharedData) > 0 ){
                            setExpandedPrtSharedIds(exp(proSharedData));
                        }
                    }
                })
                setProPersonalData(proMyData);
                let proIDS = [];
                if(proMyData && _.size(proMyData) > 0 ){
                    proIDS = exp(proMyData);
                    setExpandedPrtIds(proIDS);
                }
            }


        }
        let tempparam = decodeURI(tempname);
        tempparam = tempparam.toString().replace(/~~GQSF~~/g, '%');

        if (tempparam) {
            let getResponse = await SavedSearch.getParticularTemplate(tempparam, 'Antibody');
            if (getResponse && getResponse.response_status === 0) {
                let data = getResponse.response_content.map;
                data.savedRedo = true;
                let finalData = {}
                finalData.formData = data;
                finalData.savedRedo = true;
                updateFormData(finalData);
            }
        }

        if (userInfo && userInfo.abAccess === false) {
            history.push('/home');
        }
        // else {
        //     history.push('/home');
        // }
        //dispatch(userActions.logout()); 
    }, [userInfo]);
    function updateFormData(data) {
        if (data.redo || data.savedRedo) {
            if (data.formData) {
                data.formData['searchName'] = data.formData['title'];
                let strategyName = data.savedRedo ? data.formData.strat_name : data.formData['strategy'];
                let strategyData = _.find(Constant['strategies'], function (obj) {
                    return obj.val === strategyName;
                });
                data.formData['strategy'] = strategyData['value'];
                patientDBData[0].selected = false;
                patientDBData[1].selected = false;
                patientDBData[2].selected = false;
                patientDBData[3].selected = false;
                let protdbs = [];
                let dbData = data.redo ? data.formData.selectedDbs : data.formData.protdbs;
                dbData && dbData.length > 0 && dbData.forEach(function (value) {
                    if (value === 'p:GQPAT_PRT') {
                        patientDBData[0].selected = true;
                    } else if (value === 'p:GQPAT_PREMIUM_PRT') {
                        patientDBData[1].selected = true;
                    } else if (value === 'p:GEAA') {
                        patientDBData[2].selected = true;
                    } else if (value === 'p:CASPAT_PRT') {
                        patientDBData[3].selected = true;
                    } else {
                        protdbs.push(value);
                    }
                });
                setProDb(protdbs);
                if (protdbs.length > 0) {
                    setformCheck6(true);
                    setformCheck7(true);
                }
                if (data.formData['template_name']) {
                    //setSaveFormValue(true);
                    data.formData['formName'] = data.formData['template_name'];
                }
                setPatientDBData([...patientDBData]);
                setFormData(data.formData);

                if (data.formData.hc_cdr1_desc || data.formData.hc_cdr2_desc || data.formData.hc_cdr3_desc || data.formData.hc_desc
                    || data.formData.lc_cdr1_desc || data.formData.lc_cdr2_desc || data.formData.lc_cdr3_desc ||  data.formData.lc_desc){
                    setNamesCheckbox(true);
                }
                //setCdrCheckbox(data.formData.strat_cdr_dbflip_applied === true);
            }
        }
    }
    let pandarr =[];
    function exp(parent) {
        parent.children.forEach(child1 => {
                pandarr.push(child1.parent);
                if(child1.children.length > 0){
                    exp(child1);
                }
        });
        return pandarr;
    }

    const handleCheckbox = (event, i) => {
        patientDBData[i]['selected'] = event.target.checked;
        // patientDBData[i]['ticked'] = event.target.checked;
        setPatientDBData([...patientDBData]);
        if (event.target.checked) {
            if (showDBRequiredError) setShowDBRequiredError(false)
        }
    }

    function handleCheckbox2(){
        if((authInfo.ppuType === 2 && !authInfo.redo)) {
            if(credits >= 3) { setDisableSearch(!disableSearch); }   
        }
        else{
            setDisableSearch(!disableSearch);
        }
        

    }
    function homePage() {
        history.push('/home');
    }
    function closeSaveModal() {
        setSearchModal(false);
        homePage();
    }

    let initialData = {};
    if (formdata && formdata.savedRedo) {
        initialData = {
            searchName: formdata && formdata.searchName ? formdata.searchName : abSearchName,
            cdrhcseq1: formdata && formdata.hc_cdr1 ? formdata.hc_cdr1 : '',
            cdrhcseq2: formdata && formdata.hc_cdr2 ? formdata.hc_cdr2 : '',
            cdrhcseq3: formdata && formdata.hc_cdr3 ? formdata.hc_cdr3 : '',
            cdrlcseq1: formdata && formdata.lc_cdr1 ? formdata.lc_cdr1 : '',
            cdrlcseq2: formdata && formdata.lc_cdr2 ? formdata.lc_cdr2 : '',
            cdrlcseq3: formdata && formdata.lc_cdr3 ? formdata.lc_cdr3 : '',
            hcOption1: formdata && formdata.hc_cdr1_mismatches ? formdata.hc_cdr1_mismatches : 0,
            hcOption2: formdata && formdata.hc_cdr2_mismatches ? formdata.hc_cdr2_mismatches : 0,
            hcOption3: formdata && formdata.hc_cdr3_mismatches ? formdata.hc_cdr3_mismatches : 0,
            lcOption1: formdata && formdata.lc_cdr1_mismatches ? formdata.lc_cdr1_mismatches : 0,
            lcOption2: formdata && formdata.lc_cdr2_mismatches ? formdata.lc_cdr2_mismatches : 0,
            lcOption3: formdata && formdata.lc_cdr3_mismatches ? formdata.lc_cdr3_mismatches : 0,
            hc1Desc: formdata && formdata.hc_cdr1_desc ? formdata.hc_cdr1_desc : '',
            hc2Desc: formdata && formdata.hc_cdr2_desc ? formdata.hc_cdr2_desc : '',
            hc3Desc: formdata && formdata.hc_cdr3_desc ? formdata.hc_cdr3_desc : '',
            lc1Desc: formdata && formdata.lc_cdr1_desc ? formdata.lc_cdr1_desc : '',
            lc2Desc: formdata && formdata.lc_cdr2_desc ? formdata.lc_cdr2_desc : '',
            lc3Desc: formdata && formdata.lc_cdr3_desc ? formdata.lc_cdr3_desc : '',
            lightDesc: formdata && formdata.lc_desc ? formdata.lc_desc : '',
            heavyDesc: formdata && formdata.hc_desc ? formdata.hc_desc : '',
            strategy: formdata && formdata.strat_name ? formdata.strat_name : 'genepast',
            percId: formdata && formdata.strat_genepast_perc_id ? formdata.strat_genepast_perc_id : 80,
            expectCutoff: formdata && formdata.strat_blast_eval_cutoff ? formdata.strat_blast_eval_cutoff : 10,
            wordSize: formdata && formdata.strat_blast_word_size_pro ? formdata.strat_blast_word_size_pro : 3,
            hcFullSeq: formdata && formdata.qdb_seq_hc ? formdata.qdb_seq_hc : '',
            lcFullSeq: formdata && formdata.qdb_seq_lc ? formdata.qdb_seq_lc : '',
            formName: formdata && formdata.formName ? formdata.formName : '',
            hcPercId: formdata && formdata.strat_genepast_perc_id_hc ? formdata.strat_genepast_perc_id_hc : 80,
            lcPercId: formdata && formdata.strat_genepast_perc_id_lc ? formdata.strat_genepast_perc_id_lc : 80,
            //cdrCheckbox: formdata && formdata.strat_cdr_dbflip_applied ? formdata.strat_cdr_dbflip_applied : false,
        }
    } else {
        initialData = {
            searchName: formdata && formdata.searchName ? formdata.searchName : abSearchName,
            cdrhcseq1: formdata && formdata.cdrhcseq1 ? formdata.cdrhcseq1 : '',
            cdrhcseq2: formdata && formdata.cdrhcseq2 ? formdata.cdrhcseq2 : '',
            cdrhcseq3: formdata && formdata.cdrhcseq3 ? formdata.cdrhcseq3 : '',
            cdrlcseq1: formdata && formdata.cdrlcseq1 ? formdata.cdrlcseq1 : '',
            cdrlcseq2: formdata && formdata.cdrlcseq2 ? formdata.cdrlcseq2 : '',
            cdrlcseq3: formdata && formdata.cdrlcseq3 ? formdata.cdrlcseq3 : '',
            hcOption1: formdata && formdata.hcOption1 ? formdata.hcOption1 : 0,
            hcOption2: formdata && formdata.hcOption2 ? formdata.hcOption2 : 0,
            hcOption3: formdata && formdata.hcOption3 ? formdata.hcOption3 : 0,
            lcOption1: formdata && formdata.lcOption1 ? formdata.lcOption1 : 0,
            lcOption2: formdata && formdata.lcOption2 ? formdata.lcOption2 : 0,
            lcOption3: formdata && formdata.lcOption3 ? formdata.lcOption3 : 0,
            hc1Desc: formdata && formdata.hc_cdr1_desc ? formdata.hc_cdr1_desc : '',
            hc2Desc: formdata && formdata.hc_cdr2_desc ? formdata.hc_cdr2_desc : '',
            hc3Desc: formdata && formdata.hc_cdr3_desc ? formdata.hc_cdr3_desc : '',
            lc1Desc: formdata && formdata.lc_cdr1_desc ? formdata.lc_cdr1_desc : '',
            lc2Desc: formdata && formdata.lc_cdr2_desc ? formdata.lc_cdr2_desc : '',
            lc3Desc: formdata && formdata.lc_cdr3_desc ? formdata.lc_cdr3_desc : '',
            lightDesc: formdata && formdata.lc_desc ? formdata.lc_desc : '',
            heavyDesc: formdata && formdata.hc_desc ? formdata.hc_desc : '',
            strategy: formdata && formdata.strategy ? formdata.strategy : 'genepast',
            percId: formdata && formdata.percId ? formdata.percId : 80,
            expectCutoff: formdata && formdata.expectCutoff ? formdata.expectCutoff : 10,
            wordSize: formdata && formdata.wordSize ? formdata.wordSize : 3,
            hcFullSeq: formdata && formdata.hcFullSeq ? formdata.hcFullSeq : '',
            lcFullSeq: formdata && formdata.lcFullSeq ? formdata.lcFullSeq : '',
            formName: formdata && formdata.formName ? formdata.formName : '',
            hcPercId: formdata && formdata.hcPercId ? formdata.hcPercId : 80,
            lcPercId: formdata && formdata.lcPercId ? formdata.lcPercId : 80,
            //cdrCheckbox: formdata && formdata.strat_cdr_dbflip_applied ? formdata.strat_cdr_dbflip_applied : false,
        }
    }

    const formik = useFormik({
        initialValues: initialData,
        enableReinitialize: true,
        validationSchema: Validate.AntibodySearchValidation(saveFormValue),
        onSubmit: async (values) => {
            let { searchName, cdrhcseq1, cdrhcseq2, cdrhcseq3, cdrlcseq1, cdrlcseq2, cdrlcseq3, hcOption1, hcOption2, hcOption3, lcOption1, lcOption2, lcOption3, strategy, percId, expectCutoff, wordSize, hcFullSeq, lcFullSeq, formName,
                hc1Desc, hc2Desc, hc3Desc, lc1Desc, lc2Desc, lc3Desc, heavyDesc, lightDesc, hcPercId, lcPercId } = values;
            if (cdrhcseq1 === '' && cdrhcseq2 === '' && cdrhcseq3 === '' && cdrlcseq1 === '' && cdrlcseq2 === '' && cdrlcseq3 === '') {
                toast.error(t('CDRSeqValidation'));
                return false;
            }
            if (strategy && (strategy === 'genepast' || strategy === 'hash') && (!hcPercId || hcPercId === '' || !lcPercId || lcPercId === '')) {
                toast.error(t('percRequired'));
                return false;
            }
            // let selectDB = _.filter(patientDBData, function(user) {
            //     return user.selected;
            // });
            let selectDB = [];
            selectDB = _.filter(patientDBData, { selected: true }).map(v => "p:" + v.value);

            proDb.forEach(element => {
                selectDB.push(element);
            });

            if (selectDB.length === 0) {
                setShowDBRequiredError(true)
                return false;
            }

            let strategyItem = _.find(Constant['strategies'], function (obj) {
                return obj.value === strategy;
            });
            setSearchModal(true);
            //let parentId = '';
            let postData = {
                qdb_seq_type: 'protein',
                hc_cdr1: cdrhcseq1,
                hc_cdr1_desc: hc1Desc,
                hc_cdr1_mismatches: hcOption1,
                hc_cdr2: cdrhcseq2,
                hc_cdr2_desc: hc2Desc,
                hc_cdr2_mismatches: hcOption2,
                hc_cdr3: cdrhcseq3,
                hc_cdr3_desc: hc3Desc,
                hc_cdr3_mismatches: hcOption3,
                lc_cdr1: cdrlcseq1,
                lc_cdr1_desc: lc1Desc,
                lc_cdr1_mismatches: lcOption1,
                lc_cdr2: cdrlcseq2,
                lc_cdr2_desc: lc2Desc,
                lc_cdr2_mismatches: lcOption2,
                lc_cdr3: cdrlcseq3,
                lc_cdr3_desc: lc3Desc,
                lc_cdr3_mismatches: lcOption3,
                hc_desc: heavyDesc, 
                lc_desc: lightDesc,
                qdb_seq_hc: hcFullSeq,
                qdb_seq_lc: lcFullSeq,
                searchtype: 'FTO',
                title: searchName,
                strat_name: strategyItem['val'],
                strat_genepast_perc_id: percId,
                strat_genepast_perc_id_hc: hcPercId,
                strat_genepast_perc_id_lc: lcPercId,
                strat_genepast_perc_id_over: 'QUERY',
                strat_cdr_dbflip_applied: false, //cdrCheckbox,
                strat_blast_word_size_pro: wordSize,
                strat_blast_eval_cutoff: expectCutoff,
                strat_blast_scoring_matrix_pro: 'BLOSUM62',
                protdbs: selectDB,
                protdb_type: 'multiple',
                parent_id: workflowId,
                template_name: saveFormValue ? (formName).trim() : '', // Set this value when selecting "Save this form for later use as"

            }
            const getResponse = await searchResAntibody.submitAnitbodySearch(postData, history, t);
            setSearchModal(false);
            if (getResponse && getResponse.response_status === 0) {
                history.push('/home');
            } else {
                toast.error('Error in Search');
            }
        },
    });

    function handleDbChange(id) {
        if (proDb.includes(id)) {
            setProDb(proDb.filter(dbName => dbName !== id));
        } else {
            proDb.push(id.toString());
            setProDb([...proDb]);
        }
    }

    function setFormValue() {
        setSaveFormValue(!saveFormValue);
        formik.setFieldValue("formName", '');
    }

    if (formSubmit && formik && formik.errors && Object.keys(formik.errors).length > 0) {
        document.getElementById(Object.keys(formik.errors)[0]).scrollIntoView({ behavior: "smooth", block: "center", inline: "start" });
        setFromSubmit(false);
    }

    function submitForm() {
        formik.handleSubmit();
        setFromSubmit(true);
    }

    return (
        <div className={classes.grow}>
            {userInfo.current_user.user_class_id != 12 &&
            <form name="antibodySearchForm" onSubmit={formik.handleSubmit} className={classes.loginDiv}>

                <Row>
                    <Col lg="12" md="12" sm='12' xs='12' className={"mb-2 " + (authInfo && !authInfo.syscontrol_search_submit ? 'd-block' : 'd-none')}>
                        <Typography className="text-danger">
                            {t('ABsearchDisableText')}
                            {authInfo && authInfo.syscontrol_search_submit_txt}
                            {t('patienceThanksText')}</Typography>
                    </Col>
                    <Col lg="12" md="12" sm='12' xs='12'>
                        <h6 className={"appTextColor loginTitle"}>{t('searchDetails')}​</h6>
                        <Row>
                            {/* <Col lg="1" md="1"  className="pr-0">
                            <img src={seqSearchImg} alt="Immunoglobulin variations for Steve​"  />
                        </Col> */}
                            <Col lg="12" md="12" className="p-0 content">
                                <div className="form-group ml-3" width={"900px"} style={{display:"inline"}}>
                                    <TextInput spellcheck="false"
                                        fullWidth={false}
                                        id="searchName"
                                        name="searchName"
                                        label={t('searchName')}
                                        variant="outlined"
                                        onChange={formik.handleChange}
                                        className={classes.searchInput}
                                        value={formik.values.searchName}
                                        error={formik.touched.searchName && Boolean(formik.errors.searchName)}
                                        helperText={formik.touched.searchName && formik.errors.searchName}
                                    />   
                                    <Col className='pt-2'>                       
                                        <CheckBox
                                            name="nameCheckbox"
                                            id="nameCheckbox"
                                            checked={namesCheckbox}
                                            onChange={e => setNamesCheckbox(!namesCheckbox)}
                                            className={"absolutePosition " + classes.checkBox}
                                            color="primary"
                                        />
                                        <label className={classes.checkBoxContent + " bodyText cursorPointer"}>Add Query Description?</label>
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                        <hr style={toggle ? {backgroundColor: '#dfdfdf'}: {backgroundColor: '#aea9a9'}}/>
                    </Col>
                    <Col lg="12" md="12" sm='12' xs='12' className="mb-2">
                        <h6 className={"appTextColor loginTitle"}>{t('cdrhHeavyChain')}</h6>
                        {namesCheckbox &&
                        <Row className="mb-2">
                            <Col lg="12" md="12" className="p-0 content">
                                <TextInput spellcheck="false"
                                    id="hc1Desc"
                                    name="hc1Desc"
                                    label={"HC-CDR1 Description"}
                                    variant="outlined"
                                    className={classes.searchInput + (" float-left ml-3 mr-2")}
                                    value={formik.values.hc1Desc}
                                    onChange={formik.handleChange}
                                    error={formik.touched.hc1Desc && Boolean(formik.errors.hc1Desc)}
                                    helperText={formik.errors.hc1Desc}
                                    disabled={authInfo && authInfo.redo}
                                />
                            </Col>
                        </Row>
                        }
                        <Row className="mb-2">
                            <Col lg="12" md="12" className="p-0 content">
                                <TextInput spellcheck="false"
                                    id="cdrhcseq1"
                                    name="cdrhcseq1"
                                    label={t('cdrhcseq1')}
                                    variant="outlined"
                                    className={classes.searchInput + (" float-left ml-3 mr-2")}
                                    value={formik.values.cdrhcseq1}
                                    onChange={formik.handleChange}
                                    error={formik.touched.cdrhcseq1 && Boolean(formik.errors.cdrhcseq1)}
                                    helperText={formik.errors.cdrhcseq1}
                                    disabled={authInfo && authInfo.redo}
                                />
                                <TextInput
                                    fullWidth={false}
                                    id="hcOption1"
                                    name="hcOption1"
                                    variant="outlined"
                                    type="number"
                                    InputProps={{ inputProps: { min: 0 } }}
                                    className={classes.antibodyNumInput + (" float-left")}
                                    value={formik.values.hcOption1}
                                    onKeyDown={UtilsService.restrictCharacter}
                                    onChange={formik.handleChange}
                                    error={formik.touched.hcOption1 && Boolean(formik.errors.hcOption1)}
                                    helperText={formik.errors.hcOption1}
                                />
                                <Typography className="mx-2 mt-2 float-left">{t('missmatchAllow')}</Typography>
                            </Col>
                        </Row>
                        {namesCheckbox &&
                        <Row className="mb-2">
                            <Col lg="12" md="12" className="p-0 content">
                                <TextInput spellcheck="false"
                                    id="hc2Desc"
                                    name="hc2Desc"
                                    label={"HC-CDR2 Description"}
                                    variant="outlined"
                                    className={classes.searchInput + (" float-left ml-3 mr-2")}
                                    value={formik.values.hc2Desc}
                                    onChange={formik.handleChange}
                                    error={formik.touched.hc2Desc && Boolean(formik.errors.hc2Desc)}
                                    helperText={formik.errors.hc2Desc}
                                    disabled={authInfo && authInfo.redo}
                                />
                            </Col>
                        </Row>
                        }
                        <Row className="mb-2">
                            <Col lg="12" md="12" sm='12' xs='12' className="p-0 content">
                                <TextInput spellcheck="false"
                                    id="cdrhcseq2"
                                    name="cdrhcseq2"
                                    label={t('cdrhcseq2')}
                                    variant="outlined"
                                    className={classes.searchInput + (" float-left ml-3 mr-2")}
                                    value={formik.values.cdrhcseq2}
                                    onChange={formik.handleChange}
                                    error={formik.touched.cdrhcseq2 && Boolean(formik.errors.cdrhcseq2)}
                                    helperText={formik.errors.cdrhcseq2}
                                    disabled={authInfo && authInfo.redo}
                                />
                                <TextInput
                                    fullWidth={false}
                                    id="hcOption2"
                                    name="hcOption2"
                                    variant="outlined"
                                    type="number"
                                    className={classes.antibodyNumInput + (" float-left")}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={formik.values.hcOption2}
                                    onKeyDown={UtilsService.restrictCharacter}
                                    onChange={formik.handleChange}
                                    error={formik.touched.hcOption2 && Boolean(formik.errors.hcOption2)}
                                    helperText={formik.errors.hcOption2}
                                />
                                <Typography className="mx-2 mt-2 float-left">{t('missmatchAllow')}</Typography>
                            </Col>
                        </Row>
                        {namesCheckbox &&
                        <Row className="mb-2">
                            <Col lg="12" md="12" className="p-0 content">
                                <TextInput spellcheck="false"
                                    id="hc3Desc"
                                    name="hc3Desc"
                                    label={"HC-CDR3 Description"}
                                    variant="outlined"
                                    className={classes.searchInput + (" float-left ml-3 mr-2")}
                                    value={formik.values.hc3Desc}
                                    onChange={formik.handleChange}
                                    error={formik.touched.hc3Desc && Boolean(formik.errors.hc3Desc)}
                                    helperText={formik.errors.hc3Desc}
                                    disabled={authInfo && authInfo.redo}
                                />
                            </Col>
                        </Row>
                        }
                        <Row className="mb-2">
                            <Col lg="12" md="12" sm='12' xs='12' className="p-0 content">
                                <TextInput spellcheck="false"
                                    id="cdrhcseq3"
                                    name="cdrhcseq3"
                                    label={t('cdrhcseq3')}
                                    variant="outlined"
                                    className={classes.searchInput + (" float-left ml-3 mr-2")}
                                    value={formik.values.cdrhcseq3}
                                    onChange={formik.handleChange}
                                    error={formik.touched.cdrhcseq3 && Boolean(formik.errors.cdrhcseq3)}
                                    helperText={formik.errors.cdrhcseq3}
                                    disabled={authInfo && authInfo.redo}
                                />
                                <TextInput
                                    fullWidth={false}
                                    id="hcOption3"
                                    name="hcOption3"
                                    variant="outlined"
                                    type="number"
                                    className={classes.antibodyNumInput + (" float-left")}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={formik.values.hcOption3}
                                    onKeyDown={UtilsService.restrictCharacter}
                                    onChange={formik.handleChange}
                                    error={formik.touched.hcOption3 && Boolean(formik.errors.hcOption3)}
                                    helperText={formik.errors.hcOption3}
                                />
                                <Typography className="mx-2 mt-2 float-left">{t('missmatchAllow')}</Typography>
                            </Col>
                        </Row>
                        <h6 className={"appTextColor loginTitle"}>{t('cdrhLightChain')}</h6>
                        {namesCheckbox &&
                        <Row className="mb-2">
                            <Col lg="12" md="12" className="p-0 content">
                                <TextInput spellcheck="false"
                                    id="lc1Desc"
                                    name="lc1Desc"
                                    label={"LC-CDR1 Description"}
                                    variant="outlined"
                                    className={classes.searchInput + (" float-left ml-3 mr-2")}
                                    value={formik.values.lc1Desc}
                                    onChange={formik.handleChange}
                                    error={formik.touched.lc1Desc && Boolean(formik.errors.lc1Desc)}
                                    helperText={formik.errors.lc1Desc}
                                    disabled={authInfo && authInfo.redo}
                                />
                            </Col>
                        </Row>
                        }
                        <Row className="mb-2">
                            <Col lg="12" md="12" sm='12' xs='12' className="p-0 content">
                                <TextInput spellcheck="false"
                                    id="cdrlcseq1"
                                    name="cdrlcseq1"
                                    label={t('cdrlcseq1')}
                                    variant="outlined"
                                    className={classes.searchInput + (" float-left ml-3 mr-2")}
                                    value={formik.values.cdrlcseq1}
                                    onChange={formik.handleChange}
                                    error={formik.touched.cdrlcseq1 && Boolean(formik.errors.cdrlcseq1)}
                                    helperText={formik.errors.cdrlcseq1}
                                    disabled={authInfo && authInfo.redo}
                                />
                                <TextInput
                                    fullWidth={false}
                                    id="lcOption1"
                                    name="lcOption1"
                                    variant="outlined"
                                    type="number"
                                    className={classes.antibodyNumInput + (" float-left")}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={formik.values.lcOption1}
                                    onKeyDown={UtilsService.restrictCharacter}
                                    onChange={formik.handleChange}
                                    error={formik.touched.lcOption1 && Boolean(formik.errors.lcOption1)}
                                    helperText={formik.errors.lcOption1}
                                />
                                <Typography className="mx-2 mt-2 float-left">{t('missmatchAllow')}</Typography>
                            </Col>
                        </Row>
                        {namesCheckbox &&
                        <Row className="mb-2">
                            <Col lg="12" md="12" className="p-0 content">
                                <TextInput spellcheck="false"
                                    id="lc2Desc"
                                    name="lc2Desc"
                                    label={"LC-CDR2 Description"}
                                    variant="outlined"
                                    className={classes.searchInput + (" float-left ml-3 mr-2")}
                                    value={formik.values.lc2Desc}
                                    onChange={formik.handleChange}
                                    error={formik.touched.lc2Desc && Boolean(formik.errors.lc2Desc)}
                                    helperText={formik.errors.lc2Desc}
                                    disabled={authInfo && authInfo.redo}
                                />
                            </Col>
                        </Row>
                        }
                        <Row className="mb-2">
                            <Col lg="12" md="12" sm='12' xs='12' className="p-0 content">
                                <TextInput spellcheck="false"
                                    id="cdrlcseq2"
                                    name="cdrlcseq2"
                                    label={t('cdrlcseq2')}
                                    variant="outlined"
                                    className={classes.searchInput + (" float-left ml-3 mr-2")}
                                    value={formik.values.cdrlcseq2}
                                    onChange={formik.handleChange}
                                    error={formik.touched.cdrlcseq2 && Boolean(formik.errors.cdrlcseq2)}
                                    helperText={formik.errors.cdrlcseq2}
                                    disabled={authInfo && authInfo.redo}
                                />
                                <TextInput
                                    fullWidth={false}
                                    id="lcOption2"
                                    name="lcOption2"
                                    variant="outlined"
                                    type="number"
                                    className={classes.antibodyNumInput + (" float-left")}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={formik.values.lcOption2}
                                    onKeyDown={UtilsService.restrictCharacter}
                                    onChange={formik.handleChange}
                                    error={formik.touched.lcOption2 && Boolean(formik.errors.lcOption2)}
                                    helperText={formik.errors.lcOption2}
                                />
                                <Typography className="mx-2 mt-2 float-left">{t('missmatchAllow')}</Typography>
                            </Col>
                        </Row>
                        {namesCheckbox &&
                        <Row className="mb-2">
                            <Col lg="12" md="12" className="p-0 content">
                                <TextInput spellcheck="false"
                                    id="lc3Desc"
                                    name="lc3Desc"
                                    label={"LC-CDR3 Description"}
                                    variant="outlined"
                                    className={classes.searchInput + (" float-left ml-3 mr-2")}
                                    value={formik.values.lc3Desc}
                                    onChange={formik.handleChange}
                                    error={formik.touched.lc3Desc && Boolean(formik.errors.lc3Desc)}
                                    helperText={formik.errors.lc3Desc}
                                    disabled={authInfo && authInfo.redo}
                                />
                            </Col>
                        </Row>
                        }
                        <Row className="mb-4">
                            <Col lg="12" md="12" sm='12' xs='12' className="p-0 content">
                                <TextInput spellcheck="false"
                                    id="cdrlcseq3"
                                    name="cdrlcseq3"
                                    label={t('cdrlcseq3')}
                                    variant="outlined"
                                    className={classes.searchInput + (" float-left ml-3 mr-2")}
                                    value={formik.values.cdrlcseq3}
                                    onChange={formik.handleChange}
                                    error={formik.touched.cdrlcseq3 && Boolean(formik.errors.cdrlcseq3)}
                                    helperText={formik.errors.cdrlcseq3}
                                    disabled={authInfo && authInfo.redo}
                                />
                                <TextInput
                                    fullWidth={false}
                                    id="lcOption3"
                                    name="lcOption3"
                                    variant="outlined"
                                    type="number"
                                    className={classes.antibodyNumInput + (" float-left")}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={formik.values.lcOption3}
                                    onKeyDown={UtilsService.restrictCharacter}
                                    onChange={formik.handleChange}
                                    error={formik.touched.lcOption3 && Boolean(formik.errors.lcOption3)}
                                    helperText={formik.errors.lcOption3}
                                />
                                <Typography className="mx-2 mt-2 float-left">{t('missmatchAllow')}</Typography>
                            </Col>
                        </Row>
                        {/*<Row className="mb-4">
                            <Col lg="12" md="12" className="p-0 content">
                                <CheckBox
                                    name="cdrCheckbox"
                                    id="cdrCheckbox"
                                    checked={cdrCheckbox}
                                    onChange={e => {setCdrCheckbox(!cdrCheckbox);formik.handleChange(e)}}
                                    className={"float-left mx-2 mt-1"}
                                    color="primary"
                                />
                                <label className={"float-left mt-2"} htmlFor="cdrCheckbox">Allow subject results shorter than the CDR Sequence</label>
                            </Col>
                        </Row>*/}

                        <hr style={toggle ? {backgroundColor: '#dfdfdf'}: {backgroundColor: '#aea9a9'}}/>
                    </Col>
                    <Col lg="12" md="12" sm='12' xs='12' className="mb-2">
                        <h6 className={"appTextColor loginTitle"}>{t('heavyLightChain')}</h6>
                        <Row className="mb-2">
                            <Col lg="12" md="12" sm='12' xs='12' className="p-0 content">
                                <Col lg="3" md="3" sm='3' xs='3' className="p-0 float-left">
                                    <SelectBox
                                        margin="normal"
                                        variant="outlined"
                                        name="strategy"
                                        id="strategy"
                                        items={Constant['strategiesAB']}
                                        value={formik.values.strategy}
                                        onChange={formik.handleChange}
                                        className={"float-left ml-3"}
                                        //disabled={authInfo && authInfo.redo}
                                    />
                                </Col>
                                {formik.values.strategy !== "blast" &&
                                    <div style={{display: "flex", alignItems: "center"}}>
                                    <>
                                        <Typography className="ml-5 float-left mx-2">Require</Typography>
                                        <TextInput
                                            fullWidth={false}
                                            id="hcPercId"
                                            name="hcPercId"
                                            variant="outlined"
                                            type="number"
                                            className={(" w-12")}
                                            value={formik.values.hcPercId}
                                            InputProps={{ inputProps: { min: 65, max: 100 } }}
                                            onKeyDown={UtilsService.restrictCharacter}
                                            onChange={formik.handleChange}
                                            error={formik.touched.hcPercId && Boolean(formik.errors.hcPercId)}
                                            helperText={formik.errors.percId}
                                        />
                                        <Typography className="mx-2">% Identity over the HC Sequence, </Typography>
                                        <TextInput
                                            fullWidth={false}
                                            id="lcPercId"
                                            name="lcPercId"
                                            variant="outlined"
                                            type="number"
                                            className={(" w-12")}
                                            value={formik.values.lcPercId}
                                            InputProps={{ inputProps: { min: 65, max: 100 } }}
                                            onKeyDown={UtilsService.restrictCharacter}
                                            onChange={formik.handleChange}
                                            error={formik.touched.lcPercId && Boolean(formik.errors.lcPercId)}
                                            helperText={formik.errors.percId}
                                        />
                                        <Typography className="mx-2">% Identity over the LC Sequence</Typography>
                                    </>
                                    </div>  
                                }
                                <Col lg="9" md="9" sm='9' xs='9' className={"p-0 content " + (formik.values.strategy === "blast" ? 'd-block' : 'd-none')}>
                                    <Typography className="ml-5 mr-1 mt-2 float-left">Expect Cutoff</Typography>
                                    <TextInput
                                        fullWidth={false}
                                        id="expectCutoff"
                                        name="expectCutoff"
                                        variant="outlined"
                                        type="text"
                                        className={classes.antibodyNumInput + (" float-left")}
                                        value={formik.values.expectCutoff}
                                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                                        onChange={formik.handleChange}
                                        error={formik.touched.expectCutoff && Boolean(formik.errors.expectCutoff)}
                                        helperText={formik.errors.expectCutoff}
                                        //disabled={authInfo && authInfo.redo}
                                    />
                                    <Typography className="mx-2 mt-2 float-left">Word Size</Typography>
                                    <TextInput
                                        fullWidth={false}
                                        id="wordSize"
                                        name="wordSize"
                                        variant="outlined"
                                        type="number"
                                        className={classes.antibodyNumInput + (" float-left")}
                                        value={formik.values.wordSize}
                                        InputProps={{ inputProps: { min: 2, max: 3 } }}
                                        onKeyDown={UtilsService.restrictCharacter}
                                        onChange={formik.handleChange}
                                        error={formik.touched.wordSize && Boolean(formik.errors.wordSize)}
                                        helperText={formik.errors.wordSize}
                                        //disabled={authInfo && authInfo.redo}
                                    />
                                </Col>
                                <Col lg="12" md="12" sm='12' xs='12' className="p-0 content float-left">
                                    <div className="form-group px-3 ">
                                    {namesCheckbox &&
                                        <TextInput spellcheck="false"
                                            id="heavyDesc"
                                            name="heavyDesc"
                                            label={"Heavy Chain Description"}
                                            variant="outlined"
                                            className={classes.searchInput + (" float-left mr-2 mb-2")}
                                            value={formik.values.heavyDesc}
                                            onChange={formik.handleChange}
                                            error={formik.touched.heavyDesc && Boolean(formik.errors.heavyDesc)}
                                            helperText={formik.errors.heavyDesc}
                                            disabled={authInfo && authInfo.redo}
                                        />
                                    }
                                        <TextInput spellcheck="false"
                                            rowsMax="4"
                                            rows="4"
                                            multiline={true}
                                            fullWidth
                                            id="hcFullSeq"
                                            name="hcFullSeq"
                                            label={t('hcFullSeq')}
                                            variant="outlined"
                                            value={formik.values.hcFullSeq}
                                            onChange={formik.handleChange}
                                            error={formik.touched.hcFullSeq && Boolean(formik.errors.hcFullSeq)}
                                            helperText={formik.errors.hcFullSeq}
                                            disabled={authInfo && authInfo.redo}
                                        />
                                    </div>
                                    <div className="form-group px-3">
                                    {namesCheckbox &&
                                        <TextInput spellcheck="false"
                                            id="lightDesc"
                                            name="lightDesc"
                                            label={"Light Chain Description"}
                                            variant="outlined"
                                            className={classes.searchInput + (" float-left mr-2 mb-2")}
                                            value={formik.values.lightDesc}
                                            onChange={formik.handleChange}
                                            error={formik.touched.lightDesc && Boolean(formik.errors.lightDesc)}
                                            helperText={formik.errors.lightDesc}
                                            disabled={authInfo && authInfo.redo}
                                        />
                                    }
                                        <TextInput spellcheck="false"
                                            rowsMax="4"
                                            rows="4"
                                            multiline={true}
                                            fullWidth
                                            id="lcFullSeq"
                                            name="lcFullSeq"
                                            label={t('lcFullSeq')}
                                            variant="outlined"
                                            value={formik.values.lcFullSeq}
                                            onChange={formik.handleChange}
                                            error={formik.touched.lcFullSeq && Boolean(formik.errors.lcFullSeq)}
                                            helperText={formik.errors.lcFullSeq}
                                            disabled={authInfo && authInfo.redo}
                                        />
                                    </div>
                                </Col>
                            </Col>
                        </Row>
                        <hr style={toggle ? {backgroundColor: '#dfdfdf'}: {backgroundColor: '#aea9a9'}}/>
                    </Col>
                    <Col lg="12" md="12" sm='12' xs='12' className="mb-2">
                        <span className={showDBRequiredError ? 'd-block' : 'd-none'} style={{ color: '#f44336', fontSize: '13px', fontStyle: 'italic', marginBottom: '5px' }}>
                            {t('dbMandatoryErr')}
                        </span>
                        <h6 className={"appTextColor loginTitle mb-2 "}>{t('patientDBSearch')}</h6>
                        <Row className="mb-2">
                            <Col lg="4" md="4" sm='4' xs='4' className="p-0 content">
                                {patientDBData.map((values, i) => {
                                    // Return the element. Also pass key     
                                    return (<div className="float-left w-100 content" key={"id-" + values.value}>
                                        <CheckBox
                                            // defaultChecked
                                            // checked={true}
                                            checked={values.selected}
                                            color="primary"
                                            className={"float-left mx-2 " + (values.ticked ? 'd-block' : 'd-none')}
                                            name={values.value}
                                            id={values.value}
                                            key={values.value}
                                            onChange={(e) => handleCheckbox(e, i)}
                                        // onChange={() => { setIspublishGQUnknownDates(!ispublishGQUnknownDates) }}
                                        />
                                        <Typography className={"float-left mt-1 w-75 " + (values.ticked ? 'd-block' : 'd-none')}>
                                            {values.label}
                                        </Typography>
                                    </div>)
                                })}
                            </Col>
                        </Row>
                        {proPersonalData && _.size(proPersonalData) > 0 && <div>
                            <Accordion expanded={formCheck6} onChange={() => setformCheck6(prevState => !prevState)} style={toggle ? {backgroundColor: '#ffffff'}: {backgroundColor: '#292929'} }>
                                <AccordionSummary aria-controls="panel1c-content" id="panel1c-header" className="p-0" style={toggle ? {backgroundColor: '#ffffff'}: {backgroundColor: '#292929'} }>
                                    <p className="subHeading m-0">
                                        {formCheck6 && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                        {!formCheck6 && <ArrowRightIcon className={classes.arrowIcon} />}
                                        <span className={classes.arrowIconTitle}>{t("personalProDb")}</span>
                                    </p>
                                </AccordionSummary>
                                <AccordionDetails style={toggle ? {backgroundColor: '#ffffff'}: {backgroundColor: '#292929'} }>
                                    <FolderTreeStructure  expandedIds={expandedPrtIds} treeData={proPersonalData} parentCallBack={handleDbChange} dataArray={proDb} seQValue={sequenceTypeValue === "nucleotide" ? "nuc" : "pro"} pageType="ipseq" />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        }
                        {proSharedData && _.size(proSharedData) > 0 && <div>
                            <Accordion expanded={formCheck7} onChange={() => setformCheck7(prevState => !prevState)} style={toggle ? {backgroundColor: '#ffffff'}: {backgroundColor: '#292929'} }>
                                <AccordionSummary aria-controls="panel1c-content" id="panel1c-header" className="p-0" style={toggle ? {backgroundColor: '#ffffff'}: {backgroundColor: '#292929'} }>
                                    <p className="subHeading m-0">
                                        {formCheck7 && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                        {!formCheck7 && <ArrowRightIcon className={classes.arrowIcon} />}
                                        <span className={classes.arrowIconTitle}>{t("sharedProDb")}</span>
                                    </p>
                                </AccordionSummary>
                                <AccordionDetails style={toggle ? {backgroundColor: '#ffffff'}: {backgroundColor: '#292929'} }>
                                    <FolderTreeStructure  expandedIds={expandedPrtSharedIds} treeData={proSharedData} parentCallBack={handleDbChange} dataArray={proDb} seQValue={sequenceTypeValue === "nucleotide" ? "nuc" : "pro"} pageType="ipseq" />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        }
                        <hr style={toggle ? {backgroundColor: '#dfdfdf'}: {backgroundColor: '#aea9a9'}}/>
                    </Col>
                    <Col lg="12" md="12" xs="12" sm='12' className={"mb-2 " + (authInfo && (authInfo.ppuType === 1 || (authInfo.ppuType === 2 && !authInfo.redo) ? 'd-block' : 'd-none'))}>
                        <div className={(authInfo && (authInfo.ppuType === 1) ? 'd-block' : 'd-none')}>
                            <h6 className={"appTextColor loginTitle"}>{t('ppuuserSearchTitle')}</h6>
                            <h6 className={"appTextColor loginTitle ml-4 "}>{t('ppuuserCreditPrice')}</h6>
                        </div>
                        <div className={(authInfo && (authInfo.ppuType === 2 && !authInfo.redo) ? 'd-block' : 'd-none')}>
                            <h6 className={"appTextColor loginTitle"}>{t('ppuuserSearchTitle')}</h6>
                            <h6 className={"appTextColor loginTitle ml-4 "}>{t('ppubundleCreditPrice')}</h6>
                        </div>
                        <Row className="mb-2">
                            <Col lg="12" md="12" sm='12' xs='12' className="p-0">
                                <CheckBox
                                    // checked={values.selected}
                                    color="primary"
                                    className={"float-left mx-2"}
                                    name="ackSearch"
                                    id="ackSearch"
                                    //onChange={() => { setDisableSearch(!disableSearch) }}
                                    onChange={() => handleCheckbox2()}
                                />
                                <Typography className={"float-left mt-1 w-75"}>
                                    {t('ackAntibodySearch')}
                                </Typography>
                            </Col>
                        </Row>
                        <div className={(authInfo && (credits <=2 ) && (authInfo.ppuType === 2 && !authInfo.redo) ? 'd-block' : 'd-none')}>
                            <h6 style= {{color: 'red'}}>You do not have enough credit balance to run the search.<p>{t('pleaseContactText1')} <a href={"mailto:" + supportMail}>{supportMail}</a> {t('pleaseContactText2')}</p></h6>
                        </div>

                        <hr style={toggle ? {backgroundColor: '#dfdfdf'}: {backgroundColor: '#aea9a9'}}/>
                    </Col>
                    {/* <Col lg="12" md="12" className="p-0">
                        <Row>
                            <Col md='4' sm="4" xs="4" className="">
                                <CheckBox
                                    // defaultChecked
                                    color="primary"
                                    className={"float-left ml-2"}
                                    name="saveForm"
                                    id="saveForm"
                                    onChange={setFormValue}
                                    checked={saveFormValue}
                                />
                                <label className={"checkBoxContent" + " bodyText cursorPointer float-left mt-1 mx-2 ml-0 mr-3"} for="saveForm">{t("SaveFormForlaterUse")}</label>
                            </Col>
                            <Col md='6' sm="6" xs="6" >
                                <TextInput
                                    id="formName"
                                    name="formName"
                                    label='Name the form'
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                    fullWidth={true}
                                    disabled={!saveFormValue}
                                    value={formik.values.formName ? formik.values.formName : ""}
                                    error={saveFormValue && Boolean(formik.errors.formName)}
                                    helperText={saveFormValue && formik.errors.formName}
                                />
                            </Col>
                        </Row>
                        <hr />
                    </Col> */}

                    <Col lg="12" md="12" sm='12' xs='12' style={{ display: 'flex', flexDirection: 'row-reverse' }} className="float-right mb-3">
                        <Button variant="contained" className={!disableSearch ? 'cancelButtonDisable' : 'accountInfo'} type="submit" disabled={!disableSearch}>{t('Search')}</Button>&nbsp;
                        <Button variant="contained" color={'default'} className={classes.loginSubmitCancel} onClick={homePage} type="submit">{t('cancel')}</Button>
                    </Col>
                </Row>

            </form>}
            {/* <SearchPrompt searchModal={searchModal} /> */}
            <SeqVIModal
                show={searchModal}
                onMessage={t('searchSubmitted')}
                type="seqSearch"
                saveCallBack={closeSaveModal}
            />
        </div>

    )

}

export default SearchResultAntibody;
