import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import { makeStyles } from "@material-ui/core/styles";
import { url } from '../../reducers/url';
import { get, post } from '../../helpers/fetchServicesMethods';
import PubSub from 'pubsub-js';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

function showLoader() {
    PubSub.publish('msg', true);
}

function hideLoader() {
    PubSub.publish('msg', false);
}

const useStyles = makeStyles((theme) => ({
    mTName: {
        padding: '1px 0',
        width: '250px',
        borderRadius: '1px',
        borderColor: '#dcd7c0',
        borderWidth: '1px',
        outline: 'none',
        fontFamily: 'verdana, helvetica',
        fontSize: '12px'
    },
    mTDescription: {
        outline: 'none',
        width: '250px',
        height: '50px',
        borderColor: '#dcd7c0',
        fontFamily: 'verdana, helvetica',
        fontSize: '12px'
    },
    membersCSS: {
        fontFamily: 'verdana, helvetica',
        fontSize: '12px',
        padding: '1px 0px 0px 2px',
        color: 'black'
    },
    modalCSS: {
        size: 'lg',
    }
}));

async function getCreateManageTeamsData(id, history) {
    try {
        let createManageTeams = url.createManageTeams
        showLoader();
        return await get(createManageTeams, history)
            .then((response) => {
                hideLoader();
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
                hideLoader();
            });
    } catch (error) {
        hideLoader();
        console.error(error);
    }

}

async function createNewManageTeamData(ids, description, members, props, history) {
    try {
        let newManageTeamData = url.newManageTeamData;
        const encodedName = encodeURIComponent(ids);
        const encodedDescription = encodeURIComponent(description);
        const encodedMembers = encodeURIComponent(members);
        newManageTeamData = newManageTeamData.replace('**', encodedName);
        newManageTeamData = newManageTeamData.replace('$$', encodedDescription);
        newManageTeamData = newManageTeamData.replace('%%', encodedMembers);

        let urlParam = newManageTeamData;
        showLoader();
        return await post(urlParam, null, history)
            .then((response) => {
                hideLoader();
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
                hideLoader();
            });
    } catch (error) {
        toast.error(error.response_content.message);
        hideLoader();
        console.error(error);
    }
}

const ManageTeamsCreateModal = (props) => {
    const classes = useStyles();
    const [usersData, setUsersData] = useState([]);
    const [groupsData, setGroupsData] = useState([]);
    const [seatTypesData, setSeatTypesData] = useState([]);
    const [inputText, setInputText] = useState("");
    const [textArea, setTextArea] = useState("");
    const [userCheck, setUserCheck] = useState([]);
    const [groupsCheck, setGroupsCheck] = useState([]);
    const [seatTypeCheck, setSeatTypeCheck] = useState([]);

    const userInfo = useSelector((state) => state.setUserInfo);
    const toggle = useSelector(state => state.setToggle.toggle);

    function sortForMembers(a, b, property) {
        const nameA = a[property];
        const nameB = b[property];
        return nameA.localeCompare(nameB, 'en', { sensitivity: 'accent' });

        /*
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        // If lowercase names are equal, prioritize more capital letters
        const countCapitalLettersA = a[property].replace(/[^A-Z]/g, '').length;
        const countCapitalLettersB = b[property].replace(/[^A-Z]/g, '').length;
    
        return countCapitalLettersB - countCapitalLettersA;*/
    }
    

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                const result = await getCreateManageTeamsData();
                const userValuesArray = Object.values(result.response_content.user_candidates)
                    .sort((a, b) => sortForMembers(a, b, 'full_name'));

                setUsersData(userValuesArray);
            
                const groupValuesArray = Object.values(result.response_content.group_candidates)
                .sort((a, b) => sortForMembers(a, b, 'text_label'));

                setGroupsData(groupValuesArray);

                const seatValuesArray = Object.values(result.response_content.seat_candidates)
                .sort((a, b) => sortForMembers(a, b, 'text_label'));
                
                setSeatTypesData(seatValuesArray);

            } catch (error) {
                console.error(error);
            }
        }
        fetchMyAPI();
    }, []);

    const closeCreatePopup = (props) => {
        props.handleCloseButton(false);
    }
    const handleChange = (e) => {
        setInputText(e.target.value);
    };

    const handleTextAreaChange = (e) => {
        setTextArea(e.target.value);
    };

    const handleUserCheck = (e, userId) => {
        const updatedUsersData = usersData.map((user) => {
            if (user.id === userId) {
                return { ...user, checked: e.target.checked };
            }
            return user;
        });
        setUserCheck(updatedUsersData.filter((user) => user.checked));
        setUsersData(updatedUsersData);
    };

    const handleGroupsCheck = (e, groupId) => {
        const updatedGroupsData = groupsData.map((group) => {
            if (group.id === groupId) {
                return { ...group, checked: e.target.checked };
            }
            return group;
        });
        setGroupsCheck(updatedGroupsData.filter((group) => group.checked));
        setGroupsData(updatedGroupsData);
    }

    const handleSeatTypeCheck = (e, seatTypeId) => {
        const updatedSeatTypeData = seatTypesData.map((seatType) => {
            if (seatType.id === seatTypeId) {
                return { ...seatType, checked: e.target.checked };
            }
            return seatType;
        });
        setSeatTypeCheck(updatedSeatTypeData.filter((seatType) => seatType.checked));
        setSeatTypesData(updatedSeatTypeData);
    }

    async function onClickOkButton(props) {
        if (inputText === '') {
            toast.error("illegal team name []");
        }
        else if (inputText.length > 64) {
            toast.error("Team name cannot exceed 64 characters.");
        }
        else if (textArea.length > 255) {
            toast.error("Team Description cannot exceed 255 characters.");
        }
        else {
            const users = userCheck.map(user => user.gq_user_id);
            const groups = groupsCheck.map(group => group.id);
            const seatTypes = seatTypeCheck.map(seatType => seatType.id);
            const combinedMembers = [...new Set([...users, ...groups, ...seatTypes])];

            const result = await createNewManageTeamData(inputText, textArea, combinedMembers, props, userCheck);
            console.log(result);
            toast.success("Created Successfully");
            closeCreatePopup(props);
            props.afterCloseModal(result);

        }
    }
    return (
        <Modal
            size={(userInfo.current_user.is_admin_class) ? 'lg' : 'sm'}
            aria-labelledby="contained-modal-title-vcente"
            centered
            show={props.showModal}
            contentClassName={(userInfo.current_user.is_admin_class) ? 'modalContent' : 'modalSizeforSm'}
        >
            <div style = {toggle ? { backgroundColor: ''}: {backgroundColor: '#292929'}}>
                <div className="titlebar">
                    <span style={{ padding: '0 1px', fontSize: '14px', fontFamily: 'verdana, helvetica' }}>Create team</span>
                    <Link className='pull-right' href="#" onClick={(e) => e.preventDefault()} >
                        <CloseIcon style={{ fill: '#b5b5b5' }} onClick={() => closeCreatePopup(props)} />
                    </Link>
                </div>
                <div style={{ borderTop: '1px solid #b5b5b5', padding: '10px 8px' }}>
                    <table className='formLayout'>
                        <tbody>
                            <tr>
                                <td width="50">
                                    <label className='teamsLabel'>Name</label>
                                </td>
                                <td>
                                    <input className={classes.mTName} type="text" onChange={handleChange} value={inputText}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label className='teamsLabel'>Description </label>
                                </td>
                                <td>
                                    <textarea className={classes.mTDescription} onChange={handleTextAreaChange} value={textArea}></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label className='teamsLabel'>Members</label>
                                </td>
                                <td>
                                    <table className='formlayout'>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <label className='teamsLabel'>Users</label>
                                                </td>
                                                <div />
                                                {userInfo.current_user.is_admin_class && <td>
                                                    <label className='teamsLabel'>Groups</label>
                                                </td>}
                                                <div />
                                                {userInfo.current_user.is_admin_class && <td>
                                                    <label className='teamsLabel'>Seat Type</label>
                                                </td>}
                                            </tr>
                                            <tr>
                                                <td className='innerTables' style={{ maxWidth: '250px' }}>
                                                    <div style={{ minHeight: '100px', maxHeight: '140px', overflowY: 'auto', whiteSpace: 'nowrap' }}>
                                                        {usersData.map((user, index) => (
                                                            <div className='membersStyle' key={index}>
                                                                <label style={{ display: 'contents', color: 'black' }}>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={user.checked || false}
                                                                        onChange={(e) => handleUserCheck(e, user.gq_user_id)}
                                                                    />
                                                                    <div className={classes.membersCSS}>
                                                                        {user.full_name} &lt;{user.login_name}&gt;
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </td>
                                                <div style={{ marginLeft: '12px' }} />
                                                {userInfo.current_user.is_admin_class && <td className='innerTables' style={{ maxWidth: '157px' }}>
                                                    <div style={{ minHeight: '100px', maxHeight: '140px', overflowY: 'auto', whiteSpace: 'nowrap' }}>
                                                        {groupsData.map((group, index) => (
                                                            <div className='membersStyle' key={index}>
                                                                <label style={{ display: 'contents', color: 'black' }}>
                                                                    <input
                                                                        type="Checkbox"
                                                                        value={group.checked || false}
                                                                        onChange={(e) => handleGroupsCheck(e, group.id)}
                                                                    />
                                                                    <div className={classes.membersCSS}>
                                                                        {group.text_label}
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </td>}
                                                <div style={{ marginLeft: '12px' }} />
                                                {userInfo.current_user.is_admin_class && <td className='innerTables' style={{ maxWidth: '157px' }}>
                                                    <div style={{ minHeight: '100px', maxHeight: '140px', overflowY: 'auto', whiteSpace: 'nowrap' }}>
                                                        {seatTypesData.map((seat, index) => (
                                                            <div className='membersStyle' key={index}>
                                                                <label style={{ display: 'contents', color: 'black' }}>
                                                                    <input
                                                                        type="Checkbox"
                                                                        value={seat.checked || false}
                                                                        onChange={(e) => handleSeatTypeCheck(e, seat.id)}
                                                                    />
                                                                    <div className={classes.membersCSS}>
                                                                        {seat.def_id}
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </td>}
                                                <div style={{ marginLeft: '12px' }} />
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ float: 'right' }}>
                    <button className='DMTBtn' onClick={() => onClickOkButton(props)} >Ok</button>
                    <button className='DMTBtn' onClick={() => closeCreatePopup(props)} >Cancel</button>
                </div>
            </div>
        </Modal>
    );
}
export default ManageTeamsCreateModal;
