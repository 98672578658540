import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .Mui-error": {
            fontStyle: 'italic'
        },
        "& .MuiFormHelperText-root": {
            fontStyle: 'italic'
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: "7px",
            backgroundColor: "white",
            '&.Mui-focused fieldset': {
                borderColor: '#337AB7',
            },
        },
        "&::placeholder": {
            fontFamily: 'Arial, Helvetica, sans-serif ,Helvetica Neue',
            fontSize: '14px',
            color: '#D3D3D3',
            fontWeight: '400',
            fontStyle: 'italic',
        }

    },
    rootDark:{
        "& .Mui-error": {
            fontStyle: 'italic'
        },
        "& .MuiFormHelperText-root": {
            fontStyle: 'italic'
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: "7px",
            // Border and box shadow for the default state
            border: '1px solid grey',
            boxShadow: 'none',
            '&.Mui-focused': {
                //Styles when the input is focused
                borderColor: 'grey',
                boxShadow: '0 0 5px white',
            },
            '& fieldset': {
                // Border style for the fieldset when focused
                borderColor: 'grey',
            },
        },
        "&::placeholder": {
            fontFamily: 'Arial, Helvetica, sans-serif ,Helvetica Neue',
            fontSize: '14px',
            color: 'white',
            fontWeight: '400',
            fontStyle: 'italic',
            borderColor: 'white',
            boxShadow: '0 0 5px white'
        }

    }, 
    materialUILabel: {
        fontFamily: 'Arial, Helvetica, sans-serif ,Helvetica Neue',
        fontSize: '14px !important',
        //color: '#f7f7f7',
        fontWeight: '400',
        fontStyle: 'italic',
        lineHeight: '2px',
    },
    smallText: {
        "& .MuiFormHelperText-root.Mui-error": {
            fontStyle: 'italic',
            width: "184px"
        }
    }
}));

export default function TextInput(props) {
    const classes = useStyles();
    const toggle = useSelector(state => state.setToggle.toggle);
    return (
        <TextField
            spellCheck={props.spellcheck}
            fullWidth={props.fullWidth}
            fontSize={'14px'}
            id={props.id}
            name={props.name}
            type={props.type}
            placeholder={props.label}
            variant={props.variant}
            value={props.value}
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            className={(toggle ? classes.root : classes.rootDark) + ' ' + props.className + ' ' + (props.fullWidth ? "" : classes.smallText)}
            error={props.error}
            helperText={props.helperText}
            InputLabelProps={{
                classes: (toggle ? { root: classes.materialUILabel } : {rootDark : classes.materialUILabel }),
            }}
            InputProps={props.InputProps}
            inputProps={props.inputProps}
            multiline={props.multiline}
            rows={props.rows}
            rowsMax={props.rowsMax}
            disabled={props.disabled}
            min={props.min}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            inputRef={props.inputRef}
            autoComplete="off"
            autoFocus={props.autoFocus}
            defaultValue={props.defaultValue}
        />
    );
}
