import { createDraftSafeSelector } from "@reduxjs/toolkit";
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

//import "../TableStyles.css"

function CdrMatches(props){

    const {resid, reportName} = useParams();

    let { CdrCombos, Switch, CdrIndex, collName} = props

    const [thisData, setThisData] = useState({});
    const [cdrType, setCdrType] = useState("LC");
    const cdrSet = {
        0:[
            [1,1,1],
            [0,1,1],
            [1,0,1],
            [1,1,0]
        ],
        1:[
            [2,1,1],
            [1,2,1],
            [1,1,2],
            [0,2,1],
            [0,1,2],
            [2,0,1],
            [1,0,2],
            [2,1,0],
            [1,2,0]
        ],
        2:[
            [2,2,1],
            [1,2,2],
            [2,1,2],
            [0,2,2],
            [2,0,2],
            [2,2,0]
        ],
        3:[
            [2,2,2]
        ]
    
    }
    const [cdrSetType, setCdrSetType] = useState(cdrSet[0]);

    
    const mitchesCount ={
        0: ['PERFECT', 'MATCHES'],
        1: ['ONE', 'MISMATCHES'],
        2: ['TWO', 'MISMATCHES'],
        3: ['THREE', 'MISMATCHES']

    }
    const cdrMap = {
        0:'-',
        1:'M',
        2:'X'
    }
    const getSwitch = () =>{
        let testVar = Switch 

        if(testVar != null && testVar != ""){
            return false;
        }

        return true;
    }
    const getCdrIndex = () =>{
        let testVar = CdrIndex 

        if(testVar != null && testVar != ""){
            return cdrSet[testVar];
        }

        return cdrSet[0];
    }

    useEffect(async() => {        
        if(getSwitch()){
            setCdrType("HC")
        }

        setCdrSetType(getCdrIndex());
        
    }, [props.CdrCombos, props.Switch, props.CdrIndex])


    const applyCDRCombinationsFilter = (matchStr, orderStr) => {
        
        var abReportFilters = {
            groupBy:'ssv', 
            cdrCombinationsOrder: orderStr,
            cdrCombinationsString: matchStr.replaceAll("1","M").replaceAll("0","-").replaceAll("2","X"),
            cdrCombinations: fixStrings([cdrType+"-CDR1", cdrType+"-CDR2", cdrType+"-CDR3"]),
            reportName: decodeURIComponent(reportName) == "undefined"? "Unfiltered_Entire_Report": decodeURIComponent(reportName)
        }

        return encodeURIComponent(JSON.stringify(abReportFilters))
    }


    function fixStrings(cdrType){
        for(var i=0; i< cdrType.length; i++){
            cdrType[i] = cdrType[i].toUpperCase();
            cdrType[i] = cdrType[i].replaceAll('-','_');
        }
        return cdrType;
    }

    function findSet(cdrSetTypeIndex){
        let cdrSetRowReMapped = remap(cdrSetTypeIndex);

        if(CdrCombos != undefined){
            if(CdrCombos[cdrSetRowReMapped] != null){
                return CdrCombos[cdrSetRowReMapped];
            }
        }

        return null;
    }

    function remap(cdrSetRow){
        let cdrString ="";
        cdrString+=cdrMap[cdrSetRow[0]];
        cdrString+=cdrMap[cdrSetRow[1]];
        cdrString+=cdrMap[cdrSetRow[2]];

        return cdrString;
    }

	return (
        <div class="tableFrame">
            <p class="textFlat pCenter paddingTitle"><strong>SEQUENCES WITH <span>{mitchesCount[CdrIndex][0]}</span> CDR <span>{mitchesCount[CdrIndex][1]}</span></strong></p>
        <table class="tabler MarginBottom15" width={"98%"}>
            <tr>
                <th class="LcCdrUnderStyle">
                    <span class="LcWordSytle font14">{cdrType}-CDR1</span>
                </th>
                <th class="LcCdrUnderStyle">
                    <span class="LcWordSytle font14">{cdrType}-CDR2</span>
                </th>
                <th class="LcCdrUnderStyle">
                    <span class="LcWordSytle font14">{cdrType}-CDR3</span>
                </th>
                <th class="tablerb textFlatCenter">
                    <span class="LcLineStyle padding5 font14">Correct Order</span>
                </th>
                <th class="tablerb textFlatCenter">
                    <span class="LcLineStyle padding5 font14">Any Order</span>
                </th>
            </tr>

        {cdrSetType !=null && cdrSetType.map((value, index) => {

            let rowKey = findSet(cdrSetType[index]);

            return(
            <tr key={index}>
                <td class="matchContainer LcBorder">
                    <span class={cdrType.toLowerCase()+"Square"+cdrSetType[index][0]}>
                        {cdrSetType[index][0] == 2 &&
                            <p class="x textFlatCenter pCenter">X</p>
                        }
                    </span>
                </td>
                <td class="matchContainer LcBorder">
                    <hr class="line"></hr>
                    <span class={cdrType.toLowerCase()+"Square"+cdrSetType[index][1]}>
                        {cdrSetType[index][1] == 2 &&
                            <p class="x textFlatCenter pCenter">X</p>
                        }    
                    </span>
                </td>
                <td class="matchContainer LcBorder">
                    <span class={cdrType.toLowerCase()+"Square"+cdrSetType[index][2]}>
                        {cdrSetType[index][2] == 2 &&
                            <p class="x textFlatCenter pCenter">X</p>
                        }
                    </span>
                </td>
                <td class="tablerb LcBoder2 textFlatCenter">
                    <Link 
                    className={rowKey==null? "disabled":"textFlatCenter"}
                    target="_blank"
                    to={{
                        pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyCDRCombinationsFilter(cdrSetType[index][0].toString()+cdrSetType[index][1].toString()+cdrSetType[index][2].toString(),'Correct') + "/" + collName,
                    }}>{rowKey!=null? rowKey.CorrectOrder : "-"}</Link>
                    {/* <a className={rowKey==null? "disabled":"textFlatCenter"} onClick={()=>{applyCDRCombinationsFilter([cdrType+"-CDR1", cdrType+"-CDR2", cdrType+"-CDR3"], cdrSetType[index][0].toString()+cdrSetType[index][1].toString()+cdrSetType[index][2].toString(),'Correct')}}>{rowKey!=null? rowKey.CorrectOrder : "-"}</a> */}
                </td>
                <td class="tablerb LcBoder2 textFlatCenter">
                    <Link 
                    className={rowKey==null? "disabled":"textFlatCenter"}
                    target="_blank"
                    to={{
                        pathname: '/discover/wf:'+resid+".resdb/antibody/"+applyCDRCombinationsFilter(cdrSetType[index][0].toString()+cdrSetType[index][1].toString()+cdrSetType[index][2].toString(),'Any') + "/" + collName,
                    }}>{rowKey!=null? rowKey.AnyOrder : "-"}</Link>
                    {/* <a className={rowKey==null? "disabled":"textFlatCenter"} onClick={()=>{applyCDRCombinationsFilter([cdrType+"-CDR1", cdrType+"-CDR2", cdrType+"-CDR3"], cdrSetType[index][0].toString()+cdrSetType[index][1].toString()+cdrSetType[index][2].toString(),'Any')}}>{rowKey!=null? rowKey.AnyOrder : "-"}</a> */}
                </td>
            </tr>
            )
        })
        }
        </table>
    </div>       
    );
}

export default CdrMatches;
