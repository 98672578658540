import React, {useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import {Col} from "react-bootstrap";
import ResultTable from "../../components/Discover/ResultTable";
import CustomPagination from "../CustomPagination";
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    modalClassConDSI: {
        //position: 'absolute',
        //width: '96%',
        padding: '15px 15px 10px',
        //right: 'auto',
        //bottom: 'auto',
        minHeight: '800px',
        maxHeight: '800px',
        overflow: 'scroll',
    },
    modalClassConDSIDark: {
        padding: '15px 15px 10px',
        //right: 'auto',
        //bottom: 'auto',
        minHeight: '800px',
        maxHeight: '800px',
        overflow: 'scroll',
        backgroundColor: '#292929'
    },
    closeBtn: {
        position: 'fixed',
        right: '10px',
        bottom: '10px'
    }
}));

const DiscoverMoreItemsModal = props => {
    const classes = useStyles();
    const ref = useRef();
    const toggle = useSelector(state => state.setToggle.toggle);
    const changePage = (e, page) => {
        props.changePage(e, page, props.fieldConfig, props.filter, props.groupValue1, props.groupValue2);
        ref.current?.scrollIntoView({block: 'nearest'});
    }

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcente"
            centered
            show={props.showModal}
            contentClassName={toggle ? classes.modalClassConDSI : classes.modalClassConDSIDark}
        >
            <div className="large-font" style={{padding: '10px 20px 0 20px'}} ref={ref}>{
                props.searchResultData.itemsStats ? props.searchResultData.itemsStats.count.ali : 0
            } Alignments
                <Link href="#" onClick={(e) => e.preventDefault()} className="pull-right"
                      > {/*style={{position: 'fixed', left: '1000px'}}*/}
                    <CloseIcon onClick={props.closeModal}/>
                </Link>
            </div>
            <Col>
                <ResultTable
                    columns={props.searchResultData.columns}
                    data={props.searchResultData}
                    displayMode={props.searchResultData.displayMode}
                    tableWidth={props.searchResultData.tableWidth}
                    fieldConfig={props.fieldConfig}
                    annotState={props.annotState}
                    filter={props.filter}
                    expandAllDetailPage={props.expandAllDetailPage}
                    expandAllClaims={props.expandAllClaims}
                    expandAllAlignments={props.expandAllAlignments}
                    fromItemsPage={true}
                    groupValue1={props.groupValue1}
                    groupValue2={props.groupValue2}
                    saveAnnotation={props.saveAnnotation}
                    parentAnnotState={props.parentAnnotState}
                    refreshAnnotState={props.refreshAnnotState}
                    algorithm={props.algorithm}
                    isAbWorkflow={props.isAbWorkflow}
                />

                <Col
                    className={'d-flex justify-content-center' + ((props.searchResultData.data && Object.keys(props.searchResultData.data).length) > 0 ? ' d-block' : ' d-none')}
                    md="12">
                    <CustomPagination className={"float-right mt-2"} count={10} changePage={changePage}
                                      recordPerPage={50}
                                      showFirstButton showLastButton defaultPage={1} page={props.currentPage}
                                      totalPages={props.totalPage}/>
                </Col>
            </Col>
            <div>
                <button onClick={props.closeModal} className={'pull-right large-button gqblue'}>Close</button>
            </div>
        </Modal>
    );
}

export default DiscoverMoreItemsModal;
