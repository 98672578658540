import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";


const useStyles = makeStyles((theme) => ({
  textField: {
    // width: '194px',
    width: 'auto',
    minWidth: '250px',
    padding: '5px 0px',
    margin: '-8px 0 0 -5px',
  },
  dense: {
    marginTop: theme.spacing(2),
    //boxShadow: '0 0 5px white'
  },
  menu: {
    width: 'auto',
    top: '150px',
  },
  dropdownIconWheat: {
    color: "wheat", 
  },
  dropdownIconBlack: {
    color: "rgb(0 0 0 / 54%)",
  },
  smallBox: {
    width: '14%',
    padding: '5px 0px',
    margin: '-8px 0 0 -5px',
    '& .MuiSelect-iconOutlined': {
      right: '0px'
    }
  },
  whiteText: {
    color: 'white',
  },
  input: {
    color: 'wheat',  // Color for the input text
  },
  outlinedWhite: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      // '&:hover fieldset': {
      //   borderColor: 'white',
      // },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
  noPadding: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  }

}));

export default function DropDownBox(props) {
  const classes = useStyles();
  const toggle = useSelector(state => state.setToggle.toggle);
  const items = props.items;
  return (
    <TextField
      id={props.id}
      name={props.name}
      select
      label={props.label ? props.label: ""}
      className={`${props.className} ${props.smallSelectBox ? classes.smallBox : classes.textField} ${toggle ? '' : classes.outlinedWhite}`}
      value={props.value}
      InputLabelProps={{ shrink: true }}
      SelectProps={{
         classes: {         
            icon: toggle ? classes.dropdownIconBlack : classes.dropdownIconWheat, 
          },
        MenuProps: {
          className: (props.smallBox ? "" : classes.menu),
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null,
          classes: {
            list: classes.noPadding,  // Apply noPadding class to remove padding
          }
        }
      }}
      margin={props.margin}
      variant={props.variant}
      onChange={props.onChange}
      disabled={props.disabled}
      onBlur={props.onBlur}
    >
      {items && items.length > 0 && items.map((option) => (
        <MenuItem key={option.value} value={option.value} style={toggle ? {color: '#292929',backgroundColor:'#ffffff'}: {color: '#ffffff',backgroundColor:'#292929'}}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
    // </form>
  );
}
