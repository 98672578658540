import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { AccessAlarm } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import account from '../../assets/image/account.png';
import docsearch from '../../assets/image/docsearch.png';
import searchResultImg from '../../assets/image/searchResult.png';
import seqDb from '../../assets/image/seqDb.png';
import seqSearch from '../../assets/image/seqSearch.png';
import seqTool from '../../assets/image/seqTool.png';
import { url } from '../../reducers/url';
import { useSelector } from 'react-redux';
import SavedSearch from '../../services/savedsearch';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        width: '96%',
        margin: '30px auto',
        minHeight: '360px',
        borderBottom: '1px solid #cec7c7'
    },
    savedForm: {
        backgroundColor: '#f5f2f2',
        // paddingLeft: "30px",
        // paddingTop: "34px",
        padding: '30px',
        height: '100%'
    },
    savedFormDark: {
        backgroundColor: '#56545473',
        // paddingLeft: "30px",
        // paddingTop: "34px",
        padding: '30px',
        height: '100%'
    },
    anchorTag: {
        // textDecoration: 'none !important',
        // color: "#008EC5",
        // fontSize:"15px",
        // cursor: 'pointer',

        // width: 80px;
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'block',
        textOverflow: 'ellipsis'
        // line-height: 55px;
    },
    p: {
        color: "#008EC5",
        size: "20px"
    },
    textHeading: {
        fontWeight: "700 !important",
        marginBottom: "5px"
    },
    columnPadding: {
        paddingTop: '5px',
        paddingLeft: '20px'
    },
    pTagMargin: {
        marginBottom: "0px",
        // fontWeight: "500",
        // '& span':{
        //     color: "#5A6868",
        // }
    },
    applicationPanelRow: {
        marginBottom: "15px",
        minHeight: '70px'
    },
    appIcon: {
        fontSize: '50px',
        color: '#5A6868'
    }
}));

function BundleShareHome() {
    const { t, i18n } = useTranslation('common');
    const history = useHistory();
    const [searchFormsData, setSearchFormsData] = useState([]);

    const classes = useStyles();
    const userInfo = useSelector(state => state.setUserInfo);
    const toggle = useSelector(state => state.setToggle.toggle);
    const [userData, setUserData] = useState();
    const videoUrl = 'https://share.vidyard.com/watch/yykhiY9yu4btXRTh1VCTfM?'; // Your video link
    const thumbnailUrl = 'https://cdn.vidyard.com/thumbnails/24542653/8TgetIe4usU8ALwbrrKFlywekRpO2kIu.jpg'; 

    const overlayStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)', 
        backgroundColor: 'rgba(0, 0, 0, 0.5)', 
        borderRadius: '50%', 
        padding: '10px', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      };
    
      const playIconStyle = {
        color: 'white', 
        fontSize: '24px', 
        fontWeight: 'bold', 
      };

      const containerStyle = {
        position: 'relative',
        display: 'inline-block',
        cursor: 'pointer', 
      };
    
      const imageStyle = {
        width: '90%',
        height: 'auto',
        display: 'block', 
      };

    const VideoLinkWithThumbnail = ({ videoUrl, thumbnailUrl }) => {
        return (
          <a href={videoUrl} target="_blank" rel="noopener noreferrer">
            <img src={thumbnailUrl} alt="Video Thumbnail" style={imageStyle}  />
            <div style={overlayStyle}>
                 <span style={playIconStyle}>▶</span>
             </div>
          </a>
        );
      };

    // reset login status
    useEffect(() => {
        (async () => {
            const result = await SavedSearch.getSavedSearchData();
            if (result && result.response_content && result.response_content.templates) {
                const dta = await result.response_content.templates;
                setSearchFormsData(dta);
            }
        })()
        if (userInfo && userInfo.current_user) {
            setUserData(userInfo);
        }
    }, [userInfo]);
    function showString(str) {
        let getLength = str.length;
        if (getLength > 23) {
            str = str.substring(0, 23) + ' ...';
        }
        return str;

    }
    function accountInfoForm() {
        history.push('/accountsettings')
    }
    function changePasswordForm() {
        history.push('/changepassword')
    }
    function searchForm() {
        history.push('/search')

    }



    return (
        <div className={classes.grow}>
            {/* <Container className="p-0 m-5"> */}
            <Row>
             
                <Col >
                <Fragment>
                <p className={'subHeading ' + classes.textHeading}> It looks like you do not have access to all the features of GenomeQuest.</p>
                <p style={{ fontSize: '15px', margin: '0px' }}> Please email <a href="support@gqlifesciences.com" onClick={(e) => e.preventDefault()}>support@gqlifesciences.com</a> if you have any questions about the product.</p>
                <p style={{ fontSize: '15px' }}>  We are here to support your sequence searching needs.</p>
                
                </Fragment>
                </Col>

            </Row>
            <Row>
                <Col lg="2" md="2" sm="2" xs='2' className="pr-0">
                <Row className={classes.applicationPanelRow}></Row> 
                </Col>
            </Row>
            <Row>
            <Col md="4" sm="4" xs='4' className={classes.columnPadding}>
                    <p className={'subHeading ' + classes.textHeading}>Shared Results</p>
                    <Row className={classes.applicationPanelRow}>
                        <Col lg="2" md="2" sm="2" xs='2' className="pr-0">
                            <img src={searchResultImg} alt="searchResult" className="w-100" />
                        </Col>
                        <Col lg="10" md="10" sm="10" xs='10'>
                            <p className={classes.pTagMargin}><Link className="appLink" to='/searchResult'>Shared Results</Link></p>
                        </Col>
                    </Row>
                    <p className={'subHeading ' + classes.textHeading}>Overview of GenomeQuest</p>
                   <br></br>
                    <Row className={classes.applicationPanelRow}>
                        <Col >
                             <VideoLinkWithThumbnail videoUrl={videoUrl} thumbnailUrl={thumbnailUrl} />
                        </Col>
                    </Row>
            </Col>
                <Col md="4" sm="4" xs='4' className={classes.columnPadding}>
                    <p className={'subHeading ' + classes.textHeading}>{t('myAccount')}</p>
                    <Row className={classes.applicationPanelRow}>
                        <Col lg="2" md="2" sm="2" xs='2' className="pr-0">
                            <img src={account} alt="account" className="w-100" />
                        </Col>
                        <Col lg="10" md="10" sm="10" xs='10'>
                            <p className={classes.pTagMargin}><a onClick={accountInfoForm}>{t('accountInformation')}</a></p>
                            <p className={classes.pTagMargin}><a className={classes.anchorTag + ' appLink'} onClick={changePasswordForm}>{t('changePassword')}</a></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* </Container> */}

        </div>

    );
}

export default BundleShareHome;
