import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from '../../helpers/fetchServicesMethods';
import PubSub from 'pubsub-js';
import { url } from '../../reducers/url';

function showLoader() {
    PubSub.publish('msg', true);
}

function hideLoader() {
    PubSub.publish('msg', false);
}

const useStyles = makeStyles((theme) => ({
    EditModal:{
        height: '353.091px',
        background: '#bee5f2',
        marginRight: '12px',
        marginBottom: '12px',
        marginLeft: '5px',
        borderLeft: '1px solid #dcd7c0',
        borderRight: '1px solid #dcd7c0',
        borderBottom: '1px solid #dcd7c0',
        // borderTop: '1px solid #dcd7c0'
    },
    paraStyle:{
        fontSize: '12px', 
        fontFamily: 'verdana, helvetica',
        padding: '5px'
    },
    memberStyle:{
        minHeight : '100px',
        maxHeight: '125px',
        overflowY: 'auto',
        fontFamily: 'verdana, helvetica',
        fontSize: '12px',
        padding: '1px 15px 3px 3px',
        whiteSpace: 'nowrap'
    },
    nameStyle: {
        padding: '1px 0',
        width: '250px',
        borderRadius: '1px',
        borderColor: '#dcd7c0',
        borderWidth: '1px',
        outline: 'none',
        fontFamily: 'verdana, helvetica',
        fontSize: '12px'
    },
    descStyle: {
        outline: 'none',
        width: '250px',
        height: '50px',
        borderColor: '#dcd7c0',
        fontFamily: 'verdana, helvetica',
        fontSize: '12px'
    },
    membersCSS: {
        fontFamily: 'verdana, helvetica',
        fontSize: '12px',
        padding: '1px 0px 0px 2px', 
        color: 'black'
    },
}));
async function EditManageTeamData(name, description,id, members, props, history) {
    try {
        let editManageTeamData = url.editManageTeamData;
        const encodedName = encodeURIComponent(name);
        const encodedDescription = encodeURIComponent(description);
        const encodedId = encodeURIComponent(id);
        const encodedMembers = encodeURIComponent(members);
        editManageTeamData = editManageTeamData.replace('**', encodedName);
        editManageTeamData = editManageTeamData.replace('$$', encodedDescription);
        editManageTeamData = editManageTeamData.replace('##', encodedId);
        editManageTeamData = editManageTeamData.replace('%%', encodedMembers);

        let urlParam = editManageTeamData;
        showLoader();
        return await get(urlParam, history)
            .then((response) => {
                hideLoader();
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
                hideLoader();
            });
    } catch (error) {
        toast.error(error.response_content.message);
        hideLoader();
        console.error(error);
    }
}

function ManageTeamEdit(props){
    const classes = useStyles();
    const [candidateList, setCandidateList] = useState(props.teamsDetails.candidate_list);
    const candidateArray = candidateList ? Object.values(candidateList) : [];

    const [memberList, setMemberList] = useState(props.teamsDetails.member_list);
    const memberArray = memberList ? Object.values(memberList) : [];

    const [editName, setEditName] = useState(props.teamsDetails.text_label);
    const [editDescription, setEditDescription] = useState(props.teamsDetails.description);
    const userInfo = useSelector((state) => state.setUserInfo);
    const usersInfo = candidateArray.filter(candidate => candidate.class_name === "user");

    const groupsInfo = candidateArray.filter(candidate => candidate.class_name === "group");
    const seatTypeInfo = candidateArray.filter(candidate => candidate.class_name === "seat type");

    const [userCheck, setUserCheck] = useState([]);
    const [groupsCheck, setGroupsCheck] = useState([]);
    const [seatTypeCheck, setSeatTypeCheck] = useState([]);
    const [sortedUsersInfo, setSortedUsersInfo] = useState([]);
    const [sortedGroupsInfo, setSortedGroupsInfo] = useState([]);
    const [sortedSeatTypeInfo, setSortedSeatTypeInfo] = useState([]);

    function sortForMembers(a, b, property) {
        const nameA = a[property];
        const nameB = b[property];
        return nameA.localeCompare(nameB, 'en', { sensitivity: 'accent' });

        /*
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        // If lowercase names are equal, prioritize more capital letters
        const countCapitalLettersA = a[property].replace(/[^A-Z]/g, '').length;
        const countCapitalLettersB = b[property].replace(/[^A-Z]/g, '').length;
    
        return countCapitalLettersB - countCapitalLettersA;*/
    }

    useEffect(() => {

        const sortedUsers = [...usersInfo].sort((a, b) => sortForMembers(a, b, 'full_name'));
        if (!arraysEqual(sortedUsers, sortedUsersInfo)) {
            setSortedUsersInfo(sortedUsers);
        }

        const sortedGroups = [...groupsInfo].sort((a, b) => sortForMembers(a, b, 'text_label'));
        if (!arraysEqual(sortedGroups, sortedGroupsInfo)) {
            setSortedGroupsInfo(sortedGroups);
        }

        const sortedSeatTypes = [...seatTypeInfo].sort((a, b) => sortForMembers(a, b, 'text_label'));
        if (!arraysEqual(sortedSeatTypes, sortedSeatTypeInfo)) {
            setSortedSeatTypeInfo(sortedSeatTypes);
        }
    }, [usersInfo, groupsInfo, seatTypeInfo]);
    

    const arraysEqual = (arr1, arr2) => JSON.stringify(arr1) === JSON.stringify(arr2);

    const handleNameChange = (e) => {
        props.teamsDetails.text_label = e.target.value;
        setEditName(e.target.value);
    };

    const handleTextAreaChange = (e) => {
        props.teamsDetails.description = e.target.value;
        setEditDescription(e.target.value);
    };
    const handleUserCheck = (e, userId) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setUserCheck([...userCheck, { gq_user_id: userId }]);
        } else {
            setUserCheck(userCheck.filter(user => user.gq_user_id !== userId));
            setMemberList(memberArray.filter(member => member.gq_user_id !== userId));
        }
    };
    
    const handleGroupsCheck = (e, groupId) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setGroupsCheck([...groupsCheck, { id: groupId }]);
        } else {
            setGroupsCheck(groupsCheck.filter(group => group.id !== groupId));
            setMemberList(memberArray.filter(member => member.id !== groupId));
        }
    };

    const handleSeatTypeCheck = (e, seatId) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSeatTypeCheck([...seatTypeCheck, { id: seatId }]);
        } else {
            setSeatTypeCheck(seatTypeCheck.filter(seat => seat.id !== seatId));
            setMemberList(memberArray.filter(member => member.id !== seatId));
        }
    }; 

    async function onSaveTeamDetails(props) {
        if (editName === '') {
            toast.error("illegal team name []");
        }
        else if(editName.length >64){
            toast.error("Team name cannot exceed 64 characters.");
        }
        else if (editDescription === null) {
            setEditDescription('');
        }
        else if ((editDescription && editDescription.length > 255)) {
            toast.error("Team Description cannot exceed 255 characters.");
        }
        else {
            const users = userCheck.map(user => user.gq_user_id);
            const groups = groupsCheck.map(group => group.id);
            const id = props.teamsDetails.gq_team_id;
            const seatTypes = seatTypeCheck.map(seatType => seatType.id);
            const previousData = memberArray.map(e => e.id);
            const combinedMembers = [...new Set([...users, ...groups, ...seatTypes, ...previousData])];

            const result = await EditManageTeamData(editName, editDescription, id,combinedMembers, props, userCheck);
            console.log('ok button: ',result);
            toast.success("Updated Successfully");
            props.afterEditTeam(result);

        }
    }
    return(
        <div className={classes.EditModal}>
            <div>
                <div style={{ padding: '10px 8px' }}>
                    <table className='formLayout'>
                        <tbody>
                            <tr>
                                <td width="50">
                                    <label className='label'>Name</label>
                                </td>
                                <td>
                                    <input className={classes.nameStyle} type="text" value={editName} onChange={handleNameChange}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label className='label'>Description </label>
                                </td>
                                <td>
                                    <textarea className={classes.descStyle} value={editDescription} onChange={handleTextAreaChange}></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label className='label'>Members</label>
                                </td>
                                <td>
                                    <table className='formlayout'>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <label className='label'>Users</label>
                                                </td>
                                                <div />
                                                {userInfo.current_user.is_admin_class &&
                                                    <td>
                                                        <label className='label'>Groups</label>
                                                    </td>}
                                                <div />
                                                {userInfo.current_user.is_admin_class &&
                                                <td>
                                                    <label className='label'>Seat Type</label>
                                                </td>}
                                            </tr>
                                            <tr>
                                                <td className='innerTables' style={{ maxWidth: '500px' }}>
                                                    <div className={classes.memberStyle} style={{ whiteSpace: 'nowrap' }} >
                                                        {sortedUsersInfo.map((user, index) => {
                                                            const matchingGroup = memberArray.find((member) => member.gq_user_id === user.gq_user_id);
                                                            return (
                                                                <div className='membersStyle' key={index}>
                                                                    <label style={{display: 'contents', color: 'black'}}>
                                                                        <input
                                                                            type="checkbox"
                                                                            defaultChecked={!!matchingGroup}
                                                                            onChange={(e) => handleUserCheck(e, user.gq_user_id)}
                                                                        />
                                                                        <div className={classes.membersCSS}>
                                                                            {user.full_name} &lt;{user.login_name}&gt;
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </td>
                                                <div style={{ marginLeft: '12px' }} />
                                                {userInfo.current_user.is_admin_class && <td className='innerTables' style={{ maxWidth: '250px'}}>
                                                    <div className={classes.memberStyle} style={{ whiteSpace: 'nowrap' }}>
                                                        {sortedGroupsInfo.map((group, index) => {
                                                            const matchingGroup = memberArray.find((member) => member.id === group.id);
                                                            return (
                                                                <div className='membersStyle' key={index}>
                                                                    <label style={{display: 'contents', color: 'black'}}>
                                                                        <input
                                                                            type="checkbox"
                                                                            defaultChecked={!!matchingGroup}
                                                                            onChange={(e) => handleGroupsCheck(e, group.id)}
                                                                        />                                                                    
                                                                    <div className={classes.membersCSS}>
                                                                        {group.text_label}
                                                                    </div>
                                                                    </label>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </td>}
                                                <div style={{ marginLeft: '12px' }} />
                                                {userInfo.current_user.is_admin_class && <td className='innerTables' style={{ maxWidth: '250px'}}>
                                                    <div className={classes.memberStyle} style={{ whiteSpace: 'nowrap' }}>
                                                        {sortedSeatTypeInfo.map((seat, index) => {
                                                            const matchingGroup = memberArray.find((member) => member.id === seat.id);
                                                            return (
                                                                <div className='membersStyle' key={index}>
                                                                    <label style={{ display: 'contents', color: 'black'}}>
                                                                        <input
                                                                            type="Checkbox"
                                                                            defaultChecked={!!matchingGroup}
                                                                            onChange={(e) => handleSeatTypeCheck(e, seat.id)}
                                                                        />
                                                                        <div className={classes.membersCSS}>
                                                                            {seat.def_id}
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </td>}
                                                <div style={{ marginLeft: '12px' }} />
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div >
                    <button className='DMTBtn' style= {{ marginLeft: '526px'}} onClick={() => onSaveTeamDetails(props)} >Ok</button>
                </div>
            </div>
        </div>
    );

}

export default ManageTeamEdit;
