import React, { useState, useEffect} from 'react';
import PatentNumber from './PatentNumber';
import GlobalSearch from "./GlobalSearch";
import { useSelector } from 'react-redux';

function PatentNumberSearch(){

    const Tabs = ["patentNumber", "global"]
    const [state, setState] = useState("patentNumber");
    const userInfo = useSelector(state => state.setUserInfo);
    
    useEffect(() =>{
    },[])

    function switchPage(pageName){
        setState(pageName);
    }

    return(
        <div>
            {userInfo.current_user.user_class_id != 12 &&
            <span>
            <div style= {{ marginTop: '-33px', marginLeft: '-5px' }}>
                <button className={state === "patentNumber" ? "inactiveTab" : "accountTabs"} onClick={()=>switchPage("patentNumber")}>Patent Number</button>
                <button className={state === "global" ? "inactiveTab" : "accountTabs"}  onClick={()=>switchPage("global")}>Global Search</button>
            </div>

            {state == "patentNumber" &&
            <div>
                <PatentNumber></PatentNumber>
            </div>
            }
            {state == "global" &&
            <div>
                <GlobalSearch></GlobalSearch>
            </div>
            }
            </span>}
        </div>
    );
}

export default PatentNumberSearch;
