import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';

const CustomRadio = withStyles({
    root: {
        color: props => (props.checked ? 'white' : 'grey'), // Default color
        '&$checked': {
            color: 'green', // Color when checked
        },
    },
    checked: {},
})(Radio);

export default function RadioButton(props) {
    const { value, name, checked, onChange, id, disabled } = props;

    return (
        <CustomRadio
            checked={checked}
            onChange={onChange}
            value={value}
            name={name}
            inputProps={{ 'aria-label': 'D' }}
            disabled={disabled}
            id={id}
        />
    );
}
