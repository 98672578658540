import React, { useState, useEffect, useRef } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory, useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Row, Col, Modal } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import utilsService, { TOAST_TYPE } from '../../helpers/utils'
import { useSelector } from 'react-redux'; 
import _ from "lodash";


import TextInput from '../../shared/Fields/TextInput';
import Constant from '../../helpers/constant';
import AccountInfo from '../../services/accountInfo';
import { BsWindowSidebar } from 'react-icons/bs';





const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        width: '96%',
        margin: '0 auto 28px',
        minHeight: '260px',
        // borderBottom: '1px solid #cec7c7',
        padding: '23px 16px 14px',
    },
    headerPipe: {
        margin: '0 10px'
    },
    searchInput: {
        width: '30%'
    },
    buttonStyleCancel: {
        //float: 'right',
        textTransform: 'none',
        margin: '4px',
        color: 'white',
        backgroundColor: '#008EC5 !important',
        border: '1px solid #1F4E79 !important',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        }
    },
    buttonStyleAddData: {
        //float: 'right',
        margin: '4px 0 !important',
        textTransform: 'none !important',
        backgroundColor: '#db862c !important',
        border: '1px solid #ca751b !important',
        color: 'white !important'
    },

    buttonStyleDelData: {
      margin: '4px 0 !important',
      marginLeft: '50px !important',
      textTransform: 'none !important',
      backgroundColor: '#db862c !important',
      border: '1px solid #ca751b !important',
      color: 'white !important'
  },
  buttonStyleYes: {
    margin: '4px 0 !important',
    marginRight: '50px !important',
    textTransform: 'none !important',
    backgroundColor: '#db862c !important',
    border: '1px solid #ca751b !important',
    color: 'white !important'
},
    tablecontainer:{
        maxHeight: '400px',
        overflowY: 'auto',
        display: 'block',
        width: '50%'
    },

    table: {
        width: '100%',
        borderCollapse: 'collapse',
        display: 'table', 
      },
    
      thead: {
        display: 'table-header-group', 
      },
      tbody: {
        display: 'block',
        height: 300, 
        overflowY: 'auto',
        width: '100%',
      },
      
      col1: {
        width: '25%', 
      },
      col2: {
        width: '25%', 
      },
      th: {
        border: '1px solid #ddd',
        padding: 8,
        textAlign: 'left',
        backgroundColor: '#bcbcbc;',
      },
      td: {
        border: '1px solid #ddd',
        padding: 8,
        textAlign: 'left',
      },
      tbodyRow: {
        display: 'table-row', // Ensure rows align properly
      },
    modalBody: {
      margin: "0 20px 20px 20px",
      backgroundColor: "#EEEEEE",
      padding: "38px"
  },
  modalBodyDark: {
      // margin: "0 20px 20px 20px  ",
      backgroundColor: "#292929",
      padding: "38px"
  },
  modalHeader: {
    borderBottom: 'none !important',
    padding: '4px 4px 3px 0px',
    display: "block !important",
},
modalHeaderDark: {
    borderBottom: 'none !important',
    padding: '4px 4px 3px 0px',
    display: "block !important",
    backgroundColor: '#292929'
},
container: {
  display: 'flex',
  justifyContent: 'center', /* Horizontally centers */
  alignItems: 'center',  /* Vertically centers */
  height: '10vh'        /* Full viewport height */
},
centered: {
  width: '30px',          /* Set your desired width */
  height: '20px',         /* Set your desired height */
  backgroundColor: 'lightblue' /* Optional styling */
}
}));


  

  


function UserDomains() {
    const { t, i18n } = useTranslation('common');

    const classes = useStyles();
    const history = useHistory();
    const [authInfo, setAuthInfo] = useState();
    const [formdata, setFormData] = useState({});
    const { workflowId } = useParams();
    const toastRef = useRef(null)
    const [modalDisplayed, setModalDisplayed] = useState();
    const toggle = useSelector(state => state.setToggle.toggle);
    const [inputValue, setInputValue] = useState('');
    const [groupedData, setGroupedData] = useState({});
    const [show, setShow] = useState(false)
    const textareaRef = useRef(null);
    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const [isActive, setIsActive] = useState(false);
    const userInfo = useSelector(state => state.setUserInfo);

  

    useEffect(async () => {
        const getResponse = await AccountInfo.readDomainFile();
        const originalString = getResponse.response_content;
        const initialData = parseDataString(originalString);
    const initialGroupedData = groupDataByKey(initialData);
    setGroupedData(initialGroupedData);

    }, []);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        setIsActive(true);
        console.log(inputValue.length, "length");

      };

     const delTable = (async() => {
      setShow(false);
      const delData = await AccountInfo.clearDomainFile();
      toast.success("Deleted Successfully");
      window.location.reload();
      
    });
    
      const handleAddData = (async() => {

        const newData = inputValue;
        const getNewData = await AccountInfo.writeDomainFile(newData);
        toast.success("Updated Successfully");
        window.location.reload();
        
      });

    
    function cncl() {
        history.push('/home');
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Tab') {
          event.preventDefault(); // Prevent the default tab behavior (focus change)
          const textarea = textareaRef.current;
          const start = textarea.selectionStart;
          const end = textarea.selectionEnd;
          const newValue = inputValue.substring(0, start) + '\t' + inputValue.substring(end);   
          setInputValue(newValue);
          textarea.selectionStart = textarea.selectionEnd = start + 1;
        }
      };

      const groupDataByKey = (dataArray) => {
        return dataArray.reduce((acc, item) => {
          const [key, ...rest] = item.split('\t');
          const value = rest.join('\t');
      
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(value);
      
          return acc;
        }, {});
      };
    
      const renderGroupedData = (groupedData) => {
        return (
          <>
    
           <table className={classes.table} border="2" cellPadding="10">
           <thead className={classes.thead}>
            <tr >
              <th  className={classes.th}>Vendor</th>
              <th className={classes.th}>Domain</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(groupedData).map((key) => (
              <tr className={classes.tbodyRow} key={key}>
                <td className={`${classes.td} ${classes.col1}`}>{key}</td>
                <td className={`${classes.td} ${classes.col2}`}>
                  {groupedData[key].map((value, index) => (
                    <div key={index}>{value}</div>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        
          </>
        );
      };
      
    
      const parseDataString = (dataString) => {
      return dataString
        .trim()
        .split('\n')
        .filter(line => line.trim() !== '');
    };

    const handleClick = () => {
        setIsButtonVisible(false);
      };
    

    const formik = useFormik({
        initialValues: {

        },
        enableReinitialize: true,
        onSubmit: async (values) => {
           // let { st26input } = values;

            let postData = {
                //st26input
            }

        },
    });

    const isButtonEnabled = inputValue.trim().length > 0;

    const  buttonStyleUpdate = {
        //float: 'right',
        margin: "4px",
        textTransform: "none",
        backgroundColor: isButtonEnabled ? '#db862c' : '#EEEEEE',
        //border: "1px solid #ca751b",
        color: "white"
    };

    return (
        <div className={classes.grow}>
          {userInfo.current_user.user_class_name === "adminium" &&
            <form name="antibodySearchForm" onSubmit={formik.handleSubmit} className={classes.loginDiv}>

                <Row>
                    <Col >
                         <div className={classes.tablecontainer}>
                         {renderGroupedData(groupedData)}
                         </div>
                         <br />
                       
                        {isButtonVisible && 
                                <Button onClick={handleClick} variant="contained" disableRipple={true} color="default"  className={classes.buttonStyleAddData}> Add Data</Button>
                        }
                        {!isButtonVisible && 
                        <span>
                             <textarea
                            ref={textareaRef}
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            placeholder="Enter data here, one line per item and seperated by Tab   (e.g., Google    gmail.com)"
                            rows={3}
                            cols={50}
                             />
                            <br />
                             
                            <Button variant="contained" disableRipple={true} color="default"   disabled={!isButtonEnabled} style={buttonStyleUpdate} onClick={handleAddData}>Update Data</Button>
                            <Button variant="contained" disableRipple={true} color="default" className={classes.buttonStyleCancel} onClick={cncl}>{t('cancel')}</Button>
                        </span>
                        }
                        <Button onClick={(e) => setShow(true)} variant="contained" disableRipple={true} color="default"   className={classes.buttonStyleDelData}> Delete Data</Button>
                    
                      
                    </Col>

                </Row>
                <Modal
                    size="lg"
                    show={show}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header className={toggle? classes.modalHeader: classes.modalHeaderDark}>
                    <Link href="#" onClick={(e) => e.preventDefault()} className={"float-right  appTextColor"}><CloseIcon onClick={() => setShow(false)} /></Link>
                </Modal.Header>
                <Modal.Body className={toggle? classes.modalBody: classes.modalBodyDark}>
                  <div >
                  
                  <h5>This will erase the entire contents of the table. Do you want to continue ?</h5>
                  <div className={classes.container}>
                  <Button onClick={delTable} variant="contained" disableRipple={true} color="default"   className={classes.buttonStyleYes}> Yes</Button>
                  <Button onClick={(e) => setShow(false)} variant="contained" disableRipple={true} color="default"   className={classes.buttonStyleCancel}> Cancel</Button>
                  </div>
                  </div>
                  </Modal.Body>

                </Modal>

            </form>
          }
        </div>

    )

}

export default UserDomains;
