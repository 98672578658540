// src/reducers/index.js
import { combineReducers } from 'redux';

import loginSlice from './slice/loginSlice';
import userServerDataSlice from './slice/userServerDataSlice';
import setCommonSlice from './slice/comonSlice';
import { toggleReducer } from './slice/toggleSlice'; // Correct import

const rootReducer = combineReducers({
  user: loginSlice,
  setUserInfo: userServerDataSlice,
  setCommon: setCommonSlice,
  setToggle: toggleReducer, // Use toggleReducer here
});

export default rootReducer;