import {FaStar, FaCaretRight, FaCaretDown, FaInfoCircle} from "react-icons/fa";
import ReactHtmlParser from "react-html-parser";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import DiscoverAnnotationModal from "../../shared/Modal/DiscoverAnnotationModal";
import DiscoverService from "../../services/discoverService";
import _ from "lodash";
import pencilImg from "../../assets/image/pencil.png";
import notesImg from "../../assets/image/notes_small.png";
import {withStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";
import "./ResultTableRecDetails.css"

const Styles = styled.div`
  .pull-left {
    float: left !important;
  }
  .clearfix{*zoom:1;}.clearfix:before,.clearfix:after{display:table;content:"";line-height:0;}
  .clearfix() {
    &:before,
    &:after {
      content: " "; // 1
      display: table; // 2
    }
    &:after {
      clear: both;
    }
  }
  .gqcol {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 8px;
    margin-left: 8px;
    min-height: 20px;
    min-width: 10px;
  }
  .gqcol-alignment {
    text-overflow: ellipsis;
    overflow: visible;
    white-space: nowrap;
    margin-right: 8px;
    margin-left: 8px;
    min-height: 20px;
    min-width: 10px;
  }
  .gqcolhover {
    overflow: visible;
    white-space: normal;
  }

  .stars-div {
    letter-spacing: -2px;
    color: #D0D0D0;
    padding-top: 1px;
    padding-right: 3px;
    padding-left: 3px;
    cursor: pointer;
  }

  .text-nowrap {
    white-space: nowrap
  }

  .fa-star {
    display: inline-block;
    vertical-align: top;
  }
  
  .fa-caret {
    width: 10px;
  }

  .color-select {
    width: 30px;
    border: solid 1px #CCC;
    height: 16px;
    margin-top: 2px;
  }

  .fa-caret-down:before {
    content: "\f0d7";
  }

  ::-webkit-input-placeholder {
    font-style: italic;
    color: lightgrey;
  }

  :-moz-placeholder {
    font-style: italic;
    color: lightgrey;
  }

  ::-moz-placeholder {
    font-style: italic;
    color: lightgrey;
  }

  :-ms-input-placeholder {
    font-style: italic;
    color: lightgrey;
  }

  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    top: -0.5em;
  }
  .color-box-container {
    background-color: #FFF;
    border: solid 1px #CCC;
    padding: 8px;
    width: 221px;
    vertical-align: top;
    position: absolute;
    margin-left: 30px;
    margin-top: -15px;
    /*z-index: 500;*/
  }
  .color-box {
    width: 25px;
    height: 25px;
    border: solid 1px #CCC;
    margin: 2px;
  }

  a {
    color: #337ab7 !important;
  }

  .color-select {
    width: 30px;
    border: solid 1px #CCC;
    height: 16px;
    margin-top: 2px;
  }
  .unsel-star {
    color: #DDD;
  }
  .sel-star {
    color: #999 !important;
  }
  .fa-caret-right:before {
    content: "\f0da";
  }
  table.classification tr td {
    padding-bottom: 4px;
    padding-right: 30px;
  }
  .detail-label-sub {
    width: 220px;
    padding-right: 10px;
    padding-left: 0px;
  }
  .side-label {
    width: 200px;
  }
  .selected-focus {
    background-color: #EAEAEA;
  }
  .selected-blur {
    background-color: #F4F4F4;
  }
  .selected-focus pre { /* For formatted alignments */
    background-color: #EAEAEA !important;
  }
  .selected-blur pre { /* For formatted alignments */
    background-color: #F4F4F4 !important;
  }
  .black-font {
    color: #555 !important;
    padding: 0px 3px 1px 3px;
    border: solid 1px #999;
    border-radius: 3px;
  }
  /* used to highlight the word SEQ ID NO in the claim text */
  .seq-id-no {
    text-decoration: underline;
    font-weight: bold;
  }
  .not-available {
    font-style: italic;
    color: #CCC;
  }
  /* colors for highlight of text */

  .highlight-FFEB3B {
    background-color: #FFEB3B;
  }

  .highlight-80CBC4 {
    background-color: #80CBC4;
  }

  .highlight-B39DDB {
    background-color: #B39DDB;
  }

  .highlight-EF9A9A {
    background-color: #EF9A9A;
  }

  .highlight-F48FB1 {
    background-color: #F48FB1;
  }

  .highlight-81D4FA {
    background-color: #81D4FA;
  }

  .highlight-A5D6A7 {
    background-color: #A5D6A7;
  }

  .highlight-E6EE9C {
    background-color: #E6EE9C;
  }

  .highlight-FFF59D {
    background-color: #FFF59D;
  }

  .highlight-FFE082 {
    background-color: #FFE082;
  }

  .highlight-FFCC80 {
    background-color: #FFCC80;
  }

  .highlight-FFAB91 {
    background-color: #FFAB91;
  }

  .highlight-FF7043 {
    background-color: #FF7043;
  }

  .highlight-FFA726 {
    background-color: #FFA726;
  }

  .highlight-BCAAA4 {
    background-color: #BCAAA4;
  }

  .highlight-B0BEC5 {
    background-color: #B0BEC5;
  }
  
  /* alignment mismatch and positive */
  .mismatch {
    background-color: yellow;
  }

  .match {
    background-color: orange;
  }

  .lva-not-exact-match {
    text-transform: lowercase;
  }

  .lva-marked {
    background-color: #337ab7;
    color: white;
  }

  .lva-bar .tooltiptext {
    visibility: hidden;
    background-color: #777;
    color: #FFF;
    text-align: center;
    border-radius: 6px;
    padding: 6px 6px 6px 6px;
    box-shadow: 0px 1px 7px #888;

    font-size: 7pt;
    font-family: arial;

    /* Position the tooltip */
    position: absolute;
    bottom: 10%;
    left: 100%;
    margin-left: 10px;
    z-index: 9999;

    overflow: visible;
  }

  .lva-bar .tooltiptext:before {
    content: '';
    position: absolute;

    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid #777;
    left: -6px;
  }

  .lva-bar:hover .tooltiptext {
    visibility: visible;
  }

  .lva-bar .singletooltiptext {
    visibility: hidden;
    background-color: #777;
    color: #FFF;
    text-align: center;
    border-radius: 6px;
    padding: 6px 6px 6px 6px;
    box-shadow: 0px 1px 7px #888;

    font-size: 7pt;
    font-family: arial;

    /* Position the tooltip */
    position: absolute;
    bottom: 10%;
    margin-left: 2px;
    z-index: 10001;
  }

  .lva-bar .singletooltiptext:before {
    content: '';
    position: absolute;

    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid #777;
    left: -6px;
  }

  .lva-bar span:hover .singletooltiptext {
    visibility: visible;
  }

  .lva-bar span:hover ~ .tooltiptext {
    visibility: hidden;
  }
  .lvm-summary-header-row {
    width: 600px;
    text-align: center;
    margin-bottom: 10px;

    /*font-size: 9pt !important;*/
    font-weight: bold;
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .lvm-summary-header-label {
    width: 400px;
  }

  .lvm-summary-sub-header {
    float: left;

    /*font-size: 9pt !important;*/
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .lvm-summary-gqcol {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 4px;
    margin-left: 4px;
    display: inline-block;
    float: left;
    width: 104px;

    /*font-size: 9pt !important;*/
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .lvm-summary-gqcol:hover {
    overflow: visible;
    white-space: normal;
  }

  .lvm-summary-row {
    white-space: nowrap;
  }

  .lvm-summary-link {
    position: relative;
    margin-right: 4px;
    margin-left: 4px;
    left: 450px;
  }

  .lvm-summary-link-label {
    float: left;
    vertical-align: middle;
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
`
const toolTipStyles = {
    tooltip: {
        width: "auto",
        //height: "40px",
        fontSize: '12px',
        textAlign: 'justify',
        padding: "8px 14px 8px 14px",
        boxShadow: "0px 1px 7px #888",
        backgroundColor: '#777',
        color: '#FFF'
    }
};
const CustomTooltip = withStyles(toolTipStyles)(Tooltip);
const tooltipContent = "Annotate Records. Single click – single record, Double click – bulk records.";

export default function ResultTableRecord({ data, item, idx, fieldConfig, filter, annotState,
                                              highlight, toggleFullRecordExpand, columns,
                                              fromItemsPage, groupValue1, groupValue2, saveAnnotation, parentAnnotState,
                                          resizeColCallback, setFtWindowsToOpen}) {
    const[tableState, setTableState] = useState(annotState);
    const[key, setKey] = useState('');
    const[type, setType] = useState('');
    const[value, setValue] = useState(0);
    const[showAnnotationModal,setShowAnnotationModal] = useState(false);
    const[showColor,setShowColor] = useState(false);
    let singleClickTimer = null;
    const[showTooltip,setShowTooltip] = useState(tooltipContent);

    const [dragging, setDragging] = useState(()=>false);
    const [colConfig, setColConfig] = useState(_.cloneDeep(fieldConfig.fieldConfig));
    const [lastPos, setLastPos] = useState(0);
    const toggle = useSelector(state => state.setToggle.toggle);

    const[annotGroup, setAnnotGroup] = useState('');
    const[annotGroupDisplay, setAnnotGroupDisplay] = useState('');

    useEffect( async() => {
        setTableState({...annotState});
    },[annotState]);

    const callBack = (type, choice, annotated, key, value, annotState,
        filter, fieldConfig, fromItemsPage, groupValue1, groupValue2) => {
        saveAnnotation(type, choice, annotated, key, value, annotState,
            filter, fieldConfig, fromItemsPage, groupValue1, groupValue2, parentAnnotState);
        closeModal();
    }

    const closeModal = () => {
        setShowAnnotationModal(false);
    }

    const toggleCheckbox = (e, key, fieldConfig, item) => {
        if (singleClickTimer !== null) {
            clearTimeout(singleClickTimer);
            singleClickTimer = null;
            handleDoubleClick(key, 'checkbox', annotState.checkboxes[key] ? 1 : 0, fieldConfig, item);
        } else {
            singleClickTimer = setTimeout(() => {
                annotState.checkboxes[key] = !annotState.checkboxes[key];
                if (fromItemsPage) {
                    parentAnnotState.checkboxes[key] = annotState.checkboxes[key];
                }
                setTableState({...annotState});
                saveSingleAnnotation('checkbox', key, annotState.checkboxes[key]);
                clearTimeout(singleClickTimer);
                singleClickTimer = null;
            },  300);
        }
    }

    const handleDoubleClick = (key, type, value, fieldConfig, item) => {
        setKey(key);
        setType(type);
        setValue(value);
        calAnnotGroup(fieldConfig, item);
        setShowAnnotationModal(true);
    }

    const toggleStars = (e, key, nbStars, fieldConfig, item) => {
        if (singleClickTimer !== null) {
            clearTimeout(singleClickTimer);
            singleClickTimer = null;
            handleDoubleClick(key, 'star', annotState.stars[key] ? annotState.stars[key] : 0, fieldConfig, item);
        } else {
            singleClickTimer = setTimeout(() => {
                annotState.stars[key] = (annotState.stars[key] === nbStars) ? 0 : nbStars;
                if (fromItemsPage) {
                    parentAnnotState.stars[key] = annotState.stars[key];
                }
                setTableState({...annotState});
                saveSingleAnnotation('star', key, annotState.stars[key]);
                clearTimeout(singleClickTimer);
                singleClickTimer = null;
            },  300);
        }
    }

    const calAnnotGroup = (fieldConfig, item) => {
        if (!groupValue1) {
            let groupVal = "";
            let groupName = "";
            // Allow batch update annotations for normal groups except CAS/HSP groups
            switch (fieldConfig.tableDisplayMode) {
                case 'family':
                    groupVal = item["SUBJECT_PB"];
                    groupName = "Patent Family";
                    break;
                case 'document':
                    groupVal = item["SUBJECT_PN"];
                    groupName = "Patent Number";
                    break;
                case 'ufs':
                    groupVal = item["SUBJECT_PJ"];
                    groupName = "Unique Family Sequence";
                    break;
                /*case 'cas':
                    groupVal = item["SUBJECT_RG"];
                    groupName = "CAS Registry Number";
                    break;*/
                case 'ssv':
                    groupVal = item["SUBJECT_ID"];
                    groupName = "Subject Sequence";
                    break;
                case 'qs':
                    groupVal = item["QUERY_ID"];
                    groupName = "Query Sequence";
                    break;
                default:
                    break;
            }
            setAnnotGroup(groupVal);
            setAnnotGroupDisplay(groupName);
        }
    }

    const showColorBox = (e, key) => {
        if (singleClickTimer !== null) {
            clearTimeout(singleClickTimer);
            singleClickTimer = null;
            handleDoubleClick(key, 'color', annotState.colors[key] ? annotState.colors[key] : 0, fieldConfig, item);
        } else {
            singleClickTimer = setTimeout(() => {
                //if (!tableState.showColorBoxes) {
                   // tableState.showColorBoxes = {};
                //}
                setShowTooltip('');
                setShowColor(true);
                setTableState({...tableState});
                clearTimeout(singleClickTimer);
                singleClickTimer = null;
            },  300);
        }
    }

    const hideColorBox = (key) => {
        setShowColor(false);
        setShowTooltip(tooltipContent);
        //setTableState({...tableState});
    }

    const selectColor = (key, value, fieldConfig, item) => {
        //console.log(item);
        setShowColor(false);
        setShowTooltip('');
        annotState.colors[key] = value;
        tableState.colors[key] = value;
        if (fromItemsPage) {
            parentAnnotState.colors[key] = annotState.colors[key];
        }
        setTableState({...tableState});
        saveSingleAnnotation('color', key, value);
    }

    const saveSingleAnnotation = (annotType, key, val) => {
        let call = window.$javaCall;
        let selectedIds = [];
        selectedIds.push(tableState.dbkeys[key]);
        call.data['selectedIds'] = selectedIds;
        call.data['allPages'] = false;
        let intVal = 0;
        if (typeof val === "boolean") {
            intVal = val ? 1: 0;
        } else {
            intVal = val;
        }
        call.data['value'] = intVal;
        call.data['annotType'] = annotType;
        DiscoverService.saveAnnotation(call.data, call.data.gqApiKey);
    }
    const onMouseOver = (event, id, col) =>{
      let element = document.getElementById(id);
      element.style.whiteSpace = 'pre-wrap'; // wrap attribute is not working in FF
      //console.log("ENTERING INTO THE FUNCTION",element.style.whiteSpace);
      //console.log("ENTERING INTO THE FUNCTION",element);
    }
    const onMouseOut = (event, id, col)=>{
      let element = document.getElementById(id);
      element.style.whiteSpace = 'nowrap';
    }

    const onMouseMove = (event, id, col) => {
        let element = document.getElementById(id);
        let inDragTarget = (event.clientX >= (element.getClientRects()[0].right - 20));
        element.style.cursor = inDragTarget ? 'ew-resize' : 'default';
        element.style.borderRight = inDragTarget ? 'solid 3px #888' : 'none';
        //console.log("onMouseMove.");
    }

    const onMouseDown = (event, id, col) => {
        let element = document.getElementById(id);
        let inDragTarget = (event.clientX >= (element.getClientRects()[0].right - 20));
        if (inDragTarget && !dragging) {
            setDragging(true);
            //setLastPos(event.clientX);
            const firstPos = event.pageX;

            // this is what gets executed while the column size is being dragged
            const draggingNow = function (ev) {
                let oldWidth = parseInt(fieldConfig.fieldConfig[col].colwidth);
                if (oldWidth < 0 || oldWidth > 1000) {
                    oldWidth = 130;
                }
                //console.log("draggingNow: " + (element.getClientRects()[0].right - 20) + ", now:" + ev.target.getBoundingClientRect().right);
                let newWidth = ev.pageX - firstPos + oldWidth;
                if (newWidth !== undefined && newWidth >= 30 && newWidth < 1000) {
                    //setLastPos(ev.clientX);
                    element.style.width = newWidth + 'px';
                    //console.log("newWidth: " + newWidth);
                }
            };

            // this is what gets executed when mouse cursor goes back up after dragging
            const stopDragging = function (ev) {
                let oldWidth = parseInt(fieldConfig.fieldConfig[col].colwidth);
                if (oldWidth < 0 || oldWidth > 1000) {
                    oldWidth = 130;
                }
                let width = ev.pageX - firstPos + oldWidth;
                //setLastPos(ev.clientX);
                console.log("Move Width: " + width + ",old:" + oldWidth);
                if (width >= 30 && width < 1000) {
                    //setNewWidth(width - oldWidth);
                    fieldConfig.fieldConfig[col].colwidth = width;
                    resizeColCallback(col, width, width - oldWidth);
                }
                setDragging(false);
                document.body.removeEventListener("mousemove", draggingNow);
                document.body.removeEventListener("mouseup", stopDragging);
            };

            // dragging has started, attach events while dragging, and when dragging stops to body
            document.body.addEventListener("mousemove", draggingNow);
            document.body.addEventListener("mouseup", stopDragging);
        }
    }

    const onMouseLeave = (id, col, fieldConfig, filter) => {
        let element = document.getElementById(id);
        element.style.borderRight = 'none';
        element.style.cursor = 'default';
        if (dragging && colConfig[col].colwidth !== fieldConfig.fieldConfig[col].colwidth) {
            // When the col width is changed, saves it
            //fieldConfig.saveConfig(fieldConfig, 'default', filter);
            setDragging(false);
            console.log("onMouseLeave");
        }
    }

    const showUpdateNotes = (e, key, fieldConfig, item) => {
        // Set parameters and open the popup dialog
        setType('notes');
        setValue(annotState.notes[key]);
        setKey(key);
        calAnnotGroup(fieldConfig, item);
        setShowAnnotationModal(true);
    }

    const openFtWindow = (thing)=>{

      let data ={
          pn: getWordAfterFulldocview(thing.VT_FT),
          seqIds: extractSeqIdNumber(thing),
          kws: ""
      }
      setFtWindowsToOpen(data)
    }

    function getWordAfterFulldocview(inputString) {
      const regex = /fulldocview\/(\w+)/;
      const match = inputString.match(regex);
      return match ? match[1] : null;
    }

    const extractSeqIdNumber = (thing) => {

      if(thing !== undefined){

          if(thing.SUBJECT_SI !== undefined && thing.SUBJECT_SI !== null && thing.SUBJECT_SI !==""){
              return thing.SUBJECT_SI;
          }

          if(thing.SUBJECT_PS !== undefined){
              let str = thing.SUBJECT_PS
              const regex = /SEQ ID NO (\d+)/;
              // Use the regex to find the match
              const match = str.match(regex);
              // If a match is found, return the captured group (the number)
              if (match) {
                  if(match[1]){
                      return match[1];
                  }
              }
          }

          if(thing.SUBJECT_SEQIDNOSUMM !== undefined){
              if(thing.SUBJECT_SEQIDNOSUMM.Disclosure !== undefined){
                  if(thing.SUBJECT_SEQIDNOSUMM.Disclosure[""] !== undefined){
                      let str = thing.SUBJECT_SEQIDNOSUMM.Disclosure[""]
                      const regex = /SEQ ID NO (\d+)/;
                      // Use the regex to find the match
                      const match = str.match(regex);
                      // If a match is found, return the captured group (the number)
                      if (match) {
                          if(match[1]){
                              return match[1];
                          }
                      }
                  }else{
                      if(Object.keys(thing.SUBJECT_SEQIDNOSUMM.Disclosure)[0] !== null && Object.keys(thing.SUBJECT_SEQIDNOSUMM.Disclosure)[0] !== undefined){
                          if(Number.isInteger(parseInt(Object.keys(thing.SUBJECT_SEQIDNOSUMM.Disclosure)[0]))){
                              return Object.keys(thing.SUBJECT_SEQIDNOSUMM.Disclosure)[0];
                          }
                      }
                  }

              }else{
                  if(thing.SUBJECT_SEQIDNOSUMM.Claim  !== null && thing.SUBJECT_SEQIDNOSUMM.Claim  !== undefined){
                      if(Number.isInteger(parseInt(Object.keys(thing.SUBJECT_SEQIDNOSUMM.Claim)[0]))){
                          return Object.keys(thing.SUBJECT_SEQIDNOSUMM.Claim)[0];
                      }
                  }
              }
          }
      }

      // If no match is found, return null or any default value
      return "";
    };

    return (
        <Styles>
            <div key={'row' + item.dbkey} className="clearfix row-padding">
                <span id="RESULT_ID_{{item['RESULT_ID']}}" style={{display: 'none'}}>{item['RESULT_ID']}</span>
                {(item['type'] === 'sin' || item['type'] === 'flat') &&
                    <div className="pull-left">
                        <div className="checkbox-div pull-left">
                            <CustomTooltip arrow title={"Annotate Records. Single click – single record, Double click – bulk records."}
                                placement="top" enterDelay={500} leaveDelay={200}>
                                <input id={'cb_'+item.dbkey} type="checkbox" onClick={(e) => {toggleCheckbox(e, item.dbkey, fieldConfig, item)}}
                                   onChange={(e) => e.preventDefault()}
                                   checked={annotState.checkboxes[item.dbkey] || false}/>
                            </CustomTooltip>
                        </div>
                        <CustomTooltip arrow title={"Annotate Records. Single click – single record, Double click – bulk records."}
                            placement="top" enterDelay={500} leaveDelay={200}>
                            <div className="stars-div text-nowrap pull-left">
                                <FaStar className="fa fa-star" color={(annotState.stars[item.dbkey] && annotState.stars[item.dbkey]>0) ? '#999' : '#DDD'} onClick={(e) => toggleStars(e, item.dbkey, 1, fieldConfig, item)}></FaStar>
                                <FaStar className="fa fa-star" color={(annotState.stars[item.dbkey] && annotState.stars[item.dbkey]>1) ? '#999' : '#DDD'} onClick={(e) => toggleStars(e, item.dbkey, 2, fieldConfig, item)}></FaStar>
                                <FaStar className="fa fa-star" color={(annotState.stars[item.dbkey] && annotState.stars[item.dbkey]>2) ? '#999' : '#DDD'} onClick={(e) => toggleStars(e, item.dbkey, 3, fieldConfig, item)}></FaStar>&nbsp;
                            </div>
                        </CustomTooltip>
                        <div className="color-select-div pull-left" onMouseLeave={() => hideColorBox(item.dbkey)}>
                            <CustomTooltip arrow title={showTooltip}
                                placement="top" enterDelay={500} leaveDelay={200}>
                                <div id={'col_'+item.dbkey} className={'pull-right color-select color-' + ((annotState.colors[item.dbkey] && annotState.colors[item.dbkey] > 0) ? annotState.colors[item.dbkey] : 0)} onClick={(e) => showColorBox(e, item.dbkey)}>
                                {showColor &&
                                    <div tabIndex="0" id={'cob_'+item.dbkey} className="clearfix color-box-container" onBlur={() => hideColorBox(item.dbkey)}
                                        onMouseLeave={() => hideColorBox(item.dbkey)} >
                                        <div onClick={(e)=>{selectColor(item.dbkey, 0, fieldConfig, item);e.stopPropagation();}} className="color-0 color-box pull-left"></div>
                                        <div onClick={(e)=>{selectColor(item.dbkey, 1, fieldConfig, item);e.stopPropagation();}} className="color-1 color-box pull-left"></div>
                                        <div onClick={(e)=>{selectColor(item.dbkey, 2, fieldConfig, item);e.stopPropagation();}} className="color-2 color-box pull-left"></div>
                                        <div onClick={(e)=>{selectColor(item.dbkey, 3, fieldConfig, item);e.stopPropagation();}} className="color-3 color-box pull-left"></div>
                                        <div onClick={(e)=>{selectColor(item.dbkey, 4, fieldConfig, item);e.stopPropagation();}} className="color-4 color-box pull-left"></div>
                                        <div onClick={(e)=>{selectColor(item.dbkey, 5, fieldConfig, item);e.stopPropagation();}} className="color-5 color-box pull-left"></div>
                                        <div onClick={(e)=>{selectColor(item.dbkey, 6, fieldConfig, item);e.stopPropagation();}} className="color-6 color-box pull-left"></div>
                                        <div onClick={(e)=>{selectColor(item.dbkey, 7, fieldConfig, item);e.stopPropagation();}} className="color-7 color-box pull-left"></div>
                                        <div onClick={(e)=>{selectColor(item.dbkey, 8, fieldConfig, item);e.stopPropagation();}} className="color-8 color-box pull-left"></div>
                                        <div onClick={(e)=>{selectColor(item.dbkey, 9, fieldConfig, item);e.stopPropagation();}} className="color-9 color-box pull-left"></div>
                                        <div onClick={(e)=>{selectColor(item.dbkey, 10, fieldConfig, item);e.stopPropagation();}} className="color-10 color-box pull-left"></div>
                                        <div onClick={(e)=>{selectColor(item.dbkey, 11, fieldConfig, item);e.stopPropagation();}} className="color-11 color-box pull-left"></div>
                                        <div onClick={(e)=>{selectColor(item.dbkey, 12, fieldConfig, item);e.stopPropagation();}} className="color-12 color-box pull-left"></div>
                                        <div onClick={(e)=>{selectColor(item.dbkey, 13, fieldConfig, item);e.stopPropagation();}} className="color-13 color-box pull-left"></div>
                                    </div>
                                }
                            </div>
                            </CustomTooltip>
                        </div>
                        <div className="pull-left">
                            <div className={"appTextColor loginTitle"}>
                                <img onClick={e => showUpdateNotes(e, item.dbkey, fieldConfig, item)} src={(annotState.notes[item.dbkey] && annotState.notes[item.dbkey] !== '') ? notesImg : pencilImg}/>&nbsp;
                            </div>
                        </div>
                    </div>
                }

                <div className="pull-left fa-caret">
                    <a href="#" onClick={(e) => {toggleFullRecordExpand(item.dbkey);e.preventDefault();}}>
                        {tableState.fullRecordExpand[item.dbkey] &&
                            <FaCaretDown/>
                        }
                        {!tableState.fullRecordExpand[item.dbkey] &&
                            <FaCaretRight/>
                        }
                    </a>
                </div>

                {columns?.map(
                        (col, colIdx) => 
                        (
                            <div key={col + colIdx} className={'pull-left ' + (col === 'RESULT_FALI_FORMATTED' ? 'gqcol-alignment' : 'gqcol')}
                                 id={col + item.dbkey}
                                 onMouseMove={(e) => onMouseMove(e, col + item.dbkey, col)}
                                 onMouseOver={(e) => onMouseOver(e, col + item.dbkey, col)}
                                 onMouseOut={(e) => onMouseOut(e, col + item.dbkey, col)}
                                 onMouseDown={(e) => onMouseDown(e, col + item.dbkey, col)}
                                 onMouseLeave={() => onMouseLeave(col + item.dbkey, col, fieldConfig, filter)}
                                 style={{ width: fieldConfig.fieldConfig[col].colwidth + 'px'}}>
                                {(col === 'RESULT_FALI_FORMATTED' || col === 'SUBJECT_CT')
                                    && (tableState.fullRecordExpand[item.dbkey] || colIdx > 0) &&
                                    <span>
                                        {tableState.fullRecordExpand[item.dbkey] &&
                                            <span>&nbsp;</span>
                                        }
                                        {!tableState.fullRecordExpand[item.dbkey] && col === 'RESULT_FALI_FORMATTED' &&
                                            <pre className="ali-pre" style={toggle? {overflow: 'visible'}: {overflow: 'visible', backgroundColor: '#292929 !important', color: 'white'}} dangerouslySetInnerHTML={{__html: data.gqAlignmentsNoSumm[item.dbkey]}}></pre>
                                        }
                                        {!tableState.fullRecordExpand[item.dbkey] && col === 'SUBJECT_CT' &&
                                            <span style={{overflow: 'visible'}}>{ReactHtmlParser(highlight(fieldConfig, filter, col, item[col], true))}</span>
                                        }
                                    </span>
                                }
                                {(col === 'RESULT_FALI_FORMATTED' || col === 'SUBJECT_CT')
                                    && !tableState.fullRecordExpand[item.dbkey] && colIdx == 0 &&
                                    <a href="#" onClick={(e) => {toggleFullRecordExpand(item.dbkey);e.preventDefault();}}>
                                        {!tableState.fullRecordExpand[item.dbkey] && col === 'RESULT_FALI_FORMATTED' &&
                                            <div>
                                                <pre style={{overflow: 'visible'}}>{ReactHtmlParser(data.gqAlignmentsNoSumm[item.dbkey])}</pre>
                                            </div>
                                        }
                                        {!tableState.fullRecordExpand[item.dbkey] && col === 'SUBJECT_CT' &&
                                            <div>
                                                <span style={{overflow: 'visible'}}>{ReactHtmlParser(highlight(fieldConfig, filter, col, item[col], true))}</span>
                                            </div>
                                        }
                                    </a>
                                }

                                {(col === 'SUBJECT_PC' || col === 'SUBJECT_SEQIDNOSUMM')
                                    && colIdx > 0 && item[col] && Object.keys(item[col]).length < 1 &&
                                    <div><span>&nbsp;</span></div>
                                }
                                {(col === 'SUBJECT_PC' || col === 'SUBJECT_SEQIDNOSUMM') && colIdx == 0 && item[col] && Object.keys(item[col]).length < 1 &&
                                    <a href="#" onClick={(e) => {toggleFullRecordExpand(item.dbkey);e.preventDefault();}}><span>&nbsp;</span></a>
                                }
                                {col === 'SUBJECT_PC' && colIdx > 0 && item[col] && Object.keys(item[col]).length >= 1 &&
                                    <div><table className="classification">
                                        <tbody>
                                        {Object.keys(item[col]).map(
                                            (cls) => (
                                                <tr key={'pc_' + colIdx + cls}>
                                                    <td className="detail-label-sub">{cls}</td>
                                                    <td>{item[col][cls]}</td>
                                                </tr>
                                            )
                                        )}
                                        </tbody>
                                    </table></div>
                                }
                                {col === 'SUBJECT_PC' && colIdx == 0 && item[col] && Object.keys(item[col]).length >= 1 &&
                                    <a href="#" onClick={(e) => {toggleFullRecordExpand(item.dbkey);e.preventDefault();}}>
                                        <table className="classification">
                                        <tbody>
                                        {Object.keys(item[col]).map(
                                            (cls) => (
                                                <tr key={'pc_' + colIdx + cls}>
                                                    <td className="detail-label-sub">{cls}</td>
                                                    <td>{item[col][cls]}</td>
                                                </tr>
                                            )
                                        )}
                                        </tbody>
                                        </table>
                                    </a>
                                }

                                {col === 'SUBJECT_SEQIDNOSUMM' && colIdx > 0 && item[col] && Object.keys(item[col]).length >= 1 &&
                                    <div><table className="classification">
                                        <tbody>
                                        {item[col]['Claim'] && Object.keys(item[col]['Claim']).length > 0 &&
                                            <tr>
                                                <td className="side-label">Claimed</td>
                                                <td>
                                                    {Object.keys(item[col]['Claim']).map(
                                                        (cls) => (
                                                            <div key={colIdx + cls}>
                                                                SEQ ID NO:{cls}
                                                                {item[col]['Claim'][cls] &&
                                                                    <span key={'span_' + colIdx + cls}> ({item[col]['Claim'][cls]})</span>
                                                                }
                                                            </div>
                                                            )
                                                        )}
                                                </td>
                                            </tr>
                                        }
                                        {item[col]['Disclosure'] && Object.keys(item[col]['Disclosure']).length > 0 &&
                                            <tr>
                                                <td className="side-label">Probable disclosure</td>
                                                <td>SEQ ID NOs:
                                                    {Object.keys(item[col]['Disclosure']).map(
                                                        (cls) => (
                                                            <span key={'span_' + colIdx + cls}> {cls}
                                                                {item[col]['Disclosure'][cls] &&
                                                                    <span key={'span2_' + colIdx + cls}> ({item[col]['Disclosure'][cls]})</span>
                                                                }
                                                            ;&nbsp;</span>
                                                        )
                                                    )}
                                                </td>
                                            </tr>
                                        }
                                        {item[col]['TBD'] && Object.keys(item[col]['TBD']).length > 0 &&
                                            <tr>
                                                <td className="side-label">TBD</td>
                                                <td>SEQ ID NOs:
                                                    {Object.keys(item[col]['TBD']).map(
                                                        (cls) => (
                                                            <span key={'span_' + colIdx + cls}> {cls}
                                                                {item[col]['TBD'][cls] &&
                                                                    <span key={'span2_' + colIdx + cls}> ({item[col]['TBD'][cls]})</span>
                                                                }
                                                                ;&nbsp;</span>
                                                        )
                                                    )}
                                                </td>
                                            </tr>
                                        }
                                        </tbody>
                                    </table></div>
                                }
                                {col === 'SUBJECT_SEQIDNOSUMM' && colIdx == 0 && item[col] && Object.keys(item[col]).length >= 1 &&
                                    <a href="#" onClick={(e) => {toggleFullRecordExpand(item.dbkey);e.preventDefault();}}>
                                        <table className="classification">
                                            <tbody>
                                            {item[col]['Claim'] && Object.keys(item[col]['Claim']).length > 0 &&
                                                <tr>
                                                    <td className="side-label">Claimed</td>
                                                    <td>
                                                        {Object.keys(item[col]['Claim']).map(
                                                            (cls) => (
                                                                <div key={colIdx + cls}>
                                                                    SEQ ID NO:{cls}
                                                                    {item[col]['Claim'][cls] &&
                                                                        <span key={'span_' + colIdx + cls}> ({item[col]['Claim'][cls]})</span>
                                                                    }
                                                                </div>
                                                            )
                                                        )}
                                                    </td>
                                                </tr>
                                            }
                                            {item[col]['Disclosure'] && Object.keys(item[col]['Disclosure']).length > 0 &&
                                                <tr>
                                                    <td className="side-label">Probable disclosure</td>
                                                    <td>SEQ ID NOs:
                                                        {Object.keys(item[col]['Disclosure']).map(
                                                            (cls) => (
                                                                <span key={'span_' + colIdx + cls}> {cls}
                                                                    {item[col]['Disclosure'][cls] &&
                                                                        <span key={'span2_' + colIdx + cls}> ({item[col]['Disclosure'][cls]})</span>
                                                                    }
                                                                    ;&nbsp;</span>
                                                            )
                                                        )}
                                                    </td>
                                                </tr>
                                            }
                                            {item[col]['TBD'] && Object.keys(item[col]['TBD']).length > 0 &&
                                                <tr>
                                                    <td className="side-label">TBD</td>
                                                    <td>SEQ ID NOs:
                                                        {Object.keys(item[col]['TBD']).map(
                                                            (cls) => (
                                                                <span key={'span_' + colIdx + cls}> {cls}
                                                                    {item[col]['TBD'][cls] &&
                                                                        <span key={'span2_' + colIdx + cls}> ({item[col]['TBD'][cls]})</span>
                                                                    }
                                                                    ;&nbsp;</span>
                                                            )
                                                        )}
                                                    </td>
                                                </tr>
                                            }
                                            </tbody>
                                        </table>
                                    </a>
                                }

                                {(col !== 'RESULT_FALI_FORMATTED' && col !== 'SUBJECT_CT' && col !== 'SUBJECT_PC' && col !== 'SUBJECT_SEQIDNOSUMM' && col !== 'VT_FT')
                                    && colIdx > 0 &&
                                    <div>
                                        <span>{ReactHtmlParser(highlight(fieldConfig, filter, col, item[col], true))}</span>
                                    </div>
                                }
                                {(col !== 'RESULT_FALI_FORMATTED' && col !== 'SUBJECT_CT' && col !== 'SUBJECT_PC' && col !== 'SUBJECT_SEQIDNOSUMM')
                                    && colIdx == 0 &&
                                    <a href="#" onClick={(e) => {toggleFullRecordExpand(item.dbkey);e.preventDefault();}}>
                                        <span>{ReactHtmlParser(highlight(fieldConfig, filter, col, item[col], true))}</span>
                                    </a>
                                }
                                {(col === 'VT_FT') &&
                                    <a href="#" onClick={(e) => {e.preventDefault(); openFtWindow(item)}}>
                                    <span>Full Text Document</span>
                                    </a>
                                }
                            </div>
                           
                        )
                    )
                }
            </div>
            {showAnnotationModal && <DiscoverAnnotationModal showModal={showAnnotationModal} type={type} dbkey={key} annotState={annotState} filter={filter}
                                     fieldConfig={fieldConfig} fromItemsPage={fromItemsPage} groupValue1={groupValue1} groupValue2={groupValue2}
                                     actionModal={callBack} closeModal={closeModal} value={value} parentAnnotState={parentAnnotState}
                                     annotGroup={annotGroup} annotGroupDisplay={annotGroupDisplay}/>}
        </Styles>
    )
}
