import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from "react-i18next";
import {useHistory, Link, useParams} from "react-router-dom";
import Button from "@material-ui/core/Button";
import CloseIcon from '@material-ui/icons/Close';
import CheckBox from '../../shared/Fields/CheckBox';
import { useSelector } from 'react-redux';
import {getInitialTextFieldsValue } from '../../services/FTDocService';


const useStyles = makeStyles((theme) => ({
	modalHeader: {
		borderBottom: 'none !important',
		padding: '4px 4px 3px 0px',
		display: "block !important",
	},
	modalHeaderDark: {
		borderBottom: 'none !important',
		padding: '4px 4px 3px 0px',
		display: "block !important",
		background: '#292929'
	},
	modalBody: {
		margin: "0 20px 20px 20px",
		padding: "38px"
	},
	modalBodyDark: {
		// margin: "0 20px 20px 20px",
		backgroundColor: "#292929",
		padding: "38px"
	},
	titleFont: {
		fontSize: "20px !important"
	},
	submitDisabled: {
		border: '1px solid #e0e0e0 !important',
		textTransform: 'none',
	},
	checkboxAdjustments: {
		paddingLeft: '5px'
	},
	examplee: {
		color: 'black'
	}
}));

function TextFieldsModal(props) {

    const { t, i18n } = useTranslation("common");
    const classes = useStyles();
	const toggle = useSelector(state => state.setToggle.toggle);  

	const [textFields, setTextFields] = useState(getInitialTextFieldsValue());

	const changeSelectedField = (key) => { //handles change on checkboxes
		textFields[key[0]] = !textFields[key[0]];
		setTextFields({...textFields})
	}

	const save = () => {
		localStorage.setItem("TextFields", JSON.stringify(textFields))
		props.setTextFields(textFields)
		props.closeModal();
	}


	return (
	    <Modal
			size="md"
            show={props.show}
            onHide={props.closeModal}
			aria-labelledby="example-modal-sizes-title-lg"
			contentClassName={classes.examplee}
		>
			<Modal.Header className={toggle? classes.modalHeader : classes.modalHeaderDark}> 
				<Link to="#" onClick={(e) => e.preventDefault()} className={"float-right appTextColor"}><CloseIcon onClick={() => props.closeModal()} /></Link>
			</Modal.Header>
			<Modal.Body className={toggle? classes.modalBody : classes.modalBodyDark}>
				<div>
					<h4 className={"subHeading " + classes.titleFont}>Select Text Fields</h4>
				</div> 
					{Object.entries(textFields).map((key,value) => {
    					return (
							<div style={{paddingLeft: '10px'}} key={key+""}>
    					      <table className="toppings-list-item">
    					        <tr className="left-section">
								 <td>
    					          <CheckBox
								    color="primary"
								    className={"float-left ml-2 mt-1"}
									checked={textFields[key[0]]}
    					            onChange={()=> changeSelectedField(key)}
    					          />
								  </td>
								  <td className={classes.checkboxAdjustments}>
    					            <label onClick={(e) => changeSelectedField(key)} className="makeStyles-checkBoxContent-88 bodyText ml-0 cursorPointer ontologyFix">{key[0]}</label>
								  </td>
								</tr>
    					      </table>
							  </div>
    					  );
    					})}
						{/* <span hidden>{modalUpdater}</span> */}

					<div className="clear">
						<Button className={"submitButtonClass float-right ml-2"} onClick={() => save()}>{t('Save')}</Button>
						<Button className={"submitButtonClass float-right ml-2"} onClick={() => props.closeModal()}>{t('Cancel')}</Button>							
					</div>
			</Modal.Body>
		</Modal>
	);
}

export default TextFieldsModal;