import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import utilsService, { TOAST_TYPE } from '../../helpers/utils'
import _ from "lodash";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx/xlsx';
import ExportExcel from './ExportExcel';
import {saveAs} from 'file-saver';

import TextInput from '../../shared/Fields/TextInput';
import Constant from '../../helpers/constant';
import st26service from '../../services/st26service';
import Modal from 'react-bootstrap/Modal'
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';




const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        width: '96%',
        margin: '0 auto 28px',
        minHeight: '260px',
        // borderBottom: '1px solid #cec7c7',
        padding: '23px 16px 14px',
    },
    headerPipe: {
        margin: '0 10px'
    },
    searchInput: {
        width: '30%'
    },
    buttonStyleCancel: {
        float: 'right',
        textTransform: 'none',
        margin: '4px',
        color: 'white',
        backgroundColor: '#008EC5 !important',
        border: '1px solid #1F4E79 !important',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        }
    },

    antibodyNumInput: {
        width: '8%'
    }, 

    colorConDSI: {
        backgroundColor: 'gainsboro',
        // marginTop: '-38px',
        padding: '28px',
        paddingTop: '28px',
        paddingBottom: '65px',
        // marginLeft: '7px',
        // marginRight: '7px',
        paddingRight: '10px',
        borderRadius: '5px',
    },
    modalBody: {
        paddingBottom: '20px'
    },
    footerDiv: {
        padding: '0 10px'
    },
    loginSubmitCancel: {
        backgroundColor: '#0182C5',
        borderColor: '#1F4E79',
        border: '1px solid #1F4E79',
        color: 'white',
        margin: '4px',
        textTransform: 'capitalize',
        '&:hover': {
        backgroundColor: '#0182C5',
        boxShadow: 'none',
        },
    },
    modaltext: {
        position: 'absolute',
        top: '10px',
        right: '10px'
    },
    modalClassConDSI: {
        position: 'absolute',
        width: '96%',
        padding: '35px 15px 15px',
        // height: 'auto',
        // top: '30%',
        // left: '50%',
        right: 'auto',
        bottom: 'auto',
        // transform: 'translate(-50%, -50%)'
    },
    modalBoxContent: {
        maxHeight: '675px',
    },
    FastaBtn: {
        margin: '4px 0 !important',
        textTransform: 'none !important',
        backgroundColor: '#db862c !important',
        border: '1px solid #ca751b !important',
        color: 'white !important',
    },
    cancelbutton:{
        backgroundColor: '#EEEEEE !important',
        border: '1px solid #CCCCCC !important',
        
        margin: '4px 0 !important',
        textTransform: 'none !important',
        marginTop: '4px !important',
        color: '#777777 !important',
        boxSshadow: 'none !important',
    },
    CancelButtonDisble: {
        backgroundColor: '#EEEEEE !important',
        border: '1px solid #CCCCCC !important',
        
        margin: '4px 0 !important',
        textTransform: 'none !important',
        marginTop: '4px !important',
        color: '#777777 !important',
        boxSshadow: 'none !important',
    },
    downloadFastaButton: {
        backgroundColor: '#EEEEEE !important',
        border: '1px solid #CCCCCC !important',
        
        margin: '4px 0 !important',
        textTransform: 'none !important',
        marginTop: '4px !important',
        color: '#777777 !important',
        boxSshadow: 'none !important',
    }

}));

var ExcelExportArray =[];
function TabularToFasta() {
    const { t, i18n } = useTranslation('common');

    const classes = useStyles();
    const history = useHistory();
    const [authInfo, setAuthInfo] = useState();
    const [formdata, setFormData] = useState({});
    const { workflowId } = useParams();
    const toastRef = useRef(null)
    const [modalShow, setModalShow] = React.useState(false);
    const [jsonData, setJsonData] = useState('');
    const [file, setFile] = useState(null);
    const [fileData, setFileData] = useState(null);
    const toggle = useSelector(state => state.setToggle.toggle);

    const handleConvert = () => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                let json = XLSX.utils.sheet_to_txt(worksheet);
    
                // Adjust the JSON to FASTA format
                json = json.split('\n').map(line => {
                    const parts = line.split('\t').map(part => part.trim());
                    if (parts.length === 3) {
                        const seqid = parts[0];
                        const description = parts[1];
                        const sequence = parts[2];
                        return `>${seqid} ${description}\n${sequence}`;
                    } else if (parts.length === 2) {
                        const seqid = parts[0];
                        const sequence = parts[1];
                        return `>${seqid}\n${sequence}`;
                    }
                    return line;
                }).join('\n');
    
                setJsonData(json);
                formik.setFieldValue("st26input", json);
            };
            reader.readAsArrayBuffer(file);
        } else if (formik.values.st26input1) {
            // Convert content of st26input1 field to FASTA format
            let json = formik.values.st26input1;
            json = json.split('\n').map(line => {
                const parts = line.split('\t').map(part => part.trim());
                if (parts.length === 3) {
                    const seqid = parts[0];
                    const description = parts[1];
                    const sequence = parts[2];
                    return `>${seqid} ${description}\n${sequence}`;
                } else if (parts.length === 2) {
                    const seqid = parts[0];
                    const sequence = parts[1];
                    return `>${seqid}\n${sequence}`;
                }
                return line;
            }).join('\n');
    
            setJsonData(json);
            formik.setFieldValue("st26input", json);
        }
    };  
    
    const resetFileName = () => {
        var fileInput = document.querySelector('input[type=file]');
        fileInput.value = ''; // Clear the file input
        setFile(null); // Reset the uploaded file state
    };
      

    function downloadFasta(json, file) {
        var blob = new Blob([json], {
            type: "text/plain;charset=utf-8;"
        });
        var url = URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        if (file && file.name) {
            a.download = file.name + '.fasta.txt';
        } else {
            // If the file name is not available, prompt the user for a name
            var fileName = prompt("Please enter the name of the file:");
            if (fileName) {
                // If the user provides a name, use it for the download
                a.download = fileName + '.fasta.txt';
            } 
        }
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        window.location.reload();
    }
    

    function cncl() {
        window.location.reload();
    }

    const formik = useFormik({
        initialValues: {
            st26input: formdata.st26input,
            st26input1: formdata.st26input1
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            let { st26input } = values;

        }
    });

    // console.log(formik.values.st26input1, "values");

    function showfil(event) {
        if (window.File && window.FileReader && window.FileList && window.Blob) {
            var fileInput = event.target.files[0];
            if (fileInput) {
                setFile(fileInput); // Update the file state with the selected file
                var preview = document.getElementById('st26input1');
                var reader = new FileReader();

                 if (fileInput.type === 'application/vnd.ms-excel' || fileInput.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                    // Handle Excel file
                    reader.onload = function (event) {
                        const data = event.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });
                        const sheetName = workbook.SheetNames[0];
                        const worksheet = workbook.Sheets[sheetName];
                        const excelData = XLSX.utils.sheet_to_txt(worksheet);
                        // Convert Excel data to text and set the value of the input field
                        preview.value = excelData;
                        formik.setFieldValue("st26input1", excelData);
                    };
                    reader.readAsArrayBuffer(fileInput);
                } else {
                    preview.innerHTML = "<span class='error'>Unsupported file format!</span>";
                }
            } else {
                alert('Please select a file before attempting to read it.');
            }
        } else {
            alert('Your browser is too old to support HTML5 File API');
        }
    }
    
    
    return (
        <div className={classes.grow}>
            { jsonData && <form name="antibodySearchForm" onSubmit={formik.handleSubmit} className={classes.loginDiv}>

                <Row>
                    <Col lg="12" md="12" className={"mb-2 " + (authInfo && !authInfo.syscontrol_search_submit ? 'd-block' : 'd-none')}>
                        <Typography className="text-danger">
                            {t('ABsearchDisableText')}
                            {authInfo && authInfo.syscontrol_search_submit_txt}
                            {t('patienceThanksText')}</Typography>
                    </Col>

                    <Col lg="12" md="12" className="mb-2">
                        <h6 className={"appTextColor loginTitle"}>Fasta Sequence Input</h6>
                        <Row className="mb-3">

                            <Col lg="12" md="12" className="p-0 content float-left">
                                <div className="form-group px-3 ">
                                    <TextInput
                                        spellcheck="false"
                                        rows="20"
                                        multiline={true}
                                        fullWidth
                                        id="tabularInput"
                                        name="tabularInput"
                                        label="Copy and Paste your Fasta sequences here"
                                        variant="outlined"
                                        value={formik.values.st26input}
                                        onChange={(event) => {
                                            formik.handleChange(event);
                                            if (!event.target.value) {
                                                resetFileName(); // Clear file input when text input is cleared
                                            }
                                        }}                                        
                                        error={formik.touched.st26input && Boolean(formik.errors.st26input)}
                                        helperText={formik.errors.st26input}
                                    //disabled={authInfo && authInfo.redo}
                                    />
                                </div>

                            </Col>

                        </Row>
                        <hr style={toggle ? {backgroundColor: '#dfdfdf'}: {backgroundColor: '#aea9a9'}}/>
                    </Col>
                    

                     
                </Row>
                <Button
                        className={classes.FastaBtn}
                        color="default"
                        type="submit"
                        disableRipple={true}
                        variant="contained"
                        onClick={() => downloadFasta(jsonData, file)}// Change the desired file name here
                    >
                        Download Fasta Text File
                </Button>                
                <Button variant="contained" disableRipple={true} color="default" className={classes.buttonStyleCancel}  onClick={cncl}>{t('cancel')}</Button>

            </form> 
            }
            { !jsonData &&  
            <form>
            
                <Row>
                    <Col lg="12" md="12" className={"mb-2 " + (authInfo && !authInfo.syscontrol_search_submit ? 'd-block' : 'd-none')}>
                        <Typography className="text-danger">
                            {t('ABsearchDisableText')}
                            {authInfo && authInfo.syscontrol_search_submit_txt}
                            {t('patienceThanksText')}</Typography>
                    </Col>

                    <Col lg="12" md="12" className="mb-2">
                        <h6 className={"appTextColor loginTitle"}>Tabular Sequence Input</h6>
                        <Row className="mb-3">

                            <Col lg="12" md="12" className="p-0 content float-left">
                                <div className="form-group px-3 ">
                                    <TextInput
                                        spellcheck="false"
                                        rows="20"
                                        multiline={true}
                                        fullWidth
                                        id="st26input1"
                                        name="st26input1"
                                        label="Copy and Paste your Tabular sequences here"
                                        variant="outlined"
                                        value={formik.values.st26input1}
                                        onChange={formik.handleChange}
                                        //helperText={formik.errors.st26input1}
                                       //disabled={authInfo && authInfo.redo}
                                    />
                                </div>

                            </Col>

                        </Row>
                        <hr />
                    </Col>
                
                <Col>
                <p className='uploadTextData'> <b id="show-text">Upload .xls (Excel)  file</b></p>
                {/* <input type="file" accept= ".xls,.xlsx" onChange={(e) => setFile(e.target.files[0])} /> */}
                <input type="file" accept=".xls,.xlsx" onChange={showfil} />
                
                {!(formik.values.st26input1 && formik.values.st26input1 !== 'undefined')  ? 
                ( <Button
                    className='cancelButtonDisable' color="default" disableRipple={true} variant="contained" >
                        Convert to Fasta
                    </Button>
                ) : (
                    <Button
                    className='accountInfo'
                    color="default"
                    disableRipple={true}
                    variant="contained"
                    onClick={() => handleConvert()}
                    >
                        Convert to Fasta
                    </Button>
                )} 
                <Button variant="contained" disableRipple={true} color="default" className={classes.buttonStyleCancel} onClick={cncl}>{t('cancel')}</Button>

                </Col>
                
                </Row>
            
                </form>
            }
            
        </div>

    )

}

export default TabularToFasta;
