import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
 
const useStyles = makeStyles((theme) => ({
    modalHeader: {
        fontFamily: 'verdana, helvetica',
        fontSize: '12px',
        width: '100%'
    },
}));
 
 
 
const SaveViewsModal = (props) => {
 
    const classes = useStyles();
    const [selectedOption, setSelectedOption] = useState('createNewView'); // for manage radio button 

    useEffect(() => {
    }, []);
 
    function onCancel(props) {
        props.closeModal();
    }

    function handleOptionChange(event) {
        setSelectedOption(event.target.value);
    }
    return (
        <Modal
            id='modalCss'
            aria-labelledby="contained-modal-title-vcente"
            centered
            size='sm'
            show={props.showModal}
            contentClassName={classes.modalHeader}
        >
            <div>
                <div className="titlebar">
                    <span>Save Preferences</span>
                    <Link href="#" onClick={(e) => e.preventDefault()} className="pull-right">
                        <CloseIcon style={{ fill: '#b5b5b5' }} onClick={() => onCancel(props)} />
                    </Link>
                </div>
                <div style={{ borderTop: '1px solid #b5b5b5', padding:'12px' }}>
                    <br />
                    <form style={{border:'1px solid rgb(204,204,204)', background:'whitesmoke'}}>
                        <table >
                            <tr>
                                <td style={{paddingRight: '4px'}}>
                                    <input
                                        type='radio'
                                        value='createNewView'
                                        checked={selectedOption === 'createNewView'}
                                        onChange={handleOptionChange}
                                        style={{ verticalAlign: 'top' }}
                                    />
                                    <label style={{fontWeight:'bold', color: 'black'}}>Create New View</label>
                                <p style={{paddingLeft:'20px'}}>
                                    <label style={{color: 'black'}}>Name</label>
                                    <input
                                        required
                                        title='This field is required' 
                                    ></input>
                                </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        type='radio'
                                        value='replaceExistingView'
                                        checked={selectedOption === 'replaceExistingView'}
                                        onChange={handleOptionChange}
                                        style={{ verticalAlign: 'top' }}
                                    />
                                    <label style={{fontWeight: 'bold', color: 'black'}}>Replace Existing View</label>
                                    <p style={{paddingLeft:'20px'}}>
                                        <textarea style={{height:'100px', width:'215px'}} disabled="disabled">
                                        </textarea>
                                    </p>
                                </td>
 
                            </tr>
                            <tr>
                                <td style={{paddingRight:'4px', paddingTop:'15px'}}>
                                    <input type='checkbox'></input>
                                    <label>Set this preference as my default</label>
                                </td>
                                
                            </tr>
                            </table>
                    </form>
                </div>
                <div align='right'>
                    <button className='okButton DMTBtn' onClick={() => onCancel(props)}>Cancel</button>
                    <button className='okButton DMTBtn'>Save</button>
                </div>
            </div>
        </Modal>
    );
}
 
export default SaveViewsModal;