import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from '@material-ui/core/Button';
import TextInput from "../Fields/TextInput";
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import { supportMail } from '../../config';
import { makeStyles } from '@material-ui/core/styles';
import ftAccess from '../../services/ftAccess';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	modalHeader: {
		borderBottom: 'none !important',
		paddingTop: '11px',
		paddingRight: '4px',
		marginTop: '-7px',

	},
	modalHeaderDark: {
		borderBottom: 'none !important',
		paddingTop: '11px',
		paddingRight: '4px',
		marginTop: '-7px',
		backgroundColor: '#292929'
	},
	footerDiv: {
		padding: '0 30px',
		marginTop: '-20px',
		marginRight: '-31px',
	},
	buttonStyle: {
		float: 'right',
		textTransform: 'none',
		margin: '4px',
		backgroundColor: '##DB862D !important',
		border: '1px solid ##DB862D !important',
		marginTop: '4px',

	},
	buttonStyleCancel: {
		float: 'right',
		textTransform: 'none',
		margin: '4px',
		color: 'white',
		backgroundColor: '#008EC5 !important',
		border: '1px solid #1F4E79 !important',
		// borderColor: '#1F4E79',	

	},
	modalBoxContent: {
		maxHeight: '675px',
	},
	modalClassContent: {
		position: 'absolute',
		width: '96%',
		height: 'auto',
		top: '30%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		transform: 'translate(-50%, -50%)'
	},
	modalClassContentDark: {
		position: 'absolute',
		width: '96%',
		height: 'auto',
		top: '30%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		transform: 'translate(-50%, -50%)',
		backgroundColor: '#292929'
	},
	colorContainer: {
		backgroundColor: '#EEEEEE',
		marginTop: '-32px',
		// marginLeft: 0px;
		paddingTop: '28px',
		paddingBottom: '65px',
		marginLeft: '10px',
		marginRight: '10px',
		paddingRight: '10px',
		borderRadius: '5px',

	},
	colorContainerDark: {
		backgroundColor: '#56545473',
		marginTop: '-32px',
		// marginLeft: 0px;
		paddingTop: '28px',
		paddingBottom: '65px',
		marginLeft: '10px',
		marginRight: '10px',
		paddingRight: '10px',
		borderRadius: '5px',

	},
	modalBody: {
		// color: '#777',
		textAlign: 'center',
		marginBottom: '8px'
	},

    modalClose: {
		textAlign: 'right',
		// color: '#777',
		marginBottom: '8px',
        marginLeft: '700px'
	}



}));


function ShareEmailModal(props) {

    const innerRef = useRef();
    useEffect(() => innerRef.current && innerRef.current.focus());
	const classes = useStyles();
	const { t, i18n } = useTranslation('common');
	let mailUrl = "mailto:" + supportMail + "?subject=" + props.subjectText;
    const[formaterr, setFormaterr] = useState(false);
    const [emails, setEmails] = useState('');
	const toggle = useSelector(state=> state.setToggle.toggle);

    const handleChangee = (event) => {
		setEmails(event.target.value);
		
		
	}
    const handleCancel = (event) => {
		setEmails('');
        setFormaterr(false);
		props.onHide();	
	}

    const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
      };

    const shareResultsForm = async (eids) => {
        setFormaterr(false);
        var invalid = 0;
        const emailLines = eids.split('\n').map(line => line.trim()).filter(line => line.length > 0);

        // Check validity of each email address
        if (emailLines != ''){
			for (const email of emailLines) {
					if (!isValidEmail(email)) {
						setFormaterr(true);
						invalid=1;           
					}				
			}
		}else{invalid=1;setFormaterr(true);}

        // Join valid email addresses with a semicolon
        const joinedEmails = emailLines.join(';');
        if(!invalid){
			props.actionModal(joinedEmails);
			props.onHide();
             setEmails('');
        }
        
    }

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcente"
			centered
			contentClassName={toggle ? classes.modalClassContent : classes.modalClassContentDark}
			className={classes.modalBoxContent}

		>
			<Modal.Header  className={toggle? classes.modalHeader: classes.modalHeaderDark}>
                     <CloseIcon className={classes.modalClose} onClick={handleCancel} />
			</Modal.Header>
			<Modal.Body className={classes.modalBody}>
				{/* <h5>{props.onMessage}</h5> */}
				<div className={toggle? classes.colorContainer: classes.colorContainerDark}>

					<br></br>
					<h5>Enter the Email addresses that you want to share this with.</h5>
                 
                    <div className={classes.selectorValues + " mx-4"}>
						{/*<TextInput
						fullWidth
						id="Email"
						name="Email"
						label='Enter Email Id list seperated by semicolon'
						variant="outlined"
						value= {emails}
						onChange= {handleChangee}
						inputProps={{
						  maxLength: 200
						}}
						></TextInput>*/}
                           
                    <textarea 
                    fullWidth
                    value={emails} 
                    ref={innerRef}
                    onChange= {handleChangee}
                    id="Email"
						name="Email"
						placeholder="Enter email addresses, one per line" 
                        style={toggle ? { border: '1px solid #dcd7c0', width: '75%', height: '90px', fontFamily: 'verdana, helvetica', fontSize: '14px'} : { border: '1px solid #dcd7c0', width: '75%', height: '90px', fontFamily: 'verdana, helvetica', fontSize: '14px', backgroundColor: '#666666', color: 'white'}}>

                    </textarea>

                
                            
                                  
					</div>
                    {formaterr && <Typography className="text-danger">Invalid email format</Typography>}
					<br></br>
					<br></br>
                   
					<div className={classes.footerDiv}>
                        {emails.length > 0 && 
						<Button onClick={(e) => shareResultsForm(emails)} disableRipple={true} className='accountInfo' color="default" variant="contained">Share Results</Button> }
						<Button onClick={handleCancel} disableRipple={true} className={classes.buttonStyleCancel} color="default" variant="contained">Cancel</Button>
					</div>

				</div>
			</Modal.Body>



			{/* <Modal.Footer>
				
			</Modal.Footer> */}
		</Modal>
	);
}

export default ShareEmailModal;