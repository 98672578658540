import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';

import {makeStyles, withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import TextField from "@material-ui/core/TextField";
import DiscoverService from "../../services/discoverService";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	modalClassConDSI: {
		position: 'absolute',
		width: '96%',
		padding: '35px 15px 0px',
		right: 'auto',
		bottom: 'auto',
	},
	modalClassConDSIDark: {
		position: 'absolute',
		width: '96%',
		padding: '35px 15px 0px',
		right: 'auto',
		bottom: 'auto',
		background: 'rgb(41 41 41)'
	},
	modalText: {
		position: 'absolute',
		top: '10px',
		right: '10px'
	},
	textField: {
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "grey",
			},
			"&:hover fieldset": {
				borderColor: "black", 
			},
			"&.Mui-focused fieldset": {
				borderColor: "grey", 
			},
		},
	},
	// Dark border for dark mode
	textFieldDark: {
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: theme.palette.grey[700], 
			},
			"&:hover fieldset": {
				borderColor: theme.palette.grey[500],
			},
			"&.Mui-focused fieldset": {
				borderColor: theme.palette.grey[300],
			},
		},
	},
}));

const toolTipStyles = {
	tooltip: {
		width: "auto",
		//height: "40px",
		fontSize: '12px',
		textAlign: 'justify',
		padding: "8px 14px 8px 14px",
		boxShadow: "0px 1px 7px #888",
		backgroundColor: '#777',
		color: '#FFF'
	}
};
const CustomTooltip = withStyles(toolTipStyles)(Tooltip);
const REASONS = ['Missing sequences', 'Wrong SEQ ID NO', 'Patent sequence location',
	'Patent assignee', 'Normalized Parent', 'Legal status', 'Other'];

const DiscoverReportDataIssueModal = props => {
	const classes = useStyles();
	const [msg, setMsg] = useState('');
	const [notes, setNotes] = useState('');
	const [success, setSuccess] = useState(false);
	const [values, setValues] = useState([false, false, false, false, false, false, false]);
	const [issueTypeError, setIssueTypeError] = useState('');
	const toggle = useSelector(state => state.setToggle.toggle);


	useEffect(() => {
		setMsg('');
		setNotes('');
		setSuccess(false);
		setValues([false, false, false, false, false, false, false]);
	},[]);

	const close = () => {
		props.closeModal();
	}

	const report = async() => {
		let issues = [];
		for (let i = 0; i < 7; i++) {
			if (values[i]) {
				issues.push(REASONS[i]);
			}
		}
		if (issues.length === 0) {
			setSuccess(false);
			setIssueTypeError('Please select at least one issue type.');
			return;
		} else {
			setIssueTypeError('');
		}
		let data = {
			patent_number: props.patentNumber,
			details: notes,
			issues: issues
		};
		let response = await DiscoverService.reportDataIssue(data);
		if (response) {
			setSuccess(true);
			setMsg(response.response_content);
		}
	}

	const updateCheckbox = (idx) => {
		values[idx] = !values[idx];
		setValues(values);
		if (values[idx]) {
			setIssueTypeError('');
		}
	}

	return (
		<Modal
			size="lg"
			aria-labelledby="contained-modal-title-vcente"
			centered
			show={props.showModal}
			contentClassName={toggle ? classes.modalClassConDSI : classes.modalClassConDSIDark}
			>
			<Link href="#" onClick={(e) => e.preventDefault()} className={classes.modalText}>
				<CloseIcon onClick={close} />
			</Link>
			<div className= "appTextColor" style={{backgroundColor: toggle ? 'gainsboro' : 'rgb(65 65 65)',
				 padding: '20px', marginBottom: '20px'}}>
				<h3 style={{paddingTop: '0px', top: '0px'}}>1. Select Issue Type(s)</h3>
				{
					REASONS.map((label, idx) => (
						<div key={'checkbox_' + idx}>
							<input type="checkbox" style={{verticalAlign: 'middle'}}
								   value={values[idx] || false} id={'cb' + idx}
								   onChange={() => updateCheckbox(idx)}/>
							<label htmlFor={'cb' + idx}>&nbsp;{REASONS[idx]}</label>
						</div>
					))
				}
				<h3>2. Details</h3>
				<div>
					{/* <TextField id="notes" name="notes" variant="outlined"
							   onChange={(e) => setNotes(e.target.value)}
							   inputProps={{maxLength: 256}} defaultValue={notes} placeholder={""}
							   style={{width: '700px', verticalAlign: 'middle'}}
							   fullWidth={false} autoFocus={true} spellCheck="false" autoComplete="off"/> */}
					<TextField
						id="notes"
						name="notes"
						variant="outlined"
						onChange={(e) => setNotes(e.target.value)}
						inputProps={{ maxLength: 256 }}
						defaultValue={notes}
						placeholder=""
						style={{ width: '700px', verticalAlign: 'middle' }}
						fullWidth={false}
						autoFocus={true}
						spellCheck="false"
						autoComplete="off"
						className={toggle ? classes.textField : classes.textFieldDark}
					/>
				</div>
				{success &&
					<p style={{fontSize: '18px'}}>{msg}</p>
				}
				<br></br>
				<div style={{paddingBottom: '0px'}}>
					<CustomTooltip arrow title={issueTypeError} placement="top">
						<button onClick={report} className={'pull-right large-button ' + (success ? 'grey' : (issueTypeError === '' ? 'gqblue' : 'gqblue-alert'))} disabled={success}>Report</button>
					</CustomTooltip>
					<button onClick={close} className={'pull-right large-button ' + (success ? 'gqblue' : 'grey')}>{success ? 'OK' : 'Cancel'}</button>
				</div>
			</div>
		</Modal>
	);
}

export default DiscoverReportDataIssueModal;
