import React, { useState, useEffect } from "react";
import {useHistory, useParams} from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import fullTextService from "../../services/fulltextsearch";
import searchResSequence from "../../services/searchResSequence";
import TextInput from "../../shared/Fields/TextInput";
import { toast } from "react-toastify";
import SyntaxExplainer from "./FullTextSyntaxCard/SyntaxExplainer"
import Paging from "../../shared/components/Paging";

import FullTextSearchBox from "../../shared/components/FullTextSearchBox/FullTextSearchBox";
import FullTextResultsV2 from "./FullTextResults/FullTextResultsV2"
import FullTextPatentListModal from "./FullTextModals/FullTextPatentListModal";
import FullTextResultInfoModal from "./FullTextModals/FullTextResultInfoModal";
import "./FullText.css"
//import DocManagerLink from "../FullDocManager/DocManagerLink";

//import testy from "./ftrestest";


function FullTextSearch(props){ 

   

	const { t, i18n } = useTranslation("common");
	const history = useHistory();

	const userInfo = useSelector((state) => state.setUserInfo);

	useEffect(() => {
        if (userInfo && userInfo.current_user) {
			if (!(userInfo && userInfo.ftAccess == true)) {
		 		history.push('/home');
			}
        }
    }, [userInfo]);


	const pageCount = userInfo["Paging size"]? userInfo["Paging size"]: 20;

	const { workflowId } = useParams();
	const [redoString, setRedoString] = useState("");

	//for search
	const [ftNodeId, setFtNodeId ] = useState(null);

	const [ftSearchString, setFtSearchString] = useState("");
	const [luceneQueryValue, setLuceneQueryValue] = useState("");
	const [queryCount, setQueryCount] =  useState(0);
	const [docCount, setDocCount] = useState(0);
	const [famCount, setFamCount] = useState(0);


	//for results
		//save Results
		const [saveResultsTitle, setSaveResultsTitle] = useState("");
		const [grouping, setGrouping] = useState("Document")
		const [saveResultsError, setSaveResultsError] = useState(false);

	const [docSearchResults, setDocSearchResults] = useState({});
	const [numResultsFound, setNumResultsFound] = useState(null);
	const [currentPage, setCurrentPage] = useState(0);

	//modals
	const [syntaxShow, setSyntaxShow] = useState(true); 

	const [resultInfoModal, setResultInfoModal] = useState(false);
		const [resInfo, setResInfo] = useState({
			isAuthoritySorting: true,
			isDateSorting: true,
			dateSortingField: "filing_date",
			dateSortingDirection: "desc",
			configure1: "US",
			configure2: "EP",
			configure3: "WO",
			configure4: "",
			configure5: ""

		})

		useEffect(()=>{
			if(luceneQueryValue!=="" || pnListFormatted!==""){
				searchForResults()
			}
		},[resInfo])


	const [patentListModal, setPatentListModal] = useState(false);
		const [pnListEditMode, setPnListEditMode] = useState(false);
		const [pnList, setPnList] = useState("");
		const [pnListFormatted, setPnListFormatted] = useState("")
		const [missingPns , setMissingPns] = useState("");

		const toggle = useSelector(state => state.setToggle.toggle);

	useEffect(async() => {
		document.title = 'GenomeQuest - Patent Full-Text Search'
		
		// if (!(userInfo && userInfo.ftAccess == true)) {
		// 	history.push('/home');
		// }

		let queryObj = "";
		if(props && props.location && props.location.state){
			queryObj = props.location.state.detail;
			setRedoString(queryObj.query);

			let pns = queryObj.pns
			if(pns != "" && pns != null ){
				setPnList(pns);
			}
		}else if (workflowId) {
			const getFtQueryResponse = await searchResSequence.getFulltextQuery(workflowId);

			if (getFtQueryResponse && getFtQueryResponse.response_status == 0 && getFtQueryResponse.response_content) {
				var queryString = getFtQueryResponse.response_content.queryString;

				let pns = getFtQueryResponse.response_content.pns
				if(pns != ""){
					setPnList(pns);
					setPnListFormatted(pns);
				}
				setRedoString(queryString);
			}
		}	
		
	
		if(window.data){
			if( window.data.exportData !== undefined && window.data.exportData !== null){
				let exportdata = window.data.exportData;
				setPnList(exportdata);
				setPnListFormatted(exportdata);					//savePatentList(await navigator.clipboard.readText());
			}
		}	


	}, [userInfo]);


	const searchForResults = async (goToPageNumber, localGrounping) => { //mostly unchecked

		setSyntaxShow(false)
		let data={};
		luceneQueryValue=="" ? data.json_query = "{}" : data.json_query = luceneQueryValue
		data.query_string=ftSearchString

		data.pnList=pnListFormatted

		if(!localGrounping){
			data.grouping = grouping
		}else{
			data.grouping=localGrounping
		}
		
		if (workflowId) { 
			data.parent_id = workflowId;
		}

		if(!goToPageNumber){
			data.start = 0;
		}else{
			data.start= (goToPageNumber-1)* pageCount;
		}
		
		data.rows = pageCount;

		data.Use_authority_sorting=resInfo.isAuthoritySorting;
		if (resInfo.isAuthoritySorting) {
			 let authorityParam = [resInfo.configure1, resInfo.configure2, resInfo.configure3, resInfo.configure4, resInfo.configure5].filter(Boolean).join(",");
			 data.Authorities=authorityParam;
		}

		data.Use_date_sorting=resInfo.isDateSorting;
		if (resInfo.isDateSorting) {
			data.Date_sorting_field=resInfo.dateSortingField;
		 	data.Date_sorting_dir=resInfo.dateSortingDirection;
		}

	
		getResultCount(luceneQueryValue, pnListFormatted);
		 const searchQueryRes = await fullTextService.getFullTextSearchResult(
		   	history,
		   	"",
		   	data
		 );
		//const searchQueryRes = testy.testRes;

		if (searchQueryRes &&
			searchQueryRes.response_status == 0 &&
			searchQueryRes.response_content) {
			setDocSearchResults(searchQueryRes.response_content);

			let pageNum = 1;
			pageCount? ( pageNum = Math.floor(searchQueryRes.response_content.start/pageCount)+1) : (pageNum= Math.floor(searchQueryRes.response_content.start/20)+1);
			setCurrentPage(pageNum)
			setDocCount(searchQueryRes.response_content.documents)
			setFamCount(searchQueryRes.response_content.families)

			setNumResultsFound(searchQueryRes.response_content.numFound)

			if(searchQueryRes.response_content.missingPns){
				if(searchQueryRes.response_content.missingPns.length > 0){
					var newString = "";
					if(Array.isArray(searchQueryRes.response_content.missingPns)){
						for(let i =0; i < searchQueryRes.response_content.missingPns.length; i++){
							newString+=searchQueryRes.response_content.missingPns[i]+', ';
						}
					}else{
						newString = searchQueryRes.response_content.missingPns
					}

					setMissingPns(newString);
				}else{
					setMissingPns("")
				}
	
			}
	
		}else{
			setDocSearchResults({});
		}
	}

	let [lastCountTimer, setLastCountTimer] = useState(0);
	const getResultCount = async (luceVal, pnListParam) => {

		if(pnListParam == undefined || pnListParam == null || pnListParam == ""){
			pnListParam = pnListFormatted
		}
		//const results = Test.testCount;
		if(pnListParam !="" || (luceVal != "" && luceVal != null && luceVal != undefined)){

			if(pnListParam != "" && luceVal==""){
				luceVal ="{}";
			}


			let time = Date.now()
			if( time - lastCountTimer > 3000){
			setLastCountTimer(Date.now())

				let postData = {};
				postData['json_query'] = luceVal;
				postData['pnList'] = pnListParam;
			
				const results = await fullTextService.getCountByTerm(history, grouping, postData);

				if(results){
					if(results.response_status == 0 && Number.isInteger(results.response_content)){
						setQueryCount(results.response_content);
					}
				}

			}
		}
	}

	const submitSaveResultAs = async () => {
		var savquery = null
		luceneQueryValue!=""? savquery= luceneQueryValue: savquery="{}";
		if (saveResultsTitle !=="" && (savquery != null || pnListFormatted!=="")) {
			let data ={
				title: saveResultsTitle,
				json_query: savquery,
				query_string: ftSearchString,
				pnList: pnListFormatted,
				parent_id: workflowId,
				grouping: grouping,
				Use_date_sorting: resInfo.isDateSorting,
				Date_sorting_field: resInfo.dateSortingField,
				Date_sorting_dir: resInfo.dateSortingDirection,
				Use_authority_sorting: resInfo.isAuthoritySorting,
				Authorities: [resInfo.configure1, resInfo.configure2, resInfo.configure3, resInfo.configure4, resInfo.configure5].filter(Boolean).join(",")

			}
			fullTextService.saveFTDocument(history, "", data);
			history.push('/searchResult')
		}
	}

	const [lastCopyTime, setLastCopyTime] = useState(0);
	const copyToClip = async (clipString) => {
		if(Date.now() - lastCopyTime > 2000){
			await navigator.clipboard.writeText(ftSearchString);
			toast.success("Text Copied");
			setLastCopyTime(Date.now());
		}
	}

	const handleSaveResultsNameChange = (e) =>{
	 	const { name, value } = e.target;
		setSaveResultsTitle(value);
		// setSaveResultAsError(false);
		value ? setSaveResultsError(false) : setSaveResultsError(true);
	}

	const clearParser = ()=>{
		let node = document.getElementById(ftNodeId);

		if(node){
			node.innerHTML="";
			setLuceneQueryValue("");
			setFtSearchString("");
		}
	}

	function goToHistory(){
		history.push('/ftSearchHistory');
	}

	function goToSearchTerms(){
        window.open('#/UserSearchTerms', '_blank');
	}

	function goToPage(goToPageNumber){
		window.scrollTo(0, 0);
		searchForResults(goToPageNumber)
	}

	const [searchLock, setSearchLock] = useState(false);
	function handleHotKeys(event){
		let changeType = event.code
		if( changeType == "Enter" && !patentListModal){
			if((luceneQueryValue!="" || pnListFormatted!="") && !searchLock ){
				searchForResults(1);
			}
		}
	}

	return (
		<div className="fixWidth"
			onKeyDown={(e)=>handleHotKeys(e)}
		>
			{userInfo.current_user.user_class_id != 12 &&
			<span>
			{/* <div className="appTextColor" style={{ marginBottom: "-20px", zIndex: "500", position: "relative", width: "fit-content"}}>
				<DocManagerLink/>
			</div>
			 */}
			{(luceneQueryValue!="" || pnListFormatted!="") &&
			<div className="resCounter">
				<div className="font14 resCounterNum">{queryCount} Results</div>
			</div>
			}
			
			<div>
				<FullTextSearchBox
					setSearchLock={(bool) => setSearchLock(bool)}
					keyWordsInContext={(retRay)=>{return retRay}}
					pnListFormatted = {pnListFormatted}
					starterString={redoString}
					endString={(string)=>setFtSearchString(string)}
					setLuceneQueryValue={(queryValue)=>{setLuceneQueryValue(queryValue); getResultCount(queryValue, pnListFormatted)}}
					setNodeId = {(stringholder)=>setFtNodeId(stringholder)}
				>
				</FullTextSearchBox>
			</div>
			<div className="buttonBar">
				<span className="HistoryTable">
					<span onClick={()=>{setPatentListModal(true); setPnListEditMode(false)}} className={"appTextFont appLink"}>Patent Numbers</span>
				</span>
				<span onClick={()=> setSyntaxShow(!syntaxShow)} className={"appTextFont appLink linkPadding"} disabled={syntaxShow}>Tooltips</span><span className="appTextColor">|</span>
				<span onClick={()=> goToSearchTerms()} style={{textWrap: "nowrap"}}className={"appTextFont appLink linkPadding"}>Search Terms</span><span className="appTextColor">|</span>
				<span onClick={()=> goToHistory()} style={{paddingRight:"10px"}} className={"appTextFont appLink linkPadding"}>History</span>
				<Button
                    variant="contained"
                    disableRipple={true}
					className={"ftSearchButton "+( luceneQueryValue!="" ? 'loginSubmitButton' : 'submitDisabled')}
                    onClick={()=>copyToClip()}
					disabled={luceneQueryValue==""}
					style={toggle ? {} : {background: '#736d6d' }}
	            >
                {t("Copy")}
                </Button>
				<Button
					variant="contained"
					disableRipple={true}
					className={"mx-2 ftSearchButton " + (luceneQueryValue!="" ? 'loginSubmitCancel' : 'submitDisabled')}
					onClick={()=>clearParser()}
					disabled={luceneQueryValue==""}
					style={toggle ? {} : {background: '#736d6d' }}

				>
				{t("Clear")}
				</Button>
				<Button
					variant="contained"
					disableRipple={true}
					className={"ftSearchButton "+( luceneQueryValue!="" || pnListFormatted!="" ? 'loginSubmitButton' : 'submitDisabled')}
					onClick={() => searchForResults()}
					disabled={luceneQueryValue == "" && pnListFormatted == ""}
					style={toggle ? {} : {background: '#736d6d' }}
				>
				{t("Submit")}
				</Button>
			</div>

			{syntaxShow &&
			<div>
				<SyntaxExplainer></SyntaxExplainer>
			</div>
			}

			<div className="paddingTopFix">
                {Object.keys(docSearchResults).length > 0 && 
                    <div className="fixWidth2"> 
                        <p className="subHeading">        
                        <span className="mr-2">Found</span>
                        
						{grouping && grouping == 'Family' && 
							<span onClick={() => {setGrouping('Document'); searchForResults(1, 'Document') }} className="appLink bold">
								{docCount} Documents
							</span>
						}
                        {grouping && grouping == 'Document' && 
							<span>
								{docCount} Documents
							</span>
                        }
                        <span className="m-1">/</span>
                        {grouping && grouping == 'Document' && 
						<span onClick={() => { setGrouping('Family'); searchForResults(1, 'Family') }} className="appLink bold">
							{famCount} Families
						</span>}
                        {grouping && grouping == 'Family' && 
						<span>
                            {famCount} Families
                        </span>
                        }
                        <span className={"m-2"}>|</span>
                        <span className={'appLink'} onClick={() => {setResultInfoModal(true)}}>Configure</span>
                        </p>
                        <div className={'mb-5'}>
                            <TextInput
                                id="saveResultAs"
                                name="saveResultAs"
                                label={t('nameYourSearch')}
                                variant="outlined"
                                onChange={(event)=>{handleSaveResultsNameChange(event)}}
                                inputProps={{
                                    maxLength: 188
                                }}
                                fullWidth={true}
                                value={saveResultsTitle}
								error = {saveResultsError}
                                className={"w-50"}
                            />
                            <Button
                                variant="contained"
                                disableRipple={true}
                                disabled = {docSearchResults && docSearchResults.documents == 0 ? true : false}
                                className={ docSearchResults && docSearchResults.documents == 0 ? 'paddingFix marginLeft10': ' paddingFix marginLeft10 loginSubmitButton'}
                                onClick={()=>submitSaveResultAs()}
								style={!toggle? {color: '', backgroundColor: 'grey'}: {}}
                            >
                                {grouping && grouping == 'Document' ? `Save ${docSearchResults && docSearchResults.documents < 20000 ? docSearchResults.documents : '20000'} Documents` : `Save ${docSearchResults && docSearchResults.families < 20000 ? docSearchResults.families : '20000' } Families`}
                            </Button>

                            <Button
                                variant="contained"
                                disableRipple={true}
                                disabled = {missingPns === ""}
                                className={(missingPns === "") ? "marginLeft10" : "loginSubmitCancel marginLeft10"}
                                onClick={()=>{setPatentListModal(true); setPnListEditMode(true)}}
								style={!toggle? {color: '', backgroundColor: 'grey'}: {}}
                            >
                                Missing Patents Numbers
                            </Button>
                        </div>
                </div>
                }
		    </div>
			</span>}

			

			{Object.keys(docSearchResults).length > 0  &&
				<>
					<FullTextResultsV2
						data={docSearchResults}
					/>
					<Paging
						numberOfPages={Math.ceil(numResultsFound/pageCount)}
						currentPage={currentPage}
						goToPage={(pageNumber) =>{goToPage(pageNumber)}}
					>					
					</Paging>
				</>
			}

			<FullTextPatentListModal
				patentString={pnList}
				missingPns={missingPns}
				openOrClosed={patentListModal}
				editMode={pnListEditMode} 
				cancel={()=>{setPatentListModal(false); setPnListEditMode(false)}} 
				save={(str)=>{setPnListFormatted(str); setPatentListModal(false); setPnListEditMode(false); setPnList(str)}}
			/>

			<FullTextResultInfoModal
				openOrClosed = {resultInfoModal}
				resInfo = {{...resInfo}}
				cancel = {()=>{setResultInfoModal(false)}}
				save = {(tempInfo)=>{setResInfo({...tempInfo});setResultInfoModal(false)}}
			/>
</div>
);}



export default FullTextSearch;