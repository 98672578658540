import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: '215px',
    padding: '10px 0px',
    margin: '-8px 0 0 -5px',
  },
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 100,
    top: '150px',
  },
  smallBox: {
    width: '14%',
    padding: '10px 0px',
    margin: '-8px 0 0 -5px',
    '& .MuiSelect-iconOutlined': {
      right: '0px'
    }
  },
  whiteText: {
    color: 'white',
  },
  input: {
    color: 'wheat',  // Color for the input text
  },
  inputdark: {
    color: 'white',
  },
  outlinedWhite: {
    '& .MuiOutlinedInput-root': {
      borderColor: 'white',
      '& fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
      '&.Mui-disabled': {
        border: '0.5px solid #aeadad',
      },
    },
  },
  noPadding: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  }
}));

export default function SelectBox(props) {
  const classes = useStyles();
  const toggle = useSelector(state => state.setToggle.toggle);

  const items = props.items;
  return (
    <TextField
      id={props.id}
      name={props.name}
      select
      className={`${props.className} ${props.smallSelectBox ? classes.smallBox : classes.textField} ${toggle ? '' : classes.outlinedWhite}`}
      value={props.value}
      InputLabelProps={{ shrink: false }}
      InputProps={{
        classes: { input: toggle ? classes.input : classes.inputdark },
      }}
      SelectProps={{
        MenuProps: {
          className: (props.smallSelectBox ? "" : classes.menu),
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null,
          classes: {
            list: classes.noPadding,  // Apply noPadding class to remove padding
          }
        },
        classes: { icon: toggle ? '' : classes.input },
      }}
      margin={props.margin}
      variant={props.variant}
      onChange={props.onChange}
      disabled={props.disabled}
      onBlur={props.onBlur}
    >
      {items && items.length > 0 && items.map((option) => (
        <MenuItem key={option.value} value={option.value} style={toggle ? { color: '#292929', backgroundColor: '#ffffff' } : { color: '#ffffff', backgroundColor: '#292929' }}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}