import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextInput from '../../shared/Fields/TextInput';
import utilsService, { TOAST_TYPE } from '../../helpers/utils';
import DiscoverService from "../../services/discoverService";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        width: '96%',
        margin: '0 auto 28px',
        minHeight: '260px',
        padding: '23px 16px 14px',
    },
    buttonStyleCancel: {
        float: 'right',
        textTransform: 'none',
        margin: '4px',
        color: 'white',
        backgroundColor: '#008EC5 !important',
        border: '1px solid #1F4E79 !important',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        }
    },
    colorConDSI: {
        backgroundColor: 'gainsboro',
        padding: '28px',
        paddingTop: '28px',
        paddingBottom: '65px',
        paddingRight: '10px',
        borderRadius: '5px',
    },
    colorConDSIDark: {
        backgroundColor: 'rgba(238, 238, 238, 0.12)',
        padding: '28px',
        paddingTop: '28px',
        paddingBottom: '65px',
        paddingRight: '10px',
        borderRadius: '5px',
    },
    footerDiv: {
        padding: '0 10px'
    },
    loginSubmitCancel: {
        backgroundColor: '#0182C5',
        borderColor: '#1F4E79',
        border: '1px solid #1F4E79',
        color: 'white',
        margin: '4px',
        textTransform: 'capitalize',
        '&:hover': {
        backgroundColor: '#0182C5',
        boxShadow: 'none',
        },
    },
    modaltext: {
        position: 'absolute',
        top: '10px',
        right: '10px'
    },
    modalClassConDSI: {
        position: 'absolute',
        width: '96%',
        padding: '35px 15px 15px',
        right: 'auto',
        bottom: 'auto',
    },
    modalClassConDSIDark: {
        position: 'absolute',
        width: '96%',
        padding: '35px 15px 15px',
        right: 'auto',
        bottom: 'auto',
        backgroundColor: '#292929'
    },
    modalBoxContent: {
        maxHeight: '675px',
    },
    loaderHide: {
        display: 'none'
    },
    loader: {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height:' 100%',
        zIndex: '999999',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        display: 'block',
    },
    loaderPosition:{
        marginLeft: '602px',
        marginTop: '300px',
    }
}));

function ClustalOmega() {

    const { t } = useTranslation('common');
    const classes = useStyles();
    const history = useHistory();
    const formData = useState({});
    const toastRef = useRef(null)
    const [showLoader, setShowLoader] = useState(false);
    const toggle = useSelector(state => state.setToggle.toggle);
    const userInfo = useSelector(state => state.setUserInfo);

    function loadFile() {
       if (window.File && window.FileReader && window.FileList && window.Blob) {
            let preview = document.getElementById('textInput');
            let file = document.querySelector('input[type=file]').files[0];
            let reader = new FileReader();
      
            let textFile = /text.*/;
            if (file.type.match(textFile)) {
               reader.onload = function (event) {
               preview.value = event.target.result;
               formik.setFieldValue("textInput", event.target.result);
              };
            } else {
              preview.innerHTML =
                "<span class='error'>It doesn't seem to be a text file!</span>";
            }
            const size = file.size // it's in bytes
            // Added a max file size limit of 2MB
            if (size > 2 * 1024 * 1024) {
                utilsService.showToast(TOAST_TYPE.ERROR, 'Error: A maximum file size of 2 MB is exceeded', toastRef, { autoClose: 3000 });
                return;
            }

            reader.readAsText(file);
         } else {
            alert('Your browser is too old to support HTML5 File API');
         }
       
    }

    function resetFileInput() {
        let fileInput = document.querySelector('input[type=file]');
        fileInput.value = '';
    }

    function cancel() {
        history.push('/home');
    }

    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const formik = useFormik({
        initialValues: {
            textInput: formData.textInput
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            let { textInput } = values;

            // Validate sequences
            let count = 0;
            let sequences = "";
            const lines = textInput.trim().split(/\r?\n/);
            for (let line of lines) {
                line = line.trim();
                if (line.startsWith('>')) {
                    count++;
                    if (count > process.env.REACT_APP_CLUSTAL_LIMIT) {
                        utilsService.showToast(TOAST_TYPE.INFO, 'Only first 2,000 sequences will be processed', toastRef, { autoClose: 3000 });
                        await sleep(3000);
                        break;
                    }
                }
                sequences += line + "\n"; // Only keeps the sequences under limit
            }
            if (count < 2) {
                utilsService.showToast(TOAST_TYPE.ERROR, 'Error: A minimum of 2 sequences is required', toastRef, { autoClose: 3000 });
                return;
            }

            let postData = {
                input: sequences
            }

            setShowLoader(true);
            // Alert when takes more than 5 minutes
            let countdown = 300;
            const timer = setInterval(() => {
                (async () => {
                    countdown--;
                    if (countdown <= 0) {
                        setShowLoader(false);
                        clearInterval(timer);
                        utilsService.showToast(TOAST_TYPE.INFO, 'It takes more than 5 minutes to proceed your request, we will send an email to you when it is completed', toastRef, { autoClose: false });
                    }
                })();
            }, 1000);

            let response = await DiscoverService.exportClustal(postData);
            if (response) {
                setShowLoader(false);
                const url = "#/clustaloreport/" + response.response_id;
                const newWindow = window.open(url, '_blank');
                newWindow.reportString = response.response_content;
                if (response.response_content && response.response_content.type === 'GQEXCEPTION') {
                    newWindow.message = response.response_content.message;
                } else if (response.response_content && response.response_content.type === 'GQSCRIPTGENERALEXCEPTION') {
                    newWindow.message = "Error: " + response.response_content.message + " Please retry with smaller and fewer sequences.";
                }
                newWindow.window.focus();
                clearInterval(timer);
            }
        },
    });
    return (
        <div className={classes.grow}>
             {userInfo.current_user.user_class_id != 12 &&
            <form name="currentForm" onSubmit={formik.handleSubmit} className={classes.loginDiv}>

                <Row>
                    <Col lg="12" md="12" className="mb-2">
                        <h6 className={"appTextColor loginTitle"}>FASTA Sequence Input</h6>
                        <Row className="mb-3">

                            <Col lg="12" md="12" className="p-0 content float-left">
                                <div className="form-group px-3 ">
                                    <TextInput
                                        spellcheck="false"
                                        rows="20"
                                        multiline={true}
                                        fullWidth
                                        id="textInput"
                                        name="textInput"
                                        label="Copy and Paste your FASTA sequences here, up to 2,000 sequences or a maximum file size of 2 MB."
                                        variant="outlined"
                                        value={formik.values.textInput}
                                        onChange={(event) => {
                                            formik.handleChange(event);
                                            if (!event.target.value) {
                                                resetFileInput(); // Clear file input when text input is cleared
                                            }
                                        }}        
                                        error={formik.touched.textInput && Boolean(formik.errors.textInput)}
                                        helperText={formik.errors.textInput}
                                    />
                                </div>

                            </Col>

                        </Row>
                        <hr style={toggle ? {backgroundColor: '#dfdfdf'}: {backgroundColor: '#aea9a9'}}/>
                    </Col>

                    <Col lg="12" md="12" className="float-right mb-3">

                    <p className='uploadTextData'> <b id="show-text">Upload FASTA Sequences file</b></p>
                    <input type="file" accept= ".txt" onChange={loadFile} />
                        <Button className={formik.values.textInput ? 'accountInfo': 'cancelButtonDisable'} color="default" disableRipple={true}
                                type="submit" variant="contained" disabled={!formik.values.textInput}>Submit</Button>
                        <Button variant="contained" disableRipple={true} color="default" className={classes.buttonStyleCancel} onClick={cancel}>{t('cancel')}</Button>
                    </Col>
                </Row>

            </form>}
            {showLoader &&
                <div className={classes.loader}>
                    <div className={classes.loaderPosition}>
                        <div className="spinner-container">
                            <div className="spinner-socket">
                                <div className="spinner-hex spinner-tile-1">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                                <div className="spinner-hex spinner-tile-2">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                                <div className="spinner-hex spinner-tile-3 spinner-row-2">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                                <div className="spinner-hex spinner-tile-4 spinner-row-2">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                                <div className="spinner-hex spinner-tile-5 spinner-row-2">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>

    )

}

export default ClustalOmega;
