import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
const useStyles = makeStyles((theme) => ({
    modalHeader: {
        width: '68% !important',
    },
    modalClassContent: {
		position: 'absolute',
		width: '96%',
		height: 'auto',
		top: '30%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		transform: 'translate(-50%, -50%)'
	},
	modalClassContentDark: {
		position: 'absolute',
		width: '96%',
		height: 'auto',
		top: '30%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		transform: 'translate(-50%, -50%)',
		backgroundColor: '#292929'
	},
}));
const DiscoverFilterDeleteModal = (props) => {
    const classes = useStyles();
    const toggle = useSelector(state => state.setToggle.toggle);
    const removeCustomFilterFromList = (filter, fieldConfig, idx) => {
        props.removeCustomFilterFromListAction(filter, fieldConfig, idx);
        props.closeModal();
    }
    useEffect(() => {
    }, []);
    return (
        <Modal
            id='modalCss'
            aria-labelledby="contained-modal-title-vcente"
            centered
            show={props.showModal}
            // contentClassName={classes.modalHeader}
            contentClassName={toggle ? classes.modalClassContent : classes.modalClassContentDark}
        >
            <div>
                <div className="titlebar">
                    <span>Delete</span>
                    <Link href="#" onClick={(e) => e.preventDefault()} className="pull-right">
                        <CloseIcon style={{ fill: '#b5b5b5' }} onClick={props.closeModal} />
                    </Link>
                </div>
                <div style={{ padding: '24px' }}>
                    Are you sure you want to delete this?
                </div>
                <div style={{ float: 'right' }}>
                    <button className='okButton DMTBtn' onClick={(e) => { removeCustomFilterFromList(props.filter, props.fieldConfig, props.deleteIdx); e.preventDefault(); }}>Delete</button>
                    <button className='DMTBtn' onClick={props.closeModal} >Cancel</button>
                </div>
            </div>
        </Modal>
    );
}
export default DiscoverFilterDeleteModal;
