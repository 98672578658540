import React, {useState, useEffect} from 'react';
import {useHistory, useParams, useLocation} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import searchResSequence from '../../services/searchResSequence';
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({

    loaderHide: {
        display: 'none'
    },

    loader :{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height:' 100%',
        zIndex: '999999',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        display: 'block',

    },
    loaderPosition:{
        marginLeft: '602px',
        marginTop: '300px',
    }

}));

function VariationReport() {
    const { id, file } = useParams();
    const history = useHistory();
    const classes = useStyles();
    const [content, setContent] = useState('');
    const [showLoader, setShowLoader] = useState(false);

    useEffect(async() => {
        let timer;
        console.log("VM Report ID:" + id + ",file:" + file);
        let vmReportString = localStorage.getItem("vmReportString_" + file);
        if (vmReportString !== undefined && !_.isEmpty(vmReportString)) {
            console.log("VM Report content loaded.");
            setContent(vmReportString);
            localStorage.setItem("vmReportString_" + file, ""); // Clear the storage after the usage
        } else if (id && file) {
            let loaded = false;
            setShowLoader(true);
            const response = await searchResSequence.getVmReport(history, id, file, false);
            console.log(response, "resonse for variation ");
            if (response) {
                loaded = true;
                setShowLoader(false);
                console.log("VM Report loaded.");
                setContent(response);
            } else {
                timer = setInterval(() => {
                    (async () => {
                        if (!loaded) {
                            const res = await searchResSequence.getVmReport(history, id, file, false);
                            if (res) {
                                loaded = true;
                                setShowLoader(false);
                                console.log("VM Report loaded from timer.");
                                setContent(res);
                            }
                        }
                    })();
                }, 3000);
            }
        }
        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };

    }, []);

    function print() {
        const printContents = document.getElementById("content").innerHTML;
        if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
            const popupWin = window.open('', '_blank', 'width=600,height=600,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
            popupWin.window.focus();
            popupWin.document.write('<!DOCTYPE html><html><body onload="window.print()"><div style="width: 900px">' + printContents + '</div></body></html>');
            popupWin.document.close();
            popupWin.onbeforeunload = function (event) {
                popupWin.close();
                return '.\n';
            };
            popupWin.onabort = function (event) {
                //popupWin.document.close();
                popupWin.close();
            }
        } else {
            const popupWin = window.open('', '_blank', 'width=800,height=600');
            popupWin.document.open();
            popupWin.document.write('<html><body onload="window.print()" style="width: 900px">' + printContents + '</body></html>');
            popupWin.document.close();
        }
    }

    async function download() {
        const buffer = await searchResSequence.getVmReport(history, id, file, true);
        const blob = new Blob([buffer], {type: 'application/msword'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = file + '.doc';
        link.click();
    }

    return (
        <div>
            {content &&
                <div style={{float: "right"}}>
                    <Button color="inherit" onClick={() => download()}><span className="appLink text-initial appTextFont" >Download</span></Button>
                    <Button color="inherit" onClick={() => print()}><span className="appLink text-initial appTextFont" >Print</span></Button>
                </div>
            }
            <div id="content">{ReactHtmlParser(content)}</div>
            {showLoader &&
                <div className={classes.loader}>
                    <div className={classes.loaderPosition}>
                        <div className="spinner-container">
                            <div className="spinner-socket">
                                <div className="spinner-hex spinner-tile-1">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                                <div className="spinner-hex spinner-tile-2">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                                <div className="spinner-hex spinner-tile-3 spinner-row-2">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                                <div className="spinner-hex spinner-tile-4 spinner-row-2">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                                <div className="spinner-hex spinner-tile-5 spinner-row-2">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
export default VariationReport;
