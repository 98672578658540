import { post, get } from '../helpers/fetchServicesMethods';

async function saveState(data) {
    try {
        let apiUrl = 'do=gqresult2.save_state&format=json';
        return await post(apiUrl, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function loadVariationReport(data, apiKey) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/vmreport';
        return await post(apiUrl, data, null, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function variationAnalysisOps(data) {
    try {
        let apiUrl = 'do=gqft.vm_report_ops&format=json&id=' + data.dbId;
        return await post(apiUrl, data, null, null)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}


async function loadResultState(dbId, reportId) {
    let apiUrl = 'do=gqresult2.get_state&format=json&db=' + dbId + '&report=' + reportId;
    try {
        return await get(apiUrl)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function getApi(dbId, history) {
    let apiUrl = 'do=gqresult2.get_GQApi_info&format=json&db=' + dbId;
    try {
        return await get(apiUrl, history)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function getAuthInfo(dbId, history) {
    let apiUrl = 'do=gqresult2.get_auth_info&format=json&db=' + dbId;
    try {
        return await get(apiUrl)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function getAuthInfoAB(wfId) {
    let apiUrl = 'do=gqresult2.get_auth_info_4abov&format=json&workflowId=' + wfId;
    try {
        return await get(apiUrl)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function updateLastAccess(data, apiKey) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/workflow/updateLastAccess';
        return await post(apiUrl, data, null, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function getABReports(data, apiKey) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/getABReportNames';
        return await post(apiUrl, data, null, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function exportABVariants(data, apiKey) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/exportVariant';
        return await post(apiUrl, data, null, apiKey ,"arraybuffer")
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function makeOverviewCall(data, apiKey) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/overview';
        return await post(apiUrl, data, null, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function getAlignmentDescriptions(data, apiKey) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/AlignmentDescriptions';
        return await post(apiUrl, data, null, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function makeCDRVariationsCall(data, apiKey) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/cdrvariations';
        return await post(apiUrl, data, null, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function makeCDRCombinationsCall(data, apiKey) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/cdrCombinations';
        return await post(apiUrl, data, null, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function matchingDocumentsAll(data, apiKey) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/matchingDocumentsAll';
        return await post(apiUrl, data, null, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function matchingDocuments(data, apiKey) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/matchingDocuments';
        return await post(apiUrl, data, null, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function saveABReport(data, apiKey) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/saveABReport';
        return await post(apiUrl, data, null, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function editABReport(data, apiKey) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/editABReport';
        return await post(apiUrl, data, null, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function delABReport(data, apiKey) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/delABReport';
        return await post(apiUrl, data, null, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function matchingSequences(data, apiKey) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/matchingSequences';
        return await post(apiUrl, data, null, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function getSummary(data, apiKey) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/summary';
        return await post(apiUrl, data, null, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function loadAbReport(data, apiKey){
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/loadABReport';
        return await post(apiUrl, data, null, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function getStats(apiName, data, apiKey) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/' + apiName;
        return await post(apiUrl, data, null, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function saveAnnotation(data, apiKey) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/saveAnnotation';
        return await post(apiUrl, data, null, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

/**
 * Get the results for Highlight Text function
 *
 * @param data, combined of workflowId/userId/searchValue
 * @param apiKey, for discover Authorization
 * @returns {Promise<T>} the results for Highlight Text function
 */
async function searchText(data, apiKey) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/searchText';
        return await post(apiUrl, data, null, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function exportDocs(data, type, apiKey, contentType) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/' + type;
        return await post(apiUrl, data, null, apiKey, contentType)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function countCheckedRecords(data, history, apiKey) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/countCheckedAnnatations';
        return await post(apiUrl, data, history, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function saveFilteredResult(data) {
    try {
        let apiUrl = 'do=gqft.save_filtered_result&format=json';
        return await post(apiUrl, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function getItems(data, apiKey, history) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/items';
        return await post(apiUrl, data, history, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function clearAnnotation(data, apiKey, history) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/clearAnnotation';
        return await post(apiUrl, data, history, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function reportDataIssue(data) {
    let apiUrl = 'do=gqhelp.send_content_feedback&format=json';
    try {
        return await post(apiUrl, data,null)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function createResultReport(data, apiKey) {
    return resultReportApi('createResultReport', data, apiKey);
}

async function saveResultReport(data, apiKey) {
    return resultReportApi('saveResultReport', data, apiKey);
}

async function updateResultReport(data, apiKey) {
    return resultReportApi('updateResultReport', data, apiKey);
}

async function loadResultReport(data, apiKey) {
    return resultReportApi('loadResultReport', data, apiKey);
}

async function removeResultReport(data, apiKey) {
    return resultReportApi('removeResultReport', data, apiKey);
}

async function getReportNames(data, apiKey) {
    return resultReportApi('getReportNames', data, apiKey);
}

async function resultReportApi(methodName, data, apiKey) {
    try {
        let apiUrl = process.env.REACT_APP_BASE_URL + 'jnrb/' + methodName;
        return await post(apiUrl, data, null, apiKey)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error:", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function exportClustal(data) {
    try {
        let apiUrl = 'do=gqft.export_clustalo&format=json';
        //let postData = {data: data};
        return await post(apiUrl, data, null, null)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

async function downloadClustal(file, output) {
    try {
        let apiUrl = 'do=gqft.download_clustalo&format=json';
        let postData = {file: file, output: output};
        return await post(apiUrl, postData, null, null)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("error::", error);
            });
    } catch (error) {
        console.error(error);
    }
}

const DiscoverService = {
    saveState,
    updateLastAccess,
    loadResultState,
    getApi,
    getAuthInfo,
    getABReports,
    makeOverviewCall,
    makeCDRVariationsCall,
    makeCDRCombinationsCall,
    matchingDocumentsAll,
    matchingDocuments,
    matchingSequences,
    loadAbReport,
    saveABReport,
    editABReport,
    delABReport,
    getSummary,
    getStats,
    saveAnnotation,
    getAuthInfoAB,
    searchText,
    exportDocs,
    countCheckedRecords,
    saveFilteredResult,
    getItems,
    clearAnnotation,
    variationAnalysisOps,
    loadVariationReport,
    reportDataIssue,
    getAlignmentDescriptions,
    exportABVariants,
    createResultReport,
    updateResultReport,
    saveResultReport,
    loadResultReport,
    removeResultReport,
    getReportNames,
    exportClustal,
    downloadClustal
};

export default DiscoverService;
