import React, { useState, useEffect } from "react";
import { object } from "yup";
//import "../TableStyles.css"
import ReactHtmlParser from "react-html-parser";

function AuditTrail(props){

    const {AdTrail, strategyParams, queryAliDocStats, databases, filters} = props
    const [hcLc, setHcLc] = useState("");
    const [algorithm, setAlgorithm] = useState("");

    const correctAlgorithm = () => {
        if(Object.keys(queryAliDocStats).length > 0 && Object.keys(strategyParams).length > 0){

            var hclc="";
            if(queryAliDocStats.hasOwnProperty('hc')){
                hclc+='HC';
            }
            if(queryAliDocStats.hasOwnProperty('lc')){
                if(hclc != ""){
                    hclc += ', ';
                }
                hclc+='LC';
            }
            setHcLc(hclc);

            if (strategyParams.algo === "blast") {
                setAlgorithm("Blast with expect cutoff " + strategyParams["expectCutoff"] + " and word size " + strategyParams["wordSize"] + ".");
            } else if (strategyParams.algo === "hash") {
                setAlgorithm("Gapless with " + strategyParams["percIdHc"] + "% identity over the Heavy Chain sequence, " + strategyParams["percIdLc"] + "% identity over the Light Chain sequence.");
            } else {
                setAlgorithm("GenePAST with " + strategyParams["percIdHc"] + "% identity over the Heavy Chain sequence, " + strategyParams["percIdLc"] + "% identity over the Light Chain sequence.");
            }
        }
    }

    function doMaxColor(val){
        let str = "textFlatLeft marginAll5"

        if(val >= 300000){
            str += " red";
        }

        return str;
    }

    useEffect(() =>{
        correctAlgorithm();
    },[AdTrail, strategyParams, queryAliDocStats, databases])


	return (
        <div class="tableFrame">

        <p class="textFlat pCenter paddingTitle"><strong>AUDIT TRAIL</strong></p>
        <br></br>
        <div class="auditWM">
            <p class="textFlat textAlignLeft marginAll5"><strong>Original Search</strong></p>
            {AdTrail != null &&
            <table class="tabler" width={"100%"} color="black">    
                <tr >
                    <td class="tablerbAudit" width={"150px"}>
                        <strong class="textFlat textAlignLeft margL5 font13pt">Title</strong>
                    </td>
                    <td class="tablerbAudit">
                        <p class="textFlatLeft marginAll5">{AdTrail.Title}</p>
                    </td>
                </tr>
                <tr >
                    <td class="tablerbAudit" width={"150px"}>
                        <strong class="textFlat textAlignLeft margL5 font13pt">Owner</strong>
                    </td>
                    <td class="tablerbAudit">
                        <p class="textFlatLeft marginAll5">{AdTrail.Owner}</p>
                    </td>
                </tr>
                <tr >
                    <td class="tablerbAudit" width={"150px"}>
                        <strong class="textFlat textAlignLeft margL5 font13pt">Launch Start</strong>
                    </td>
                    <td class="tablerbAudit">
                        <p class="textFlatLeft marginAll5">{AdTrail["Launch Start"]}</p>
                    </td>
                </tr>
                <tr >
                    <td class="tablerbAudit" width={"150px"}>
                        <strong class="textFlat textAlignLeft margL5 font13pt">Launch Finish</strong>
                    </td>
                    <td class="tablerbAudit">
                        <p class="textFlatLeft marginAll5">{AdTrail["Launch Finished"]}</p>
                    </td>
                </tr>
                <tr >
                    <td class="tablerbAudit" width={"150px"}>
                        <strong class="textFlat textAlignLeft margL5 font13pt">Workflow ID</strong>
                    </td>
                    <td class="tablerbAudit">
                        <p class="textFlatLeft marginAll5">{AdTrail["Workflow ID"]}</p>
                    </td>
                </tr>
                <tr>
                    <td class="tablerbAudit" width={"150px"}>
                        <strong class="textFlat textAlignLeft margL5 font13pt">Workflow Status</strong>
                    </td>
                    <td class="tablerbAudit">
                        <p class="textFlatLeft marginAll5">{AdTrail["Workflow Status"]}</p>
                    </td>
                </tr>
                <tr >
                    <td class="tablerbAudit" width={"150px"}>
                        <strong class="textFlat textAlignLeft margL5 font13pt">Disk Usage</strong>
                    </td>
                    <td class="tablerbAudit">
                        <p class="textFlatLeft marginAll5">{AdTrail["Disk Usage"]}</p>
                    </td>
                </tr>
            </table>
            }
        
            <br class="marginAll5">
            </br>
            <br>
            </br>
        
            <table class="tabler" width={"100%"}>
        
                <th class="tablerbAudit textFlat" width={"150px"}></th>
                <th class="tablerbAudit textFlat" width={"225px"}><p class="textAlignLeft font13pt marginAll5">Mismatch(es) allowed</p></th>
                <th class="tablerbAudit textFlat"><p class=" textAlignLeft  font13pt marginAll5">Total results</p></th>
        

                {AdTrail.HC_CDR1 != null && queryAliDocStats != null &&
                <tr>
                    <td class="tablerbAudit" width={"150px"}>
                        <strong class="textFlat margL5 textAlignLeft font13pt" >HC CDR1</strong>
                    </td>
                    <td class="tablerbAudit" >
                        <p class="textFlatLeft marginAll5" >{AdTrail.HC_CDR1}</p>
                    </td>
                    <td class="tablerbAudit" >
                        <p className={doMaxColor(queryAliDocStats.hc_cdr1.numOfAlis)} >{queryAliDocStats.hc_cdr1.numOfAlis}<span hidden={queryAliDocStats.hc_cdr1.numOfAlis<300000}> MAX</span></p>
                    </td>
                </tr>
                }
                {AdTrail.HC_CDR2 != null && queryAliDocStats != null &&
                <tr>
                    <td class="tablerbAudit" width={"150px"}>
                        <strong class="textFlat margL5 textAlignLeft font13pt" >HC CDR2</strong>
                    </td>
                    <td class="tablerbAudit" >
                        <p class="textFlatLeft marginAll5" >{AdTrail.HC_CDR2}</p>
                    </td>
                    <td class="tablerbAudit" >
                        <p className={doMaxColor(queryAliDocStats.hc_cdr2.numOfAlis)} >{queryAliDocStats.hc_cdr2.numOfAlis}<span hidden={queryAliDocStats.hc_cdr2.numOfAlis<300000}> MAX</span></p>
                    </td>
                </tr>
                }
                {AdTrail.HC_CDR3 != null && queryAliDocStats != null &&
                <tr>
                    <td class="tablerbAudit" width={"150px"}>
                        <strong class="textFlat margL5 textAlignLeft font13pt" >HC CDR3</strong>
                    </td>
                    <td class="tablerbAudit" >
                        <p class="textFlatLeft marginAll5" >{AdTrail.HC_CDR3}</p>
                    </td>
                    <td class="tablerbAudit" >
                        <p className={doMaxColor(queryAliDocStats.hc_cdr3.numOfAlis)} >{queryAliDocStats.hc_cdr3.numOfAlis}<span hidden={queryAliDocStats.hc_cdr3.numOfAlis<300000}> MAX</span></p>
                    </td>
                </tr>
                }
                {AdTrail.LC_CDR1 != null && queryAliDocStats != null &&
                <tr>
                    <td class="tablerbAudit" width={"150px"}>
                        <strong class="textFlat margL5 textAlignLeft font13pt" >LC CDR1</strong>
                    </td>
                    <td class="tablerbAudit" >
                        <p class="textFlatLeft marginAll5" >{AdTrail.LC_CDR1}</p>
                    </td>
                    <td class="tablerbAudit" >
                        <p className={doMaxColor(queryAliDocStats.lc_cdr1.numOfAlis)} >{queryAliDocStats.lc_cdr1.numOfAlis}<span hidden={queryAliDocStats.lc_cdr1.numOfAlis<300000}> MAX</span></p>
                    </td>
                </tr>
                }
                {AdTrail.LC_CDR2 != null && queryAliDocStats != null &&
                <tr>
                    <td class="tablerbAudit" width={"150px"}>
                        <strong class="textFlat margL5 textAlignLeft font13pt" >LC CDR2</strong>
                    </td>
                    <td class="tablerbAudit" >
                        <p class="textFlatLeft marginAll5" >{AdTrail.LC_CDR2}</p>
                    </td>
                    <td class="tablerbAudit" >
                        <p className={doMaxColor(queryAliDocStats.lc_cdr2.numOfAlis)} >{queryAliDocStats.lc_cdr2.numOfAlis}<span hidden={queryAliDocStats.lc_cdr2.numOfAlis<300000}> MAX</span></p>
                    </td>
                </tr>
                }
                {AdTrail.LC_CDR3 != null && queryAliDocStats != null &&
                <tr>
                    <td class="tablerbAudit" width={"150px"}>
                        <strong class="textFlat margL5 textAlignLeft font13pt" >LC CDR3</strong>
                    </td>
                    <td class="tablerbAudit" >
                        <p class="textFlatLeft marginAll5" >{AdTrail.LC_CDR3}</p>
                    </td>
                    <td class="tablerbAudit" >
                        <p className={doMaxColor(queryAliDocStats.lc_cdr3.numOfAlis)} >{queryAliDocStats.lc_cdr3.numOfAlis} <span hidden={queryAliDocStats.lc_cdr3.numOfAlis<300000}> MAX</span></p>
                    </td>
                </tr>
                }
                {(hcLc !== "" && algorithm != "") &&
                <tr>
                    <td class="tablerbAudit">
                        <strong class="textFlat textALignLeft margL5 font13pt">{hcLc}</strong>
                    </td>
                    <td class="tablerbAudit" colSpan="2">
                        <p class="textFlatLeft marginAll5">{algorithm}</p>
                    </td>
                </tr>
                }
            </table>
        
            <br>
            </br>
            <br>
            </br>

            <p class="textFlat textAlignLeft marginAll5"><strong>Patent Sequence Databases Searched</strong></p>
        
            <table class="tabler" width={"100%"}>
        
                <th class="tablerbAudit textFlat"><p class="textAlignLeft marginAll5 font13pt">Title</p></th>
                <th class="tablerbAudit textFlat"><p class="textAlignLeft marginAll5 font13pt">Version</p></th>
                <th class="tablerbAudit textFlat"><p class="textAlignLeft marginAll5 font13pt">Release Date</p></th>
        
                <tbody>
                {databases != null && Object.entries(databases).map((value, index) => {
                    return(
                        <tr >
                            {value[1].map((val, dex) =>{
                                return(
                                <td key={dex} class="tablerbAudit textFlat" width={"1%"}>
                                    <p class="textAlignLeft marginAll5">{ReactHtmlParser(val)}</p>
                                </td>
                                )
                                })
                            }
                        </tr>
                    )})}
                </tbody>
            </table>

            <br>
            </br>
            <br>
            </br>

            {filters != null && filters != undefined && filters != "" &&
            <div>
                <p class="textFlat textAlignLeft marginAll5"><strong>Filters Applied to This Report</strong></p>
                <table class="tabler" width={"100%"}>   
                    <tr >
                        <td class="tablerbAudit" width={"100px"}>
                            <strong class="textFlat textAlignLeft margL5 font13pt">Filter</strong>
                        </td>
                        <td class="tablerbAudit">
                            <p class="textFlatLeft marginAll5">{filters}</p>
                        </td>
                    </tr>
                </table>
            </div>
            }
        </div>
    </div>
    );
}

export default AuditTrail;
