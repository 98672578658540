import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import utilsService, { TOAST_TYPE } from '../../helpers/utils'
import _ from "lodash";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx/xlsx';
import ExportExcel from './ExportExcel';
import ViewExcel from './ViewExcelFormat';
import {saveAs} from 'file-saver';

import TextInput from '../../shared/Fields/TextInput';
import Constant from '../../helpers/constant';
import st26service from '../../services/st26service';
import Modal from 'react-bootstrap/Modal'
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';




const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        width: '96%',
        margin: '0 auto 28px',
        minHeight: '260px',
        // borderBottom: '1px solid #cec7c7',
        padding: '23px 16px 14px',
    },
    headerPipe: {
        margin: '0 10px'
    },
    searchInput: {
        width: '30%'
    },
    buttonStyleCancel: {
        float: 'right',
        textTransform: 'none',
        margin: '4px',
        color: 'white',
        backgroundColor: '#008EC5 !important',
        border: '1px solid #1F4E79 !important',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        }
    },

    antibodyNumInput: {
        width: '8%'
    }, 

    colorConDSI: {
        backgroundColor: 'gainsboro',
        // marginTop: '-38px',
        padding: '28px',
        paddingTop: '28px',
        paddingBottom: '65px',
        // marginLeft: '7px',
        // marginRight: '7px',
        paddingRight: '10px',
        borderRadius: '5px',
    },
    colorConDSIDark: {
        backgroundColor: 'rgba(238, 238, 238, 0.12)',
        // marginTop: '-38px',
        padding: '28px',
        paddingTop: '28px',
        paddingBottom: '65px',
        // marginLeft: '7px',
        // marginRight: '7px',
        paddingRight: '10px',
        borderRadius: '5px',
    },
    modalBody: {
        paddingBottom: '20px'
    },
    footerDiv: {
        padding: '0 10px'
    },
    loginSubmitCancel: {
        backgroundColor: '#0182C5',
        borderColor: '#1F4E79',
        border: '1px solid #1F4E79',
        color: 'white',
        margin: '4px',
        textTransform: 'capitalize',
        '&:hover': {
        backgroundColor: '#0182C5',
        boxShadow: 'none',
        },
    },
    modaltext: {
        position: 'absolute',
        top: '10px',
        right: '10px'
    },
    modalClassConDSI: {
        position: 'absolute',
        width: '96%',
        padding: '35px 15px 15px',
        // height: 'auto',
        // top: '30%',
        // left: '50%',
        right: 'auto',
        bottom: 'auto',
        // transform: 'translate(-50%, -50%)'
    },
    modalClassConDSIDark: {
        position: 'absolute',
        width: '96%',
        padding: '35px 15px 15px',
        // height: 'auto',
        // top: '30%',
        // left: '50%',
        right: 'auto',
        bottom: 'auto',
        backgroundColor: '#292929'
        // transform: 'translate(-50%, -50%)'
    },
    modalBoxContent: {
        maxHeight: '675px',
    }
}));

var ExcelExportArray =[];
var ViewExcelArray =[];
function ConvertSequence() {
    const { t, i18n } = useTranslation('common');

    const classes = useStyles();
    const history = useHistory();
    // const [strategy, setStrategy] = useState('genepast');
    const [authInfo, setAuthInfo] = useState();
    // const [workflowId, setWorkflowId] = useState();
    const [formdata, setFormData] = useState({});
    const { workflowId } = useParams();
    const toastRef = useRef(null)
    const [modalShow, setModalShow] = React.useState(false);
    const [modalDisplayed, setModalDisplayed] = React.useState(false);
    const toggle = useSelector(state => state.setToggle.toggle);

    const closeModal = () => {
        setModalShow(false);
    }

    // useEffect(async () => {
    //     //const getResponse = await st26service.getAuthInfoSt26(workflowId, history);
    //     // document.title = 'GenomeQuest - Sequence Format Conversion';
    //     if (getResponse && getResponse.response_status == 0) {
    //         setAuthInfo(getResponse.response_content);
    //         updateFormData(getResponse.response_content);
    //         //setDisableSearch(true);
    //     }
    //     //dispatch(userActions.logout()); 
    // }, []);

    // function updateFormData(data) {
    //     setFormData(data.formData);
    //     var expiredTime = data.expiredTime;
    //     if (expiredTime && expiredTime.date) {
    //         var expiredTimeInSecs = Math.round(new Date(expiredTime.date).getTime() / 1000);
    //         // Sets the session timeout based on the user expired time in database, -90 for timeout 60 secs and 30 more buffer
    //         expiredTimeInSecs = expiredTimeInSecs - Math.round(new Date().getTime() / 1000) - 90;
    //         // Idle.setIdle(expiredTimeInSecs < 0 ? (30 * 59) : expiredTimeInSecs);
    //         //Idle.watch();
    //     }
    //}

    function showfil() {
       if (window.File && window.FileReader && window.FileList && window.Blob) {
            var preview = document.getElementById('fastaInput');
            var file = document.querySelector('input[type=file]').files[0];
            var reader = new FileReader();
      
            var textFile = /text.*/;
            if (file.type.match(textFile)) {
               reader.onload = function (event) {
            // preview.innerHTML = event.target.result;
               preview.value = event.target.result;
               formik.setFieldValue("st26input", event.target.result);
              };
            } else {
              preview.innerHTML =
                "<span class='error'>It doesn't seem to be a text file!</span>";
            }
            reader.readAsText(file);
         } else {
            alert('Your browser is too old to support HTML5 File API');
          }
       
    }
    function resetFileInput() {
        var fileInput = document.querySelector('input[type=file]');
        fileInput.value = '';
    }
    function fileNames() {
        var fileInput = document.querySelector('input[type=file]');
        var file = fileInput.files[0];
        
        if (file === undefined || file.name === null || file.name === "") {
            var fileName = prompt("Please enter the name of the file:");
                console.log(fileName, "filename");
                return fileName;
        } else {
            return file.name;
        }
    }

    function exportbutton() {
        if (formik.values.st26input) {
            if (modalDisplayed === true) {
                setModalShow(false);
            } else {
                setModalShow(true);
            }
        }
    }

    

    function cncl() {
        history.push('/home');
    }

    const formik = useFormik({
        initialValues: {
            st26input: formdata.st26input

        },
        enableReinitialize: true,
        //validationSchema: Validate.AntibodySearchValidation(),
        onSubmit: async (values) => {
            let { st26input } = values;

            let postData = {
                st26input
            }

            var getResponse = await st26service.convertText(postData, history, t);
            if (getResponse === 'parsererror') {
                setModalDisplayed(true);
                utilsService.showToast(TOAST_TYPE.ERROR, 'The Fasta data is not correctly structured.\nPlease correct the text data and try again.', toastRef)
            } else if (getResponse === 'missing') {
                utilsService.showToast(TOAST_TYPE.ERROR, 'The Fasta data is not correctly structured.\nEither the moltype or Sequence data  is missing.', toastRef);
            }
            else if (getResponse === 'empty') {
                utilsService.showToast(TOAST_TYPE.ERROR, 'The Fasta xml has no sequence data to be parsed.', toastRef)
            }
            else {
                setModalDisplayed(false);
                ExcelExportArray = getResponse;
                ViewExcelArray = getResponse;
            }
        },
    });
    return (
        <div className={classes.grow}>
            <form name="antibodySearchForm" onSubmit={formik.handleSubmit} className={classes.loginDiv}>

                <Row>
                    <Col lg="12" md="12" className={"mb-2 " + (authInfo && !authInfo.syscontrol_search_submit ? 'd-block' : 'd-none')}>
                        <Typography className="text-danger">
                            {t('ABsearchDisableText')}
                            {authInfo && authInfo.syscontrol_search_submit_txt}
                            {t('patienceThanksText')}</Typography>
                    </Col>

                    <Col lg="12" md="12" className="mb-2">
                        <h6 className={"appTextColor loginTitle"}>FASTA Sequence Input</h6>
                        <Row className="mb-3">

                            <Col lg="12" md="12" className="p-0 content float-left">
                                <div className="form-group px-3 ">
                                    <TextInput
                                        spellcheck="false"
                                        rows="20"
                                        multiline={true}
                                        fullWidth

                                        id="fastaInput"
                                        name="st26input"
                                        label="Copy and Paste your FASTA sequences here"
                                        variant="outlined"
                                        value={formik.values.st26input}
                                        onChange={(event) => {
                                            formik.handleChange({...event});
                                            if (!event.target.value) {
                                                resetFileInput(); // Clear file input when text input is cleared
                                            }
                                        }}        
                                        error={formik.touched.st26input && Boolean(formik.errors.st26input)}
                                        helperText={formik.errors.st26input}
                                    //disabled={authInfo && authInfo.redo}
                                    />
                                </div>

                            </Col>

                        </Row>
                        <hr style={toggle ? {backgroundColor: '#dfdfdf'}: {backgroundColor: '#aea9a9'}}/>
                    </Col>

                    <Col lg="12" md="12" className="float-right mb-3">

                    <p className='uploadTextData'> <b id="show-text">Upload .xml ST.26 file</b></p>
                    <input type="file" accept= ".txt" onChange={showfil} />
                        
                        {!formik.values.st26input ?                                                                                                                                                                                                                           
                            <Button className='cancelButtonDisable' color="default" disableRipple={true} variant="contained" onClick={() => exportbutton()}>Convert to Tabular</Button>
                            : <Button className='accountInfo' color="default" disableRipple={true} type="submit" variant="contained" onClick={() => exportbutton()}>Convert to Tabular</Button>
                        }
                        <Button variant="contained" disableRipple={true} color="default" className={classes.buttonStyleCancel} onClick={cncl}>{t('cancel')}</Button>
                    </Col>     
                </Row>

            </form>
            {modalDisplayed === false &&
                <Modal
                    show={modalShow}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcente"
                    centered
                    contentClassName={toggle ? classes.modalClassConDSI : classes.modalClassConDSIDark}
                    className={classes.modalBoxContent }

                >
                    <Link href="#" onClick={(e) => e.preventDefault()} className={classes.modaltext}>
                        <CloseIcon onClick={closeModal} />
                    </Link>
                    <div className={classes.modal_body_selected + " appTextColor"}>
                        <div className={toggle ? classes.colorConDSI: classes.colorConDSIDark}>
                            {/* <div className={(confirmContent ? 'd-block' : 'd-none')}> */}
                            <p className="mb-3">{t('downloadExcel')}</p>
                            <div className={classes.footerDiv + " float-right"}>
                                <Button><ViewExcel excelData={ViewExcelArray} className="styled-button"></ViewExcel></Button>
                                <Button><ExportExcel excelData={ExcelExportArray} fileName={fileNames} className="styled-button" onClose={closeModal} setFieldValue={formik.setFieldValue} resetFileInput={resetFileInput}> </ExportExcel></Button>
                                <Button onClick={closeModal} className={classes.loginSubmitCancel} >{t('cancel')}</Button>

                                {/* <Button onClick={() => deleteSearch('record')} color={(!termsDisable ? 'default' : 'secondary')} disabled={!termsDisable} className={"text-capitalize mr-2 " + ' ' + (!termsDisable ? 'cancelButtonDisable' : 'accountInfo')} variant="contained">{t('deleteSelItems')}</Button> */}
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </div>

    )

}

export default ConvertSequence;
