import React, { useState  } from 'react';
import { useHistory, useParams,Link  } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  excelReaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  tableContainer: {
    overflowX: 'auto',
    maxWidth: '100%',
  },
  table: {
   // borderCollapse: 'collapse',
    width: '100%',
  },
  th: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
  },
  td: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: 'white',
    border: '2px solid #000',
    padding: theme.spacing(2, 4, 3),
    width: '1000px', // Adjust the width as needed
    height: '500px', // Adjust the height as needed
    overflow: 'auto', // Add overflow to handle scroll if needed
  },
  modalContentDark: {
    border: '2px solid #000',
    padding: theme.spacing(2, 4, 3),
    width: '1000px', // Adjust the width as needed
    height: '500px', // Adjust the height as needed
    overflow: 'auto', // Add overflow to handle scroll if needed
    backgroundColor: '#292929'
  },
  close:{
    float: 'right',
    zIndex: 2,
    textTransform: 'none',
    margin: '100px',
    color: 'white',
    backgroundColor: '#008EC5 !important',
    border: '1px solid #1F4E79 !important',
    boxShadow: 'none',
    '&:hover': {
        boxShadow: 'none',
    }
  },
  modaltext: {
    // position: 'absolute',
    top: '10px !important',
    float: 'right',
    right: '10px !important'
  },
}));

function ExcelReader({ excelData }) {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const toggle = useSelector(state => state.setToggle.toggle);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const closeModal = () => {
    setShowModal(false);
}

  return (
    <div className={classes.excelReaderContainer}>
      <Button variant='contained' onClick={handleOpenModal}>Load Excel Data</Button>
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        className={classes.modal}
        aria-labelledby="excel-modal-title"
        aria-describedby="excel-modal-description"
      >
        <div className={toggle ? classes.modalContent: classes.modalContentDark}>
        <Link href="#" onClick={(e) => e.preventDefault()} className={classes.modaltext}>
                        <CloseIcon onClick={closeModal} />
                    </Link>
          <h2 id="excel-modal-title">Excel Data</h2>
          <div className={classes.tableContainer}>
            <table className={classes.table}>
              {excelData
                .filter((row) => row.some((cell) => cell.trim() !== '')) // Filter out rows with only empty cells
                .map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row
                      .filter((cell) => cell.trim() !== '') // Filter out empty cells
                      .map((cell, cellIndex) => (
                        <td key={cellIndex} className={cellIndex === 0 ? classes.th : classes.td}>
                          {cell}
                        </td>
                      ))}
                  </tr>
                ))}
            </table>
          </div>
          <button className={classes.close} onClick={handleCloseModal}>Close</button>
        </div>
      </Modal>
    </div>
  );
}

export default ExcelReader;
