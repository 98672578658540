import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    width: '270px',
    //padding: "6px"
    //padding: '10px 0px',
    paddingLeft: '6px',
    paddingTop: '14px',
    margin: '-8px 0 0 -5px'
  },
  dense: {
    marginTop: theme.spacing(2)
  },
  smallBox: {
    width: '180px',
    //padding: '10px 0px',
    backgroundColor: 'white',
    color: 'black !important',
    '& .MuiSelect-iconOutlined': {
      right: '0px'
    }
  },
  middleBox: {
    width: '200px',
    //padding: '10px 0px',
    backgroundColor: 'white',
    color: 'black !important',
   '& .MuiSelect-iconOutlined': {
      right: '0px'
    }
  },
  input: {
    margin: theme.spacing(0),
    height: 36,
  },
  dropdownIconWheat: {
    color: "wheat", 
  },
  dropdownIconBlack: {
    color: "rgb(0 0 0 / 54%)",
  },
  outlinedWhite: {
    '& .MuiOutlinedInput-root': {
      borderColor: '#ffffff',
      '& fieldset': {
        borderColor: '#ffffff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ffffff',
      },
    },
    '& .MuiInputBase-input': {
        color: '#777 !important',  // White text in dark mode
        backgroundColor: '#292929',
      },
  },
  noPadding: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  }
}));

export default function DiscoverSelectBox(props) {
  const classes = useStyles();

  const items = props.items;
  const toggle = useSelector(state => state.setToggle.toggle);
  return (
    // <form className={classes.container} noValidate autoComplete="off">
    <TextField
      id={props.id}
      name={props.name}
      select
      // label={gender=== "" ? "Gender": ""}
      className={`${props.className} ${props.smallSelectBox ? classes.smallBox : classes.textField} ${toggle ? '' : classes.outlinedWhite}`}
      value={props.value}
      InputLabelProps={{ shrink: false }}
      InputProps={{ className: classes.input }}
      SelectProps={{
        classes: {         
          icon: toggle ? classes.dropdownIconBlack : classes.dropdownIconWheat, 
        },
        MenuProps: {
          className: (props.smallSelectBox ? "" : classes.textField),
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null,
          classes: {
            list: classes.noPadding,  // Apply noPadding class to remove padding
            icon: toggle ? '' : classes.input,
          },
          //classes: { icon: toggle ? '' : classes.input },
        }
      }}
      margin={props.margin}
      variant={props.variant}
      onChange={props.onChange}
      disabled={props.disabled}
      onBlur={props.onBlur}
    >
      {items && items.length > 0 && items.map((option) => (
        <MenuItem key={option.value} value={option.value} style={toggle ? { color: 'black !important', backgroundColor: '#ffffff' } : { color: '#ffffff', backgroundColor: '#292929' }}>
          <span style={{color: option.color}}>{ReactHtmlParser(option.label)}</span>
        </MenuItem>
      ))}
    </TextField>
    // </form>
  );
}
