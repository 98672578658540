import React, { useState, useEffect } from "react";
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { TableHead } from "@material-ui/core";
import { CheckBox } from "@material-ui/icons";
import FullTextSearchBox from "../../shared/components/FullTextSearchBox/FullTextSearchBox";
import "./CustomDb.css"
import RadioButton from "../../shared/Fields/RadioButton";
import TextInput from "../../shared/Fields/TextInput";
import { Button } from "react-bootstrap";

function CustomDBCreator(props){;
    

    const [dbType, setDbType] = useState("Protein")
    const [databases, setDatabases] = useState([]);
    const [patentNumbers, setPatentNumbers] = useState('');
    const [isSubmitActive, setIsSubmitActive] = useState(false)

    const [method, setMethod] = useState(true) //toggle between ft and db browser

    // Handle text change
    const handleChange = (e) => {
        setPatentNumbers(e.target.value);
    };

    // Handle file drop
    const handleFileDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && file.type === "text/plain") {
        const reader = new FileReader();
        reader.onload = (event) => {
            setPatentNumbers(event.target.result);
        };
        reader.readAsText(file);
        } else {
        alert("Please drop a valid text file.");
        }
    };

    // Prevent default behavior for drag-and-drop events
    const preventDefault = (e) => e.preventDefault();

    // Set up drag and drop listeners
    useEffect(() => {
        window.addEventListener("dragover", preventDefault);
        window.addEventListener("drop", preventDefault);

        return () => {
        window.removeEventListener("dragover", preventDefault);
        window.removeEventListener("drop", preventDefault);
        };
    }, []);



    return (
       <div style={{margin: "auto", width: "900px", display: "flex", flexDirection: "column"}}>
        <div>
            <div>
                <div  style={{paddingTop: "10px"}}>
                    <span className="subHeading">New Database Name:</span>
                </div>
                <div style={{width: "50%", paddingTop: "10px"}}>
                    <TextInput 
                        style={{paddingTop: "10px"}}
                        fullWidth
                        spellcheck="false"
                        variant="outlined"
                        error={false}
                        placeholder= {"name your db"}
                    />
                </div>
            </div>            
            <div style={{paddingTop: "10px", display: "flex", alignItems: "center"}}>
                <span className="subHeading">Type:</span>
                <RadioButton></RadioButton><span style={{paddingLeft: "2px", paddingRight: "10px"}}>Protein</span>
                <RadioButton></RadioButton><span style={{paddingLeft: "2px", paddingRight: "10px"}}>Nucletide</span>
                <RadioButton></RadioButton><span style={{paddingLeft: "2px", paddingRight: "10px"}}>Both</span>
            </div>
        </div>
        <div style={{paddingTop: "10px"}}>
            <div style={{paddingTop: "5px"}}>
                <span className="tab" onClick={()=>{setMethod(true)}}>Databases</span>
                <span className="tab" onClick={()=>{setMethod(false)}}>Text Search</span>
            </div>
            <div className={"fixDisplay"}>
                {method &&
                <div>
                    <div>
                        <table>
                            <tr>
                                <th style={{  border: "1px solid"}}>
                                    <CheckBox></CheckBox>
                                </th>
                            
                                <th style={{  border: "1px solid"}}>
                                    Name
                                </th>
                            
                                <th style={{  border: "1px solid"}}>
                                    Database Type
                                </th>
                            
                                <th style={{  border: "1px solid"}}>
                                    Count
                                </th>
                            
                                <th style={{  border: "1px solid"}}>
                                    Creation Date
                                </th>
                            </tr>
                            
                            {databases.length > 0 && databases.map(()=>{
                                return(<tr>test</tr>)
                            })}

                        </table>
                    </div>
                </div>
                }
                {!method &&
                <div>
                    <div
                        onDrop={handleFileDrop}
                        style={{
                            display: "flex",
                            border: '2px dashed #ccc',
                            padding: '5px',
                            borderRadius: '8px',
                            textAlign: 'center',
                            width: '100%',
                            margin: '20px auto',
                        }}
                    >
                    <textarea
                        value={patentNumbers}
                        onChange={handleChange}
                        placeholder="Paste patent numbers here or drag and drop a .txt file"
                        rows="5"
                        style={{ width: '100%', padding: '10px', borderRadius: '5px' }}
                    />
                    </div>
                    <span>Text Filter</span>
                    <FullTextSearchBox
                        setNodeId = {(stringholder)=>{return("")}}
                    >
                    </FullTextSearchBox>

                    <div>
                        <div>
                            <span>Output:</span>
                        </div>
                        <div>
                            <span>Size</span>
                            <span>Count</span>
                            <span>other</span>
                        </div>
                    </div>
                </div>
                }
            </div>

            {isSubmitActive &&
                <Button className="accountInfo">
                    submit
                </Button>
            }
            {!isSubmitActive && 
            <Button className="cancelButtonDisable" disabled>
                submit
            </Button>}
        </div>

       </div>
    );
}

export default CustomDBCreator;