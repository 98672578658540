import React, { useState, useEffect } from "react";
import {useHistory} from "react-router-dom";
import CaretPositioning from "../../../services/EditCaretPositioning"
import { useSelector } from "react-redux";
import MiniSpinner from "../MiniSpinner";
import Constant from "../../../helpers/constant";
import luceneQueryParser from "../../../assets/lib/lucene-query-parser";
import fullTextService from "../../../services/fulltextsearch"

import "./FtSearchBox.css"
import FtAutoComplete from "./FtUtils/FtAutoComplete";
import FtOntologyModal from "../../Modal/FtOntologyModal";
import FtOntologySuggestion from "./FtUtils/FtOntologySuggestion";
import FtOntologyOptions from "./FtUtils/FtOntologyOptions";

import { useRef } from 'react';

function FullTextSearchBox(props){

	const [mouseOverSearchBox, setMouseOverSearchBox] = useState(false);

	const history = useHistory();

    //returns luceneQueryValue == Search
    const {setSearchLock, pnListFormatted, setLuceneQueryValue, starterString, keyWordsInContext, endString, setNodeId} = props;

	const userInfo = useSelector((state) => state.setUserInfo);

    const node = useRef(null);

	const [autoNodeId, setAutoNodeId] = useState(null)
	const [ftNodeId, setFtNodeId] = useState(null)
    
	//state Values
    const [errorList, setErrorList] = useState([]);
	const [errorPopup, setErrorPopup] = useState(false);

    //ftEnhance popup
	const [focusedTerm, setFocusedTerm] = useState("");
	const [topPosition, setTopPosition] = useState(0);
	const [leftPosition, setLeftPosition] = useState(0);

	const [ontologyEditorPopup, setOntologyEditorPopup] = useState(false);
	const [ontologyTermData, setOntologyTermData] = useState({});
	const [ontologyEditMode , setOntologyEditMode] = useState(false);
	const [ontologyEditorTermData, setOntologyEditorTermData] = useState({});

	const [termLocation , setTermLocation]= useState(0);

	//suggestions
	const [ontologySuggestionPopup, setOntologySuggestionPopup] = useState(false); //for ontology Suggestions
	const [searchedTerm, setSearchedTerm] = useState("");
	const [resCount, setResCount] =useState(0);
	

	function checkOntologyListPopup(){ //ontologySuggestions opens itself
			if(focusedTerm != "" && searchedTerm != "" &&  fullTextService.levenshteinDistance(focusedTerm, searchedTerm) < 3 && !ontologyEditorPopup && !autoCompletePopup && !ontologyEditMode && resCount>0){
				setOntologySuggestionPopup(true);
			}else{
				setOntologySuggestionPopup(false);
			}
			//setFocusedTerm(trimCaretFocusedTerm(ftPhraseArray));
	}

	useEffect(()=>{
		checkOntologyListPopup()
	},[focusedTerm, searchedTerm])
	
    const [autoCompletePopup, setAutoCompletePopup] = useState(false);
	const [fieldStack, setFieldStack] = useState([]);

	const [showMiniLoader, setShowMiniLoader] = useState(false);
	const toggle = useSelector(state => state.setToggle.toggle);

	let ANDString = '<span class="andClass">AND</span>';
	let ORString = '<span class="orClass">OR</span>';
	let NOTString = '<span class="notClass">NOT</span>';
	let toString = '<span class="toClass">TO</span>';
	let nowString = '<span class="blueText">now</span>';

	let questString = '<span class="orangeClass">?</span>';
	let starString = '<span class="orangeClass">*</span>';
	let underScoreString = '<span class="orangeClass">_</span>';
	let percString = '<span class="greenClass">%</span>';

	//ontologies
	const [ontologySelectionPopup, setOntologySelectionPopup] = useState(false); //for the saving and changing of ontologys as shows here
	const [ontologyState, setOntologyState] = useState({});

	useEffect(async() => {

		let ontFromMem = JSON.parse(localStorage.getItem('FTOntologies'));
		if (ontFromMem != null) {
			setOntologyState(ontFromMem);
		}

		if(starterString != undefined && starterString != null && starterString != "" && starterString!="undefined"){
			let newStartString = starterString+"";
			caretLocation = newStartString.length
			handleChangePart2(newStartString, true)
		}

		if(pnListFormatted != undefined && pnListFormatted != null && pnListFormatted != "" && pnListFormatted!="undefined"){
			if(errorList.includes("text Box is empty")){
				setErrorList([]);
			}
		}

	}, [userInfo, starterString, pnListFormatted]);


	function getOntologyString(){

		let ontologyString = "";
		let keys = Object.keys(ontologyState);

		for(var i =0; i<keys.length; i++){
			if(ontologyState[keys[i]][1]){
				ontologyString+=';'+keys[i];
			}
		}

		return ontologyString;
	}

	const openOntologyModal = () =>{
		setOntologySelectionPopup(true);
	}

	function closeOntologyModal(){
		setOntologySelectionPopup(false);
	}


	let thisErrorList = [];
	function addError(newError){
		if(!thisErrorList.includes(newError)){
			thisErrorList.push(newError);
		}
	}


	let caretLocation = 0;
	const handleChange = (event) => {
		
        event.preventDefault();
        removeAutoComplete();
		closeAllPopups();
        
		caretLocation = CaretPositioning.saveSelection(event.currentTarget).start;

		var ftSearchString = node.current.textContent
        var changeType = event.nativeEvent.inputType;
		var deleteFlag = false;


		if(ftSearchString.replace(/[\n\s]+/g, "").length > 0){
			
			if(changeType == "deleteContentBackward"){
				
				let textCut = ftSearchString.slice(0, caretLocation);
				let textSave = ftSearchString.slice(caretLocation, ftSearchString.length);
				let matchingOnto = textCut.split(/"[a-zA-Z0-9\s\,\.\-\(\)\']+"↭"[A-Za-z]+:[a-zA-Z0-9]+"$/g);

				let newString = "";
				if(matchingOnto.length > 1){
					
					matchingOnto.forEach(element => {
						newString +=element;
					});

                    ftSearchString = newString+" "+textSave;

					caretLocation = newString.length;
				}
				
				deleteFlag = true;

			}else{

				//takes care of key input
				if(changeType == "insertText"){
					ftSearchString = handleKeyCode(ftSearchString);
				}

			}
			handleChangePart2(ftSearchString, deleteFlag);

		}else if(pnListFormatted == ""){
			handleOntologies("");
			setLuceneQueryValue("");
			endString("")
			setErrorList(["text Box is empty"]);
			CaretPositioning.setCurrentCursorPosition(node, 0);

		}else{
			handleOntologies("");
			setLuceneQueryValue("");
			endString("")

		}
	}

	function handleChangePart2(ftSearchString, deleteFlag){
	
		var ftPhraseArray = reasemblePhrases(ftSearchString);

		if(!deleteFlag){
			ftPhraseArray = insertAnds(ftPhraseArray);
		}

		//string for html
		var newftSearchString = reasembleString(ftPhraseArray)
		endString(newftSearchString)
		handleOntologies(newftSearchString);


		var QueryString = handleStackedFields(ftPhraseArray);
		handleKeyWordsInContext(QueryString)
		if(checkSyntaxSuppressed(QueryString)){ //sets the luceneQueryValue if its fine
			
			let fullAutoTerm = trimCaretFocusedTerm(ftPhraseArray) // caret might be in middle of fullAutoTerm
			if(fullAutoTerm !=""){
				ftPhraseArray = addAutoComplete(ftPhraseArray, fullAutoTerm);
			}
		}

		newftSearchString = addHtmlToQuery(ftPhraseArray);

		node.current.innerHTML = newftSearchString;

		setErrorList(thisErrorList);
		CaretPositioning.setCurrentCursorPosition(node, caretLocation);
		movePopup();
	}

	function reasemblePhrases(ftString){
		var ftCharArray = [...ftString]
		
		var ftPhraseArray = [];
		var arrayIndex = 0;

		var lBracket = 0;
		var rBracket = 0;
		var lClamp = 0;
		var rClamp = 0;
		var lParentheses = 0;
		var rParentheses = 0;

		if(Array.isArray(ftCharArray)){

			let combineBefore = false;
			let combineAfter = false;

			for(let i = 0; i < ftCharArray.length; i++){
				
				let character = ftCharArray[i]; //char
				let newPhrase = character; //"";

				if(!/[\s\n]/.test(character)){
					switch(character){

						//handle "Quotes"
						case "\"": ///[\u0022'"\"]/g
							var errorFlag = true;
							for( var subDex = i+1; subDex < ftCharArray.length; subDex++){ //find next \"
								
								newPhrase += ftCharArray[subDex];
								i = subDex;

								if(/\"/.test(ftCharArray[subDex])){
									errorFlag = false;
									break;
								}
							}

							if(errorFlag){
								addError("Missing Quotation Mark");
							}
							break;

						//handle [brakets]
						case "\[":
							lBracket++;
							break;
						case "\]":
							rBracket++;
							break;

						//handle <brakets>
						case "\<":
							lClamp++;
							break;
						case "\>":
							rClamp++;
							break;

						//handle (parentheses)
						case "\(":
							lParentheses++;
							break;
						case "\)":
							rParentheses++;
							break;

						//handle \^
						case "^":
							combineAfter = true;
							break;

						//handle \↭
						case "↭":
							combineBefore = true;
							combineAfter = true;
							break;
					

						//handle characters ~basically
						default:

							for( var subDex = i+1; subDex < ftCharArray.length; subDex++){ //find next \"
								if(ftCharArray[subDex].match(/[a-zA-Z0-9,•\:\-\*\/\\\_]/)){
									newPhrase+=ftCharArray[subDex];

									if(ftCharArray[subDex].match(/[\:]/)){
										subDex++;
										break;
									}

								}else{
									break;
								}
							}

							i = subDex-1;
							break;
					}
				}else{
					for( var subDex = i+1; subDex < ftCharArray.length; subDex++){ //find next \"
						if(/[\n]/.test(ftCharArray[subDex])){
							i++;
						}else if(/[\s]/.test(ftCharArray[subDex])){
							newPhrase+=ftCharArray[subDex]
							i++;
						}else{
							break;
						}
					}
				}
				if(combineBefore){
					combineWithPhraseBefore(ftPhraseArray, newPhrase);
					combineBefore = false;
				
				}else{
					ftPhraseArray[arrayIndex] = newPhrase;
					arrayIndex++;
				}
				if(combineAfter){
					combineBefore = true;
					combineAfter = false;
				}
			}
		}

		if(lBracket != rBracket){
			addError("Missmatching [ ] Count");
		}
		if(lClamp != rClamp){
			addError("Missmatching < > Count");
		}
		if(lParentheses !=rParentheses){
			addError("Missmatching ( ) Count");
		}

		return ftPhraseArray;
	}

	function reasembleString(ftPhraseArray){
		
		let ftSearchString = "";
		if(Array.isArray(ftPhraseArray)){

			for(let i =0; i<ftPhraseArray.length; i++){
				ftSearchString+=ftPhraseArray[i];
			}

		}
		return ftSearchString;
	}

	function addHtmlToQuery(ftPhraseArray){ //in progress - inserthtml

		let ftSearchHtml = "";
		
		if(Array.isArray(ftPhraseArray)){

			var inPoBLock = false;
			var inBLock = false;
			let totalSubStringLength = 0;

			for(let i = 0; i<ftPhraseArray.length; i++){


				let currentStringStartLength = ftPhraseArray[i].length
				if(ftPhraseArray[i].includes("↭")){ //ontology
					let stringBuilder = ftPhraseArray[i].trim().split("↭");
                
					if(stringBuilder[0].length > 2){

						var cslass = "autoquery"; //yellow
						if(stringBuilder[1].startsWith("\"USER:")){ //user blue
							cslass = "autoqueryUser";
						}

						let stringA = "<span class=\""+cslass+"\" contentEditable=\"false\" id="+stringBuilder[1]+">"+stringBuilder[0]+"</span>";
						let stringB = "<span hidden contentEditable=\"false\">↭"+stringBuilder[1]+"</span>";
						currentStringStartLength = stringBuilder[0].length
						ftPhraseArray[i] = stringA+stringB;
					}

				}else if(ftPhraseArray[i].includes("\"")){

				}

				if(ftPhraseArray[i]== "<"){ //notice the open <span> ends i+n iterations, not neccesarily next loop
					if(i<ftPhraseArray.length-1){
						if(ftPhraseArray[i+1] == ">"){
							addError("Insert phrase in <>")
						}
					}
					ftPhraseArray[i] = ftPhraseArray[i].replace(/</g, '&lt;');
					ftPhraseArray[i] = "<span class=\"ftBoldItem\" >"+ftPhraseArray[i]+"</span>";
					inBLock=true;

				}else if(ftPhraseArray[i]== ">"){
					ftPhraseArray[i] = ftPhraseArray[i].replace(/>/g, '&gt;');
					ftPhraseArray[i] = "<span class=\"ftBoldItem\" >"+ftPhraseArray[i]+"</span>";
					inBLock=false;

				}else if(ftPhraseArray[i]== "["){
					let newSubdex = totalSubStringLength
					let k = i;
					let isEmpty = false;
					let hasTo = false;
					let hasEndDate = false
					let needsSpaceAfterTo = false

					let endEarly = false;
					for(k; k < ftPhraseArray.length && !endEarly; k++){
						let check = k - i;
						switch(check){
							case 1:
								if(ftPhraseArray[k] == "]"){
									isEmpty = true;
									endEarly = true;
									addError("Insert phrase in [ ] at "+newSubdex)
								}else if(ftPhraseArray[k] == " "){
									ftPhraseArray[k] = '<span class=\"errorColor\"> </span>'
									addError("Remove red highlights")
								}else if(!(/^[0-9•\*]+$/.test(ftPhraseArray[k]) || ftPhraseArray[k].toLowerCase() == "now")){
									//addError("Incorrect date format at "+newSubdex)
								}
							break;
							case 2:
								if(!(/^[\s]+$/.test(ftPhraseArray[k]))){
									addError("Needs space at "+newSubdex)
								}
							break;
							case 3:
								if(ftPhraseArray[k].toUpperCase() !== "TO"){
									addError("insert phrase 'TO' between [ ] at "+newSubdex)
								}else{
									hasTo = true
								}
							break;
							case 4:
								if(!(/^[\s]+$/.test(ftPhraseArray[k]))){
									addError("Needs space at "+newSubdex)
									needsSpaceAfterTo = true;
								}
							break;
							case 5:
								if(!(/^[\d•*]+$/.test(ftPhraseArray[k]) || ftPhraseArray[k].toLowerCase == "now")){
									//addError("Incorrect date format at "+newSubdex)
								}else if(ftPhraseArray[k] == "]"){
									addError("end Date phrase in [ ] at "+newSubdex)
									endEarly = true;
								}else if(ftPhraseArray[k] == " " && hasTo && hasEndDate && needsSpaceAfterTo){ //hello

									ftPhraseArray[k] = '<span class=\"errorColor\"> </span>'
									addError("Remove red highlights")
								}
							break;
							case 6:
								if(ftPhraseArray[k] != "]"){ //hello
									ftPhraseArray[k] = '<span class=\"errorColor\">'+ftPhraseArray[k]+'</span>'
									addError("Expected ']' at "+newSubdex)

								}
						}
						newSubdex+=ftPhraseArray[k].length;
					}
					if(k!=6 && !isEmpty && endEarly){
						addError("Makem brackets '[<date> TO <date>]' format at "+ (newSubdex-1))
					}

					ftPhraseArray[i] = "<span class=\"ftBoldItem\" >"+ftPhraseArray[i]+"</span>";
					inPoBLock=true;
				}else if(ftPhraseArray[i]== "]"){
					ftPhraseArray[i] = "<span class=\"ftBoldItem\" >"+ftPhraseArray[i]+"</span>";
					inPoBLock=false;

				}else if(ftPhraseArray[i]== "("){
					if(i<ftPhraseArray.length-1){
						if(ftPhraseArray[i+1] == ")"){
							addError("Insert phrase in ()")
						}
					}
					ftPhraseArray[i] = "<span class=\"ftBoldItem\" >"+ftPhraseArray[i]+"</span>";
					inPoBLock=true;

				}else if(ftPhraseArray[i]== ")"){
					ftPhraseArray[i] = "<span class=\"ftBoldItem\" >"+ftPhraseArray[i]+"</span>";
					inPoBLock=false;
				}

				// if(ftPhraseArray[i].includes("-")){
				// 	ftPhraseArray[i] = ftPhraseArray[i].replaceAll("-","•")
				// }
				
				if(ftPhraseArray[i].toLowerCase() == "and"){ 
					ftPhraseArray[i] = ANDString;
				}else if(ftPhraseArray[i].toLowerCase() == "or"){
					ftPhraseArray[i] = ORString;
				}else if(ftPhraseArray[i].toLowerCase() == "not"){
					ftPhraseArray[i] = NOTString;
				}else if(inPoBLock){
					if(ftPhraseArray[i].toLowerCase() == "now"){
						ftPhraseArray[i] = nowString;
					}
					if(ftPhraseArray[i].toLowerCase() == "to"){
						ftPhraseArray[i] = toString;
					}
				}

				if(ftPhraseArray[i].includes("?")){
					ftPhraseArray[i] = ftPhraseArray[i].replaceAll("?", questString);
				}
				if(ftPhraseArray[i].includes("*")){
					ftPhraseArray[i] = ftPhraseArray[i].replaceAll("*", starString);
				}
				if(ftPhraseArray[i].includes("%")){
					ftPhraseArray[i] = ftPhraseArray[i].replaceAll("%", percString);
				}

				let test = ftPhraseArray[i]
				if(/^\d+[A-Z]$/i.test(test) && inBLock){

					ftPhraseArray[i]= "<span class=\"ftNumberLetter\">"+ftPhraseArray[i]+"</span>"

				}else if(/^[\d•]+$/.test(ftPhraseArray[i].toUpperCase())){
					
					if(ftPhraseArray[i].match(/^\d{8}$/g)){
						var year = ftPhraseArray[i].slice(0,4);
						var month = ftPhraseArray[i].slice(4,6);
						var day = ftPhraseArray[i].slice(6,8);

						if(month <= 12 && day <=31 ){
							ftPhraseArray[i] = year+'&bull;'+month+'&bull;'+day;
							currentStringStartLength+=2;

							if(totalSubStringLength+4 <= caretLocation){
								caretLocation++
							}
							if(totalSubStringLength+4+2+2+1 <= caretLocation){
								caretLocation++
							}
						}
					}

					ftPhraseArray[i]= "<span class=\"blueClass\">"+ftPhraseArray[i]+"</span>"


				}else if(/^\^[1-9]+$/.test(ftPhraseArray[i].toUpperCase())){
					ftPhraseArray[i]= "<span class=\"greenClass\">"+ftPhraseArray[i]+"</span>"
				}
				
				//auto complete
				if(ftPhraseArray[i].endsWith(":")){
					ftPhraseArray[i]= "<span class=\"fieldquery\">"+ftPhraseArray[i]+"</span>"
					let noTerm = true;
					for(let k = i; k< i+2; k++){
						if(/[\s]*/.test(ftPhraseArray[k])){
							noTerm = false
							break;
						}
					}
					if(noTerm){
						addError("need term after field at "+caretLocation)
					}

				}else if(ftPhraseArray[i].includes("_")){
					ftPhraseArray[i] = ftPhraseArray[i].replaceAll("_", underScoreString);
				}

				totalSubStringLength+=currentStringStartLength;
			}

			//set html string
			for(let i = 0; i < ftPhraseArray.length; i++){
				ftSearchHtml+=ftPhraseArray[i]
			}


		}else{
			ftSearchHtml = ftPhraseArray;
		}

		return ftSearchHtml
	}

	function combineWithPhraseBefore(ftPhraseArray, newPhrase){
		ftPhraseArray[ftPhraseArray.length-1] += newPhrase;
	}

    function handleKeyCode(ftSearchString){ //checked

		var location = caretLocation-1
		var changedChar = ftSearchString.charAt(location)

		if(changedChar.length > 1){
			throw 'not a char';
		}

		if(!/[a-z]/ig.test(changedChar)){
			switch(changedChar){
				case '{':
					ftSearchString = ftSearchString.slice(0, location+1) + "}" + ftSearchString.slice(location+1);
				break;

				case '(':
					ftSearchString = ftSearchString.slice(0, location+1) + ")" + ftSearchString.slice(location+1);
				break;

				case '<':
					ftSearchString = ftSearchString.slice(0, location+1) + ">" + ftSearchString.slice(location+1);
				break;

				case '[':
					ftSearchString = ftSearchString.slice(0, location+1) + "]" + ftSearchString.slice(location+1);
				break;

				case '"':
					ftSearchString = ftSearchString.slice(0, location+1) + '"' + ftSearchString.slice(location+1);
				break;

				case '?':
					let spaceCheck = ftSearchString.charCodeAt(location-1);
					if(location == 0 || spaceCheck == 32 || spaceCheck == 160 || spaceCheck == 40){
						handleOntologies("");
                        setAutoCompletePopup(true);
					}
				break;
			}
		}

		return ftSearchString;
	}

	function trimCaretFocusedTerm(ftPhraseArray){ // almost checked

		var focusedPhrase = "";
		if(Array.isArray(ftPhraseArray)){

			var subStrLengthCounter = 0;
			for(var i = 0; i <ftPhraseArray.length; i++){

				subStrLengthCounter+= ftPhraseArray[i].length;
				if(subStrLengthCounter >= caretLocation){
					var testString = ftPhraseArray[i].replaceAll("\"", "");
					if(
						testString.length >= 2 &&
						testString.toLowerCase() !== "and" &&
						testString.toLowerCase() !== "not" &&
						testString.toLowerCase() !== "now" &&
						testString.toLowerCase() !== "to" &&
						testString.toLowerCase() !== "or" &&
						!testString.includes("↭") &&
						!testString.startsWith("\^") &&
						!testString.endsWith(":") &&
						!testString.endsWith(",") &&
						!/^[0-9\-\•]*$/.test(testString) &&
						!(/^[0-9]+[DW]{1}$/).test(testString) &&
						!(/^[\s]*$/).test(testString)

					){
						focusedPhrase = testString;
						break;
					}else{
						break;
					}
				}
			}

		}

		return focusedPhrase;
	}

	function handleOntologies(selectionText){
		
		if(selectionText ==""){
			setFocusedTerm("")
		}else {

			var ftCharArray = [...selectionText];
			var ftPhraseArray = reasemblePhrases(ftCharArray);
			setFocusedTerm(trimCaretFocusedTerm(ftPhraseArray));
		}		
	}


	const insertOntology = async (data) => { //used for autocomplete popup

		removeAutoComplete();

		let ftPhraseArray = reasemblePhrases(node.current.textContent);

		let strLengthCounter = 0;
		for(let i =0 ; i < ftPhraseArray.length; i++){
			strLengthCounter+= ftPhraseArray[i].length;

			if(strLengthCounter >= termLocation){
				ftPhraseArray[i] = "\""+data.term+"\"↭\""+data.id+"\"";
				caretLocation = termLocation+1 + data.term //for quotes


				if(i==0 ||  ftPhraseArray[i-1] != " "){
					ftPhraseArray.splice(i,0,' ')
					caretLocation++;
					i++;
				}

				if(i >= ftPhraseArray.length-1 || ftPhraseArray[i+1] != " "){
					ftPhraseArray.splice(i+1, 0,' ');
					caretLocation++;
					i++;
				}


				break;
			}
		}

		setSearchedTerm("");
		setFocusedTerm("");

		let text = reasembleString(ftPhraseArray)
		closeAllPopups();
		setOntologySuggestionPopup(false)
		handleChangePart2(text, false);
	}

	const selectAutoComplete = (term) => { //used for autocomplete popup

		removeAutoComplete();

		let selectionStart = 0;
		let ftPhraseArray = reasemblePhrases(node.current.textContent);

		let strLengthCounter = 0;
		for(let i =0 ; i < ftPhraseArray.length; i++){
			strLengthCounter+= ftPhraseArray[i].length;

			if(strLengthCounter >= termLocation && (ftPhraseArray[i] == "?" || ftPhraseArray[i].endsWith(":"))){
				selectionStart= strLengthCounter;
				ftPhraseArray[i] = term+":";
				caretLocation = strLengthCounter+ftPhraseArray[i].length
				break;
			}
		}

		let text = reasembleString(ftPhraseArray)
		closeAllPopups();
		setFocusedTerm("");
		handleChangePart2(text, false);
	}

	function insertAnds(ftPhraseArray){ //prolly 99%
		if(Array.isArray(ftPhraseArray) ){
			if(ftPhraseArray.length <= 2){
				return ftPhraseArray
			}else{

				var inPoBLock = false //i (index) is between [Brackets] or <Clamps>
				var StringLengthCounter = ftPhraseArray[0].length;
				for(let i = 1; i < ftPhraseArray.length-1; i++){ //add ands
					
					StringLengthCounter+=ftPhraseArray[i].length;

					if(ftPhraseArray[i] == "<" || ftPhraseArray[i] == "[" || ftPhraseArray[i-1] == "<" || ftPhraseArray[i-1] == "["){
						inPoBLock = true;
					}else if(ftPhraseArray[i] == ">" || ftPhraseArray[i] == "]"){
						inPoBLock = false
						continue;
					}

					if(!inPoBLock){
					if(ftPhraseArray[i].length ==1 && /\s/g.test(ftPhraseArray[i])){
					if(ftPhraseArray[i-1].charAt(ftPhraseArray[i-1].length-1)!="\:"){
					if(!ftPhraseArray[i-1].endsWith("AND")){
					if(ftPhraseArray[i].toLowerCase() !== "and" && ftPhraseArray[i+1].toLowerCase() !== "and" && ftPhraseArray[i-1].toLowerCase() !== "and"){
					if(ftPhraseArray[i].toLowerCase() !== "or" && !"or".includes(ftPhraseArray[i+1].toLowerCase()) && ftPhraseArray[i-1].toLowerCase() !== "or"){
					if(ftPhraseArray[i].toLowerCase() !== "not" && !"not".includes(ftPhraseArray[i+1].toLowerCase()) && ftPhraseArray[i-1].toLowerCase() !== "not") {
					if(ftPhraseArray[i+1].toLowerCase() !== ")"&& ftPhraseArray[i-1].toLowerCase() !== ")" && ftPhraseArray[i-1].toLowerCase() !== "(" && ftPhraseArray[i+1].toLowerCase() !== "(") {
					if(ftPhraseArray[i-1].toLowerCase() !== "]" && ftPhraseArray[i-1].toLowerCase() !== "[" && ftPhraseArray[i+1].toLowerCase() !== "[") {
					if(ftPhraseArray[i-1].toLowerCase() !== ">" && ftPhraseArray[i-1].toLowerCase() !== "<" && ftPhraseArray[i+1].toLowerCase() !== "<") {

							
							ftPhraseArray = [
								...ftPhraseArray.slice(0, i),
								" ",
								"AND",
								...ftPhraseArray.slice(i, ftPhraseArray.length)
							];
							if(caretLocation >= StringLengthCounter){
								caretLocation+=4;
							}

					}}}}}}}}}}
				}
			}
		}else{
			return ftPhraseArray;
		}
		return ftPhraseArray;
    }


	function handleStackedFields(ftPhraseArray){
		var newFTString = "";
		if(Array.isArray(ftPhraseArray) && ftPhraseArray.length > 0){
			for(let i = 0; i < ftPhraseArray.length-1; i++){

				if(ftPhraseArray[i].endsWith(":")){ //finds a field

					let splitField = ftPhraseArray[i].split(',')

					if(Array.isArray(splitField) && splitField.length > 1){

						var openBPCount= 0;
						var subDexStart = i;
						var subDexEnd = i+1;

						for(var k = i+1; k<ftPhraseArray.length; k++){ //find next term
							if(ftPhraseArray[k] === " "){
								continue;
							}else{
								if(ftPhraseArray[k]== "[" || ftPhraseArray[k]== "(" || ftPhraseArray[k]== "<"){
									openBPCount++;
								}else if(ftPhraseArray[k]== "]" || ftPhraseArray[k]== ")" || ftPhraseArray[k]== ">"){
									openBPCount--;
								}
								
								if(openBPCount == 0 && ( ftPhraseArray[k]!=" " && ftPhraseArray[k]!="")){
									subDexEnd = k;
									break;
								}
							}
						}
						var foo = arrayToFTString(ftPhraseArray, subDexStart+1, subDexEnd);
						var bar = reasemblePhrases(foo);
						var rightTerm = handleStackedFields(bar);
						
						for(var k = 0 ; k < splitField.length; k++){
							if(k == 0){
								splitField[k]= "("+splitField[k]
							}
							if(!splitField[k].endsWith(":")){
								splitField[k]+=":";
							}
							if(!splitField[k].endsWith(" ")){
								splitField[k]+=" ";
							}

							splitField[k]+=rightTerm.trim(); //adds in term
							
							if(k < splitField.length-1){
								splitField[k]+= " OR "
							}else{
								splitField[k]+= ")"
							}
							
						}

						var newStackString = reasembleString(splitField)

						ftPhraseArray = [
							...ftPhraseArray.slice(0, subDexStart),
							newStackString,
							...ftPhraseArray.slice(subDexEnd+1, ftPhraseArray.length)
						];
						i = subDexEnd+1
					}

				}
			}
		}
		return reasembleString(ftPhraseArray);
	}

	function arrayToFTString(ftArray, startDex, endDex){
		var str = "";
		for(let i = startDex; i <= endDex; i++){
			str+= ftArray[i]
		}
		return str;
	}

	function handleKeyWordsInContext(queryString){
		var phraseArray = reasemblePhrases(queryString);
		var retRay = [];
		let denier = -3 // arbitrary
		let inBrack = false

		for(let i = 0; i< phraseArray.length; i++ ){

			if(phraseArray[i].length > 1){

				if(/[A-Za-z0-9]*/.test(phraseArray[i])){
					if(phraseArray[i].includes("↭")){
						let check = phraseArray[i].trim().split("↭")[0].replaceAll("\"","")
						retRay.push(check);
					}else if(
						phraseArray[i] != "AND" &&
						phraseArray[i] != "OR" &&
						phraseArray[i] != "NOT" &&
						phraseArray[i] != "TO" &&
						phraseArray[i].toLowerCase() != "now" &&
						phraseArray[i] != "" &&
						!(/^[\s]*$/).test(phraseArray[i]) &&
						!phraseArray[i].startsWith("^") &&
						!phraseArray[i].endsWith(":") &&
						!/^[0-9\-\•]*$/.test(phraseArray[i]) &&
						!(inBrack && ((/^[0-9]+[DW]{1}$/).test(phraseArray[i])))
					){

						if(!retRay.includes(phraseArray[i]) && i-denier>2){
							retRay.push(phraseArray[i])
						}
					}
				}
			}

			if(phraseArray[i].toLowerCase() == "gq_doc:"){
				denier = i;
			}

			if(phraseArray[i].toLowerCase() == "<"){
				inBrack = true
			}
			if(phraseArray[i].toLowerCase() == ">"){
				inBrack = false
			}
		}

		keyWordsInContext(retRay);
	}

	function fixQuery(string){
		let newString = "";
		newString = string.replace(/\s/g, " ");
		newString = newString.replace(/&nbsp;/g, " ");
		newString = newString.replace(/\u2022/g, "");
		newString = newString.replace(/•/g, "");
		newString = newString.replace(/\&/g, "\&");
		return newString+" ";
	}


	function checkSyntaxSuppressed(text){ 
 
		text = fixQuery(text); 

		try{
			if(setLuceneQueryValue){
				let luceVal = luceneQueryParser.parse(text)
				setLuceneQueryValue(JSON.stringify(luceVal));
			}
			return true;
		}catch(error){
			//addError("Failed to Pass");
			console.log(error)
			setLuceneQueryValue("");
			return false;
		}

	}

	const handleClick = (event) => {
		removeAutoComplete();
		closeAllPopups();
		setOntologySuggestionPopup(false);
		caretLocation = CaretPositioning.saveSelection(event.currentTarget).start;

		let ftSearchText = node.current.textContent;
		movePopup()

		if (event.target.className != "") {
			let splitClass = [];
			let eventId = event.target.id ? event.target.id : '';

			if (typeof event.target.className != "object") {
				splitClass = (event.target.classList && event.target.classList.length > 0) ? event.target.className.split(' ') : [];
			}
			if (splitClass.length > 0 && (splitClass[0] == "autoquery" || splitClass[0] == "autoqueryUser")) {
				
				GetOntologyList(eventId)
				var test;
			}else{
				handleOntologies(ftSearchText)
				setOntologyTermData({});
			}
			if (splitClass.length > 0 && (splitClass[0] == "fieldquery")) {
				setAutoCompletePopup(true);
			}
		}
		

	}


	async function GetOntologyList(TermId){
		if (TermId) {
			setShowMiniLoader(true);
			const ontologySynonyms = await fullTextService.getACSynonyms(
			 	history,
			 	TermId
			);
			//const ontologySynonyms = Test.AC;
			setShowMiniLoader(false);
			if (ontologySynonyms && ontologySynonyms.response_status == 0) {
				if (ontologySynonyms.response_content) {
					setOntologyTermData(ontologySynonyms.response_content);
					setOntologyEditMode(false);
					setSearchLock(false)
					setOntologyEditorTermData(ontologySynonyms.response_content.synonyms ? ontologySynonyms.response_content.synonyms.sort(
						function(a,b, key = ontologySynonyms.response_content.term){
							if(a === key) return -1
							if(b === key) return 1
							return a.localeCompare(b);
						}
					) : []);
					setFocusedTerm("")
					setSearchedTerm("")
					setOntologyEditorPopup(true);
					setOntologySuggestionPopup(false);
				}
			}
		}
	}

	function handleSpecialKeys(event){

		caretLocation = CaretPositioning.saveSelection(event.currentTarget).start;

		let changeType = event.code
		let keyName = event.key
		//let ftSearchString = document.getElementById(ftNodeId).textContent;

		if( changeType == "Enter" || changeType == "Tab" ){

			event.preventDefault();
		}


		if(changeType == "Tab"){

			caretLocation+=insertAutoComplete();
			let ftSearchString = document.getElementById(ftNodeId).textContent;
			handleChangePart2(ftSearchString, false);
			handleOntologies("");
			closeAllPopups();

		}

		if(changeType == 'Comma' && keyName == ','){
			
			event.preventDefault();
			caretLocation+=insertAutoCompleteComma();
			let ftSearchString = document.getElementById(ftNodeId).textContent;
			handleChangePart2(ftSearchString, false);
			handleOntologies("");
			closeAllPopups();

		}

		if(changeType.includes("Arrow")){
			removeAutoComplete();
			closeAllPopups();
			let ftSearchString = document.getElementById(ftNodeId).textContent;
			handleChangePart2(ftSearchString, false);
		}

		if( changeType == "Escape"){
			setOntologySuggestionPopup(false);
			closeAllPopups();
		}

	}

	function removeAutoComplete(){ //checked

		let aNode = document.getElementById(autoNodeId);
		if(aNode){
			aNode.outerHTML = "";
		}
		return;
	};

	function addAutoComplete(ftPhraseArray, autoTerm){ 

		let aTermRay = autoTerm.split(',');
		let finalATerm = aTermRay[aTermRay.length-1];
		
		let keywords = Constant.ftKeywords;
		for(var i = 0; i < keywords.length; i++ ){

			if(keywords[i].startsWith(finalATerm) && finalATerm != keywords[i]){ //same as: if const autoterm is substring of keyword
				

				let splitDex = getIndexOfFtArray(ftPhraseArray, caretLocation)
				
				if(splitDex !=-1){
					let stringholder = autoNodeId
					if(!stringholder){
						stringholder = "autoNode"+Date.now()
						setAutoNodeId(stringholder)
					}

					var autofillstring = //=>
					'<span id="'+stringholder+'" class=autoComplete contenteditable="false">'+keywords[i].substring(finalATerm.length)+'</span>'


					ftPhraseArray = [
						...ftPhraseArray.slice(0, splitDex+1),
						autofillstring,
						...ftPhraseArray.slice(splitDex+1, ftPhraseArray.length)
					];
				}
				return ftPhraseArray;
					
			}
		}
		return ftPhraseArray;		
	};

	function insertAutoComplete(){
		let nodex = document.getElementById(autoNodeId)
		if(nodex){
			nodex.outerHTML = nodex.textContent;
		}

		if(nodex)
		{return nodex.textContent.length;}
			
		return 0;
	};

	function insertAutoCompleteComma(){
		let nodex = document.getElementById(autoNodeId)
		if(nodex){
			nodex.outerHTML =  nodex.textContent.replaceAll(':',',');
		}

		if(nodex)
		{return nodex.textContent.length;}
			
		return 0;
	};

	// function setCurrentCursorPosition(caretDestination) { //places courser (treat as blackbox)
	// 	if (caretDestination >= 0) {

	// 		if(caretDestination > node.current.textContent.length){
	// 			console.log("FutherLong")
	// 			caretDestination=node.current.textContent.length
	// 		}

	// 		var selection = window.getSelection();
	
	// 		let range = createRange(node.current, { count: caretDestination });
	// 		node.current.focus();
	// 		if (range) {
	// 			range.collapse(false);
	// 			selection.removeAllRanges();
	// 			selection.addRange(range);
	// 		}
	// 	}
	// };

	function movePopup(){

		setTermLocation(caretLocation)
		var nodeBoundes = node.current.getBoundingClientRect()

		const sel = document.getSelection()
		const range = sel.getRangeAt(0)	
		const caretnode = range.startContainer;
		const offset = range.startOffset

		let rect, r2
		r2 = document.createRange()
		r2.setStart(caretnode, offset)
		r2.setEnd(caretnode, (offset))
		rect = r2.getBoundingClientRect()
		    
		setLeftPosition(rect.left - 5 - nodeBoundes.left);
		setTopPosition(rect.top + 25 - nodeBoundes.top);
	}

	const saveOntologyEditorData = async (wordList) => {
		// setOpenACSynPopup(false);
		let userId = userInfo && userInfo.current_user.gq_user_id;

		let postData = {
			'user_id': userId,
			'term_id': ontologyTermData?.id,
			'word_list': wordList,
		}

		const updateACSynonyms = await fullTextService.updateACSynonyms(
			postData,
			history
		);

		//updateACSynonyms.response_content.id = 'ff';
		if(updateACSynonyms.response_content != undefined){
			insertOntology(updateACSynonyms.response_content);//todo)
		}
	}

	function getIndexOfFtArray(ftArray, caretLocation){

		let returnDex = -1;

		let strCounter = 0;
		for(let i= 0 ; i <= ftArray.length; i++){
			strCounter+=ftArray[i].length
			if(strCounter >= caretLocation){
				returnDex = i
				break;
			}

		}

		return returnDex;

	}

    function closeAllPopups(){

		setOntologyEditorPopup(false);
		setOntologyEditMode(false);
		setFieldStack([]);
        setAutoCompletePopup(false);
        setErrorPopup(false);
    }

	function getFtId(){
		let stringholder = ftNodeId
		if(!stringholder){
			stringholder = "ftNode"+Date.now()
			setFtNodeId(stringholder)
			setNodeId(stringholder)
		}
		return stringholder
	}

	function useOutsideAlerter(ref) {
		useEffect(() => {
		  /**
		   * Alert if clicked on outside of element
		   */
		  function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				
				setOntologyEditorPopup(false) 
				setOntologySuggestionPopup(false)
				setOntologyEditMode(false)
				setMouseOverSearchBox(false)			
			}else{
				setMouseOverSearchBox(true)			
			}
		  }
		  // Bind the event listener
		  document.addEventListener("mousedown", handleClickOutside);
		  return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		  };
		}, [ref]);
	}

	const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
	

	function openOntologyOptions(commands){
		setFocusedTerm("");
		setSearchLock(true)
		setOntologyEditorTermData([focusedTerm]);
		setOntologyTermData({});
		setOntologySuggestionPopup(false)
		setOntologyEditorPopup(true) 
		setOntologyEditMode(true)
	}

    return (
    
        <div width={"315px"} 
				ref={wrapperRef}
			>
			<div className={"ftContainer"}>
				<div className={"ftTabsContainer"}>
					<div className={"ftontologySpacer"}>
						<span 
							className="font14 ftZFix"
							onClick={()=> setErrorPopup(!errorPopup)}
						>
						{errorList.length==0 && 
							<span>
								 0 Errors
							</span>
						}
						{errorList.length!=0 && 
							<span className="ftErrorText" >
								{errorList.length+" "}
								ERROR
								{errorList.length>1&&
								<span>S</span>
								}
							</span>
						}
						</span>
						{toggle &&
						<span className={"ftTinyWhiteBox"}>
							<span className={"ftOutterBevel"}></span>
						</span>}
						<span onClick={()=> openOntologyModal()} className={"ftOntologies appLink"}>Ontologies</span>
					</div>
				</div>
				<div
					id="ftPopup"
					className={((topPosition>=0 && leftPosition>=0)&&(ontologySuggestionPopup || ontologyEditorPopup || autoCompletePopup) && mouseOverSearchBox)? "ftPositionRelative": "ftDisplayNone"}
					style={{ top: topPosition, left: leftPosition }}
				>
					<div className="tinyBlueUpArrow"></div>
					<div className={"ftPopupBackground"}>
						<div>
						{showMiniLoader &&
							<MiniSpinner></MiniSpinner>
						}
						</div>
						<div>
							<FtOntologyOptions
								setSearchLock={(bool) => setSearchLock(bool)}
								TermData={ontologyEditorTermData}
								topPosition={topPosition}
								leftPosition={leftPosition} 
								openOrClosed={ontologyEditorPopup} 
								saveOntologyList ={(termData)=>saveOntologyEditorData(termData)}
								editMode={ontologyEditMode}
								setEditMode = {(bool) => setOntologyEditMode(bool)}
								closeSelf={()=>closeAllPopups()}
							/>
						</div>
						<div>
							<FtOntologySuggestion
								topPosition={topPosition}
								leftPosition={leftPosition}
								userInfo={userInfo}
								ontologyString={getOntologyString()}
								FocusedTerm = {focusedTerm}
								searchedTerm = {searchedTerm}
								setSearchedTerm = {(term)=>setSearchedTerm(term)}
								selectSearchTerm = {(data)=>insertOntology(data)}
								setResCount = {(count)=>setResCount(count)}
								openOrClosed={ontologySuggestionPopup}
								checkOpen={()=>checkOntologyListPopup()}
								openOntologyOptions={()=>openOntologyOptions()}
							/>
						</div>

						<div>
							<FtAutoComplete
								topPosition = {topPosition}
								leftPosition = {leftPosition}
								callBackFunction = {(term)=>selectAutoComplete(term)}
								openOrClosed = {autoCompletePopup}
								fieldStack = {fieldStack}
							/>
						</div>
					</div>
				</div>
				<div
					id={getFtId()+""}
					ref={node}
					spellCheck="false"
					contentEditable="true"
					onInput = {(event) => handleChange(event)}
					onKeyDown = {(event) => handleSpecialKeys(event)}
					onClick = {(event) => handleClick(event)}
					className={(toggle? "ftEnhancement " : "ftEnhancementDark ")+(! errorList.length> 0? "" : "queryError")}
				>
				</div>
			</div>
			<div>
				{errorPopup && errorList.length > 0 &&
					<div className="ftErrorPopup">
						<span className={"ftErrorTinyWhiteBox"}>
							<span className={"ftOutterErrorBevel"}></span>
						</span>
					<div className="ftScrolly ftErrorListContainer">
					{errorList.map((obj, index) => {return (
						<li key={index} className="errorListItem">
							<span>
								&bull;&nbsp;
							</span>
							<span>
								{obj}
							</span>


							{index < errorList.length-1 &&
								<hr></hr>
							}
						</li>
					);})}	
					</div>
					</div>
				}
				</div>
			<div>
				<FtOntologyModal
					show={ontologySelectionPopup}
					closeModal={() => closeOntologyModal()}
					saveOntologyState = {(e)=>{setOntologyState(e)}}
				/>
			</div>
        </div>       
    );
}

export default FullTextSearchBox;
